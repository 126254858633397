import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectQuotes, setSelectedQuotesToShare } from "../../Quotes.slice";
import styled from "styled-components/macro";
import { Skeleton } from "@mui/material";
import { Checkbox } from "@mui/material";
import { useGetQuoteQuery } from "../../../rider/services/rider";
import useGetAmount from "../../../../custom-hooks/useGetAmount";
import useGetInWords from "../../../../custom-hooks/useGetInWords";
import { selectICLogo } from "../../../frontendBoot/frontendBoot.slice";

function ShareQuotesMobile({
  allProductIds,
  selected,
  setSelected,
  currentSection,
}) {
  const quotes = useSelector(selectQuotes);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (selected) {
      dispatch(setSelectedQuotesToShare(selected));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div>
      {Object.keys(quotes).map(company_alias => (
        <Card
          company_alias={company_alias}
          selected={selected}
          setSelected={setSelected}
        />
      ))}
    </div>
  );
}

export default ShareQuotesMobile;

const Card = ({ company_alias, selected, setSelected, different }) => {
  const { isFilterUpdating, ...filters } = useSelector(state => state.filters);

  const { data, isLoading, isFetching } = useGetQuoteQuery({
    company_alias,
    filters,
  });

  const quotes = data ? data.data : null;
  if (isLoading || isFetching) return <Skeleton />;
  if (!quotes) return null;

  return (
    <>
      {quotes.map(quote => {
        return (
          <IndCard
            quoteData={quote}
            selected={selected}
            setSelected={setSelected}
            different={different}
          />
        );
      })}
    </>
  );
};

const IndCard = ({ quoteData, selected, setSelected, different }) => {
  //
  const logo = useSelector(selectICLogo(quoteData.company_alias));
  const product = quoteData.product_name;
  const [planName] = product.split("-").map(item => item && item.trim());
  const benefit = quoteData.maturity_benefit;
  const benefitWord = useGetInWords(benefit);
  const getMoneyAs = useSelector(state => state.filters.getMoneyAs);
  const investForValue = useSelector(state => state.filters.investFor) || "10";
  const returnsAfterValue = quoteData?.guaranteed_lumpsum_at || "20";
  const incomeForValue = useSelector(state => state.filters.incomeFor) || "20";
  const totalAmount = useGetAmount();
  const totalAmountWords = useGetInWords(totalAmount);

  const incomeBenefitInWords = useGetInWords(quoteData?.income_benfit);
  const income_benefit = quoteData?.income_benfit;
  const deathBenefit = useGetInWords(quoteData.death_benefit);
  const productID = quoteData.product.id;

  const totalAmountToBeReceived =
    quoteData.income_benfit * incomeForValue +
    benefit +
    quoteData.additional_lumpsum +
    quoteData.additional_rop;

  const totalAmountInvested = quoteData.premium * investForValue;
  const returns =
    Math.round((totalAmountToBeReceived / totalAmountInvested) * 100) / 100;

  return (
    <CardWrapper>
      <Upper>
        <img className="company_logo" src={logo} alt="company_logo" />
        <span
          css={`
            text-align: left;
          `}
        >
          <span
            css={`
              font-weight: 600;
              font-size: 0.9em;
            `}
          >
            {planName}
          </span>
          <br />
          <span
            css={`
              font-size: 0.8em;
              color: #00a364;
            `}
          >
            Death Benefit : {deathBenefit}
          </span>
        </span>
        <Checkbox
          className="checkbox"
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 18,
            },
          }}
          value={productID}
          checked={selected && selected.includes(productID)}
          onClick={e => {
            if (e.target.checked) {
              setSelected([...selected, productID]);
            } else {
              setSelected(selected.filter(item => item !== productID));
            }
          }}
        />
      </Upper>
      <HR />

      <Lower>
        <Section>
          <span className="section_name">You Invest</span>
          <span className="section_value">{totalAmountWords}</span>
          <span className="section_desc">Per Year</span>
          <span className="section_desc">For {investForValue} Years</span>
        </Section>
        <Section>
          <span className="section_name">You Receive</span>
          <span className="section_value">
            {income_benefit ? incomeBenefitInWords : benefitWord}
          </span>
          <span className="section_desc">
            <span className="w-100 d-flex justify-content-start">
              {getMoneyAs === "I" || getMoneyAs === "B" ? "Per Year" : "After"}
            </span>
            <span className="w-100 d-flex justify-content-start   ">
              {getMoneyAs === "I" || getMoneyAs === "B"
                ? `For ${incomeForValue} Years`
                : `${returnsAfterValue} Years`}
            </span>
          </span>
        </Section>
        <Section>
          <span className="section_name">Returns</span>
          <span className="section_value">{returns} times</span>
        </Section>
      </Lower>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  padding: 10px 1em;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 16%);
  margin-bottom: 10px;
`;
const Upper = styled.div`
  display: flex;
  gap: 1em;
  justify-content: flex-start;
  align-items: center;
  & .checkbox {
    margin-left: auto;
  }
  & .company_logo {
    height: 25px;
  }
`;
const HR = styled.div`
  background: #e6e6e6;
  margin: 4px 0;
  height: 0.5px;
`;
const Lower = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 2px;
  & .section_name {
    font-size: 9px;
  }
  & .section_value {
    font-weight: 600;
  }
  & .section_desc {
    font-size: 9px;
  }
`;
