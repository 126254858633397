import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
//
import {
  generateBI,
  getProposalApi,
  proposalUpdate,
  saveProposalApi,
  submitProposalApi,
} from "./serviceApi";
import { download } from "../ComparePage/components/DownloadCompareButton";
const proposal = createSlice({
  name: "proposalForm",

  initialState: {
    proposalData: {},
    formList: [],
    activeFormIndex: 0,
    currentICFormCount: 0,
    position: 0,
    isBi: false,
    isDocumentUploadLoading: false,
    distributor_summary: null,
    redirection_triggered: false,
    doc_required: [],
    doc_length: 0,
    proposal_reference_id: "",
    isLoading: false,
  },
  reducers: {
    setProposalData: (state, { payload }) => {
      state.proposalData = { ...state.proposalData, ...payload };
    },
    clearData: (state, { payload }) => {
      state.proposalData = {};
    },
    setPosition: (state, { payload }) => {
      state.position = payload;
    },
    setIsBi: (state, { payload }) => {
      state.isBi = payload;
    },
    setFormNamesList: (state, { payload }) => {
      state.formList = payload;
    },
    setCurrentICFormCount: (state, { payload }) => {
      state.currentICFormCount = payload;
    },
    setActiveFormIndex: (state, { payload }) => {
      state.activeFormIndex = payload;
    },
    clearProposalSubmitLoading: state => {
      state.isProposalSubmitLoading = false;
    },
    clearProposalLoading: state => {
      state.isProposalLoading = false;
    },
    setRedirectData: (state, { payload }) => {
      state.redirectData = { ...state.redirectData, ...payload };
    },
    initProposalSubmitLoading: state => {
      state.isProposalSubmitLoading = true;
    },
    initDocumentUploadLoading: state => {
      state.isDocumentUploadLoading = true;
    },
    clearDocumentUploadLoading: state => {
      state.isDocumentUploadLoading = false;
    },
    setDistributorSummary: (state, action) => {
      state.distributor_summary = action.payload;
    },
    //used in rider page
    setRedirectionTriggered: (state, action) => {
      state.redirection_triggered = action.payload;
    },
    setDocRequired: (state, { payload }) => {
      state.doc_required = payload;
    },
    setDocLength: (state, { payload }) => {
      state.doc_length = payload;
    },
    setProposalRefId: (state, { payload }) => {
      state.proposal_reference_id = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});
export const {
  setRedirectData,
  setFormNamesList,
  setCurrentICFormCount,
  setActiveFormIndex,
  setPosition,
  setProposalData,
  setIsBi,
  clearData,
  initProposalSubmitLoading,
  clearProposalLoading,
  clearProposalSubmitLoading,
  initDocumentUploadLoading,
  clearDocumentUploadLoading,
  setDistributorSummary,
  setRedirectionTriggered,
  setIsLoading,
  setDocLength,
  setDocRequired,
  setProposalRefId,
} = proposal.actions;
export const getProposalData = () => {
  return async (dispatch, getState) => {
    try {
      const { data } = await getProposalApi();
      dispatch(setProposalData(data.data));
      if (data.data instanceof Array) {
        dispatch(clearData());
      }
      dispatch(setPosition(Object.keys(data.data).length));
      dispatch(clearProposalLoading());
    } catch (err) {
      // toast.error("Something went wrong", {
      //   hideProgressBar: true,
      //   toastId: "failed1",
      //   autoClose: 3000,
      //   position: "bottom-center",
      //   theme: "colored",
      // });
      toast.error(`${err.response.data.message}`, {
        hideProgressBar: true,
        toastId: "failed1",
        autoClose: 3000,
        position: "top-center",
        theme: "colored",
      });
    }
  };
};

export const clearProposalData = () => {
  return async (dispatch, getState) => {
    try {
      const { data } = await getProposalApi();
      dispatch(clearData(data));
      dispatch(setPosition(0));
    } catch (err) {
      toast.error(`${err.response.data.message}`, {
        hideProgressBar: true,
        toastId: "failed1",
        autoClose: 3000,
        position: "top-center",
        theme: "colored",
      });
    }
  };
};

export const saveProposalData = (req, next = () => {}, isBI, company_alias) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setProposalData(req));
      // eslint-disable-next-line no-unused-vars
      const { data } = await saveProposalApi(req);
      let url = "";
      if (isBI) {
        const {
          data: { data },
        } = await generateBI({ company_alias });
        dispatch(setIsBi(false));
        url = data[0].bi_url;
      }

      next();
      return url;
    } catch (err) {
      toast.error(`${err.response.data.message}`, {
        hideProgressBar: true,
        toastId: "failed1",
        autoClose: 3000,
        position: "top-center",
        theme: "colored",
      });
    }
  };
};
export const submitProposalData = (req, next = () => {}) => {
  return async dispatch => {
    try {
      const { data } = await submitProposalApi(req);
      dispatch(setRedirectData(data.data[0]));
      next(data.data[0]);
      dispatch(clearProposalSubmitLoading());
    } catch (err) {
      dispatch(clearProposalSubmitLoading());
      toast.error(`${err.response.data.message}`, {
        hideProgressBar: true,
        toastId: "failed1",
        autoClose: 3000,
        position: "top-center",
        theme: "colored",
      });
    }
  };
};
export const savePosition = data => {
  return async dispatch => {
    try {
      dispatch(setPosition(data));
    } catch (err) {
      console.error(err);
    }
  };
};
export const updateProposal = data => {
  return async dispatch => {
    try {
      dispatch(setIsLoading(true));
      const res = await proposalUpdate(data);
      dispatch(setDocRequired(res?.data?.proposal?.document_list));
      dispatch(setDocLength(res?.data?.proposal?.document_list?.length));
      dispatch(setProposalRefId(res?.data?.proposal?.proposal_reference_id));
      if (res?.data?.proposal?.extras?.document_list) {
        dispatch(setIsLoading(false));
        res?.data?.proposal?.extras?.document_list?.proposal_pdf &&
          download(res?.data?.proposal?.extras?.document_list?.proposal_pdf);
      }
      return res;
    } catch (error) {
      console.error(error);
    }
  };
};
export const proposal_status = {
  PROPOSAL_SUBMITTED: "proposal_submitted",
  POLICY_ISSUED: "policy_issued",
  POLICY_REJECTED: "policy_rejected",
  POLICY_CANCELLED: "policy_cancelled",
  DOCUMENT_PENDING: "document_pending",
  DOCUMENT_SUBMITTED: "document_submitted",
  MEDICAL_PENDING: "medical_pending",
  MEDICAL_SUBMITTED: "medical_submitted",
  OTP_PENDING: "otp_pending",
  OTP_VERIFIED: "otp_verified",
};
export default proposal.reducer;
