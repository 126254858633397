import { Button } from "@mui/material";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";

const CreateCustomCompare = () => {
  const navigate = useNavigate();
  const enquiryId = useUrlQuery("enquiryId");

  return (
    <CreateCustomCompareWrapper
      onClick={() => {
        navigate(`/new-compare?enquiryId=${enquiryId}`);
      }}
    >
      Create Custom Compare
    </CreateCustomCompareWrapper>
  );
};

const CreateCustomCompareWrapper = styled(Button)`
  margin: 1rem 0;
  text-transform: none !important;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px !important;
  border: solid 1px var(--border-light) !important;
  background: #fff;
  border-radius: 5px;
`;
export default CreateCustomCompare;
