import CompareHeader from "./components/CompareHeader";
import CompareFilters from "./components/CompareFilters";
import CompareTable from "./components/CompareTable";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import * as mq from "../../styles/mediaQueries";
import NavbarMobile from "../QuotesPage/components/Mobile/components/NavbarMobile";
import Navbar from "../../components/navbar/Navbar-Component";
import CompareHeaderMobile from "./components/CompareHeaderMobile";
import CompareFiltersMobile from "./components/CompareFiltersMobile";
import { useState } from "react";
import CompareEmptyCard from "./components/CompareEmptyCard";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Box } from "@mui/material";

const NewCompare = () => {
  const isMobileOrTablet = useMediaQuery(`${mq.mdForHook}`);
  const [openFilter, setOpenFilter] = useState(false);
  const { quotesList } = useSelector(state => state?.newCompare);
  return (
    <div>
      {isMobileOrTablet ? <NavbarMobile /> : <Navbar />}
      {isMobileOrTablet ? <CompareHeaderMobile /> : <CompareHeader />}
      <div className="container">
        {isMobileOrTablet ? (
          <CompareFiltersMobile
            isFirst={quotesList?.length ? true : false}
            show={openFilter}
            setShow={setOpenFilter}
          />
        ) : (
          <CompareFilters />
        )}
        {isMobileOrTablet && (quotesList?.length ? true : false) && (
          <AddNewButtonWrapper onClick={() => setOpenFilter(true)}>
            Add New
          </AddNewButtonWrapper>
        )}
        {(quotesList?.length ? true : false) ? (
          <CompareTable isMobileOrTablet={isMobileOrTablet} />
        ) : isMobileOrTablet ? (
          <CompareEmptyCard setOpenFilter={setOpenFilter} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default NewCompare;

const AddNewButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.25rem;
  background: #f2faff;
  border-radius: 7px;
  border: 1px dashed #367cff;
  color: #367cff;
  font-size: 0.75rem;
  cursor: pointer;
  height: 2rem;
`;
