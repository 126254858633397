import React, { useState } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { getAge, getAnnualIncome, getGender, tobaccoHabit } from "./helper";
import EditDetails from "./EditDetails";
import * as mq from "../../../../../styles/mediaQueries";
import { useMediaQuery } from "@mui/material";
import EditDetailsModalMobile from "../../Mobile/components/Modal/EditDetailsModalMobile";
import { useLocation } from "react-router";
import { clearQuotes } from "../../../../ComparePage/ComparePlanPopupSlice";

const PersonalDetails = () => {
  const { data } = useSelector(state => state.inputForm);
  const [showEdit, setShowEdit] = useState(false);
  const matches = useMediaQuery(`${mq.mdForHook}`);
  const location = useLocation();
  const dispatch = useDispatch();
  const isNewCompare = location.pathname.includes("new-compare");
  return (
    <>
      <DetailsWrapper
        css={`
          ${mq.sm} {
            margin-top: auto;
            margin-bottom: auto;
          }'
          
        `}
      >
        <DetailsText
          css={`
            ${mq.sm} {
              font-size: 13px;
            }
            ${mq.md} {
              font-size: 13px;
            }
          `}
        >
          {getGender(data.gender)} |{" "}
        </DetailsText>
        <DetailsText
          css={`
            ${mq.sm} {
              font-size: 10px;
            }
            ${mq.md} {
              font-size: 0.75rem;
            }
          `}
        >
          {getAge(data.dob)} Years |{" "}
        </DetailsText>
        <DetailsText
          css={`
            ${mq.sm} {
              display: none;
            }
            ${mq.md} {
              display: none;
            }
          `}
        >
          {tobaccoHabit(data.tobacco_habit)} |{" "}
        </DetailsText>
        <DetailsText
          css={`
            ${mq.sm} {
              display: none;
            }
            ${mq.md} {
              display: none;
            }
          `}
        >
          {getAnnualIncome(data.annual_income)} |
        </DetailsText>
        {location.pathname !== "/shortlisted-plans" && (
          <>
            <EditDetailText
              onClick={() => {
                dispatch(clearQuotes());
                setShowEdit(true);
              }}
            >
              {isNewCompare ? "View" : "Edit"}
            </EditDetailText>
          </>
        )}
      </DetailsWrapper>

      {matches ? (
        <EditDetailsModalMobile show={showEdit} setShow={setShowEdit} />
      ) : (
        <EditDetails show={showEdit} setShow={setShowEdit} />
      )}
    </>
  );
};

const DetailsWrapper = styled.div`
  width: 52%;
  display: flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  ${mq.md} {
    border: none;
    margin-top: 0px;
    padding: 0px;
    display: flex;
    height: fit-content;
    gap: 6px;
    width: fit-content;
    :hover {
      color: var(--primary-color);
      border: none;
    }
  }
  ${mq.xs} {
    gap: 2px;
  }
`;

// const iconCss = `
// font-size:15px;
// margin-left:10px;
// cursor:pointer;
// `;

const DetailsText = styled.p`
  margin-bottom: 0px;
  font-size: 12px;
  color: black;
  font-weight: 500;
  ${mq.md} {
    width: fit-content;
    font-size: 12px;
  }
  ${mq.sm} {
    width: fit-content;
    font-size: 10px;
  }
  ${mq.xs} {
    font-size: 9.5px;
  }
`;
const EditDetailText = styled.p`
  width: fit-content;
  margin-bottom: 0px;
  font-size: 13px;

  color: var(--primary-color);
  cursor: pointer;
  ${mq.xs} {
    font-size: 12px;
  }
  ${mq.sm} {
    font-size: 13px;
  }
`;

export default PersonalDetails;
