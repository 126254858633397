import React, { useRef, useState } from "react";

// import { BasicDetails } from "./ComparePageFilters";
import { md } from "../../../styles/mediaQueries";
import styled from "styled-components/macro";
import { useLocation } from "react-router-dom";
import ShareCompareQuotes from "../share/ShareCompareQuotes";
import { FaDownload } from "react-icons/fa";
import { IoFunnelOutline } from "react-icons/io5";

import { clearDownloading, initDownloading } from "../Compare.slice";
import { download } from "../components/DownloadCompareButton";
import { downloadCompare } from "../../Landing/serviceApi";
import { BiShareAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";
import DotLoader from "../../../components/Loaders/DotLoader/DotLoader";
const CompareFiltersMobile = () => {
  const [comparePage, setComparePage] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === "/Compare") setComparePage(true);
    else setComparePage(false);
  }, [location]);

  return (
    <div>
      <ComparePageTabBar isComparePage={comparePage} />
    </div>
  );
};

export default CompareFiltersMobile;
function TabBar({ children, currentActive, ...props }) {
  return (
    <TabBarWrap {...props}>
      <TabItems>{children}</TabItems>
    </TabBarWrap>
  );
}

function useTabBar() {
  const { hash } = useLocation();

  const checkIsActive = idToCheck => hash === `#${idToCheck}`;

  const value = { checkIsActive };

  return value;
}

TabBar.Item = function Item({ id, icon, active, children, ...props }) {
  if (!id) throw new Error("missing a required argument `id` for TabItem");
  const { checkIsActive } = useTabBar();

  const isActive = checkIsActive(id);

  const enquiryId = useUrlQuery("enquiryId");

  return (
    <TabItemWrap {...props}>
      <StyledLink to={`?enquiryId=${enquiryId}#${id}`}>
        {isActive && <Indicator />}

        <div
          css={`
            font-size: 1.397em;
            color: var(--tertiary-text);
          `}
        >
          {icon}
        </div>
        <div
          css={`
            font-size: 0.721em;
            font-family: Inter;
            font-weight: ${isActive ? "600" : "400"};
            user-select: none;
            color: ${isActive
              ? "var(--primary-color)"
              : "var(--tertiary-text)"};
          `}
        >
          {children}
        </div>
      </StyledLink>
    </TabItemWrap>
  );
};

TabBar.ComparePage = function ComparePageItem({
  id,
  icon,
  active,
  children,
  ...props
}) {
  const enquiryId = useUrlQuery("enquiryId");

  return (
    <TabItemWrap {...props}>
      <StyledLink to={`?enquiryId=${enquiryId}#${id}`}>
        {active && <Indicator />}

        <div
          css={`
            font-size: 1.397em;
            color: var(--tertiary-text);
          `}
        >
          {icon}
        </div>
        <div
          css={`
            font-size: 0.721em;
            font-weight: ${active ? "600" : "400"};
            user-select: none;
            color: ${active ? "var(--primary-color)" : "var(--tertiary-text)"};
          `}
        >
          {children}
        </div>
      </StyledLink>
    </TabItemWrap>
  );
};

export function ComparePageTabBar({ isComparePage, ...props }) {
  const [show, setShow] = useState(false);
  // const [active, setActive] = useState(false);
  // const { checkIsActive } = useTabBar();
  const { isDownloading } = useSelector(state => state.compare);
  const { addedRiders } = useSelector(state => state.quotes);
  const { showDifference, selectedFeatures } = useSelector(
    state => state.compare,
  );
  const ref = useRef();
  const dispatch = useDispatch();

  return (
    <div ref={ref} {...props}>
      <TabBar>
        <TabBar.ComparePage id="filter" icon={<IoFunnelOutline />}>
          Filters
        </TabBar.ComparePage>
        <TabBar.ComparePage
          id="share"
          active={show}
          onClick={e => setShow(!show)}
          icon={<BiShareAlt />}
        >
          Share
        </TabBar.ComparePage>
        <TabBar.ComparePage
          active={isDownloading}
          id="download"
          onClick={() => {
            dispatch(initDownloading());
            downloadCompare({
              selected_features: selectedFeatures,
              show_difference: showDifference,
              selected_riders: addedRiders,
            })
              .then(({ data }) => {
                download(data.data);
                dispatch(clearDownloading());
              })
              .catch(err => {
                console.error(err);
                dispatch(clearDownloading());
              });
          }}
          icon={<FaDownload />}
        >
          {isDownloading ? <DotLoader text={"Downloading"} /> : "Download"}
        </TabBar.ComparePage>
      </TabBar>

      <ShareCompareQuotes showPopup={show} setShowPopup={setShow} />
      {/* <ShareMobile show={show} /> */}
    </div>
  );
}
const TabBarWrap = styled.nav`
  border-radius: 10px 10px 0 0;
  box-shadow: rgb(0 0 0 / 16%) 0px 5px 15px 0px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  display: none;
  z-index: 100;
  ${md} {
    display: block;
  }
`;

const TabItems = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
`;

const TabItemWrap = styled.li`
  flex: 1;
  list-style: none;
  text-align: center;
  padding-top: 0.37em;
  padding-bottom: 0.19em;
  color: var(--tertiary-text);
  position: relative;
`;

const Indicator = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  height: 0.37em;
  width: 2em;
  border-radius: 1em;
  background-color: var(--primary-color);
`;

const StyledLink = styled(Link)`
  color: var(--tertiary-text);
  text-decoration: none;
`;
