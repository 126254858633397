import React, { useEffect, useState } from "react";
import "styled-components/macro";
import { yupResolver } from "@hookform/resolvers/yup";
import FormWithTitle from "../../FormWithTitle/FormWithTitle";
import * as mq from "../../../../../styles/mediaQueries";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { form4 } from "../../../validations";
import { lowerCase } from "../../../validations";
import { useDispatch, useSelector } from "react-redux";
import { saveData, saveOtpData, setData } from "../../../input.slice";
import {
  allowOnlyNumbers,
  allowOnlyUniqueAlphabetsNoSpace,
  toCapitalize,
} from "../../../../InputUtils/onInput";
import TextInput from "../../TextInput";
import useUrlQuery from "../../../../../custom-hooks/useUrlQuery";
import OTPFormGeneral from "./OTPPopup";
import { toast } from "react-toastify";
import { useGetSellerInfoQuery } from "../../../../rider/services/rider";
function Proffession({ setCurrent }) {
  const { investmentinputpage, investmentsettings } = useSelector(
    state => state.frontendBoot.frontendData,
  );
  const USERTYPE = useUrlQuery("USERTYPE");
  const USERID = useUrlQuery("USERID");
  const token = useUrlQuery("token");
  const { data: seller_data } = useGetSellerInfoQuery(
    {
      token: token,
    },
    {
      skip: !token,
    },
  );
  const params = {
    USERTYPE,
    USERID,
    token,
    other_req_data: seller_data?.other_req_data,
  };
  // eslint-disable-next-line no-unused-vars
  const { otpData, otpResponse } = useSelector(state => state.inputForm);
  const [otp, setOtp] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  useEffect(() => {
    if (otpResponse?.status === true && otpResponse.message === "Verified") {
      setOtpVerified(true);
      toast.success("OTP Verified");
    }
  }, [otpResponse?.status, otpResponse.message]);
  const dispatch = useDispatch();
  const getLocalData = () => {
    const field_3 = localStorage.getItem("Investment Personal Details");
    if (field_3) return JSON.parse(field_3);
    else
      return {
        name: "",
        email: "",
        mobile: "",
      };
  };

  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(form4),
    mode: "onChange",
    defaultValues: getLocalData(),
  });

  const navigate = useNavigate();
  const onSubmit = data => {
    dispatch(setData({ ...data, params }));
    localStorage.setItem(
      "Investment Personal Details",
      JSON.stringify({
        name: data.name,
        email: data.email,
        mobile: data.mobile,
      }),
    );

    dispatch(
      saveData(enquiryId => {
        navigate(`/quote?enquiryId=${enquiryId}`);
      }),
    );
  };
  const mobileNumber = watch("mobile");
  const email = watch("email");
  return (
    <div>
      <FormWithTitle
        onSubmit={handleSubmit(onSubmit)}
        current={3}
        title={
          investmentinputpage?.investmentinputpage3
            ? investmentinputpage?.investmentinputpage3
            : "<h1>Tell Us About Personal Details</h1>"
        }
        // title={"Tell Us About Personal Details"}
      >
        <ProfessionForm>
          <ProfessionInputWrapper>
            <div className="w-100 ">
              <TextInput
                name="name"
                label="Full Name*"
                error={errors.name}
                errorIcon={errors.name ? true : false}
                register={register}
                clearErrors={clearErrors}
                // capitalize={true}
                capitalize="true"
                inputProps={{
                  maxLength: 30,
                }}
                onInput={toCapitalize && allowOnlyUniqueAlphabetsNoSpace}
                helperText={errors.name && errors.name.message}
              />
            </div>

            <div className="w-100 mt-4">
              <TextInput
                name="email"
                label="Email*"
                error={errors.email}
                clearErrors={clearErrors}
                errorIcon={errors.email ? true : false}
                helperText={errors.email && errors.email.message}
                register={register}
                inputProps={{
                  maxLength: 50,
                }}
                onChange={e => {
                  e.target.value = e.target.value.replace(" ", "");
                  setValue("email", e.target.value);
                  lowerCase(e);
                  clearErrors("email");
                  trigger("email");
                }}
              />
            </div>

            <div className="w-100 mt-4">
              <TextInput
                name="mobile"
                label="Mobile*"
                register={register}
                onInput={allowOnlyNumbers}
                error={errors.mobile}
                errorIcon={errors.mobile ? true : false}
                helperText={errors.mobile && errors.mobile.message}
                inputProps={{
                  maxLength: 10,
                  inputMode: "numeric",
                }}
              />
            </div>
          </ProfessionInputWrapper>
          <ProfessionButton>
            <div className="w-100 d-flex justify-content-center">
              <div className="d-flex justify-content-center w-100">
                <button
                  type="button"
                  className="d-flex justify-content-center w-100 flex-1 align-items-center
                 m-1 border-0 py-3 btn text-black fw-bolder"
                  onClick={() => {
                    setCurrent(prev => prev - 1);
                  }}
                  css={`
                    background-color: rgba(215, 228, 251, 0.695);
                  `}
                >
                  <span> Go Back</span>
                </button>
                {investmentsettings?.otp_journey === "Yes" &&
                otpVerified === false ? (
                  <button
                    className="d-flex justify-content-center w-100 flex-1 align-items-center
                 m-1 border-0  bg-primary btn text-white"
                    onClick={e => {
                      e.preventDefault();
                      trigger("name");
                      trigger("email");
                      trigger("mobile");
                      if (
                        email &&
                        !errors?.email &&
                        mobileNumber &&
                        !errors?.mobile
                      ) {
                        dispatch(
                          saveOtpData({
                            mobile: mobileNumber,
                            email: email,
                            section: "term",
                          }),
                        );
                        setOtp(true);
                      }
                    }}
                  >
                    View Quotes
                  </button>
                ) : otpResponse?.status === true &&
                  otpResponse.message === "Verified" ? (
                  <button
                    type="submit"
                    className="d-flex justify-content-center w-100 flex-1 align-items-center
                 m-1 border-0  bg-primary btn text-white"
                  >
                    View Quotes
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="d-flex justify-content-center w-100 flex-1 align-items-center
                 m-1 border-0  bg-primary btn text-white"
                  >
                    View Quotes
                  </button>
                )}
              </div>
            </div>
            <OTPFormGeneral
              mobileNumber={mobileNumber}
              email={email}
              setOtp={setOtp}
              otp={otp}
            />
          </ProfessionButton>
        </ProfessionForm>
      </FormWithTitle>
    </div>
  );
}

export default Proffession;

const ProfessionForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${"" /* margin-bottom: 20px; */}
  ${mq.sm} {
    width: 100%;
  }
`;

// const ProffessionTitle = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: center;
//   width: 100%;
//   height: 3.5em;

//   ${mq.sm} {
//     width: 100%;
//   }
// `;

const ProfessionInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  ${mq.sm} {
    width: 100%;
  }
`;

const ProfessionButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5em;

  ${mq.sm} {
    width: 100%;
  }
`;
export const Button = styled.button`
  width: 100%;
  background: var(--primary-color);
  padding: 20px 0;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  font-family: Inter;
  ${mq.md} {
    width: 100%;
    font-size: 14px;
  }
  ${mq.sm} {
    width: 100%;
    font-size: 14px;
  }
`;
