import React, { useState, useEffect } from "react";

import { Modal } from "react-bootstrap";
import styled from "styled-components/macro";
import CancelCircleIcon from "../../../../../assets/svg/CancelCircleIcon";
import TextInput from "./TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { modifyDetails } from "../../../../Landing/validations";
import { InputAdornment } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { setData } from "../../../../Landing/input.slice";
import {
  allowOnlyNumbers,
  allowOnlyUniqueAlphabetsNoSpace,
  isDigits,
  toCapitalize,
} from "../../../../InputUtils/onInput";
import Selectt from "../../../../ProposalPage/components/Selectt";

import { changeFilter } from "../../../../Filters/filterSlice";

import DateComponent from "../../../../ProposalPage/components/DateComponent";
import { useLocation } from "react-router-dom";

const EditDetails = ({ show, setShow }) => {
  const location = useLocation();
  const isNewCompare = location.pathname.includes("new-compare");
  const handleClose = () => {
    setValue("name", data.name);
    setValue("email", data.email);
    setValue("mobile", data.mobile);
    setValue("gender", data.gender);
    setValue("tobacco_habit", data.tobacco_habit);
    setValue("annual_income", parseInt(data.annual_income));
    setValue("risk_profile", data?.suitability?.risk_profile);
    setValue("life_stage", data?.suitability?.life_stage);
    setValue(
      "financial_and_familyGoal",
      data?.suitability?.financial_and_familyGoal,
    );
    setValue("pincode", data.pincode);
    setShow(false);
  };
  const { data } = useSelector(state => state.inputForm);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(modifyDetails({ minAge: 18, maxAge: 85 })),
    defaultValues: {
      tobacco_habit: data.tobacco_habit,
    },
  });

  // eslint-disable-next-line no-unused-vars
  const [age, setAge] = useState(
    moment().diff(moment(data.dob, "YYYY-MM-DD"), "years"),
  );
  useEffect(() => {
    reset({
      dob: data?.dob?.split("-").reverse().join("/"),
    });
    setAge(moment().diff(moment(data.dob, "YYYY-MM-DD"), "years"));
  }, [reset, data]);

  useEffect(() => {
    setValue("name", data.name);
    setValue("email", data.email);
    setValue("mobile", data.mobile);
    setValue("gender", data.gender);
    setValue("tobacco_habit", data.tobacco_habit);
    setValue("annual_income", parseInt(data.annual_income));
    setValue("risk_profile", data?.suitability?.risk_profile);
    setValue("life_stage", data?.suitability?.life_stage);
    setValue(
      "financial_and_familyGoal",
      data?.suitability?.financial_and_familyGoal,
    );
    setValue("pincode", String(data.pincode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const name = watch("name");
  const email = watch("email");
  const mobile = watch("mobile");
  const pincode = watch("pincode");
  const gender = watch("gender");
  const tobacco_habit = watch("tobacco_habit");
  const termAnnualIncome = watch("annual_income");
  const dateofbirth = watch("dob");
  const riskprofile = watch("risk_profile");
  const lifestage = watch("life_stage");
  const financialandfamilyGoal = watch("financial_and_familyGoal");
  const dispatch = useDispatch();
  const checkDataChanged = values => {
    for (let key in values) {
      if (key === "dob") {
        if (
          moment(values[key], "DD/MM/YYYY").format("YYYY-MM-DD") !== data[key]
        ) {
          return true;
        }
      } else if (values[key] !== data[key]) {
        return true;
      }
    }
  };
  const onSubmit = values => {
    if (checkDataChanged(values)) {
      dispatch(
        setData({
          ...values,
          name: values.name.replace(/^(.)|\s+(.)/g, c => c.toUpperCase()),
          dob: values.dob.split("/").reverse().join("-"),
          suitability: {
            risk_profile: values.risk_profile,
            life_stage: values.life_stage,
            financial_and_familyGoal: values.financial_and_familyGoal,
          },
        }),
      );
      dispatch(
        changeFilter({
          ...values,
          name: values.name.replace(/^(.)|\s+(.)/g, c => c.toUpperCase()),
          dob: values.dob.split("/").reverse().join("-"),
          suitability: {
            risk_profile: values.risk_profile,
            life_stage: values.life_stage,
            financial_and_familyGoal: values.financial_and_familyGoal,
          },
        }),
      );
    }
    setShow(false);
  };

  function calcFunction() {
    return value => {
      var with2Decimals = value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      return with2Decimals;
    };
  }
  // const date = watch("dob");
  const calc = calcFunction();
  const getInWords = getInWordsFunction();

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        css={`
          .modal-dialog {
            max-width: 900px;
            margin-top: 0;
          }
        `}
      >
        <Modal.Header
          css={`
            padding: 0px;
          `}
        >
          <CancelCircleIcon
            width="30"
            onClick={() => handleClose()}
            style={{
              position: "absolute",
              right: "-10px",
              top: "-10px",
            }}
          />
          <Heading>{isNewCompare ? "View Details" : "Edit Details"}</Heading>
        </Modal.Header>
        <Modal.Body
          css={`
            padding: 1rem 0 0;
            display: flex;
            flex-wrap: wrap;
          `}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ProfessionInputWrapper>
              <Conatainer>
                <TextInput
                  label="Full Name*"
                  capitalize
                  onInput={toCapitalize && allowOnlyUniqueAlphabetsNoSpace}
                  // onInput={allowOnlyUniqueAlphabetsNoSpace}
                  onChange={e => {
                    setValue("name", e.target.value);
                    trigger("name");
                  }}
                  error={errors.name && errors.name.message}
                  // error={errors.name && errors?.name.message}
                  value={name}
                  inputProps={{
                    minLength: 2,
                    maxLength: 30,
                    readOnly: isNewCompare,
                  }}
                />
              </Conatainer>
              <Conatainer>
                <TextInput
                  label="Email ID"
                  size="large"
                  onChange={e => {
                    setValue("email", e.target.value);
                    trigger("email");
                  }}
                  error={errors.email && errors.email.message}
                  value={email}
                  inputProps={{
                    minLength: 2,
                    maxLength: 50,
                    inputMode: "email",
                    readOnly: isNewCompare,
                  }}
                />
              </Conatainer>
              <Conatainer>
                <TextInput
                  label="Mobile No.*"
                  onChange={evt => {
                    evt.target.value = evt.target.value.replace(/[^0-9]/g, "");
                    setValue("mobile", evt.target.value);
                    trigger("mobile");
                  }}
                  value={mobile}
                  error={errors.mobile && errors.mobile.message}
                  onInput={evt => {
                    if (evt.target.value && !isDigits(evt.target.value)) return;
                  }}
                  inputProps={{
                    maxLength: 10,
                    inputMode: "numeric",
                    readOnly: isNewCompare,
                  }}
                />
              </Conatainer>
            </ProfessionInputWrapper>
            <ProfessionInputWrapper>
              <Conatainer>
                {/* <DateField
                  disableFuture
                  name="dob"
                   label="Date of Birth*"
                  value={dateofbirth}
                  handleChange={e => {
                    setValue("dob", e);
                    register("dob");
                  }}
                  error={errors?.dob && errors?.dob?.message}
                /> */}
                <DateComponent
                  name="dob"
                  disableFuture
                  label="Date of Birth*"
                  value={dateofbirth}
                  readOnly={isNewCompare}
                  handleChange={e => {
                    setValue("dob", e);
                    trigger("dob");
                  }}
                  error={errors?.dob && errors?.dob?.message}
                  ageWrapper
                />
              </Conatainer>
              <Conatainer>
                <Selectt
                  options={[
                    { code: "M", display_name: "Male" },
                    { code: "F", display_name: "Female" },
                  ]}
                  name="gender"
                  label="Gender*"
                  noSearch
                  error={errors.gender}
                  errorIcon={errors.gender ? true : false}
                  handleChange={e => {
                    setValue("gender", e);
                    trigger("gender");
                  }}
                  register={register}
                  placeholder="Select Your Gender"
                  selectedValue={gender}
                  value={gender}
                  readOnly={isNewCompare}
                />
              </Conatainer>
              <Conatainer>
                <Selectt
                  name="tobacco_habit"
                  label="Tobacco Habit"
                  readOnly={isNewCompare}
                  noSearch
                  options={[
                    { code: "S", display_name: "Smoker" },
                    { code: "NS", display_name: "Non Smoker" },
                  ]}
                  handleChange={e => {
                    setValue("tobacco_habit", e);
                    trigger("tobacco_habit");
                  }}
                  register={register}
                  selectedValue={tobacco_habit}
                />
              </Conatainer>
            </ProfessionInputWrapper>
            <ProfessionInputWrapper>
              <Conatainer>
                <TextInput
                  name="annual_income"
                  label="Annual Income*"
                  placeholder="Enter Your Annual Income"
                  register={register}
                  error={errors.annual_income}
                  helperText={
                    errors.annual_income && errors?.annual_income.message
                  }
                  onInput={allowOnlyNumbers}
                  noSearch
                  value={termAnnualIncome}
                  onChange={e => {
                    setValue("annual_income", e.target.value);
                    trigger("annual_income");
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {errors.annual_income &&
                        errors.annual_income.message &&
                        !termAnnualIncome ? (
                          <span className="fs-5">{/* <MdError /> */}</span>
                        ) : (
                          <span
                            css={`
                              color: var(--primary-color);
                            `}
                          >
                            {getInWords(termAnnualIncome)}
                          </span>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: 12,
                    inputMode: "numeric",
                    minLength: 6,
                    readOnly: isNewCompare,
                  }}
                />
              </Conatainer>
              <Conatainer>
                <Selectt
                  name="risk_profile"
                  label="Risk Profile*"
                  noSearch
                  readOnly={isNewCompare}
                  options={[
                    {
                      code: "Conservative",
                      display_name: "Conservative",
                    },
                    {
                      code: "Risk Averse",
                      display_name: "Risk Averse",
                    },
                    {
                      code: "Balance",
                      display_name: "Balance",
                    },
                    {
                      code: "Growth",
                      display_name: "Growth",
                    },
                    {
                      code: "Aggressive",
                      display_name: "Aggressive",
                    },
                  ]}
                  handleChange={e => {
                    setValue("risk_profile", e);
                    trigger("risk_profile");
                  }}
                  register={register}
                  selectedValue={riskprofile}
                />
              </Conatainer>
              <Conatainer>
                <Selectt
                  name="life_stage"
                  label="Life Stage*"
                  noSearch
                  readOnly={isNewCompare}
                  options={[
                    {
                      code: "Single",
                      display_name: "Single",
                    },
                    {
                      code: "Married",
                      display_name: "Married",
                    },
                    {
                      code: "Married with Young Children",
                      display_name: "Married with Young Children",
                    },
                    {
                      code: "Married with Older Children",
                      display_name: "Married with Older Children",
                    },
                    {
                      code: "Near Retirement",
                      display_name: "Near Retirement",
                    },
                    {
                      code: "Retirement",
                      display_name: "Retirement",
                    },
                    {
                      code: "Others",
                      display_name: "Others",
                    },
                  ]}
                  handleChange={e => {
                    setValue("life_stage", e);
                    trigger("life_stage");
                  }}
                  register={register}
                  selectedValue={lifestage}
                />
              </Conatainer>
            </ProfessionInputWrapper>
            <ProfessionInputWrapperLast>
              <Conatainer>
                <Selectt
                  name="financial_and_familyGoal"
                  label="Financial And Family Goal*"
                  readOnly={isNewCompare}
                  noSearch
                  options={[
                    {
                      code: "Saving and Investments",
                      display_name: "Saving and Investments",
                    },
                    {
                      code: "Regular Income",
                      display_name: "Regular Income",
                    },
                    {
                      code: "Retirement",
                      display_name: "Retirement",
                    },
                    {
                      code: "Child",
                      display_name: "Child",
                    },
                    {
                      code: "Protection",
                      display_name: "Protection",
                    },
                  ]}
                  handleChange={e => {
                    setValue("financial_and_familyGoal", e);
                    trigger("financial_and_familyGoal");
                  }}
                  register={register}
                  selectedValue={financialandfamilyGoal}
                />
              </Conatainer>
              <Conatainer>
                <TextInput
                  label="Pincode No.*"
                  onChange={evt => {
                    setValue("pincode", evt.target.value);
                    trigger("pincode");
                  }}
                  value={pincode}
                  error={errors.pincode && errors.pincode.message}
                  onInput={allowOnlyNumbers}
                  inputProps={{
                    maxLength: 10,
                    inputMode: "numeric",
                    readOnly: isNewCompare,
                  }}
                />
              </Conatainer>
            </ProfessionInputWrapperLast>
            {!isNewCompare && (
              <div
                css={`
                  width: 100%;
                `}
              >
                <div
                  css={`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                  `}
                  // className='btn btn-primary'
                >
                  <SubmitButton
                    type="submit"
                    // onClick={onDispatch}
                    css={`
                      border-radius: 5px;
                      background-color: var(--primary-color);
                      color: #fff;
                      padding: 8px 15px 8px 15px;
                      font-size: 14px;
                      border: none;
                    `}
                    // className='btn btn-primary'
                  >
                    Confirm
                  </SubmitButton>
                </div>
              </div>
            )}
          </Form>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </div>
  );
  function getInWordsFunction() {
    return value => {
      if (!value) return null;
      value = String(value);
      const length = value.length;
      if (length >= 8) {
        return `₹ ${calc(value / Math.pow(10, 7))} Crore`;
      } else if (length >= 6) {
        return `₹ ${calc(value / Math.pow(10, 5))} Lakhs`;
      } else if (length >= 4) {
        return `₹ ${calc(value / Math.pow(10, 3))} K`;
      } else return value;
    };
  }
};

export default EditDetails;

const SubmitButton = styled.button`
  text-align: center;
  margin: 3px 0 10px 0;
`;

const Heading = styled.div`
  text-align: center;
  background-color: #f1f3f6;
  font-family: Inter;
  font-weight: 600;
  padding: 20px 0;
  font-size: 20px;
  //   margin-bottom: 16px;
  width: 100%;
`;

const ProfessionInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 24px;
  width: 100%;
`;

const ProfessionInputWrapperLast = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 24px;
  width: 100%;
`;

const Conatainer = styled.div`
  display: inline-block;
  position: relative;
  width: 33.33%;
  padding: 0 10px;
`;

const Form = styled.form`
  padding: 0 3rem;
  display: flex;
  flex-wrap: wrap;
`;
