import React from "react";
import { useSelector } from "react-redux";
import * as mq from "../../../../styles/mediaQueries";
import styled from "styled-components/macro";

import useGetInvestmentData from "../../../../custom-hooks/useGetInvestmentData";
import useGetInWords from "../../../../custom-hooks/useGetInWords";
import { BiChevronsRight, BiChevronsLeft } from "react-icons/bi";
import useMediaQuery from "../../../../custom-hooks/useMediaQuery";

function MobileUIHeader({
  quoteData,
  currentSection,
  setCurrentSection,
  prevNum,
}) {
  const returnsAfter = quoteData?.guaranteed_lumpsum_at;
  const deathBenefit = useGetInWords(quoteData.death_benefit);
  const product = quoteData?.product_name ? quoteData?.product_name : "";
  const [planName] = product.split("-").map(item => item && item.trim());
  const { investedAmountInWords, frequency, investForValue } =
    useGetInvestmentData({
      quoteData,
    });

  const {
    frontendData: { investmentcompanies },
  } = useSelector(state => state.frontendBoot);

  const header_sections = [
    "Benefit TimeLine",
    "Plan Details",
    "Additional Benefits",
    "Add on Coverage",
    "About Company",
  ];
  const matches = useMediaQuery(`${mq.mdForHook}`);

  React.useEffect(() => {
    document
      .getElementById(`${currentSection}`)
      .scrollIntoView({ behavior: "smooth", block: "center" });
  }, [currentSection]);

  return (
    <>
      <div
        css={`
          display: flex;
          flex-wrap: wrap;
          background: #fff;
          align-items: center;
          width: 100%;
          padding: 5px 15px 0px 15px;
        `}
      >
        <img
          css={`
            height: 35px;
          `}
          src={investmentcompanies[quoteData.company_alias]?.logo}
          alt="Company Logo"
        />

        <div
          css={`
            display: block;
            margin-left: 12px;
            margin-top: 3px;
            font-weight: 600;
          `}
        >
          <div>
            <p
              css={`
                margin: 0px;
              `}
            >
              {planName}
            </p>
          </div>
          <div>
            <p
              css={`
                color: var(--primary-color);
                margin: 0px;
              `}
            >
              (Total Premium :₹{quoteData?.total_premium})
            </p>
          </div>
        </div>
      </div>
      <div
        css={`
          width: 100%;
          padding: 5px 15px 0px 15px;
        `}
      >
        <div
          css={`
            width: 100%;
            height: 4.5em;
            background: #fff;
            border-radius: 3px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px 1px;
            padding: 8px;
            display: flex;
            justify-content: space-around;
          `}
        >
          <div
            css={`
              display: flex;
              gap: 0.5em;
              flex-direction: column;
            `}
          >
            <span
              css={`
                display: flex;
                gap: 1em;
                justify-content: space-between;
              `}
            >
              <span
                css={`
                  color: #4d4d4f;
                  font-size: 10px;
                `}
              >
                Investment
              </span>{" "}
              <span
                css={`
                  font-weight: bold;
                  font-size: 11px;
                `}
              >
                {investedAmountInWords}/{frequency}
              </span>
            </span>

            <span
              css={`
                display: flex;
                gap: 1em;
                justify-content: space-between;
              `}
            >
              <span
                css={`
                  color: #4d4d4f;
                  font-size: 10px;
                `}
              >
                PPT
              </span>{" "}
              &nbsp;
              <span
                css={`
                  font-weight: bold;
                  font-size: 11px;
                `}
              >
                {investForValue} Years
              </span>
            </span>
          </div>
          <div
            css={`
              border: 0.5px solid grey;
            `}
          ></div>
          <div
            css={`
              display: flex;
              gap: 0.5em;
              flex-direction: column;
            `}
          >
            <span
              css={`
                display: flex;
                gap: 1em;
                justify-content: space-between;
              `}
            >
              <span
                css={`
                  color: #4d4d4f;
                  font-size: 10px;
                `}
              >
                Lumpsum After
              </span>{" "}
              <span
                css={`
                  font-weight: bold;
                  font-size: 11px;
                `}
              >
                {returnsAfter} Years
              </span>
            </span>
            <span
              css={`
                display: flex;
                gap: 1em;
              `}
            >
              <span
                css={`
                  color: #4d4d4f;
                  font-size: 10px;
                `}
              >
                Death Benefit
              </span>{" "}
              &nbsp;
              <span
                css={`
                  font-weight: bold;
                  font-size: 11px;
                `}
              >
                {deathBenefit}
              </span>
            </span>
          </div>
        </div>
      </div>
      <HeaderWrapper id="contain">
        <BiChevronsLeft
          size={28}
          onClick={e => {
            currentSection > 1 &&
              document.getElementById("contain").scrollBy({
                top: 0,
                left: -100,
                behavior: "smooth",
              });
          }}
          style={{
            display: currentSection === 1 ? "none" : "",
            position: "fixed",
            top: "124px",
            left: "0%",
            color: "var(--primary-color-dark)",
            backgroundColor: "var(--tertiary-shade)",
            zIndex: "100",
          }}
        />

        {header_sections.map((item, idx) => (
          <>
            <HeaderLabel
              id={idx + 1}
              key={item}
              active={idx + 1 === currentSection}
              onClick={() => {
                prevNum.current = currentSection;
                setCurrentSection(idx + 1);
              }}
            >
              {item}
            </HeaderLabel>
            {matches ? (
              <div
                style={{
                  display: "flex",
                  border: "0.1px solid gray",
                  margin: "10px 0",
                }}
              ></div>
            ) : (
              <></>
            )}
          </>
        ))}

        <BiChevronsRight
          size={28}
          onClick={e => {
            currentSection < 5 &&
              document.getElementById("contain").scrollBy({
                top: 0,
                left: 100,
                behavior: "smooth",
              });
          }}
          style={{
            display: currentSection === 5 ? "none" : "",
            position: "fixed",
            top: "124px",
            right: "0%",
            color: "var(--primary-color-dark)",
            backgroundColor: "var(--tertiary-shade)",
            zIndex: "100",
          }}
        />
      </HeaderWrapper>
    </>
  );
}

export const HeaderLabel = styled.div`
  display: inline-block;
  text-align: center;
  font-size: clamp(12px, 2vw, 16px);
  background-color: ${props =>
    props.active ? "var(--primary-color-dark)" : "#fff"};
  color: ${props => (props.active ? "#fff" : "var(--primary-text)")};

  ${mq.md} {
    background-color: var(--tertiary-shade);
    border-bottom: 3px solid
      ${props =>
        props.active ? "var(--primary-color-dark)" : "var(--tertiary-shade)"};
    color: ${props => (props.active ? "var(--primary-color-dark)" : "#43464a")};
    border-radius: 0;
    font-weight: bold;
    font-size: 11px;
    min-width: 35%;
    padding: 10px 0;
    margin: 0 5px;
  }
`;

export const HeaderWrapper = styled.div`
  border: 1px solid #e2e2e2;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 40px;
  position: relative;
  transition: all 0.3s ease;

  ${mq.md} {
    border-radius: 0px;
    background: var(--tertiary-shade);
    padding-right: 10px;
    overflow: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default MobileUIHeader;
