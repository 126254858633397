import React from "react";
import logo from "../../../assets/images/logo/logo.png";
import * as mq from "../../../styles/mediaQueries";

function LandingNavbar() {
  return (
    <div>
      <img src={logo} alt="" height={40} />
    </div>
  );
}

export default LandingNavbar;
