import React, { useState } from "react";
import styled from "styled-components/macro";
import useOutsideClickRef from "../../custom-hooks/useOutsideClickRef";
import { useEffect } from "react";
import { useRef } from "react";
import * as mq from "../../styles/mediaQueries";
const FilterSelect = ({
  offset,
  options = [],
  label,
  placeholder,
  selectedItem,
  customButton,
  customButtonText,
  customOnClick,
  onChange = () => {},
  onClick = () => {},
}) => {
  const [selected, setSelected] = useState(placeholder);
  const [show, setShow] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const [activeIndex, setActiveIndex] = useState(
    // eslint-disable-next-line eqeqeq
    options.filter(item => item.code == selectedItem) || 0,
  );

  useEffect(() => {
    if (options.length) {
      setFilteredOptions(options);
      // eslint-disable-next-line eqeqeq
      setActiveIndex(options.filter(item => item.code == selectedItem));
    }
  }, [options, selectedItem]);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    let x = options.filter(item => item.code == selectedItem);
    if (x[0]) {
      setSelected(x[0].display_name);
    } else setSelected(selectedItem);
  }, [selectedItem, options]);

  const ref = useRef();
  const itemRef = useRef();

  useEffect(() => {
    if (show) {
      setActiveIndex(
        filteredOptions.filter(item => {
          return item.display_name === selected;
        }) || 0,
      );
    }
  }, [show, filteredOptions, selected]);

  useEffect(() => {
    if (itemRef.current) {
      itemRef.current.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [activeIndex, show]);

  useOutsideClickRef(ref, () => {
    setShow(false);
  });

  return (
    <>
      <CustomWrapper ref={ref} onClick={onClick}>
        <TextInput
          onClick={() => {
            setShow(!show);
          }}
        >
          <div
            css={`
              display: flex;
              flex-direction: column;
              width: 80%;
            `}
          >
            <Label> {label}</Label>
            <div
              css={`
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <Value offset={offset}>{selected}</Value>
              <DropDownArrow show={show}>
                <i className="fas fa-chevron-down"></i>
              </DropDownArrow>
            </div>
          </div>
        </TextInput>
        <DropDownList show={show} autoFocus>
          {filteredOptions.map((item, index) => (
            <Item
              key={index}
              selected={index === activeIndex}
              ref={index === activeIndex ? itemRef : null}
              onClick={() => {
                onChange(item.code);
                setShow(false);
              }}
            >
              {item.display_name}
            </Item>
          ))}
          {customButton && (
            <div className="custom-button-wrapper">
              <button
                className="custom-button-button"
                onClick={() => {
                  customOnClick();
                  setShow(false);
                }}
              >
                {customButtonText || "Pay Once"}
              </button>
            </div>
          )}
        </DropDownList>
      </CustomWrapper>
    </>
  );
};

export default FilterSelect;

const CustomWrapper = styled.div`
  width: 100%;
  position: relative;
  & img {
    width: 14px;
    margin-left: 2px;
    margin-top: -1px;
  }
`;
export const TextInput = styled.div`
  background-color: transparent;
  border: none;
  width: 90%;
  display: flex;
  justify-content: center;
  & .silver-color {
    color: #b0b6c1;
    margin-left: 20px;
  }
  &:focus {
    outline: none;
  }
`;

const Label = styled.span`
  width: 100%;
  font-size: 11px;
  ${mq.lg} {
    font-size: 10px;
  }
`;
const Value = styled.div`
  display: inline-block;
  font-family: "Inter";
  font-weight: 600;
  font-size: 13px;
  color: ${props => (props.offset > 80 ? "white" : "#111")};
  width: 50%;
  white-space: pre;
  text-overflow: ellipsis;
  ${mq.lg} {
    font-size: 11.5px;
  }
`;
export const DropDownArrow = styled.div`
  & i {
    margin-left: 44px;
    ${mq.lg} {
      margin-left: 22px;
    }
  }
  width: 15px;
  display: flex;
  height: 15px;
  align-items: center;
  justify-content: center;
  ${mq.lg} {
    width: 13px;
    height: 13px;
  }
`;
const DropDownList = styled.div`
  max-height: ${props => (props.show ? "200px" : "0")};
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  position: absolute;
  top: 125%;
  width: 100%;
  z-index: 101;
  background-color: #fff;
  /* display: ${props => (props.show ? "inline-block" : "none")}; */
  border: 3px solid rgb(233, 236, 239);
  border-top: none;
  border: ${props => !props.show && "0px"};
  box-shadow: 0px 50px 100px 0px rgb(229 232 235 / 20%);
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: block;
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(157, 170, 185, 0.24);
    display: block;
    outline: 1px solid #708090;
    border-radius: 6px;
  }
  .custom-button-wrapper {
    width: 100%;
    position: sticky;
    bottom: 0px;
    .custom-button-button {
      width: 100%;
      padding: 6px 0;
      background-color: #fff;
      font-family: Inter;
      font-weight: 500;
      color: var(--primary-color);
      border: 2px solid var(--primary-color);
    }
  }
`;

const Item = styled.div`
  cursor: pointer;
  font-size: 13px;
  position: relative;
  min-height: 20px;
  text-align: center;
  width: 100%;
  line-height: 2;
  color: #111;
  background-color: ${props => {
    if (props.selected) return "var(--primary-color)";
  }};
  color: ${props => {
    if (props.selected) return "#fff";
  }};
  ${"" /* padding: 4px 0px; */}
  &:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
`;
