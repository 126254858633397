import React, { useState } from "react";
import {
  CustomButton,
  CustomGroup,
} from "../Mobile/components/Modal/ModalBody/customButton.styled";

function DifferencesButton({ setCurrentSection }) {
  const [a, setA] = useState("1");
  return (
    <CustomGroup
      color="primary"
      value={a}
      exclusive
      onChange={e => setA(e.target.value)}
    >
      <CustomButton value={`1`} onClick={() => setCurrentSection(1)}>
        Fixed Deposits vs Guaranteed Returns Plan
      </CustomButton>
      <CustomButton value={`2`} onClick={() => setCurrentSection(2)}>
        Returns Calculator
      </CustomButton>
    </CustomGroup>
  );
}

export default DifferencesButton;
