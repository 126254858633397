import { useState } from "react";
import DOMPurify from "dompurify";

export const isDigits = (str = "") => !!str.match(/^[0-9]*$/);

export function range(start = 0, end = 10, interval = 1) {
  const rangeArr = [];
  for (let i = start; i <= end; i += interval) {
    rangeArr.push(i);
  }
  return rangeArr;
}
const sanitizer = DOMPurify.sanitize;

export function createMarkup(markup = "") {
  return { __html: sanitizer(markup) };
}

export function amount(number) {
  return `₹ ${parseInt(number).toLocaleString("en-In")}`;
}

export const capitalize = (str = "") =>
  str.charAt(0).toUpperCase() + str.slice(1);

export function useNumberInput(defaultValue) {
  const [value, setValue] = useState(defaultValue);

  const handleChange = evt => {
    const { value: givenInput } = evt.target;

    if (givenInput && !isDigits(givenInput)) return;

    setValue(givenInput);
  };
  return [value, handleChange];
}
export const isValidHttpUrl = string => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const convertIntoYearly = (payFrequency, investmentAmount) => {
  switch (payFrequency) {
    case "0":
      return investmentAmount;
    case "1":
      return investmentAmount * 2;
    case "2":
      return investmentAmount * 4;
    case "3":
      return investmentAmount * 12;
    default:
      return null;
  }
};
