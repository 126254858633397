import React from "react";
import { useSelector } from "react-redux";
import { FiBookmark } from "react-icons/fi";
import styled from "styled-components/macro";
import { Modal } from "react-bootstrap";

export function ShortListQuotesMobile({ navigate, enquiryId }) {
  const { ShortListedPlansData } = useSelector(state => state.quotes);
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div
      onClick={() => {
        if (ShortListedPlansData?.length) {
          navigate(`/shortlisted-plans?enquiryId=${enquiryId}`);
        } else {
          handleShow();
          if (show) {
            handleClose();
          }
        }
      }}
      css={`
        display: flex;
        flex-direction: column;
        color: rgb(128, 128, 128);
        cursor: pointer;
        position: relative;
      `}
    >
      {ShortListedPlansData.length > 0 && (
        <>
          <ShortlistBadge>{ShortListedPlansData.length}</ShortlistBadge>
        </>
      )}
      <FiBookmark
        css={`
          margin: auto;
          font-size: 20px;
        `}
      />
      <FooterText>Shortlisted</FooterText>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        css={`
          backdrop-filter: blur(5px);
          .modal-content {
            width: 80%;
            margin: auto;
          }
        `}
      >
        <Modal.Header
          onClick={handleClose}
          closeButton
          css={`
            height: 25px;
          `}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body
          css={`
            height: 120px;
          `}
        >
          You have not shortlisted any plan. Please shortlist a plan first.
        </Modal.Body>
      </Modal>
    </div>
  );
}
const FooterText = styled.p`
  font-size: 10px;
  font-family: Inter;
  font-weight: 500;
  margin: 0px auto;
`;

const ShortlistBadge = styled.div`
  position: absolute;
  top: -10px;
  right: -8px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  height: 20px;
  font-weight: bold;
  width: 20px;
  text-align: center;
  background-color: var(--primary-color);
  border-radius: 50%;
`;
