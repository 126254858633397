import React, { useEffect } from "react";
import { useSelector } from "react-redux";

function useTotalQuotes() {
  const quotes = useSelector(state => state.quotes);
  const { showMoreQuotes } = useSelector(state => state.quotes);

  function getLen() {
    const vals = quotes?.quotes;
    let len = 0;
    if (vals) {
      const companies = Object.keys(vals);
      companies.length > 0 &&
        companies.map(item => {
          if (vals[item] != null && vals[item].length > 0) {
            if (showMoreQuotes?.includes(item)) {
              len += vals[item].length;
            } else {
              len++;
            }
          }
        });
    }
    return len;
  }
  let length = getLen();

  useEffect(() => {
    length = getLen();
  }, [quotes?.quotes]);

  return length;
}

export default useTotalQuotes;
