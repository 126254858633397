import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiTableHead from "@material-ui/core/TableHead";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDifferentQuotes,
  selectQuotes,
  setSelectedQuotesToShare,
  setShareQuotesCanvas,
} from "../../Quotes.slice";
import styled from "styled-components/macro";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";
import CardSkeletonLoader from "../../../../components/Loaders/CardSkeletonLoader/CardSkeletonLoader";
import {
  useGetDifferentQuoteQuery,
  useGetQuoteQuery,
} from "../../../rider/services/rider";
import useGetAmount from "../../../../custom-hooks/useGetAmount";
import useGetInWords from "../../../../custom-hooks/useGetInWords";

const TableHead = withStyles(theme => ({}))(MuiTableHead);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    margin: "2px",
  },
  tableHead: {
    borderRadius: "5px !important",
    backgroundColor: "rgb(237,238,240)",
    color: "#000",
    position: "sticky",

    top: 0,
    zIndex: 1000,
  },

  tableRow: {},
});

function QuotesTable({ allProductIds, allQuotesData }) {
  const quotes = useSelector(selectQuotes);
  const { showDifferentQuotes } = useSelector(state => state.quotes);
  const diffQuotes = useSelector(selectDifferentQuotes);
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (selected) {
      const sendToBack = selected.map(product => product?.product?.id);
      dispatch(setSelectedQuotesToShare(sendToBack));
      dispatch(setShareQuotesCanvas(selected));
    } else {
      dispatch(setSelectedQuotesToShare([]));
      dispatch(setShareQuotesCanvas([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div>
      <div
        css={`
          color: red;
          visibility: ${selected.length === 0 ? "visible" : "hidden"};
        `}
      >
        * Select atleast one quote to share
      </div>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "375px",
          // padding: '10px',
          // margin: "2px",
          overflowX: "hidden",
          overflowY: "scroll",
          boxShadow: "0 0px 0px 0 rgba(0, 0, 0, 0.25)",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingBottom: "10px",
        }}
      >
        <Table
          stickyHeader
          className={classes.table}
          sx={{ borderSpacing: "0 7px", borderCollapse: "separate" }}
        >
          <TableHead className={classes.tableHead}>
            <TableRow
              sx={{
                position: "sticky",
                top: "0",
                borderRadius: "5px",
              }}
            >
              <TableCell
                align="left"
                sx={{
                  padding: "4px 8px",
                  height: "80px",
                }}
              >
                <Checkbox
                  checked={
                    allProductIds.length
                      ? allProductIds.length === selected.length
                      : false
                  }
                  onClick={e => {
                    if (e.target.checked) {
                      setSelected(allQuotesData);
                    } else {
                      setSelected([]);
                    }
                  }}
                />
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  padding: "4px 8px",
                  height: "80px",
                }}
              >
                Product Name
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  padding: "4px",
                  height: "80px",
                }}
              >
                You Invest
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  padding: "4px",
                  height: "80px",
                }}
              >
                You Receive
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  padding: "4px",
                  height: "80px",
                }}
              >
                Returns
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  padding: "4px",
                  height: "80px",
                }}
              >
                Policy Term
              </TableCell>
              {/* <TableCell align="left">Premium</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(quotes).map(company_alias => (
              <Rows
                quote={quotes[company_alias]}
                company_alias={company_alias}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
            {showDifferentQuotes && diffQuotes && (
              <>
                <TableRow>
                  <TableCell
                    colSpan={6}
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      background: "rgb(237,238,240)",
                    }}
                  >
                    Plans with slightly different criteria
                  </TableCell>
                </TableRow>
                {Object.keys(diffQuotes).map(company_alias => (
                  <Rows
                    quote={quotes[company_alias]}
                    company_alias={company_alias}
                    selected={selected}
                    setSelected={setSelected}
                    isDifferent
                  />
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default QuotesTable;

const Rows = ({ quote, company_alias, selected, setSelected, isDifferent }) => {
  const { newFilters } = useSelector(state => state.inputForm);
  const {
    isLoading: isDifferentQuoteLoading,
    isFetching: isDifferentQuoteFetching,
    // isUninitialized,
    // isError,
    data: differentQuoteData,
  } = useGetDifferentQuoteQuery(
    {
      company_alias,
      newFilters,
    },
    { skip: !isDifferent },
  );
  const { data, isLoading, isFetching } = useGetQuoteQuery(
    {
      company_alias,
      newFilters,
    },
    { skip: isDifferent },
  );
  quote = isDifferent
    ? differentQuoteData
      ? differentQuoteData.data
      : null
    : data
    ? data.data
    : null;
  if (
    isLoading ||
    isFetching ||
    isDifferentQuoteLoading ||
    isDifferentQuoteFetching
  )
    return <CardSkeletonLoader />;

  if (!quote) return null;
  return (
    <>
      {quote?.map(quote => {
        if (!quote) {
          return null;
        }
        return (
          <Row
            quote={quote}
            company_alias={company_alias}
            selected={selected}
            setSelected={setSelected}
            isDifferent={isDifferent}
          />
        );
      })}
    </>
  );
};

const Row = ({ quote, company_alias, selected, setSelected, isDifferent }) => {
  const totalAmount = useGetAmount();
  const totalAmountWords = useGetInWords(totalAmount);
  const getMoneyAs = useSelector(state => state.filters.getMoneyAs);

  const {
    frontendData: { investmentcompanies },
  } = useSelector(state => state.frontendBoot);
  const incomeForValue = useSelector(state => state.filters.incomeFor) || "20";

  const classes = useStyles();

  const benefit = quote;
  const policy_term = benefit?.policy_term;
  const benefit_amt = benefit?.maturity_benefit;
  const benefitWord = useGetInWords(benefit_amt);
  const incomeBenefitInWords = useGetInWords(quote?.income_benfit);
  const income_benefit = quote?.income_benfit;
  const death_benefit = quote;
  const death_benefit_amt = death_benefit?.death_benefit;
  const death_benefit_word = useGetInWords(death_benefit_amt);

  const investForValue = useSelector(state => state.filters.investFor) || "10";
  const totalAmountToBeReceived =
    quote?.income_benfit * incomeForValue +
    benefit_amt +
    quote?.additional_lumpsum +
    quote?.additional_rop;

  const totalAmountInvested = quote?.premium * investForValue;
  const returns =
    Math.round((totalAmountToBeReceived / totalAmountInvested) * 100) / 100;
  const returnsAfterValue =
    useSelector(state => state.filters.returnsAfter) || "20";

  return (
    <>
      {quote?.product?.id && ( // If Empty data
        <TableRow
          // key={row.name}
          className={classes.tableRow}
          sx={{
            outline:
              selected &&
              selected?.some(
                item =>
                  item?.product?.id === quote?.product?.id &&
                  item?.policy_term === quote?.policy_term,
              )
                ? "1px solid rgb(0, 86, 254,0.5)"
                : "1px solid rgb(146, 180, 246,0.5)",
            padding: "0px",
            borderRadius: "5px",
          }}
        >
          <TableCell
            borderRadius
            align="left"
            sx={{
              borderBottom: "none",
              padding: "4px",
            }}
          >
            <Checkbox
              value={quote?.product?.id}
              checked={selected?.some(
                item =>
                  item?.product?.id === quote?.product?.id &&
                  item?.policy_term === quote?.policy_term,
              )}
              onClick={e => {
                if (e.target.checked) {
                  setSelected([...selected, quote]);
                } else {
                  setSelected(
                    selected.filter(
                      item =>
                        item?.product?.id !== quote?.product?.id ||
                        item?.policy_term !== quote?.policy_term,
                    ),
                  );
                }
              }}
            />
          </TableCell>
          <TableCell
            align="left"
            sx={{
              borderBottom: "none",
              padding: "4px",
            }}
          >
            <div
              css={`
                display: flex;
                gap: 15px;
                // border: 1px solid #607a8d;
                // padding: 10px 16px;
                // border-radius: 10px;
              `}
            >
              <img
                src={investmentcompanies[quote?.company_alias]?.logo}
                alt="company_logo"
                css={`
                  height: 30%;
                  width: 18%;
                `}
              />
              <div
                css={`
                  display: flex;
                  flex-direction: column;
                `}
              >
                <CellValue
                  css={`
                    font-size: 14px;
                  `}
                >
                  {quote?.product?.name.split("-")[0]}
                </CellValue>
                <CellValue
                  css={`
                    font-size: 12px;
                    font-weight: 400;
                  `}
                >
                  {quote?.product?.name.split("-")[1]}
                </CellValue>
                <CellDesc
                  css={`
                    color: #00a364;
                  `}
                >
                  Death Benefit: {death_benefit_word}
                </CellDesc>
              </div>
            </div>
          </TableCell>

          <TableCell
            align="left"
            sx={{
              borderBottom: "none",
              padding: "4px",
            }}
          >
            <CellValue> {totalAmountWords}</CellValue>
            <br />
            <CellDesc>Per Year</CellDesc>
            <br />
            <CellDesc>For {investForValue} Years</CellDesc>
          </TableCell>
          <TableCell
            align="left"
            sx={{
              borderBottom: "none",
              padding: "0px",
            }}
          >
            <CellValue>
              {" "}
              {income_benefit ? incomeBenefitInWords : benefitWord}
            </CellValue>
            <br />{" "}
            <CellDesc>
              {getMoneyAs === "I" || getMoneyAs === "B" ? (
                <span className="w-100 d-flex justify-content-start">
                  Per Year
                  {/* {getMoneyyAs()}/month */}
                </span>
              ) : (
                <span className="w-100 d-flex justify-content-start   ">
                  After
                </span>
              )}
              {getMoneyAs === "I" ? (
                <span className="w-100 d-flex justify-content-start   ">
                  For {incomeForValue} Years
                </span>
              ) : (
                <span className="w-100 d-flex justify-content-start   ">
                  {returnsAfterValue} Years
                </span>
              )}
            </CellDesc>
          </TableCell>
          <TableCell
            align="left"
            sx={{
              borderBottom: "none",
              padding: "4px",
            }}
          >
            <CellValue> {returns} times</CellValue>
            <br />
            <CellDesc></CellDesc>
          </TableCell>

          <TableCell
            align="left"
            sx={{
              borderBottom: "none",
              padding: "4px",
            }}
          >
            <CellValue> {policy_term} Years</CellValue>
            <br />
            <CellDesc></CellDesc>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const CellValue = styled.span`
  font-weight: 600;
  font-size: 15px;
`;
const CellDesc = styled.span`
  font-size: 13px;
`;
