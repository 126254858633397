import React from "react";
import { LandingWrapper } from "./Landing.style";
import "styled-components/macro";
import LandingMessage from "./Components/LandingMessage/LandingMessage";
import LandingForm from "./Components/LandingForm/LandingForm";
import * as mq from "../../styles/mediaQueries";
import LandingLogo from "./landingLogo/LandingLogo";
import Navbar from "../../components/navbar/Navbar-Component";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import styled from "styled-components";
import { BsWindowSidebar } from "react-icons/bs";
import { useSelector } from "react-redux";

const Landing = () => {
  const matches = useMediaQuery(`${mq.smForHook}`);
  window.scrollTo(0, 0);
  const { investmentsettings } = useSelector(
    state => state.frontendBoot?.frontendData,
  );

  return (
    <div>
      <Navbar />

      <LandingWrapper background_image={investmentsettings?.background_image}>
        {/* {matches ? (
          <>
            <div
              className="d-flex justify-content-end"
              css={`
                width: 100%;
                display: flex;
                justify-content: flex-start !important;
                padding: 4px;

                ${mq.sm} {
                  width: 100%;
                  padding-top: 4em;
                  padding-left: 1em;
                }
              `}
            >
              <LandingLogo />
            </div>
          </>
        ) : (
          <div
            className="d-flex justify-content-end"
            css={`
              width: 50%;
              position: relative;
              top: 3em;
              right: 1em;
              // padding-bottom: 3em;
              ${mq.sm} {
                width: 100%;
              }
            `}
          >
            <LandingLogo />
          </div>
        )} */}

        <div
          css={`
            display: flex;
            flex-direction: row;
            justify-content: center;
            height: 100%;
            padding-top: 20px;
            width: 100%;
            ${mq.sm} {
              padding-top: 0px;
            }
          `}
        >
          <LandingMain>
            <LandingMessage />
            <LandingForm />
          </LandingMain>
        </div>
      </LandingWrapper>
    </div>
  );
};

export default Landing;

const LandingMain = styled.div`
  display: flex;
  flex-direction: row;

  width: 1440px;
  ${mq.sm} {
    display: block;
    padding-top: 10px;
    width: 100%;
  }
  ${mq.md} {
    display: block;
    padding-top: 10px;
    width: 100%;
  }
`;
