import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as mq from "../../../../styles/mediaQueries";
import styled from "styled-components/macro";
import WhatsAppShare from "./WhatsAppShare";
import ShareOnMailForm from "./ShareOnMailForm";
import { Modal } from "react-bootstrap";
import { Checkbox } from "@mui/material";
import CancelCircularIcon from "../../../../assets/svg/CancelCircleIcon";
import ShareOnText from "./ShareOnText";
import { useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import {
  selectDifferentQuotes,
  selectQuotes,
  selectSelectedQuotesToShare,
  setSelectedQuotesToShare,
  setShareQuotesCanvas,
} from "../../Quotes.slice";
import useMediaQuery from "../../../../custom-hooks/useMediaQuery";
import QuotesTable from "./QuotesTable";
import ShareQuotesMobile from "./ShareQuotesMobile";
import ShareQuotesCanvas from "./ShareQuotesCanvas";
const ShareQuoteBtnComp = ({ showPopup, setShowPopup }) => {
  const [currentSection, setCurrentSection] = useState(0);
  const handleShow = () => setShowPopup(true);
  const handleClose = () => {
    setShowPopup(false);
    setCurrentSection(0);
  };
  return (
    <>
      <ShareModalComponent
        handleClose={handleClose}
        showPopup={showPopup}
        handleShow={handleShow}
        currentSection={currentSection}
        setCurrentSection={setCurrentSection}
      />
    </>
  );
};

export default ShareQuoteBtnComp;

function ShareFeatureContent({ imageUrl }) {
  const { data } = useSelector(state => state.inputForm);
  const [img, setImg] = useState("");
  const [load, setLoad] = useState(true);

  getQuotesPageImage().then(data => {
    if (data) {
      setImg(data);
      setLoad(false);
    }
  });

  return (
    <div>
      {load ? (
        <div
          css={`
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 50px 0px;
            font-size: 15px;
          `}
        >
          Loading.....
        </div>
      ) : (
        <div
          css={`
            width: 100%;
            // padding: 1rem 1rem 0;

            ${mq.sm} {
              padding: 1rem 0 0;
            }
          `}
        >
          {
            <div
              css={`
                animation-duration: 0.9s;
                animation-name: fadein;

                @keyframes fadein {
                  0% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              `}
            >
              <ShareOnMailForm imageUrl={img} />
              <WhatsAppShare mobile={data.mobile} imageUrl={img} />
              <ShareOnText imageUrl={img} />
              {/* <MoreOptionsButton onClick={toggleShow}>
                {showMore ? "Hide" : "View More Options"}
              </MoreOptionsButton> */}
            </div>
          }
          {/* {showMore && (
            <div>
              <QRCodeComp />
              <CopyToClipboardForm />
            </div>
          )} */}
        </div>
      )}
    </div>
  );
}

const ShareModalComponent = ({
  showPopup,
  handleShow,
  handleClose,
  currentSection,
  setCurrentSection,
}) => {
  const { data } = useSelector(state => state.inputForm);
  const capitalize = (str = "") => str.charAt(0).toUpperCase() + str.slice(1);
  const proposerName = capitalize(data?.name?.split(" ")[0]);
  const dispatch = useDispatch();
  const [shareAll, setShareAll] = useState(false);
  const [previous, setPrevious] = useState(null);
  const [selected, setSelected] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [imageUrl, setImageUrl] = useState("");
  const allProducts = useSelector(selectSelectedQuotesToShare);
  const matches = useMediaQuery(`${mq.smForHook}`);
  const quotes = useSelector(selectQuotes);
  const differentQuotes = useSelector(selectDifferentQuotes);
  //for diff quotes
  let diffQuotes = Object.keys(differentQuotes).map(
    item => differentQuotes[item],
  );
  let differentQuotesData = diffQuotes
    .map(quote => quote !== null && quote[0])
    .filter(item => item !== false);
  let differentProductIds = diffQuotes.map(
    item => item !== null && item[0]?.product?.id,
  );
  //for normal quotes
  let usualQuotes = Object.keys(quotes).map(item => quotes[item]);
  let usualProductIds = usualQuotes
    .map(
      item =>
        item !== null &&
        (item.length === 1
          ? item[0]?.product?.id
          : item.map(i => i?.product?.id)),
    )
    .flat();

  let usualQuotesData = usualQuotes
    .map(
      quote =>
        quote !== null && (quote.length === 1 ? quote[0] : quote.map(i => i)),
    )
    .flat()
    .filter(item => item !== false);

  //merged diff and normal quotes
  let allQuotesData = [...usualQuotesData, ...differentQuotesData];
  //merged diff and normal quotes
  let allProductIds = [
    ...usualProductIds.filter(item => item !== false),
    ...differentProductIds.filter(item => item !== false),
  ];
  useEffect(() => {
    if (shareAll) {
      dispatch(setSelectedQuotesToShare(allProductIds));
      dispatch(setShareQuotesCanvas(allQuotesData));
    } else {
      dispatch(setSelectedQuotesToShare([]));
      dispatch(setShareQuotesCanvas([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareAll]);

  // useEffect(() => {

  // }, [shareQuotesCanvas]);

  return (
    <>
      <Modal
        centered
        show={showPopup}
        onHide={handleClose}
        css={`
          overflow-y: hidden;
          // padding-top: 1rem;
          // padding-bottom: 1rem;
          ${mq.sm} {
            padding-top: 1.5em;
            padding-bottom: 1em;
          }

          .modal-content {
            bottom: 18px;
          }
          .modal-dialog {
            max-width: 1000px;
            ${mq.md} {
              margin: 1em;
            }
          }
        `}
      >
        <Modal.Header
          css={`
            // border-bottom: none;
            // padding-bottom: 0px;
          `}
        >
          <Modal.Title
            css={`
              font-size: 1.2rem;
              font-family: Inter;
              font-weight: 500;
              // padding-right: 2.67em;
              // padding-left: 0.1em;/
              ${mq.sm} {
                width: 100%;
                font-size: 0.8rem;
                padding-right: 0px;
              }
            `}
          >
            <ModalHeading>
              Hi {proposerName}, Please choose the way you wish to share your
              Life Insurance Quotes.
            </ModalHeading>
          </Modal.Title>
          <CancelCircularIcon
            width={"27"}
            onClick={() => handleClose()}
            style={{
              position: "absolute",

              right: "-10px",
              top: "-10px",
            }}
          />
        </Modal.Header>
        <Modal.Body
          css={`
            padding: 1rem;
            padding-top: 0.5rem;
            padding-bottom: 0.7rem;

            // padding: 0.7rem;
            // margin: 0px;
          `}
        >
          <div
            css={`
              display: flex;
              gap: 2em;
              justify-content: center;
              ${mq.sm} {
                flex-wrap: wrap;
                align-items: center;
                gap: 0.5em;
              }
            `}
          >
            {currentSection !== 0 && !shareAll && (
              <Progress contentToDisplay={currentSection} />
            )}
            {currentSection === 0 && (
              <>
                <div
                  css={`
                    padding: 1.7rem;
                    display: flex;
                    gap: 2em;
                    ${mq.sm} {
                      display: block;
                    }
                  `}
                >
                  <StyledButton
                    allBtn
                    onClick={() => {
                      setShareAll(true);
                      setCurrentSection(2);
                      setPrevious(0);
                    }}
                  >
                    Share All Quotes{" "}
                  </StyledButton>
                  <StyledButton
                    onClick={() => {
                      setShareAll(false);
                      setCurrentSection(1);
                      setPrevious(0);
                    }}
                  >
                    Share Selected Quotes Only
                  </StyledButton>
                </div>
              </>
            )}
          </div>

          <div
            css={`
                color: red;
                display: inline-block;
                visibility: ${
                  currentSection === 1 && matches && allProducts.length === 0
                    ? "visible;"
                    : "hidden;"
                }
                position: relative;
                 top: 30px;
              `}
          >
            * Select atleast one quote.
          </div>

          {matches && currentSection === 1 && (
            <div
              css={`
                text-align: right;
                display: block;
              `}
            >
              <Checkbox
                checked={allProductIds.length === selected.length}
                onClick={e => {
                  if (e.target.checked) {
                    setSelected(allProductIds);
                  } else {
                    setSelected([]);
                  }
                }}
              />{" "}
              Select All
            </div>
          )}
          {currentSection === 1 && (
            <TableWrapper>
              {matches ? (
                <ShareQuotesMobile
                  allProductIds={allProductIds}
                  selected={selected}
                  setSelected={setSelected}
                  currentSection={currentSection}
                />
              ) : (
                <QuotesTable
                  allProductIds={allProductIds}
                  allQuotesData={allQuotesData}
                />
              )}
            </TableWrapper>
          )}

          {currentSection === 2 && (
            <LastComp>
              {" "}
              <ShareFeatureContent imageUrl={imageUrl} />
            </LastComp>
          )}
          <ShareQuotesCanvas />
          {/* <ShareFeatureContent /> */}
        </Modal.Body>
        {/* <hr
          css={`
            margin: 0px;
          `}
        /> */}

        <Modal.Footer
          css={`
            padding: 0px;
            margin: 0;
            // padding-top: 10px;
            // padding-bottom: 10px;
            // margin-top: 10px;
            // margin-right: 1em;
            ${mq.sm} {
              margin-right: 0px;
              padding-bottom: 0px;

              // padding-bottom: 10px;
            }
          `}
        >
          {/* {currentSection !== 0 && (
            <>
              <BackButton
                onClick={() => {
                  setCurrentSection(previous);
                  setPrevious(previous - 1);
                  // setCurrentSection(currentSection - 1);
                }}
              >
                Back
              </BackButton>
            </>
          )} */}
          {currentSection === 1 && (
            <>
              <BackButton
                onClick={() => {
                  setCurrentSection(previous);
                  setPrevious(previous - 1);
                  // setCurrentSection(currentSection - 1);
                }}
              >
                Back
              </BackButton>
              <NextButton
                css={`
                  background-color: ${currentSection === 1 &&
                  allProducts.length === 0 &&
                  "#dddddd !important"};
                  color: ${currentSection === 1 &&
                  allProducts.length === 0 &&
                  "var(--secondary-text) !important"};
                  border: ${currentSection === 1 &&
                  allProducts.length === 0 &&
                  "none"};
                `}
                onClick={() => {
                  setCurrentSection(2);
                  setPrevious(1);
                }}
                disabled={currentSection === 1 && allProducts.length === 0}
              >
                Next
              </NextButton>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

function Progress({ contentToDisplay, ...props }) {
  return (
    <div
      css={`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.3em;
        margin: 5px 0 10px 0;
        ${mq.sm} {
          font-size: 10px;
          margin: 0;
        }
      `}
      {...props}
    >
      <Dot state={contentToDisplay >= 1 ? "past" : "future"} />
      <ProgressText>Selected Plans</ProgressText>
      <Line state={contentToDisplay >= 2 ? "past" : "future"} />
      <Dot state={contentToDisplay >= 2 ? "past" : "future"} />
      <ProgressText>Share with</ProgressText>
    </div>
  );
}

const StyledButton = styled.button`
  width: 250px;
  background: ${props => (props.allBtn ? "var(--primary-color)" : "#fff")};
  padding: 10px;
  color: ${props => (props.allBtn ? "#ffffff" : "var(--primary-color)")};

  border-radius: 5px;
  border: ${props =>
    props.allBtn ? "none" : "1px solid var(--primary-color)"};
  font-size: 15px;
  &:hover {
    background: var(--primary-color);
    color: #fff;
  }
  ${mq.sm} {
    margin: 0px;
  }
  ${mq.md} {
    margin: 4px;
  }
`;
const NextButton = styled.button`
  padding: 5px 30px;
  margin-right: 25px;
  font-size: 15px;
  background-color: ${props => !props.disabled && "var(--primary-color)"};
  color: ${props => !props.disabled && "#fff"};
  border-radius: 5px;
  border: none;
  ${mq.sm} {
  }
`;

const ModalHeading = styled.div`
  font-size: 20px;
  ${"" /* margin-left: 25px; */}
  font-weight: 500;
  ${"" /* font-weight: bold; */}
  font-family : Inter;
  ${"" /* color: #000; */}
  &::before {
    content: "";
    position: absolute;
    height: 27px;
    width: 9px;
    top: 4;
    left: 0;
    background: #ffcb00;
    border-radius: 0 45px 45px 0;
  }
  ${mq.sm} {
    font-size: 12px;
    margin-left: 20px;
  }
`;

const TableWrapper = styled.div`
  ${mq.sm} {
    height: 340px;
    padding: 5px;
    overflow-y: scroll;
  }
`;

const ProgressText = styled.div`
  font-weight: 700;
  font-size: 13px;
`;
const BackButton = styled.div`
  font-size: 15px;
  padding: 5px 30px;
  /* color:var(--primary-color)border:1px solid var(--primary-color) */
  border: none;
  background-color: transparent;
  cursor: pointer;

  ${"" /* border-radius: 5px; */}
`;

const PROGRESS_STATE_COLORS = {
  past: "var(--primary-color)",
  present: "white",
  future: "#dedede",
};

const Dot = styled.div`
  height: 1em;
  width: 1em;
  border-radius: 50%;

  background-color: ${({ state = "present" }) => PROGRESS_STATE_COLORS[state]};
`;

const Line = styled.div`
  height: 0.21em;
  width: 2em;
  border-radius: 1em;
  background-color: ${({ state = "present" }) => PROGRESS_STATE_COLORS[state]};
`;

const LastComp = styled.div`
  ${"" /* padding: 1rem 1rem 0; */}

  ${mq.sm} {
    ${"" /* padding-top: 2em; */}
    padding-top :0px;
    padding: 0px;
    padding-left: 0px;
    ${
      "" /* display: flex;
    width: 100%;
    justify-content: center;sss
    align-items: center; */
    }
  }
`;
async function getQuotesPageImage() {
  const root = document.getElementById("share-quotes");
  const canvas = await html2canvas(root, {
    scrollX: 0,
    scrollY: -window.scrollY,
    allowTaint: true,
    useCORS: true,
    scale: 1,
  });
  const image = canvas.toDataURL("image/jpeg");
  return image.split(",")[1];
}
