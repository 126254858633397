import styled from "styled-components";
import * as mq from "../../styles/mediaQueries";

export const SuccessImage = styled.img`
  width: 20%;
  margin: 0px auto;
  margin-top: 16px;
  margin-bottom: 30px;
  display: flex;
`;

export const ShowText = styled.p`
  color: ${props => (props.failed ? "#c21313" : "rgb(43 71 139)")};
  font-size: 17px;
  line-height: 28px;
  font-weight: 700 !important;
  background-image: linear-gradient(
    to right,
    rgb(226 240 255),
    rgb(255, 255, 255)
  );
  padding: 10px;
  width: 100%;
  text-align: center;
`;

export const LabelText = styled.p`
  color: #555555;
  font-size: 13px;
  line-height: 21px;
  font-weight: 700;
  background-image: linear-gradient(
    to right,
    rgb(226 240 255),
    rgb(255, 255, 255)
  );
  padding: 10px;
  width: 100%;
  text-align: center;
  font-family: Inter;
`;

export const SumbitButton = styled.button`
  display: flex;
  background-color: #0182ff;
  color: white;
  border: unset;
  font-family: "Inter";
  line-height: 50px;
  padding: 0 40px;
  font-size: 16px;
  margin: 10px auto;
  margin-top: 25px;
  ${mq.sm} {
    margin-top: 0px;
    font-size: 14px;
    padding: 0 20px;
  }
`;
