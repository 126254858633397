import React from "react";
import { useGetEnquiriesQuery } from "../../rider/services/rider";
import styled from "styled-components/macro";
import * as mq from "../../../styles/mediaQueries";
import { toast } from "react-toastify";

function TraceId({ label }) {
  const { data, isLoading } = useGetEnquiriesQuery();
  if (isLoading) return <Loader>Loading...</Loader>;
  return (
    <div>
      <HeaderNavRight>
        <TraceLabel>{label}</TraceLabel>
        <span
          css={`
            color: var(--primary-color) !important;
          `}
        >
          {data?.data?.trace_id}
        </span>
        <TraceID className="ps-1 ">
          <i
            onClick={() => {
              navigator.clipboard.writeText(data?.data?.trace_id);
              toast.success("Copied to clipboard", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 1000,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }}
            title="Copy to Clipboard"
            style={{
              fontSize: "13px",
              color: "var(--tertiary-text)",
              display: "inline",
              marginLeft: "8px",
              cursor: "pointer",
            }}
            className="far fa-copy"
          />
        </TraceID>
      </HeaderNavRight>
    </div>
  );
}

export default TraceId;

const HeaderNavRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  color: #000;

  ${mq.lg} {
    font-size: 10px;
  }

  ${mq.sm} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 9px;
    padding-left: 0px;
  }
  ${mq.md} {
    padding-left: 2px;
  }
`;

const TraceID = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img {
    font-weight: 600;
  }
  ${mq.md} {
    padding: 2px;
  }
  ${mq.sm} {
    padding: 0;
  }
`;

const TraceLabel = styled.div`
  ${mq.md} {
    padding: 2px;
  }
  ${mq.sm} {
    padding: 0;
  }
  ${"" /* color: #6b7789; */}
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #000;
  font-size: 12px;

  ${mq.sm} {
    font-size: 10px;
  }
`;
