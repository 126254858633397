import React from "react";
import "styled-components/macro";
import { yupResolver } from "@hookform/resolvers/yup";
import FormWithTitle from "../../FormWithTitle/FormWithTitle";
import * as mq from "../../../../../styles/mediaQueries";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import Select from "../../Select";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../../input.slice";
import { form2 } from "../../../validations";
import { educationTypes, occupationTypes } from "../../EligibilityOptions";
// import TextInput from "../../TextInput";
import { allowOnlyNumbers } from "../../../../InputUtils/onInput";
import TextInput from "../../TextInput";

function Eligibility({ setCurrent }) {
  const dispatch = useDispatch();
  const { investmentinputpage } = useSelector(
    state => state.frontendBoot.frontendData,
  );

  const getLocalData = () => {
    const field_2 = localStorage.getItem("Eligibility Info");
    if (field_2) return JSON.parse(field_2);
    else
      return {
        occupation: "",
        education: "",
        pincode: "",
      };
  };
  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(form2),
    mode: "onChange",
    defaultValues: getLocalData(),
  });

  const [occupation, setOccupation] = React.useState(getLocalData().occupation);
  const [education, setEducation] = React.useState(getLocalData().education);
  //   const [financial, setFinancial] = React.useState(
  //     getLocalData().financial_and_familyGoal,
  //   );

  const onSubmit = data => {
    setCurrent(prev => prev + 1);
    dispatch(setData(data));
    localStorage.setItem(
      "Eligibility Info",
      JSON.stringify({
        occupation: data?.occupation,
        education: data?.education,
        pincode: data?.pincode,
        // financial_and_familyGoal: data.financial_and_familyGoal,
      }),
    );
  };

  return (
    <div>
      <FormWithTitle
        onSubmit={handleSubmit(onSubmit)}
        current={1}
        title={
          investmentinputpage?.investmentinputpage2
            ? investmentinputpage?.investmentinputpage2
            : "<h1>Some More Questions</h1>"
        }

        // title={"Some More Questions"}
      >
        <ProfessionForm>
          <ProfessionInputWrapper>
            <div className="w-100 ">
              <Select
                noSearch
                label="Occupation*"
                options={occupationTypes}
                error={errors.occupation && errors.occupation.message}
                handleChange={val => {
                  setOccupation(val);
                  setValue("occupation", val);
                  trigger("occupation");
                }}
                selectedValue={occupation}
              />
            </div>

            <div className="w-100 mt-4">
              <Select
                label="Education*"
                options={educationTypes}
                error={errors.education && errors.education.message}
                handleChange={val => {
                  setEducation(val);
                  setValue("education", val);
                  trigger("education");
                }}
                noSearch
                selectedValue={education}
              />
            </div>

            <div className="w-100 mt-4">
              <TextInput
                name="pincode"
                label="Pincode*"
                error={errors.pincode}
                inputProps={{ maxLength: 6, inputMode: "numeric" }}
                clearErrors={clearErrors}
                onInput={allowOnlyNumbers}
                errorIcon={errors.pincode ? true : false}
                helperText={errors.pincode && errors.pincode.message}
                register={register}
                onChange={e => {
                  setValue("pincode", e.target.value);
                  clearErrors("pincode");
                  trigger("pincode");
                }}
              />
            </div>
          </ProfessionInputWrapper>
          <ProfessionButton>
            <div className="w-100 d-flex justify-content-center">
              <div className="d-flex justify-content-center w-100">
                <button
                  // style={{
                  //   maxHeight: "60px",
                  //   maxWidth: "100%",
                  //   borderRadius: "5px",
                  //   border: "none",
                  //   fontSize: "1rem",
                  //   display: "flex",
                  //   alignItems: "center",
                  //   justifyContent: "center",
                  // }}

                  className="d-flex justify-content-center w-100 flex-1 align-items-center
                 m-1 border-0 py-3 btn text-black fw-bolder"
                  css={`
                    background-color: rgba(215, 228, 251, 0.695);
                    ${mq.sm} {
                      padding: 15px 0x;
                      font-size: 14px;
                    }
                  `}
                  onClick={() => {
                    setCurrent(prev => prev - 1);
                  }}
                >
                  <span> Go Back</span>
                </button>
                <button
                  type="submit"
                  // disabled={
                  //   selectedValue.riskProfile.length === 0 ||
                  //   selectedValue.lifeStage.length === 0 ||
                  //   selectedValue.financialAndFamilyGoal.length === 0
                  // }
                  className="d-flex justify-content-center w-100 flex-1 align-items-center
                 m-1 border-0  bg-primary btn text-white"
                  // style={{
                  //   fontSize: "1.1rem",
                  //   maxHeight: "60px",
                  //   maxWidth: "100%",
                  //   backgroundColor: "var(--primary-color)",
                  //   borderColor: "var(--primary-color)",
                  //   border: "none",
                  //   display: "flex",
                  //   alignItems: "center",
                  //   justifyContent: "center",
                  // }}
                  // onClick={() => {
                  //   setCurrent((prev) => prev + 1);
                  // }}
                >
                  <span>Continue</span>
                </button>
              </div>
            </div>
          </ProfessionButton>
        </ProfessionForm>
      </FormWithTitle>
    </div>
  );
}

export default Eligibility;

const ProfessionForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${"" /* margin-bottom: 20px; */}
  ${mq.sm} {
    width: 100%;
  }
`;

// const ProffessionTitle = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: center;
//   width: 100%;
//   height: 3.5em;

//   ${mq.sm} {
//     width: 100%;
//   }
// `;

const ProfessionInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  ${mq.sm} {
    width: 100%;
  }
`;

const ProfessionButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5em;

  ${mq.sm} {
    width: 100%;
  }
`;
export const Button = styled.button`
  width: 100%;
  background: var(--primary-color);
  padding: 20px 0;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  font-family: Inter;
  ${mq.md} {
    width: 100%;
    font-size: 14px;
  }
  ${mq.sm} {
    width: 100%;
    font-size: 14px;
  }
`;
