import { createSlice } from "@reduxjs/toolkit";

const newCompare = createSlice({
  name: "newCompare",
  initialState: {
    insuranceCompany: {},
    product: {},
    investmentAmount: "",
    getMoneyAs: "",
    frequency: {},
    investFor: {},
    returnsAfter: {},
    incomeFor: {},
    selectedQuoteToBuy: {},
    isPremiumLoading: false,
    quotesList: [],
  },
  reducers: {
    setInsuranceCompany: (state, action) => {
      state.insuranceCompany = action.payload;
      state.product = {};
      state.investmentAmount = "";
      state.getMoneyAs = "";
      state.frequency = {};
      state.investFor = {};
      state.returnsAfter = {};
      state.incomeFor = {};
    },
    setProduct: (state, action) => {
      state.product = action.payload;
      state.investmentAmount = "";
      state.frequency = {};
      state.investFor = {};
      state.returnsAfter = {};
      state.incomeFor = {};
    },
    setInvestmentAmount: (state, action) => {
      state.investmentAmount = action.payload;
      state.frequency = {};
      state.investFor = {};
      state.returnsAfter = {};
      state.incomeFor = {};
    },
    setFrequency: (state, action) => {
      state.frequency = action.payload;
      state.investFor = {};
      state.returnsAfter = {};
      state.incomeFor = {};
    },
    setInvestFor: (state, action) => {
      state.investFor = action.payload;
      state.returnsAfter = {};
      state.incomeFor = {};
    },
    setReturnsAfter: (state, action) => {
      state.returnsAfter = action.payload;
    },
    setIncomeFor: (state, action) => {
      state.incomeFor = action.payload;
      state.returnsAfter = action?.payload?.policy_term?.[0];
    },
    setGetMoneyAs: (state, action) => {
      state.getMoneyAs = action.payload;
    },

    setIsPremiumLoading: (state, action) => {
      state.isPremiumLoading = action.payload;
    },
    addQuoteInList: (state, action) => {
      state.quotesList = [...state.quotesList, action.payload];
    },
    updateQuoteInList: (state, action) => {
      state.quotesList = state?.quotesList?.map((ele, i) => {
        return i === action?.payload?.i ? { ...action?.payload?.quote } : ele;
      });
    },
    removeQuoteInList: (state, action) => {
      state.quotesList = state.quotesList?.filter((quote, i) => {
        return i !== action.payload;
      });
    },
    resetStates: state => {
      state.insuranceCompany = {};
      state.product = {};
      state.investmentAmount = "";
      state.investFor = {};
      state.frequency = {};
      state.returnsAfter = {};
    },
    setSelectedQuoteToBuy: (state, action) => {
      state.selectedQuoteToBuy = action.payload;
    },
  },
});

export const {
  setInsuranceCompany,
  setProduct,
  setInvestmentAmount,
  setGetMoneyAs,
  setFrequency,
  setInvestFor,
  setIncomeFor,
  setReturnsAfter,
  addQuoteInList,
  setIsPremiumLoading,
  updateQuoteInList,
  removeQuoteInList,
  resetStates,
  setSelectedQuoteToBuy,
} = newCompare.actions;

export default newCompare.reducer;
