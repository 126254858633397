import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CustomButton, CustomGroup } from "./customButton.styled";
import useGetInWords from "../../../../../../../custom-hooks/useGetInWords";

const PolicyTermBodyMobile = ({
  quoteData,
  getMoneyAs,
  setShow,
  setPolicyTermToShow,
  policyTermToShow,
}) => {
  const pay = [
    { code: "0", display_name: "Yearly" },
    { code: "1", display_name: "Half Yearly" },
    { code: "2", display_name: "Quarterly" },
    { code: "3", display_name: "Monthly" },
  ];
  // const [termValue, setTermValue] = useState(policyTermToShow);

  const selectedFrequency = pay.find(item => {
    return item.code === quoteData.pay_frequency && item?.display_name;
  });
  const [activeIndex, setActiveIndex] = useState();
  const investmentAmount = useGetInWords(quoteData?.investment_amount);
  const optionsList = quoteData?.pt_death_benefit;

  useEffect(() => {
    if (policyTermToShow) {
      setActiveIndex(
        optionsList.findIndex(item => item === policyTermToShow) || "0",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyTermToShow]);

  return (
    <>
      <DivWrapper>
        <DivFlex>
          <ParentSpan>
            <FirstChildSpan>Investment Amt :</FirstChildSpan>
            <SecondChildSpan>{investmentAmount}</SecondChildSpan>
          </ParentSpan>

          <ParentSpan>
            <FirstChildSpan>Invest For :</FirstChildSpan>
            <SecondChildSpan>{quoteData.pay_term} Years</SecondChildSpan>
          </ParentSpan>
        </DivFlex>

        <Divider />

        <DivFlex>
          <ParentSpan>
            <FirstChildSpan>Pay Frequency :</FirstChildSpan>
            <SecondChildSpan>
              {selectedFrequency?.display_name}{" "}
            </SecondChildSpan>
          </ParentSpan>

          {getMoneyAs === "I" || getMoneyAs === "B" ? (
            <ParentSpan>
              <FirstChildSpan>Income For :</FirstChildSpan>
              <SecondChildSpan>{quoteData.income_for} Years</SecondChildSpan>
            </ParentSpan>
          ) : (
            <ParentSpan>
              <FirstChildSpan>Returns After :</FirstChildSpan>
              <SecondChildSpan>{quoteData.policy_term} Years</SecondChildSpan>
            </ParentSpan>
          )}

          {/* {getMoneyAs === "L" && (
            <ParentSpan>
              <FirstChildSpan>Policy Term :</FirstChildSpan>
              <SecondChildSpan>{quoteData.income_for}</SecondChildSpan>
            </ParentSpan>
          )} */}

          {/* {(getMoneyAs === "I" || getMoneyAs === "B") && (
            <ParentSpan>
              <FirstChildSpan>Income For :</FirstChildSpan>
              <SecondChildSpan>{quoteData.income_for}</SecondChildSpan>
            </ParentSpan>
          )} */}
        </DivFlex>
      </DivWrapper>

      <CustomGroup>
        {optionsList.map((value, index) => {
          return (
            <CustomButton
              key={index}
              selected={index === parseInt(activeIndex)}
              onClick={() => {
                setShow(false);
                setPolicyTermToShow(value);
              }}
              value={policyTermToShow}
            >
              {value} Years
            </CustomButton>
          );
        })}
      </CustomGroup>
    </>
  );
};

export default PolicyTermBodyMobile;
const DivWrapper = styled.div`
  width: 100%;
  // height: 5e;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  margin: 0 0 15px 0;
  padding: 6px;
  display: flex;
  justify-content: space-evenly;
`;

const Divider = styled.div`
  border: 0.5px solid rgba(79, 92, 111, 0.2);
`;

const DivFlex = styled.div`
  display: flex;
  gap: 0.5em;
  flex-direction: column;
`;

const ParentSpan = styled.span`
  display: flex;
  gap: 1em;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;
const FirstChildSpan = styled.span`
  color: rgb(77, 77, 79);
  font-size: 10px;
`;

const SecondChildSpan = styled.span`
  font-weight: bold;
  font-size: 11px;
  // color: black;
`;