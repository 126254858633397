import { extractNumbers, getInWords } from "../../../utils/helper";
import { useRef, useState } from "react";
import { Box } from "@mui/material";
import styled from "styled-components";
import { useDispatch } from "react-redux";

const InvestmentAmountTextInput = ({
  investmentAmount,
  onChangeHandler,
  isReset,
  isDisable,
}) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(investmentAmount);
  const focusRef = useRef();
  edit && focusRef.current.focus();
  const dispatch = useDispatch();
  return (
    <InvestmentAmountFilterStyled isDisable={isDisable}>
      <input
        disabled={isDisable}
        type="number"
        onKeyDown={e => {
          if (e.which === 38 || e.which === 40) {
            e.preventDefault();
          }
        }}
        ref={focusRef}
        value={
          isReset
            ? edit
              ? extractNumbers(value)
              : getInWords(value)
            : investmentAmount
        }
        onChange={e => {
          e.target.value?.length <= 11 && setValue(e.target.value);
          e.target.value?.length <= 11 &&
            dispatch(onChangeHandler(e.target.value));
        }}
        placeholder={
          isReset
            ? edit
              ? extractNumbers(value)
              : getInWords(value)
            : "Enter Amount"
        }
        onBlur={() => setEdit(false)}
        onFocus={() => setEdit(true)}
      />
      {edit ? (
        <i
          key={"tick"}
          style={{
            fontSize: ".8rem",
          }}
          className="fas fa-check-circle"
          onClick={() => setEdit(false)}
        />
      ) : (
        <i
          key={"edit"}
          className="fas fa-edit"
          style={{
            fontSize: ".8rem",
          }}
          onClick={() => !isDisable && setEdit(true)}
        />
      )}
    </InvestmentAmountFilterStyled>
  );
};

export default InvestmentAmountTextInput;

const InvestmentAmountFilterStyled = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  input {
    margin-right: 5px;
    margin-left: -4px;
    width: 90%;
    font-size: 0.85rem;
    font-weight: 700;
    border: none;
    outline: none;
    padding: 2px 3px;
    border: 2px solid transparent;
    background-color: #fff;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      display: none;
    }
    :focus {
      border: 2px solid #dadada;
      border-radius: 4px;
    }
    ::placeholder {
      color: ${props => (props?.isDisable ? "#8b8b8b" : "#000")};
    }
  }
  i {
    margin-bottom: 1px;
    color: ${props => (props?.isDisable ? "#8b8b8b" : "#000")};
  }
`;
