export const addressProofOptions = [
  { code: "1023010128", display_name: "Driving License" },
  { code: "1023010129", display_name: "Passport" },
  { code: "1023010133", display_name: "Aadhar card, UID" },
  { code: "1023010131", display_name: "Voter ID Card" },
  { code: "1023010724", display_name: "Bank KYC" },
  { code: "1026010511", display_name: "Job Card by NREGA" },
];

export const residentialStatusOptions = [
  {
    code: "FN",
    display_name: "FN",
  },
  {
    code: "NRI",
    display_name: "NRI",
  },
  {
    code: "OCI",
    display_name: "OCI",
  },
  {
    code: "PIO",
    display_name: "PIO",
  },
  {
    code: "RI",
    display_name: "Resident-Others",
  },
  // {
  //   code: "RF",
  //   display_name: "Resident-Others",
  // },
];

export const countryOptions = [
  {
    code: "VT",
    display_name: "VIETNAM",
  },
  {
    code: "VU",
    display_name: "VANUATU",
  },
  {
    code: "WF",
    display_name: "WALLIS AND FUTUNA",
  },
  {
    code: "WS",
    display_name: "SAMOA",
  },
  {
    code: "YE",
    display_name: "YEMEN",
  },
  {
    code: "YT",
    display_name: "MAYOTTE",
  },
  {
    code: "ZA",
    display_name: "SOUTH AFRICA",
  },
  {
    code: "ZM",
    display_name: "ZAMBIA",
  },
  {
    code: "ZW",
    display_name: "ZIMBABWE",
  },
  {
    code: "AD",
    display_name: "ANDORRA",
  },
  {
    code: "AF",
    display_name: "AFGHANISTA",
  },
  {
    code: "AL",
    display_name: "ALBANIA",
  },
  {
    code: "AO",
    display_name: "ANGOLA",
  },
  {
    code: "AS",
    display_name: "AMERICAN SAMOA",
  },
  {
    code: "AB",
    display_name: "ANTIGUA",
  },
  {
    code: "AE",
    display_name: "UNITED ARAB EMIRATES",
  },
  {
    code: "AG",
    display_name: "ANTIGUA and BARBUDA",
  },
  {
    code: "AI",
    display_name: "ANGUILLA",
  },
  {
    code: "AM",
    display_name: "ARMENIA",
  },
  {
    code: "AN",
    display_name: "NETHERLANDS ANTILLES",
  },
  {
    code: "AQ",
    display_name: "ANTARCTICA",
  },
  {
    code: "AR",
    display_name: "ARGENTINA",
  },
  {
    code: "AT",
    display_name: "AUSTRIA",
  },
  {
    code: "AU",
    display_name: "AUSTRALIA",
  },
  {
    code: "AW",
    display_name: "ARUBA",
  },
  {
    code: "AZ",
    display_name: "AZERBAIJAN",
  },
  {
    code: "BA",
    display_name: "BOSNIA and HERZEGOVINA",
  },
  {
    code: "BB",
    display_name: "BARBADOS",
  },
  {
    code: "BD",
    display_name: "BANGLADESH",
  },
  {
    code: "BE",
    display_name: "BELGIUM",
  },
  {
    code: "BF",
    display_name: "BURKINA FASO",
  },
  {
    code: "BG",
    display_name: "BULGARIA",
  },
  {
    code: "BH",
    display_name: "BAHRAIN",
  },
  {
    code: "BI",
    display_name: "BURUNDI",
  },
  {
    code: "BJ",
    display_name: "BENIN",
  },
  {
    code: "BM",
    display_name: "BERMUDA",
  },
  {
    code: "BN",
    display_name: "BRUNEI DARUSSALAM",
  },
  {
    code: "BO",
    display_name: "BOLIVIA",
  },
  {
    code: "BQ",
    display_name: "BONAIRE, SINT EUSTATIUS and SABA",
  },
  {
    code: "BR",
    display_name: "BRAZIL",
  },
  {
    code: "BS",
    display_name: "BAHAMAS",
  },
  {
    code: "BT",
    display_name: "BHUTAN",
  },
  {
    code: "BW",
    display_name: "BOTSWANA",
  },
  {
    code: "BY",
    display_name: "BELARUS",
  },
  {
    code: "BZ",
    display_name: "BELIZE",
  },
  {
    code: "CA",
    display_name: "CANADA",
  },
  {
    code: "CC",
    display_name: "COCOS (KEELING) ISLANDS",
  },
  {
    code: "CD",
    display_name: "CONGO, THE DEMOCRATIC",
  },
  {
    code: "CF",
    display_name: "CENTRAL AFRICAN REPUBLIC",
  },
  {
    code: "CG",
    display_name: "CONGO",
  },
  {
    code: "CH",
    display_name: "SWITZERLAND",
  },
  {
    code: "CI",
    display_name: "CõTE DIVOIRE",
  },
  {
    code: "CK",
    display_name: "COOK ISLANDS",
  },
  {
    code: "CL",
    display_name: "CHILE",
  },
  {
    code: "CM",
    display_name: "CAMEROON",
  },
  {
    code: "CN",
    display_name: "CHINA",
  },
  {
    code: "CO",
    display_name: "COLOMBIA",
  },
  {
    code: "CR",
    display_name: "COSTA RICA",
  },
  {
    code: "CU",
    display_name: "CUBA",
  },
  {
    code: "CV",
    display_name: "CAPE VERDE",
  },
  {
    code: "CW",
    display_name: "CURA€AO",
  },
  {
    code: "CX",
    display_name: "CHRISTMAS ISLAND",
  },
  {
    code: "CY",
    display_name: "CYPRUS",
  },
  {
    code: "CZ",
    display_name: "CZECH REPUBLIC",
  },
  {
    code: "DE",
    display_name: "GERMANY",
  },
  {
    code: "DJ",
    display_name: "DJIBOUTI",
  },
  {
    code: "DK",
    display_name: "DENMARK",
  },
  {
    code: "DM",
    display_name: "DOMINICA",
  },
  {
    code: "DO",
    display_name: "DOMINICAN REPUBLIC",
  },
  {
    code: "DP",
    display_name: "DEMOCRATIC",
  },
  {
    code: "DZ",
    display_name: "ALGERIA",
  },
  {
    code: "EC",
    display_name: "ECUADOR",
  },
  {
    code: "EE",
    display_name: "ESTONIA",
  },
  {
    code: "EG",
    display_name: "EGYPT",
  },
  {
    code: "EH",
    display_name: "WESTERN SAHARA",
  },
  {
    code: "ER",
    display_name: "ERITREA",
  },
  {
    code: "ES",
    display_name: "SPAIN",
  },
  {
    code: "ET",
    display_name: "ETHIOPIA",
  },
  {
    code: "FI",
    display_name: "FINLAND",
  },
  {
    code: "FJ",
    display_name: "FIJI",
  },
  {
    code: "FK",
    display_name: "FALKLAND ISLANDS (MALVINAS)",
  },
  {
    code: "FM",
    display_name: "FEDERATED STATES MICRONESIA",
  },
  {
    code: "FO",
    display_name: "FAROE ISLANDS",
  },
  {
    code: "FR",
    display_name: "FRANCE",
  },
  {
    code: "GA",
    display_name: "GABON",
  },
  {
    code: "GB",
    display_name: "UNITED KINGDOM",
  },
  {
    code: "GD",
    display_name: "GRENADA",
  },
  {
    code: "GE",
    display_name: "GEORGIA",
  },
  {
    code: "GF",
    display_name: "FRENCH GUIANA",
  },
  {
    code: "GG",
    display_name: "GUERNSEY",
  },
  {
    code: "GH",
    display_name: "GHANA",
  },
  {
    code: "GI",
    display_name: "GIBRALTAR",
  },
  {
    code: "GL",
    display_name: "GREENLAND",
  },
  {
    code: "GM",
    display_name: "GAMBIA",
  },
  {
    code: "GN",
    display_name: "GUINEA",
  },
  {
    code: "GP",
    display_name: "GUADELOUPE",
  },
  {
    code: "GQ",
    display_name: "EQUATORIAL GUINEA",
  },
  {
    code: "GR",
    display_name: "GREECE",
  },
  {
    code: "GS",
    display_name: "SOUTH GEORGIA and SANDWICH ISLAND",
  },
  {
    code: "GT",
    display_name: "GUATEMALA",
  },
  {
    code: "GU",
    display_name: "GUAM",
  },
  {
    code: "GW",
    display_name: "GUINEA-BISSAU",
  },
  {
    code: "GY",
    display_name: "GUYANA",
  },
  {
    code: "HK",
    display_name: "HONG KONG",
  },
  {
    code: "HM",
    display_name: "HEARD AND MCDONALD ISLANDS",
  },
  {
    code: "HN",
    display_name: "HONDURAS",
  },
  {
    code: "HR",
    display_name: "CROATIA",
  },
  {
    code: "HT",
    display_name: "HAITI",
  },
  {
    code: "HU",
    display_name: "HUNGARY",
  },
  {
    code: "IA",
    display_name: "IRAN",
  },
  {
    code: "ID",
    display_name: "INDONESIA",
  },
  {
    code: "IE",
    display_name: "IRELAND",
  },
  {
    code: "IL",
    display_name: "ISRAEL",
  },
  {
    code: "IM",
    display_name: "ISLE OF MAN",
  },
  {
    code: "IN",
    display_name: "INDIA",
  },
  {
    code: "IO",
    display_name: "BRITISH INDIAN OCEAN TERRITORY",
  },
  {
    code: "IQ",
    display_name: "IRAQ",
  },
  {
    code: "IR",
    display_name: "ISLAMIC REPUBLIC OF IRAN",
  },
  {
    code: "IS",
    display_name: "ICELAND",
  },
  {
    code: "IT",
    display_name: "ITALY",
  },
  {
    code: "JE",
    display_name: "JERSEY",
  },
  {
    code: "JM",
    display_name: "JAMAICA",
  },
  {
    code: "JO",
    display_name: "JORDAN",
  },
  {
    code: "JP",
    display_name: "JAPAN",
  },
  {
    code: "KE",
    display_name: "KENYA",
  },
  {
    code: "KG",
    display_name: "KYRGYZSTAN",
  },
  {
    code: "KH",
    display_name: "CAMBODIA",
  },
  {
    code: "KI",
    display_name: "KIRIBATI",
  },
  {
    code: "KM",
    display_name: "COMOROS",
  },
  {
    code: "KN",
    display_name: "SAINT KITTS AND NEVIS",
  },
  {
    code: "KP",
    display_name: "NORTH KOREA",
  },
  {
    code: "KR",
    display_name: "SOUTH KOREA(REPUBLIC OF KOREA)",
  },
  {
    code: "KW",
    display_name: "KUWAIT",
  },
  {
    code: "KY",
    display_name: "CAYMAN ISLANDS",
  },
  {
    code: "KZ",
    display_name: "KAZAKHSTAN",
  },
  {
    code: "LA",
    display_name: "LAO PEOPLE DEMOCRATIC REPUBLIC",
  },
  {
    code: "LB",
    display_name: "LEBANON",
  },
  {
    code: "LC",
    display_name: "SAINT LUCIA",
  },
  {
    code: "LD",
    display_name: "LAO PDR",
  },
  {
    code: "LI",
    display_name: "LIECHTENSTEIN",
  },
  {
    code: "LK",
    display_name: "SRI LANKA",
  },
  {
    code: "LR",
    display_name: "LIBERIA",
  },
  {
    code: "LS",
    display_name: "LESOTHO",
  },
  {
    code: "LT",
    display_name: "LITHUANIA",
  },
  {
    code: "LU",
    display_name: "LUXEMBOURG",
  },
  {
    code: "LV",
    display_name: "LATVIA",
  },
  {
    code: "LY",
    display_name: "LIBYAN ARAB JAMAHIRIYA",
  },
  {
    code: "MA",
    display_name: "MOROCCO",
  },
  {
    code: "MC",
    display_name: "MONACO",
  },
  {
    code: "MD",
    display_name: "MOLDOVA, REPUBLIC OF",
  },
  {
    code: "ME",
    display_name: "MONTENEGRO",
  },
  {
    code: "MG",
    display_name: "MADAGASCAR",
  },
  {
    code: "MH",
    display_name: "MARSHALL ISLANDS",
  },
  {
    code: "MK",
    display_name: "MACEDONIA,",
  },
  {
    code: "ML",
    display_name: "MALI",
  },
  {
    code: "MM",
    display_name: "MYANMAR",
  },
  {
    code: "MN",
    display_name: "MONGOLIA",
  },
  {
    code: "MO",
    display_name: "MACAO",
  },
  {
    code: "MP",
    display_name: "NORTHERN MARIANA ISLANDS",
  },
  {
    code: "MQ",
    display_name: "MARTINIQUE",
  },
  {
    code: "MR",
    display_name: "MAURITANIA",
  },
  {
    code: "MS",
    display_name: "MONTSERRAT",
  },
  {
    code: "MT",
    display_name: "MALTA",
  },
  {
    code: "MU",
    display_name: "MAURITIUS",
  },
  {
    code: "MV",
    display_name: "MALDIVES",
  },
  {
    code: "MW",
    display_name: "MALAWI",
  },
  {
    code: "MX",
    display_name: "MEXICO",
  },
  {
    code: "MY",
    display_name: "MALAYSIA",
  },
  {
    code: "MZ",
    display_name: "MOZAMBIQUE",
  },
  {
    code: "NA",
    display_name: "NAMIBIA",
  },
  {
    code: "NC",
    display_name: "NEW CALEDONIA",
  },
  {
    code: "NE",
    display_name: "NIGER",
  },
  {
    code: "NF",
    display_name: "NORFOLK ISLAND",
  },
  {
    code: "NG",
    display_name: "NIGERIA",
  },
  {
    code: "NI",
    display_name: "NICARAGUA",
  },
  {
    code: "NL",
    display_name: "NETHERLANDS",
  },
  {
    code: "NO",
    display_name: "NORWAY",
  },
  {
    code: "NP",
    display_name: "NEPAL",
  },
  {
    code: "NR",
    display_name: "NAURU",
  },
  {
    code: "NU",
    display_name: "NIUE",
  },
  {
    code: "NZ",
    display_name: "NEW ZEALAND",
  },
  {
    code: "OM",
    display_name: "OMAN",
  },
  {
    code: "PA",
    display_name: "PANAMA",
  },
  {
    code: "PE",
    display_name: "PERU",
  },
  {
    code: "PF",
    display_name: "FRENCH POLYNESIA",
  },
  {
    code: "PG",
    display_name: "PAPUA NEW GUINEA",
  },
  {
    code: "PH",
    display_name: "PHILIPPINES",
  },
  {
    code: "PK",
    display_name: "PAKISTAN",
  },
  {
    code: "PL",
    display_name: "POLAND",
  },
  {
    code: "PM",
    display_name: "SAINT PIERRE AND MIQUELON",
  },
  {
    code: "PN",
    display_name: "PITCAIRN",
  },
  {
    code: "PR",
    display_name: "PUERTO RICO",
  },
  {
    code: "PS",
    display_name: "PALESTINIAN TERRITORY",
  },
  {
    code: "PT",
    display_name: "PORTUGAL",
  },
  {
    code: "PW",
    display_name: "PALAU",
  },
  {
    code: "PY",
    display_name: "PARAGUAY",
  },
  {
    code: "QA",
    display_name: "QATAR",
  },
  {
    code: "RE",
    display_name: "REUNION",
  },
  {
    code: "RO",
    display_name: "ROMANIA",
  },
  {
    code: "RS",
    display_name: "SERBIA",
  },
  {
    code: "RU",
    display_name: "RUSSIAN FEDERATION",
  },
  {
    code: "RW",
    display_name: "RWANDA",
  },
  {
    code: "SA",
    display_name: "SAUDI ARABIA",
  },
  {
    code: "SB",
    display_name: "SOLOMON ISLANDS",
  },
  {
    code: "SC",
    display_name: "SEYCHELLES",
  },
  {
    code: "SD",
    display_name: "SUDAN",
  },
  {
    code: "SE",
    display_name: "SWEDEN",
  },
  {
    code: "SG",
    display_name: "SINGAPORE",
  },
  {
    code: "SH",
    display_name: "SAINT HELENA",
  },
  {
    code: "SI",
    display_name: "SLOVENIA",
  },
  {
    code: "SJ",
    display_name: "SVALBARD AND JAN MAYEN",
  },
  {
    code: "SK",
    display_name: "SLOVAK REPUBLIC",
  },
  {
    code: "SL",
    display_name: "SIERRA LEONE",
  },
  {
    code: "SM",
    display_name: "SAN MARINO",
  },
  {
    code: "SN",
    display_name: "SENEGAL",
  },
  {
    code: "SO",
    display_name: "SOMALIA",
  },
  {
    code: "SP",
    display_name: "SYRIA",
  },
  {
    code: "SR",
    display_name: "SURINAME",
  },
  {
    code: "SS",
    display_name: "SOUTH SUDAN",
  },
  {
    code: "ST",
    display_name: "SAO TOME AND PRINCIPE",
  },
  {
    code: "SV",
    display_name: "EL SALVADOR",
  },
  {
    code: "SX",
    display_name: "SINT MAARTEN (DUTCH PART)",
  },
  {
    code: "SY",
    display_name: "SYRIAN ARAB REPUBLIC",
  },
  {
    code: "SZ",
    display_name: "SWAZILAND",
  },
  {
    code: "TA",
    display_name: "TANZANIA",
  },
  {
    code: "TC",
    display_name: "TURKS AND CAICOS ISLANDS",
  },
  {
    code: "TD",
    display_name: "CHAD",
  },
  {
    code: "TF",
    display_name: "FRENCH SOUTHERN TERRITORIES",
  },
  {
    code: "TG",
    display_name: "TOGO",
  },
  {
    code: "TH",
    display_name: "THAILAND",
  },
  {
    code: "TJ",
    display_name: "TAJIKISTAN",
  },
  {
    code: "TK",
    display_name: "TOKELAU",
  },
  {
    code: "TL",
    display_name: "TIMOR-LESTE",
  },
  {
    code: "TM",
    display_name: "TURKMENISTAN",
  },
  {
    code: "TN",
    display_name: "TUNISIA",
  },
  {
    code: "TO",
    display_name: "TONGA",
  },
  {
    code: "TR",
    display_name: "TURKEY",
  },
  {
    code: "TT",
    display_name: "TRINIDAD",
  },
  {
    code: "TV",
    display_name: "TUVALU",
  },
  {
    code: "TW",
    display_name: "TAIWAN, PROVINCE OF CHINA",
  },
  {
    code: "TZ",
    display_name: "TANZANIA",
  },
  {
    code: "UA",
    display_name: "UKRAINE",
  },
  {
    code: "UG",
    display_name: "UGANDA",
  },
  {
    code: "US",
    display_name: "UNITED STATES",
  },
  {
    code: "UY",
    display_name: "URUGUAY",
  },
  {
    code: "UZ",
    display_name: "UZBEKISTAN",
  },
  {
    code: "VA",
    display_name: "VATICAN CITY",
  },
  {
    code: "VC",
    display_name: "SAINT VINCENT and THE GRENADINES",
  },
  {
    code: "VE",
    display_name: "VENEZUELA",
  },
  {
    code: "VG",
    display_name: "VIRGIN ISLANDS, BRITISH",
  },
  {
    code: "VI",
    display_name: "VIRGIN ISLANDS, U.S.",
  },
  {
    code: "VN",
    display_name: "VIET NAM",
  },
];
