import styled from "styled-components";
import * as mq from "../../../styles/mediaQueries";

export const QuoteCardWrapper = styled.div`
  padding: 0px 1px;
  ${mq.md} {
    display: none;
  }
`;
export const QuotecardComponent = styled.div`
  margin: 10px auto;
`;
export const QuoteCard1KnowMorePlanSection = styled.div`
  background: var(--primary-color-light);
  padding: 3px 0px;
  border-radius: 10px;
  color: #0056fe;
  font-size: 10px;
  position: relative;
  top: 8px;
  font-family: Inter;
  font-weight: 500;
  display: block;
  text-align: center;
  cursor: pointer;
`;

export const SectionWrapper = styled.div`
  display: flex;
  border: 2px solid #e6e6e6;
  border-radius: 10px;
  position: relative;
  ${"" /* box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25); */}
  background-color: #fff;
  width: 100%;
  height: 145px;
`;
export const QuotecardSection = styled.div`
  position: relative;
  width: 24%;

  text-align: center;
  position: relative;
  font-family: "Inter";
`;
