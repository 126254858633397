import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";

import { useMemo } from "react";
import {
  useGetCartQuery,
  useGetDistributorSummaryQuery,
} from "../../../rider/services/rider";
import Selectt from "../../../ProposalPage/components/Selectt";
import {
  setDistributorSummary,
  setRedirectionTriggered,
} from "../../../ProposalPage/ProposalPage.slice";

const HdfcDistributor = () => {
  const { data } = useGetDistributorSummaryQuery();
  const { data: cartData } = useGetCartQuery();
  const alias = cartData?.product?.company?.alias;
  const { selectedQuote } = useSelector(state => state.quotes);
  const isSell =
    selectedQuote?.journey_name === "S" ||
    selectedQuote?.journey_name === "POS";
  const options = useMemo(() => {
    if (!data?.data[0]) return [];
    return data?.data[0]?.flscodedetails.map(item => ({
      code: item.flscd,
      display_name: item.flsnm,
    }));
  }, [data]);
  const { distributor_summary, redirection_triggered } = useSelector(
    state => state.proposalForm,
  );
  const flscodedetails = data?.data[0]?.flscodedetails;
  const dispatch = useDispatch();
  if (isSell && alias === "hdfc_life")
    return (
      <>
        <FieldWrap>
          <Label>Agent Details</Label>
          <InputWrapper>
            <Selectt
              options={options}
              label="Agent Details"
              selectedValue={distributor_summary?.flscd}
              error={
                !distributor_summary &&
                redirection_triggered &&
                "This field is required"
              }
              handleChange={val => {
                dispatch(
                  setDistributorSummary(
                    flscodedetails.find(item => item.flscd === val),
                  ),
                );
                dispatch(setRedirectionTriggered(false));
              }}
            ></Selectt>
          </InputWrapper>
        </FieldWrap>
      </>
    );
  else return <></>;
};

export default HdfcDistributor;
export const FieldWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0;
`;
export const Label = styled.label`
  width: 50%;
  font-size: 18px;
`;
const InputWrapper = styled.div`
  width: 50%;
`;
