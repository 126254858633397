import { Divider } from "@mui/material";
import React from "react";
import styled from "styled-components";

const CustomCompareSkeletonLoaderMobile = () => {
  return (
    <SkeletonContainerStyled>
      <div className="loader-header">
        <div className="ic-lg"></div>
        <div className="ic-name">
          <div></div>
          <div></div>
        </div>
        <div className="premium"></div>
      </div>
      <Divider
        sx={{
          margin: ".5rem 0",
          backgroundColor: "#e7f0f7",
        }}
      />
      <div className="loader-body-1">
        <div>
          <div></div>
          <div></div>
        </div>
        <div>
          <div></div>
          <div></div>
        </div>
        <div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="loader-body-2">
        <div>
          <p></p>
          <p></p>
        </div>
        <div>
          <p></p>
          <p></p>
        </div>
      </div>
      <div className="loader-body-3">
        <div>
          <p></p>
          <p></p>
        </div>
        <div>
          <p className="delete"></p>
        </div>
      </div>
    </SkeletonContainerStyled>
  );
};

export default CustomCompareSkeletonLoaderMobile;

const SkeletonContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 6px 12px 20px #eaf5fe;
  div {
    animation: zoomAnimation 0.7s infinite alternate;
    @keyframes zoomAnimation {
      0% {
        transform: scale(0.96);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  .loader-header {
    display: flex;
    div {
      margin: 10px;
    }
    .ic-lg {
      width: 25%;
      background-color: #efefef;
    }
    .ic-name {
      width: 50%;
      div {
        margin: 5px;
        height: 12px;
        background-color: #efefef;
      }
    }
    .premium {
      width: 35%;
      background-color: #efefef;
    }
  }
  .loader-body-1 {
    display: flex;
    justify-content: space-around;
    div {
      width: 30%;
      div {
        width: 90%;
        height: 12px;
        margin: 5px auto;
        background-color: #efefef;
      }
    }
  }
  .loader-body-2 {
    display: flex;
    justify-content: space-around;
    div:nth-child(1) {
      width: 40%;
    }
    div:nth-child(2) {
      width: 55%;
    }
    div {
      p {
        width: 90%;
        height: 12px;
        margin: 5px auto;
        background-color: #efefef;
      }
    }
  }
  .loader-body-3 {
    display: flex;
    justify-content: space-between;
    div:nth-child(1) {
      width: 60%;
    }
    div:nth-child(2) {
      margin-right: 20px;
    }
    div {
      p {
        width: 90%;
        height: 12px;
        margin: 5px auto;
        background-color: #efefef;
      }
      .delete {
        height: 30px;
        width: 30px;
        border-radius: 50%;
      }
    }
  }
`;
