import React, { useState } from "react";
import ShareViaForm from "./ShareForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import * as yup from "yup";
import TextInput from "../EditDetailsSection/components/TextInput";
import { InputAdornment } from "@material-ui/core";
import { MdMailOutline } from "react-icons/md";
import { useShareQuoteMutation } from "../../../rider/services/rider";
import { selectQuotes, selectSelectedQuotesToShare } from "../../Quotes.slice";

const ShareOnMailForm = ({ imageUrl }) => {
  const [shareQuotes, { isLoading, isError }] = useShareQuoteMutation();
  const allProducts = useSelector(selectSelectedQuotesToShare);
  const quotes = useSelector(selectQuotes);
  const { data } = useSelector(state => state.inputForm);
  let allQuotes = Object.keys(quotes).map(item => quotes[item]);
  let productIds = allQuotes.map(item => item && item[0]?.product?.id);
  let allProductIds = productIds.filter(item => item && item);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { email: data.email || "" },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = async formData => {
    // const { data } = await patchEnquiries({
    //   input: {
    //     selected_addons: addedRiders,
    //   },
    // });
    shareQuotes({
      purpose: "quote",
      email: formData.email,
      via: "email",
      image_to_send: imageUrl,
      product_ids: allProducts,
      sms: "",
      mode: ["EMAIL"],
      stage: "QUOTE",
      whatsapp: "",
    }).then(data => {
      if (data.error) {
        toast.error("Sharing Failed!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.success("Email Shared Successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
  };
  const [email, setEmail] = useState(data.email);
  return (
    <ShareViaForm onSubmit={handleSubmit(onSubmit)} isLoading={isLoading}>
      <TextInput
        error={errors.email && errors.email.message}
        value={email}
        onChange={evt => {
          register("email").onChange(evt);
          setEmail(evt.target.value);
          setValue("email", evt.target.value);
        }}
        label=" Email*"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdMailOutline className="fs-6" />
            </InputAdornment>
          ),
        }}
      />
    </ShareViaForm>
  );
};

export default ShareOnMailForm;

const schema = yup.object({
  email: yup.string().email().required("Please enter email"),
});
