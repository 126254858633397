import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import "styled-components/macro";
import useOutsideClickRef from "../../custom-hooks/useOutsideClickRef";
import { getValue } from "../../utils/helper";

const FilterSelect = ({
  placeholder,
  options = [],
  selectedItem,
  wrapperStyle = {},
  inputStyle = {},
  itemStyle = {},
  listStyle = {},
  noFocus,
  onChange = () => {},
  searchable,
  onClick = () => {},
  label,
  valueStyle,
  arrow,
  arrowStyle,
  customButton = false,
  customOnClick = () => {},
  customButtonText = "",
  isDifferent,
  modal,
  increasingPercentage,
}) => {
  const [selected, setSelected] = useState(placeholder);
  const [additionalText, setAdditionalText] = useState(null);
  const [searchText, setSearchText] = useState("");
  // const [searchMode, setSearchMode] = useState(false);
  const [show, setShow] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [activeIndex, setActiveIndex] = useState(
    options.findIndex(item => item.code === selectedItem) || 0,
  );

  useEffect(() => {
    if (options.length) {
      setFilteredOptions(options);
      setActiveIndex(options.findIndex(item => item.code === selectedItem));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    let x = options.filter(item => item.code === selectedItem);

    if (x[0]) {
      setSelected(x[0].display_name);
    } else setSelected(getValue(selectedItem));
  }, [selectedItem, options]);

  useEffect(() => {
    if (
      increasingPercentage &&
      increasingPercentage === "Y" &&
      (selectedItem === "MI" || selectedItem === "LMI")
    )
      setAdditionalText("Increasing");
    else setAdditionalText("");
  }, [increasingPercentage, selectedItem]);

  const ref = useRef();
  const searchRef = useRef();
  const itemRef = useRef();
  useEffect(() => {
    setFilteredOptions(
      options.filter(item => {
        if (!searchText) return true;
        else
          return String(item.display_name)
            .toLowerCase()
            .includes(searchText.toLowerCase());
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useOutsideClickRef(ref, () => {
    setShow(false);
  });
  const handleKeyDown = evt => {
    evt.stopPropagation();

    if (show) {
      switch (evt.key) {
        case "ArrowDown":
          if (activeIndex === filteredOptions.length - 1) setActiveIndex(0);
          else setActiveIndex(prev => prev + 1);
          break;
        case "ArrowUp":
          if (!activeIndex) setActiveIndex(filteredOptions.length - 1);
          else setActiveIndex(prev => prev - 1);
          break;
        case "Enter":
          if (options.length > 0 && modal !== "Y") {
            setActiveIndex(filteredOptions[activeIndex]);
            setSelected(filteredOptions[activeIndex].display_name);
            onChange(filteredOptions[activeIndex].code);
            setShow(false);
            searchRef.current.blur();
          }
          break;
        case "Escape":
          setShow(false);
          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    if (show) {
      setActiveIndex(
        filteredOptions.findIndex(item => item.display_name === selected) || 0,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  //   useEffect(() => {
  //     if (!filteredOptions.includes(selected)) {
  //       setActiveIndex(0);
  //     }
  //   }, [selected]);
  useEffect(() => {
    if (itemRef.current) {
      itemRef.current.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [activeIndex, show]);
  return (
    <>
      <CustomWrapper
        style={wrapperStyle}
        onKeyDown={handleKeyDown}
        ref={ref}
        onClick={onClick}
      >
        <TextInput
          ref={searchRef}
          style={inputStyle}
          onClick={() => {
            setShow(!show);
          }}
        >
          {label}
          {<>&nbsp;&nbsp;</>}
          <Value
            className="filter__sticky"
            style={valueStyle}
            isDifferent={isDifferent}
          >
            {additionalText + " " + selected}
          </Value>
          <DropDownArrow show={show} style={{ ...arrowStyle }}>
            <i className="fas fa-chevron-down"></i>
          </DropDownArrow>
        </TextInput>
        {label === "Life Cover" ||
        label === "Cover till age" ||
        label === "Pay till age" ? (
          <DropDownListWithScrollBar show={show} style={listStyle}>
            {filteredOptions.map((item, index) => (
              <Item
                style={itemStyle}
                key={index}
                selected={index === activeIndex}
                ref={index === activeIndex ? itemRef : null}
                onClick={e => {
                  if (modal !== "Y") {
                    setSelected(e.target.innerText);
                  }
                  onChange(item.code);
                  setSearchText("");
                  // setSearchMode(false);
                  setShow(false);
                }}
              >
                {item.display_name}
              </Item>
            ))}
            {customButton && (
              <div className="custom-button__wrapper">
                <button
                  className="custom-button__button"
                  onClick={() => {
                    customOnClick();
                    setSearchText("");
                    // setSearchMode(false);
                    setShow(false);
                  }}
                >
                  {customButtonText || "Pay Once"}
                </button>
              </div>
            )}
          </DropDownListWithScrollBar>
        ) : (
          <DropDownList show={show} style={listStyle} autoFocus>
            {filteredOptions.map((item, index) => (
              <Item
                style={itemStyle}
                key={index}
                selected={index === activeIndex}
                ref={index === activeIndex ? itemRef : null}
                onClick={e => {
                  if (modal !== "Y") {
                    setSelected(e.target.innerText);
                  }
                  onChange(item.code);
                  setSearchText("");
                  // setSearchMode(false);
                  setShow(false);
                }}
              >
                {item.display_name}
              </Item>
            ))}
            {customButton && (
              <div className="custom-button__wrapper">
                <button
                  className="custom-button__button"
                  onClick={() => {
                    customOnClick();
                    setSearchText("");
                    // setSearchMode(false);
                    setShow(false);
                  }}
                >
                  {customButtonText || "Pay Once"}
                </button>
              </div>
            )}
          </DropDownList>
        )}
      </CustomWrapper>
    </>
  );
};

export default FilterSelect;
const CustomWrapper = styled.div`
  width: 100%;
  position: relative;
  & img {
    width: 14px;
    margin-left: 2px;
    margin-top: -1px;
  }
`;
const TextInput = styled.div`
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 8px 0;
  text-align: center;
  & .silver-color {
    color: #b0b6c1;
    margin-left: 2px;
  }
  &:focus {
    outline: none;
  }
`;
const Value = styled.div`
  display: inline-block;
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: ${props => (props.isDifferent ? "var(--primary-color)" : "#111")};
  max-width: 175px;
  white-space: pre;
  text-overflow: ellipsis;
`;
const DropDownArrow = styled.div`
  display: inline-block;
  padding-left: 4px;
  & img {
    width: 10px;
    height: 10px;
  }
`;
const DropDownList = styled.div`
  max-height: ${props => (props.show ? "200px" : "0")};
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  position: absolute;
  left: 8px;
  top: 100%;
  width: calc(100% - 16px);
  z-index: 101;
  background-color: #fff;
  /* display: ${props => (props.show ? "inline-block" : "none")}; */
  border: 3px solid #e9ecef;
  border: ${props => !props.show && "0px"};
  border-top: none;
  box-shadow: 0px 50px 100px 0px rgb(229 232 235 / 20%);
  border-radius: 0 0 3px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: block;
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #9daab93d;
    display: block;
    border-color: transparent;
    border-radius: 6px;
  }
`;

const DropDownListWithScrollBar = styled.div`
  max-height: 200px;
  position: absolute;
  left: 8px;
  top: 100%;
  width: calc(100% - 16px);
  z-index: 101;
  background-color: #fff;
  display: ${props => (props.show ? "inline-block" : "none")};
  border: 3px solid #e9ecef;
  border-top: none;
  box-shadow: 0px 50px 100px 0px rgb(229 232 235 / 20%);
  border-radius: 0 0 3px;
  overflow-y: overlay;

  ::-webkit-scrollbar {
    display: block;
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #dbdad5;
    outline: none;
    display: block;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #babac0;
  }
`;

const Item = styled.div`
  cursor: pointer;
  font-size: 11px;
  position: relative;
  min-height: 20px;
  width: 100%;
  color: #111;
  background-color: ${props => {
    if (props.selected) return "var(--primary-color)";
  }};
  color: ${props => {
    if (props.selected) return "#fff";
  }};
  padding: 4px 8px;

  &:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
`;
