import { useSelector } from "react-redux";
import useGetAmount from "./useGetAmount";
import useGetInWords from "./useGetInWords";
import { findFrequency } from "../utils/helper";

const useGetInvestmentData = ({ quoteData }) => {
  const benefit = quoteData?.maturity_benefit;
  const benefitWord = useGetInWords(benefit);
  const getMoneyAs = useSelector(state => state?.filters?.getMoneyAs);
  const { selectedQuote } = useSelector(state => state?.quotes);
  const investForValue = useSelector(state => state.filters.investFor) || 10;
  const returnsAfterValue =
    useSelector(state => state.filters.returnsAfter) || 20;
  const incomeForValue = useSelector(state => state.filters.incomeFor) || 0;
  const totalAmount = useGetAmount();
  const totalAmountWords = useGetInWords(totalAmount);
  const returnCalc = (amount, returnAmount, payTerm) => {
    if (amount === "0") return "0";
    const total = parseInt(amount) * parseInt(payTerm);
    let amt = parseInt(parseInt(returnAmount) - parseInt(total));
    if (amt <= 0) return "0";
    amt = parseFloat(amt) / parseFloat(total);
    amt = amt.toFixed(2);
    return amt.toString();
  };
  const returnPercentage = returnCalc(totalAmount, benefit, investForValue);

  // let investedAmount = useSelector(state => state.filters.investedAmount);
  const investedAmount = quoteData?.investment_amount;
  const payFrequency = useSelector(state => state.filters.payFrequency);

  const multiplier = {
    0: 1,
    1: 2,
    2: 4,
    3: 12,
  };
  const incomeMonth = () => {
    const benefit = quoteData?.maturity_benefit;
    const timeTakes = incomeForValue * parseInt(12);
    const FinalValue = parseInt(parseInt(benefit) / parseInt(timeTakes));
    return FinalValue;
  };

  const incomePerMonth = useGetInWords(incomeMonth());

  return {
    totalAmountWords,
    returnPercentage,
    benefitWord,
    getMoneyAs,
    investForValue,
    incomeForValue,
    incomePerMonth,
    returnsAfterValue: Number(returnsAfterValue),
    investedAmountInWords: useGetInWords(selectedQuote?.investment_amount),
    frequency: findFrequency(payFrequency),
    totalInvestment: useGetInWords(
      investedAmount * investForValue * multiplier[payFrequency],
    ),
    isLumpsum: getMoneyAs === "L",
    isBoth: getMoneyAs === "B",
    isIncome: getMoneyAs === "I",
    multiplier: multiplier[payFrequency],
    isSinglePay: +investForValue === 1,
  };
};
export default useGetInvestmentData;
