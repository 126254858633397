import React from "react";
import "styled-components/macro";
import styled from "styled-components";
import * as mq from "../../styles/mediaQueries";
import { BsPlus } from "react-icons/bs";
import { AdditionalText } from "../QuotesPage/components/Details/AdditionalFeatures";

function RiderCardNew() {
  return (
    <RiderCardWrapper>
      <RiderCardHeader>
        <div
          css={`
            font-size: 14px;
          `}
        >
          Critical Illness Rider
        </div>
        <AddButton>
          ₹ 1,110
          <AddIcon>
            <BsPlus />
          </AddIcon>
        </AddButton>
      </RiderCardHeader>
      <AdditionalText>
        In case of diagnosis of any of the{" "}
        <span
          css={`
            font-size: inherit;
            color: var(--primary-color);
          `}
        >
          Critical Illnesses,
        </span>{" "}
        Sum Assured chosen for Critical Illness benefit is payable.
      </AdditionalText>
      <RiderFilterSection>
        <RiderFilterInd>
          <div className="rider-filter-head">Rider Cover</div>
          <div className="rider-filter-text">1 Cr</div>
        </RiderFilterInd>
        <RiderFilterInd>
          <div className="rider-filter-head">Rider Term</div>
          <div className="rider-filter-text">5 years</div>
        </RiderFilterInd>
        <RiderFilterInd>
          <div className="rider-filter-head">Pay Term</div>
          <div className="rider-filter-text">5 years</div>
        </RiderFilterInd>
      </RiderFilterSection>
    </RiderCardWrapper>
  );
}

export default RiderCardNew;

const RiderCardWrapper = styled.div`
  width: calc(50% - 15px);
  padding: 15px;
  box-shadow: rgb(0 0 0 / 13%) 0px 1px 5px 0px;
  ${mq.sm} {
    width: 90%;
    margin-top: 20px;
  }
`;

const RiderCardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  align-items: center;
  font-family: Inter;
  font-weight: 600;
  margin-bottom: 17px;
`;

const AddButton = styled.button`
  font-family: Inter;
  font-weight: 600;
  width: 35%;
  height: 53px;
  outline: none;
  padding: 0px 10px;
  font-size: 12px;
  border: 1px solid var(--primary-color);
  background-color: white;
  color: var(--primary-color) !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: rgb(123 147 171 / 15%) 0px 10px 25px 0px;
`;

const AddIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  font-size: 14px !important;
  color: var(--primary-color) !important;
  height: 25px;
  border-radius: 50%;
  background-color: rgb(230, 243, 255);
`;

const RiderFilterSection = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 50px;
`;

const RiderFilterInd = styled.div`
  height: 100%;
  width: 31%;
  border: 1px solid var(--tertiary-shade);
  padding: 3.6px 7.2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12.2px;
  .rider-filter-head {
    font-family: Inter;
    color: var(--primary-color);
  }
  .rider-filter-text {
    font-family: Inter;
    font-weight: 500;
  }
`;
