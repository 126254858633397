import styled from "styled-components";
import * as mq from "../../../styles/mediaQueries";

export const CompanyLogo = styled.img`
  /* height: 45px; */
  width: 130px;
`;
export const Row = styled.tr`
  border-bottom: 1px solid #f2f4f6;
  height: 25px;
`;

export const TableHeader = styled.th`
  color: gray !important;
  font-size: 12px;
  font-weight: 100 !important;
  width: 170px;
  text-align: left;
`;

export const TableData = styled.td`
  text-align: right;
  font-size: 12px;
  width: 80px;
  color: black;
`;
export const TableFooter = styled.tfoot`
  height: 50px;
  color: black;
  font-size: 13px;
  background-color: #f2f4f6;
  border-radius: 10px;
`;

export const ContinueButton = styled.button`
  display: block;
  width: 100%;
  border: none;
  margin: auto;
  margin-top: 1em;
  padding: 1.2em 0;
  color: #fff;
  border-radius: 2px;
  background-color: var(--primary-color);
  text-transform: uppercase;
  :disabled {
    background-color: var(--primary-color-light);
  }
  ${mq.sm} {
  //   padding: 10px 4px;
  //   margin: 0;
  //   width: 115px;
  //   text-transform: capitalize;
  // }
`;
