import { Box, Stack, Typography, Button, Divider, Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";

import alarm from "../../../assets/images/alarm.png";
import investment from "../../../assets/images/investment.png";
import frequencyIcon from "../../../assets/images/frequency.png";
import irr from "../../../assets/images/irr.png";
import incomeBenefits from "../../../assets/images/income-benefits.png";
import wallet from "../../../assets/images/wallet.png";
import trash from "../../../assets/images/trash.png";
import { useDispatch } from "react-redux";
import { memo } from "react";
import { removeQuoteInList } from "../slice/newCompare.slice";
import useCompareTableQuote from "../hooks/useCompareTableQuote";
import * as mq from "../../../styles/mediaQueries";
import { getInWords } from "../../../utils/helper";
import CustomCompareSkeletonLoaderMobile from "../../../components/Loaders/CustomCompareSkeletonLoader/CustomCompareSkeletonLoaderMobile";

const CompareTableMobileQuote = ({ quote, i, setShowGetDetailsPopup }) => {
  const dispatch = useDispatch();
  const {
    insuranceCompany,
    product,
    investmentAmount,
    frequency,
    investFor,
    returnsAfter,
  } = quote || {};
  const {
    isLoading,
    data,
    incomeBenefit,
    maturityBenefit,
    irrValue,
    handleGetDetails,
  } = useCompareTableQuote({
    quote,
    i,
    setShowGetDetailsPopup,
  });
  return isLoading && !data ? (
    <CustomCompareSkeletonLoaderMobile />
  ) : (
    <QuoteCardContainer>
      <QuoteWrapper>
        <Header>
          <Grid
            container
            spacing={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={8}
              sm={6}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <img
                src={insuranceCompany?.logo}
                alt="IC Logo"
                height={21}
                width={46}
              />
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "12px",
                  paddingLeft: "0.625rem",
                }}
              >
                {product?.display_name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sm={6}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                variant="contained"
                size="small"
                onClick={handleGetDetails}
                sx={{
                  fontSize: ".75rem",
                  height: "32px",
                  width: "100px",
                  background: "var(--primary-color)",
                  whiteSpace: "nowrap",
                }}
              >
                {`₹ ${Number(data?.data?.[0]?.total_premium).toLocaleString(
                  "en-IN",
                )}`}
              </Button>
            </Grid>
          </Grid>

          <Divider
            sx={{
              margin: ".5rem 0",
              backgroundColor: "#e7f0f7",
            }}
          />
        </Header>
        <Body>
          <Stack
            direction="row"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Stack
              direction="column"
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Stack direction="row" spacing={0.5}>
                <img src={investment} alt="investment" width={11} height={11} />
                <Typography
                  sx={{
                    fontSize: "0.6rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  Investment Amount
                </Typography>
              </Stack>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "0.75rem",
                  fontWeight: "600",
                }}
              >
                {getInWords(investmentAmount)}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Stack direction="row" spacing={0.5}>
                <img
                  src={frequencyIcon}
                  alt="frequencyIcon"
                  width={11}
                  height={11}
                />
                <Typography
                  sx={{
                    fontSize: "0.6rem",
                  }}
                >
                  Frequency
                </Typography>
              </Stack>
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  fontWeight: "600",
                }}
              >
                {frequency?.display_name}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Stack direction="row" spacing={0.5} display="flex">
                <img src={wallet} alt="wallet" width={11} height={11} />
                <Typography
                  sx={{
                    fontSize: "0.6rem",
                  }}
                >
                  Invest For
                </Typography>
              </Stack>
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  fontWeight: "600",
                }}
              >
                {investFor?.display_name}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Stack
              direction="column"
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Stack direction="row" spacing={0.5}>
                <img src={alarm} alt="alarm" width={11} height={11} />
                <Typography
                  sx={{
                    fontSize: "0.6rem",
                  }}
                >
                  Returns After
                </Typography>
              </Stack>
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  fontWeight: "600",
                }}
              >
                {returnsAfter?.display_name}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 2,
              }}
            >
              <Stack direction="row" spacing={0.5}>
                <img
                  src={incomeBenefits}
                  alt="incomeBenefits"
                  width={11}
                  height={11}
                />
                <Typography
                  sx={{
                    fontSize: "0.6rem",
                  }}
                >
                  Income / Lumsum Benefit
                </Typography>
              </Stack>
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  fontWeight: "600",
                }}
              >
                {getInWords(incomeBenefit ? incomeBenefit : maturityBenefit)}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Stack
              direction="column"
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1.1,
              }}
            >
              <Stack direction="row" spacing={0.5}>
                <img src={irr} alt="irr" width={11} height={11} />
                <Typography
                  sx={{
                    fontSize: "0.6rem",
                  }}
                >
                  IRR (Internal Rate of Returns)
                </Typography>
              </Stack>
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  fontWeight: "600",
                }}
              >
                {irrValue} %
              </Typography>
            </Stack>
            <Stack
              direction="column"
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 0,
              }}
            >
              <TrashIconWrapper>
                <img
                  src={trash}
                  alt="trash"
                  height={16}
                  width={16}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => dispatch(removeQuoteInList(i))}
                />
              </TrashIconWrapper>
            </Stack>
          </Stack>
        </Body>
      </QuoteWrapper>
    </QuoteCardContainer>
  );
};

export default memo(CompareTableMobileQuote);

const QuoteCardContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuoteWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 6px 12px 20px #eaf5fe;
  padding: 1rem;
  ${mq.sm} {
    margin-bottom: 1rem;
  }
`;

const Header = styled(Box)`
  width: 100%;
`;
const Body = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const TrashIconWrapper = styled(Box)`
  background-color: #e7f0f7;
  border-radius: 50%;
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
