import maxlife from "./maxlife.png";
import hdfc from "./hdfc-life.png";
import icici from "./icici.png";
import kotak from "./kotak.png";
import tokio from "./edel.png";
import canara from "./canara_hsbc.png";
import bajaj from "./bajaj-life.png";
import tata from "./tata-aia.png";
import indiafirst from "./India-first.png";
import birla from "./birla-sun-life.png";
import aegon from "./aegon-life.png";
import exide from "./Exide-Life.png";
import bharti from "./Bharati-Axa.png";
import pnb from "./PNB-Metlite.png";
import sbi from "./SBI-Life.png";

const images = {
  max_life: maxlife,
  hdfc_life: hdfc,
  kotak_life: kotak,
  icici_pru: icici,
  edelweiss_tokio_life: tokio,
  canara_hsbc: canara,
  aegon_life: aegon,

  birla_sun_life: birla,
  bajaj_life: bajaj,
  tata_aia: tata,
  indiafirst_life: indiafirst,
};

export { images };
