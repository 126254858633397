import React from "react";
import { GiInjustice } from "react-icons/gi";
import styled from "styled-components";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";
import { useNavigate } from "react-router-dom";

const CreateCustomCompareMobile = () => {
  const navigate = useNavigate();
  const enquiryId = useUrlQuery("enquiryId");

  return (
    <CreateCustomCompareWrapperStyled
      onClick={() => {
        navigate(`/new-compare?enquiryId=${enquiryId}`);
      }}
    >
      <GiInjustice size={20} />
      {/* <span>Create Custom Compare</span> */}
    </CreateCustomCompareWrapperStyled>
  );
};

const CreateCustomCompareWrapperStyled = styled.div`
  width: 45px;
  height: 45px;
  opacity: 0.9;
  /* width: 80%; */
  z-index: 100;
  position: fixed;
  bottom: 60px;
  /* left: calc(50% - 40%); */
  right: 2.4%;
  /* border: 1px solid var(--primary-color); */
  /* background: rgba(255, 255, 255, 0.9); */
  color: white;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 6px; */
  border-radius: 50%;
  /* padding: 0 2rem; */
  svg {
    margin: 10px;
  }
  span {
    margin-right: 10px;
    font-size: 0.7rem;
    font-weight: 600;
  }
`;
export default CreateCustomCompareMobile;
