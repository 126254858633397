import { Box, Stack } from "@mui/material";
import matchCompare from "../../../assets/images/MatchCompare.png";
import { useSelector } from "react-redux";
import styled from "styled-components";

const CompareEmptyCard = ({ setOpenFilter }) => {
  const { quotesList } = useSelector(state => state?.newCompare);
  return (
    <div className="container">
      {(quotesList?.length ? false : true) && (
        <Stack
          mt={10}
          mb={2}
          spacing={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Stack>
            <img src={matchCompare} alt="Match Compare" width={180} />
          </Stack>
          <Stack display="flex" justifyContent="center" alignItems="center">
            <p style={{ textAlign: "center", fontWeight: "600" }}>
              No Quotes generated to show !! Create Your own comparison between
              different Investment Insurance Plans.
            </p>
            <GenerateQuoteButton onClick={() => setOpenFilter(true)}>
              Generate Quote
            </GenerateQuoteButton>
          </Stack>
        </Stack>
      )}
    </div>
  );
};

const GenerateQuoteButton = styled(Box)`
  height: 2.5rem;
  background: #f2faff;
  border-radius: 7px;
  border: 1px dashed #367cff;
  color: #367cff;
  font-size: 0.8rem;
  cursor: pointer;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
`;

export default CompareEmptyCard;
