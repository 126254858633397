import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import * as mq from "../../../../../styles/mediaQueries";

import AddOnCoverage from "./AddOnCoverage";
import RiderCardWithoutCovers from "./RiderCardWithoutCovers";

const AddonCoveragesWrap = () => {
  const { selectedQuote, ridersList } = useSelector(state => state.quotes);

  const freeRiders =
    ridersList[selectedQuote?.product?.id] !== undefined
      ? ridersList[selectedQuote?.product?.id].filter(
          item => item.rider_type === "Free",
        )
      : [];

  return (
    <Container
      css={`
        padding-top: 40px;
        padding-bottom: 40px;
        // padding-left: 110px;
        margin-left: 65px;

        ${mq.sm} {
          // padding-top: 5rem;
          padding-top: 60px;
          padding-left: 0.8rem;
          margin-left: 1px;
        }
      `}
    >
      <h4
        css={`
          margin-bottom: 30px;
          font-family: Inter;
          font-weight: 700;
          font-size: 20px;
          ${mq.sm} {
            font-weight: bold;
            font-size: 15px;
          }
        `}
      >
        Customize Your Plan By Selecting Add-Ons
      </h4>
      <AddOnCoverage quote={selectedQuote} riders={ridersList} />
      {freeRiders.length !== 0 ? (
        <FreeAddon freeRiders={freeRiders} selectedQuote={selectedQuote} />
      ) : (
        <></>
      )}
    </Container>
  );
};

const FreeAddon = ({ freeRiders, selectedQuote }) => {
  return (
    <>
      <hr style={{ border: "2px solid #dfdfdf" }} />
      <h4
        css={`
          margin-bottom: 30px;
          font-family: Inter;
          font-weight: 700;
          font-size: 20px;
          ${mq.sm} {
            font-weight: bold;
            font-size: 15px;
          }
        `}
      >
        Inbuilt Coverages
      </h4>
      <div
        css={`
          width: 90%;
          ${mq.sm} {
            width: 100%;
          }
        `}
      >
        <ul
          css={`
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 0.6em;
            margin: 10px auto 80px auto;
            ${mq.sm} {
              flex-direction: column;
            }
          `}
        >
          {freeRiders.map(item => (
            <>
              <li
                css={`
                  flex: 0 0 calc(50% - 0.3em);
                  display: flex;
                  ${mq.sm} {
                  }
                `}
              >
                <RiderCardWithoutCovers rider={item} quote={selectedQuote} />
              </li>
            </>
          ))}
        </ul>
      </div>
    </>
  );
};
export default AddonCoveragesWrap;
