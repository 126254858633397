import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useOutsideClickRef from "../../../custom-hooks/useOutsideClickRef";

const PolicyTermSelect = ({
  quoteData,
  setShowModal,
  policyTermToShow,
  setPolicyTermToShow,
}) => {
  const optionsList = quoteData?.pt_death_benefit;
  const [value, setValue] = useState(policyTermToShow);
  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(
    optionsList?.findIndex(item => item === value) || "0",
  );
  useEffect(() => {
    if (value) {
      setActiveIndex(optionsList.findIndex(item => item === value) || "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const ref = useRef();
  const itemRef = useRef();

  useEffect(() => {
    if (itemRef.current) {
      itemRef.current.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [activeIndex, show]);

  useOutsideClickRef(ref, () => {
    setShow(false);
  });
  return (
    <CustomWrapper disabled={optionsList?.length > 1 ? false : true} ref={ref}>
      <TextInput
        onClick={() => {
          if (optionsList?.length > 1)
            setShowModal ? setShowModal(true) : setShow(!show);
        }}
      >
        <Label>Policy Term : </Label>
        {policyTermToShow > 100 ? (
          <Value>Till Death</Value>
        ) : (
          <Value>{policyTermToShow} Years</Value>
        )}
        {/* <Value>{value} Years</Value> */}
        {optionsList?.length > 1 && (
          <DropDownArrow show={show}>
            <i className="fas fa-chevron-down"></i>
          </DropDownArrow>
        )}
      </TextInput>
      <DropDownList show={show} autoFocus>
        {optionsList?.map((value, index) => {
          return (
            <Item
              key={index}
              selected={index === parseInt(activeIndex)}
              ref={index === parseInt(activeIndex) ? itemRef : null}
              onClick={() => {
                setShow(false);
                setValue(value);
                setPolicyTermToShow(value);
              }}
              value={value}
            >
              {value} Years
            </Item>
          );
        })}
      </DropDownList>
    </CustomWrapper>
  );
};

export default PolicyTermSelect;
const CustomWrapper = styled.div`
  width: 100%;
  position: relative;
  cursor: ${props => (props.disabled ? "" : "pointer")};
  margin-top: 5px;
  & img {
    width: 14px;
    margin-left: 2px;
    margin-top: -1px;
  }
`;
export const TextInput = styled.div`
  background-color: transparent;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2px;
  & .silver-color {
    color: #b0b6c1;
    margin-left: 20px;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
    justify-content: end;
    flex-wrap: nowrap;
  }
`;

const Label = styled.span`
  font-size: 11px;
  font-weight: 700;
  @media (max-width: 1024px) {
    font-size: 10px;
    font-family: Inter;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
  }
`;
const Value = styled.div`
  font-weight: 700;
 font-size: 11px;
  color: ${props => (props.isDifferent ? "var(--primary-color)" : "#111")};
  white-space: pre;
  text-overflow: ellipsis;

  @media (max-width: 1024px) {
    font-size: 10px;
    color: black;
    font-family: Inter;
    font-weight: 900;
    margin: 0px 0px 0px 0px;
  }
`;
export const DropDownArrow = styled.div`
  width: 20px;
  display: flex;
  height: 20px;
  align-items: center;
  cursor: pointer;
  background: rgba(226, 240, 255, 0.5);
  border-radius: 15px;
  justify-content: center;
  margin-top: -1px;
     @media (max-width: 1024px) {
   width: 16px;
  height: 16px;
  margin:0 5px;
  }
`;
const DropDownList = styled.div`
  max-height: ${props => (props.show ? "200px" : "0")};
  transition: all 0.3s cubic-bezier(0, 1, 0, 1);
  position: absolute;
  left: 10%;
  top: 125%;
  width: 80%;
  z-index: 101;
  background-color: #fff;
  /* display: ${props => (props.show ? "inline-block" : "none")}; */
  border: 3px solid #e0f2ff;
  border: ${props => !props.show && "0px"};
  box-shadow: 0px 50px 100px 0px rgb(229 232 235 / 20%);
  border-radius: 10px;
  overflow-y: auto;
   @media (max-width: 1024px) {
   width: 100%;
  }
  ::-webkit-scrollbar {
    display: block;
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(157, 170, 185, 0.24);
    display: block;
    outline: 1px solid #708090;
    border-radius: 6px;
  }

  .custom-button-wrapper {
    width: 100%;
    position: sticky;
    bottom: 0px;
    .custom-button-button {
      width: 100%;
      padding: 6px 0;
      background-color: #fff;
      font-family: Inter;
      font-weight: 500;
      color: var(--primary-color);
      border-radius: 0 0 10px 10px;
      border: 2px solid var(--primary-color);
    }
  }
`;

const Item = styled.div`
  cursor: pointer;
  font-size: 13px;
  position: relative;
  min-height: 20px;
  text-align: center;
  width: 100%;
  line-height: 2;
  color: #111;
  background-color: ${props => {
    if (props.selected) return "var(--primary-color)";
  }};
  color: ${props => {
    if (props.selected) return "#fff";
  }};
  ${"" /* padding: 4px 0px; */}

  &:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
`;
