import styled from "styled-components";

export const DetailsButton = styled.button`
  padding: 10px;
  background: #fff;
  font-family: Inter;
  font-weight: 600;
  font-size: 12px;
  color: #0a87ff;
  border: 2px solid #0a87ff;
  border-radius: 5px;

  ${"" /* width: 100%; */}

  & ul {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;

    & li {
      font-size: 14px;
      padding: 0px 5px;
    }
    & li:not(:last-child) {
      border-right: 1px solid black;
    }
  }
`;

export const ShareContactButton = styled.button`
  background: #fff;
  font-family: Inter;
  font-weight: 600;
  font-size: 12px;
  color: var(--primary-color);
  height: 31.5px;
  width: 142px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  transition: all 0.5s ease;
  &:hover {
    color: #fff;
    background: var(--primary-color);
  }
`;
