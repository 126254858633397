import React, { useState } from "react";
import AutoComplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useEffect } from "react";
import { Popper } from "@mui/material";
import TextInput from "./TextInput";
import { styled } from "@material-ui/styles";
const Select = ({
  options = [{ code: "", display_name: "" }],
  handleChange = () => { },
  selectedValue,
  label,
  error,
  readOnly,
  noSort,
  ...props
}) => {
  const [value, setValue] = useState(null);

  const [inputValue, setInputValue] = useState("");
  const onChange = (e, value) => {
    setValue(value);
    handleChange(value.code);
  };
  useEffect(() => {
    if (selectedValue) {
      const x = options.find(item => item.code === selectedValue);
      setValue(x || null);
      setInputValue(x?.display_name || "");
    } else {
      setValue(null);
      setInputValue("");
    }
  }, [selectedValue]);
  const onInputChange = (e, newValue) => {
    setInputValue(newValue);
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: option => option.display_name,
  });

  return (
    <>
      {readOnly ? (
        <TextInput
          value={inputValue}
          label={label}
          inputProps={{ readOnly: true }}
        />
      ) : (
        <StyledAutoComplete
          filterOptions={filterOptions || ""}
          // options={
          //   noSort
          //     ? options
          //     : options.sort((a, b) =>
          //         a.display_name
          //           .toLowerCase()
          //           .localeCompare(b.display_name.toLowerCase())
          //       )
          // }
          disablePortal
          disableClearable
          blurOnSelect
          onChange={onChange}
          onInputChange={onInputChange}
          getOptionLabel={item => item.display_name || ""}
          options={options}
          value={value}
          clearOnBlur
          PopperComponent={({ style, ...props }) => (
            <Popper {...props} style={{ ...style, height: 0 }} />
          )}
          ListboxProps={{ style: { maxHeight: "50vh" } }}
          inputValue={inputValue || ""}
          isOptionEqualToValue={(item, value) => item.code === value.code}
          renderInput={params => (
            <TextInput
              {...params}
              label={label}
              error={error}
              inputProps={{
                ...params.inputProps,
                readOnly: props?.noSearch,
              }}
            />
          )}
        />
      )}
    </>
  );
};
const StyledAutoComplete = styled(AutoComplete)({
  "& .MuiOutlinedInput-root": {
    padding: "6px 8px !important",
    borderRadius: "0",
    color: "#000 !important",
    backgroundColor: "rgb(255, 255, 255)",
    "&.Mui-focused.Mui-error fieldset": {
      borderColor: "#d32f2f",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--primary-color)",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "0 !important",
  },
});
export default Select;
