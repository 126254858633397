import React, { useState } from "react";
import styled from "styled-components/macro";
import OverlayCalFirst from "./OverlayCalFirst";
import OverlayCalSecond from "./OverlayCalSecond";
import Navbar from "../../../../components/navbar/Navbar-Component";
import { LeftIcon } from "../../../rider/RiderPage";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import DifferencesButton from "./DifferencesButton";
import useUrlQuery from "../../../../custom-hooks/useUrlQuery";
export const DifferencesNav = () => {
  const enquiryId = useUrlQuery("enquiryId");
  const [currentSection, setCurrentSection] = useState(1);
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);
  const [swipe, setSwipe] = React.useState("forward");
  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
  }
  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }
  function handleTouchEnd() {
    if (touchStart - touchEnd > 100) {
      if (currentSection !== 2) {
        setCurrentSection(currentSection + 1);
        setSwipe("forward");
      }
    }
    if (touchStart - touchEnd < 100) {
      if (currentSection !== 2) {
        setCurrentSection(currentSection - 1);
        setSwipe("backward");
      }
    }
  }

  return (
    <>
      <Main>
        <div
          css={`
            width: 1500px;
            overflow-x: hidden;
          `}
        >
          <Navbar />

          <Header>
            <Link
              to={`/quote?enquiryId=${enquiryId}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <LeftIcon
                css={`
                  position: absolute;
                  left: 0px;
                  width: 180px !important;
                `}
              >
                <AiOutlineArrowLeft />
                <div
                  css={`
                    &:hover {
                      text-decoration: underline;
                    }
                  `}
                >
                  Go Back To Quote
                </div>
              </LeftIcon>
            </Link>
            <Heading>
              Comparison With Fixed Deposits And Returns Calculator
            </Heading>
          </Header>

          <Wrapper>
            <DifferencesButton setCurrentSection={setCurrentSection} />

            {currentSection === 1 && (
              <ContentContainer>
                <div
                  className={swipe}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                  onTouchMove={handleTouchMove}
                >
                  <OverlayCalFirst />
                </div>
              </ContentContainer>
            )}
            {currentSection === 2 && (
              <ContentContainer>
                <div
                  className={swipe}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                  onTouchMove={handleTouchMove}
                >
                  <OverlayCalSecond />
                </div>
              </ContentContainer>
            )}
          </Wrapper>
        </div>
      </Main>
    </>
  );
};

export default DifferencesNav;

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 90%;
  margin: auto;
`;

const ContentContainer = styled.div`
  width: 93%;
  margin: auto;
  margin-top: 25px;
  ${"" /* margin-bottom: 40px; */}
  ${"" /* background-color: rgb(240, 240, 240, 0.2); */}
  ${"" /* padding: 3%; */}
  border-radius: 10px;
`;

const Header = styled.div`
  display: flex;
  width: 85%;
  justify-content: center;
  margin: auto;
  position: relative;
  margin-top: 20px;
  margin-bottom: 22px;
  ${"" /* margin-bottom: 50px; */}
  font-size: 18px;
`;

const Heading = styled.p`
  font-family: Inter;
  font-weight: 600;
  margin: 0px;
`;
