import education from "./Json/Miscellaneous/education.json";
import occupation from "./Json/Miscellaneous/occupation.json";
import companies from "./Json/Companies/companies.json";
import proof from "./Json/Proofs/Proofs.json";
export const educationOption = Object.keys(education).map(item => ({
  code: item,
  display_name: education[item],
}));
export const occupationOption = Object.keys(occupation).map(item => ({
  code: item,
  display_name: occupation[item],
}));
export const idProofOption = Object.keys(proof.id).map(item => ({
  code: item,
  display_name: proof.id[item],
}));
export const incomeProofOption = Object.keys(proof.income).map(item => ({
  code: item,
  display_name: proof.income[item],
}));
export const ageProofOptions = Object.keys(proof.age).map(item => ({
  code: item,
  display_name: proof.age[item],
}));
export const addressProofOptions = Object.keys(proof.address).map(item => ({
  code: item,
  display_name: proof.address[item],
}));
export const eiaOptions = Object.keys(companies.eia).map(item => ({
  code: item,
  display_name: companies.eia[item],
}));
export const indTypeOptions = Object.keys(companies.industry_type).map(
  item => ({
    code: item,
    display_name: companies.industry_type[item],
  }),
);
export const industryNameOptions = Object.keys(companies.industry_names).map(
  item => ({
    code: item,
    display_name: companies.industry_names[item],
  }),
);

export const eduToCode = {
  post_graduate: "GR",
  graduate: "GR",
  "12th_pass": "HSC",
  "10th_pass": "MT",
};

export const occupationToCode = {
  salaried_gov: "SG",
  salaried: "SN",
  self_employed: "B",
  professional: "P",
};
