import { useSelector } from 'react-redux';
import './cardskeletonloader.scss';

const CardSkeletonLoader = ({ noOfCards = 1 }) => {
  const getMoneyAs = useSelector((state) => state.filters.getMoneyAs);
  return Array(noOfCards)
    .fill('-')
    .map((_, i) => (
      <div id='u_0_v' key={i}>
        <div className='lightui1'>
          <div className='lightui1-shimmer'>
            <div className='_2iwr'></div>
            <div className='_2iws'></div>
            <div className='_2iwt'></div>
            <div className='_2iwu'></div>

            <div className='_2iww'></div>
            <div className='_2iwv'></div>
            <div className='_2iwx'></div>
            <div className='_2iwy'></div>

            <div className='_2iw-'></div>
            <div className='_2ix0'></div>
            <div className='_2iwz'></div>
            <div className='_2iw_'></div>

            <div className='_2ix2'></div>
            <div className='_2ix1'></div>
            <div className='_2ix3'></div>
            <div className='_2ix4'></div>

            <div className='_2ix5'></div>
            <div className='_2ix6'></div>
            {getMoneyAs === 'L' ? <></> : <div className='_2ix7'></div>}
            {/* <div className="_2ix6"></div>
            <div className="_2ix7"></div>
            <div className="_2ix8"></div>
            <div className="_2ix9"></div>
            <div className="_2ix-"></div>
            <div className="_2ix10"></div> */}
          </div>
        </div>
      </div>
    ));
};

export default CardSkeletonLoader;
