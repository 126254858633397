import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import * as mq from "../../styles/mediaQueries";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import { clearQuotes, removeQuote } from "./ComparePlanPopupSlice";
import { FaTimes } from "react-icons/fa";
import { getAnnualIncome } from "../QuotesPage/components/EditDetailsSection/components/helper";
import { useNavigate } from "react-router";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import { setShowDifference } from "./Compare.slice";
import { usePutCompareProductsMutation } from "../rider/services/rider";
import ButtonLoader from "../../components/Loaders/ButtonLoader/ButtonLoader";

function ComparePlanPopup() {
  const matches = useMediaQuery(`${mq.mdForHook}`);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const enquiryId = useUrlQuery("enquiryId");
  const { compareQuotes } = useSelector(state => state.compareQuotes);
  const {
    frontendData: { investmentcompanies },
  } = useSelector(state => state.frontendBoot);

  const [putCompareProducts, { isLoading }] = usePutCompareProductsMutation();

  const handleCompareClick = () => {
    putCompareProducts({
      products: compareQuotes.map(quote => ({
        company_alias: quote.company_alias,
        product_id: quote.product.id,
        investment_insurance_id: quote.investment_insurance_id,
        different_quote: quote.differentQuote ? "Y" : "N",
      })),
    }).then(() => {
      compareQuotes.length >= 2 && navigate(`/Compare?enquiryId=${enquiryId}`);
    });
    dispatch(setShowDifference(false));
  };

  return (
    <div
      css={`
        ${mq.md} {
          display: none;
        }
      `}
    >
      <PopupWrapper
        className="d-flex justify-content-evenly"
        activate={compareQuotes.length}
      >
        {matches && (
          <div
            css={`
              width: 100%;
              margin-top: -5px;
              font-size: 16px;
              display: flex;

              justify-content: flex-end;
            `}
            onClick={() => dispatch(clearQuotes())}
          >
            <FaTimes />
          </div>
        )}
        {!matches
          ? [0, 1, 2].map(index => {
              return compareQuotes[index] ? (
                <div
                  className="quote_to_compare d-flex align-items-center position-relative h-100"
                  key={index}
                >
                  <div className="product_img_container d-flex align-items-center">
                    <img
                      src={
                        investmentcompanies[compareQuotes[index]?.company_alias]
                          .logo
                      }
                      alt="PRODUCT_IMG"
                      className="w-100"
                    />
                  </div>
                  <div className="product_info_container text-center flex-fill">
                    <span className="product_name">
                      {compareQuotes[index].product.name}
                    </span>
                    <br />
                    <span className="product_cover">
                      Cover: ₹{" "}
                      {getAnnualIncome(compareQuotes[index]?.investment_amount)}
                    </span>
                  </div>
                  <span className="cancle_btn position-absolute">
                    <i
                      className="far fa-times-circle"
                      onClick={() =>
                        dispatch(removeQuote(compareQuotes[index]))
                      }
                    ></i>
                  </span>
                </div>
              ) : (
                <div className="add_plan d-flex align-items-center">
                  Select Plan To Compare
                </div>
              );
            })
          : [0, 1].map(index => {
              return compareQuotes[index] ? (
                <div
                  className="quote_to_compare d-flex align-items-center position-relative h-100"
                  key={index}
                >
                  <div className="product_img_container d-flex align-items-center">
                    <img
                      src={
                        investmentcompanies[compareQuotes[index]?.company_alias]
                          .logo
                      }
                      alt="PRODUCT_IMG"
                      className="w-100"
                    />
                  </div>
                  <div className="product_info_container text-center flex-fill">
                    <span className="product_name">
                      {compareQuotes[index].product.name}
                    </span>
                    <br />
                    <span className="product_cover">
                      Cover: ₹{" "}
                      {getAnnualIncome(compareQuotes[index].investment_amount)}
                    </span>
                  </div>
                  <span className="cancle_btn position-absolute">
                    <i
                      className="far fa-times-circle"
                      onClick={() =>
                        dispatch(removeQuote(compareQuotes[index]))
                      }
                    ></i>
                  </span>
                </div>
              ) : (
                <div className="add_plan d-flex align-items-center text-center">
                  Select Plan To Compare
                </div>
              );
            })}
        <div className="d-flex justify-content-between align-items-center">
          <button
            onClick={() => handleCompareClick()}
            className="btn compare_now_btn"
            disabled={compareQuotes.length <= 1}
            css={`
              background-color: ${compareQuotes.length >= 2 &&
              "var(--primary-color) !important"};
              color: ${compareQuotes.length >= 2 && "white !important"};
            `}
          >
            {isLoading ? <ButtonLoader /> : "Compare now"}
          </button>

          {!matches && (
            <button
              className="btn close_popup_btn mx-2"
              onClick={() => dispatch(clearQuotes())}
            >
              Close
            </button>
          )}
        </div>
      </PopupWrapper>
    </div>
  );
}

export default ComparePlanPopup;

const PopupWrapper = styled.div`
  position: fixed;
  box-shadow: 0 -5px 6px 0 rgb(0 0 0 / 16%);
  bottom: ${props => (props.activate ? "0px" : "-100%")};
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #fff;
  z-index: 9999999;

  ${mq.md} {
    padding: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1em;
  }
  .btn {
    background-color: rgb(223, 225, 230);
    color: rgb(122, 134, 154);
    height: fit-content;
    font-size: 12px;
    ${mq.md} {
      color: #000;
      font-size: 15px;
    }
  }
  .quote_to_compare {
    background-color: #e2f0ff;
    box-shadow: 4px 4px 0 0 rgba(0, 86, 254, 0.1);
    ${"" /* background-color: #f4f5f7; */}
    .product_img_container {
      width: 85px;
      height: 55px;
      border: 1px solid #dfe1e6;
      background-color: #fff;
      border-radius: 5px;
      padding: 5px;
    }
    .product_name {
      font-size: 11px;
      color: var(--secondary-text);
    }
    .product_cover {
      color: #7a869a !important;
      font-size: 10px !important;
    }
    .cancle_btn {
      top: -8px;
      right: -8px;
      font-size: 16px;
      cursor: pointer;
      ${mq.md} {
        font-size: 14px;
      }
    }
  }

  .add_plan {
    font-size: 12px;
    color: var(--secondary-text);
  }

  .quote_to_compare,
  .add_plan {
    width: 22%;
    border: 1px dashed #dfe1e6;
    border-radius: 4px;
    padding: 12px;
    box-shadow: 0 2px 9px 0 hsl(0deg 0% 87% / 30%);
    ${mq.md} {
      width: 45%;
      padding: 10px 5px;
      height: 80px !important;
    }
  }
  .add_plan {
    justify-content: center;
  }
`;
