import React from "react";
import "styled-components/macro";
import { yupResolver } from "@hookform/resolvers/yup";
import FormWithTitle from "../../FormWithTitle/FormWithTitle";
import * as mq from "../../../../../styles/mediaQueries";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import Select from "../../Select";
import {
  financialAndFamilyGoal,
  lifeStage,
  riskProfile,
} from "../../QuestionOption";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../../input.slice";
import { form3 } from "../../../validations";

function Question({ setCurrent }) {
  const dispatch = useDispatch();
  const { investmentinputpage } = useSelector(
    state => state.frontendBoot.frontendData,
  );

  const getLocalData = () => {
    const field_2 = localStorage.getItem("Investment Question Details");
    if (field_2) return JSON.parse(field_2);
    else
      return {
        risk_profile: "",
        life_stage: "",
        financial_and_familyGoal: "",
      };
  };
  const {
    setValue,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(form3),
    mode: "onChange",
    defaultValues: getLocalData(),
  });

  const [risk, setRisk] = React.useState(getLocalData().risk_profile);
  const [life, setLife] = React.useState(getLocalData().life_stage);
  const [financial, setFinancial] = React.useState(
    getLocalData().financial_and_familyGoal,
  );

  const onSubmit = data => {
    setCurrent(prev => prev + 1);
    dispatch(setData({ suitability: { ...data } }));
    localStorage.setItem(
      "Investment Question Details",
      JSON.stringify({
        risk_profile: data.risk_profile,
        life_stage: data.life_stage,
        financial_and_familyGoal: data.financial_and_familyGoal,
      }),
    );
  };

  return (
    <div>
      <FormWithTitle
        onSubmit={handleSubmit(onSubmit)}
        current={2}
        title={
          investmentinputpage?.investmentinputpage2
            ? investmentinputpage?.investmentinputpage2
            : "<h1>Some More Questions</h1>"
        }
      >
        <ProfessionForm>
          <ProfessionInputWrapper>
            <div className="w-100 ">
              <Select
                label="Risk Profile*"
                options={riskProfile}
                error={errors.risk_profile && errors.risk_profile.message}
                handleChange={val => {
                  setRisk(val);
                  // setSelectedValue((prev) => ({ ...prev, risk_profile: item }));
                  setValue("risk_profile", val);
                  trigger("risk_profile");
                }}
                selectedValue={risk}
              />
            </div>

            <div className="w-100 mt-4">
              <Select
                label="Life Stage*"
                options={lifeStage}
                error={errors.life_stage && errors.life_stage.message}
                handleChange={val => {
                  setLife(val);
                  // setSelectedValue((prev) => ({ ...prev, life_stage: item }));
                  setValue("life_stage", val);
                  trigger("life_stage");
                }}
                selectedValue={life}
              />
            </div>

            <div className="w-100 mt-4">
              <Select
                label="Financial and Family Goal*"
                options={financialAndFamilyGoal}
                error={
                  errors.financial_and_familyGoal &&
                  errors.financial_and_familyGoal.message
                }
                handleChange={val => {
                  setFinancial(val);

                  setValue("financial_and_familyGoal", val);
                  trigger("financial_and_familyGoal");
                }}
                selectedValue={financial}
              />
            </div>
          </ProfessionInputWrapper>
          <ProfessionButton>
            <div className="w-100 d-flex justify-content-center">
              <div className="d-flex justify-content-center w-100">
                <button
                  className="d-flex justify-content-center w-100 flex-1 align-items-center
                 m-1 border-0 py-3 btn text-black fw-bolder "
                  css={`
                    background-color: rgba(215, 228, 251, 0.695);
                    ${mq.sm} {
                      padding: 15px 0x;
                      font-size: 14px;
                    }
                  `}
                  onClick={() => {
                    setCurrent(prev => prev - 1);
                  }}
                >
                  <span> Go Back</span>
                </button>
                <button
                  type="submit"
                  className="d-flex justify-content-center w-100 flex-1 align-items-center
                 m-1 border-0  bg-primary btn text-white"
                >
                  <span>Continue</span>
                </button>
              </div>
            </div>
          </ProfessionButton>
        </ProfessionForm>
      </FormWithTitle>
    </div>
  );
}

export default Question;

const ProfessionForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${mq.sm} {
    width: 100%;
  }
`;

const ProfessionInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  ${mq.sm} {
    width: 100%;
  }
`;

const ProfessionButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5em;

  ${mq.sm} {
    width: 100%;
  }
`;
export const Button = styled.button`
  width: 100%;
  background: var(--primary-color);
  padding: 20px 0;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  font-family: Inter;
  ${mq.md} {
    width: 100%;
    font-size: 14px;
  }
  ${mq.sm} {
    width: 100%;
    font-size: 14px;
  }
`;
