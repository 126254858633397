import { Box, Stack, Typography, styled } from "@mui/material";
import magic from "../../../assets/images/magic-wand.svg";
import TraceId from "../../QuotesPage/components/TraceId";
import { useNavigate } from "react-router-dom";
import * as mq from "../../../styles/mediaQueries";
import { MdClear } from "react-icons/md";

const CompareHeaderMobile = () => {
  const navigate = useNavigate();

  return (
    <div
      className="container"
      // style={{ background: "rgba(227, 235, 241, 0.41)" }}
    >
      <HeaderBoxWrapper>
        <Stack
          direction="row"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          <Stack direction="row" spacing={2}>
            <Stack direction="row">
              <img src={magic} alt="" height={30} width={30} />
            </Stack>
            <Stack direction={"column"}>
              <Typography variant="body1" fontWeight={600}>
                Create my plan
              </Typography>
              <TraceId label="CRN/Trace Id : " />
            </Stack>
          </Stack>
          <Stack>
            <MdClear
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            />
          </Stack>
        </Stack>
      </HeaderBoxWrapper>
    </div>
  );
};

export default CompareHeaderMobile;

const HeaderBoxWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mq.sm} {
    width: 100%;
    margin-top: 1rem;
  }
`;
