import React from "react";
import * as mq from "../../../../styles/mediaQueries";
import styled from "styled-components/macro";
import ButtonLoader from "../../../../components/Loaders/ButtonLoader/ButtonLoader";
const ShareViaForm = ({
  children,
  onSubmit = () => {},
  icon,
  submitButtonText = "Share",
  submitButtonIcon = "fas fa-share",
  isLoading = false,
  errorMessage = "",
  ...props
}) => {
  return (
    <div
      css={`
        width: 100%;
        // margin: 0.4em 0;
      `}
    >
      <form
        css={`
          display: flex;
          justify-content: space-between;
          gap: 1em;
          margin-bottom: 8px;
        `}
        onSubmit={onSubmit}
        autoComplete="off"
        {...props}
      >
        <div
          css={`
            display: flex;
            align-items: center;
            flex: 1;
            // border: 1px solid #cfcfcf;
            gap: 1em;
            min-width: 0;
            padding: 0 1em;
            ${mq.sm} {
              padding: 0;
            }
          `}
        >
          <div
            css={`
              font-size: 2em;
              color: #56595e;
            `}
          >
            {icon}
          </div>
          {children}
        </div>
        <button
          type="submit"
          css={`
            width: 120px;
            background-color: var(--primary-color);
            border: none;
            color: #fff;
            padding: 0.8em;
            font-size: 15px;
            border-radius: 0;
            ${mq.sm} {
              width: 75px;
              font-size: 12px;
            }
          `}
        >
          {isLoading ? (
            <ButtonLoader />
          ) : (
            <>
              {submitButtonText}
              <i className={`${submitButtonIcon} ms-2`} />
            </>
          )}
        </button>
      </form>
      <ErrorMsg
        css={`
          font-size: 1rem;
          position: static;
        `}
      >
        {errorMessage}
      </ErrorMsg>
    </div>
  );
};
export default ShareViaForm;

export const MoreOptionsButton = styled.button`
  width: 100%;
  background: var(--primary-color);
  border: none;
  color: #fff;
  padding: 0.8em;
  font-size: 15px;
`;
export const ShareFormInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-size: 15px;
`;

export const ErrorMsg = styled.div`
  position: ${props =>
    props.rel ? "relative" : props.checkbox ? "absolute" : ""};
  color: #d8000c;
  font-size: 10px;
  padding-bottom: ${props => (props.checkbox ? "0px" : props && "10px")};
  left: ${props => props.checkbox && "18px"};
  bottom: ${props => props.checkbox && "0px"};
`;
