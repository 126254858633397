import React from "react";
import styled from "styled-components/macro";
import * as mq from "../../../styles/mediaQueries";
import LifeInsurance from "../../../assets/images/LifeInsurance.png";
import RiderCard from "../../../assets/images/RiderCard.png";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";

function PersonalizedDetailsBox() {
  const enquiryId = useUrlQuery("enquiryId");
  const { investmentquotepage } = useSelector(
    state => state.frontendBoot?.frontendData,
  );
  const matches = useMediaQuery(`${mq.lgForHook}`);
  return (
    <>
      {/* <DetailsBox>
        <div>
          <CardData titleColor={"yellow"}>
            {investmentquotepage?.savingplandefinition
              ? parse(investmentquotepage?.savingplandefinition)
              : parse(
                  "<h1>What Are Savings plans?</h1><p>Savings plans are financial solutions made to encourage responsible saving while providing consistent returns that aid in reaching your financial objectives. These plans also guarantee your loved ones' financial stability in the event of your passing.</p>",
                )}
          </CardData>
        </div>
      </DetailsBox> */}

      {investmentquotepage?.investmentdefinition &&
        investmentquotepage?.investmentdefinition !== "NULL" && (
          <DetailsBox>
            <div>
              <CardData titleColor={"yellow"}>
                {investmentquotepage?.investmentdefinition
                  ? parse(investmentquotepage?.investmentdefinition)
                  : parse(
                      "<h1>What are Endowment Policy Plans?</h1><p>Endowment policy plans are a combination of insurance and savingsplans. In case, when the policyholder survives after the maturityperiod of the policy, he/she gets the total sum assured. Not onlythis, but any endowment plan also ensures that the policyholdershould get some amount throughout the policy period.</p>",
                    )}
              </CardData>
              <div
                css={`
                  ${mq.sm} {
                    display: flex;
                    justify-content: start;
                  }
                `}
              >
                <Link
                  css={`
                    text-decoration: none;
                    font-size: 10px;
                    color: #757575;
                    font-weight: 600;
                    display: ${investmentquotepage?.investmentdefinition &&
                    investmentquotepage?.investmentdefinition !== "NULL"
                      ? "block"
                      : "none"};
                    letter-spacing: 0.6px;
                  `}
                  to={`/investment-information/?enquiryId=${enquiryId}`}
                >
                  Tap to Learn More
                </Link>
              </div>
            </div>
            {!matches && (
              <div
                css={`
                  display: flex;
                  align-items: center;
                  height: 100%;
                `}
              >
                <img
                  src={LifeInsurance}
                  alt=""
                  className="information-card-image"
                />
              </div>
            )}
          </DetailsBox>
        )}

      {investmentquotepage?.riderdefinition &&
        investmentquotepage?.riderdefinition !== "NULL" && (
          <DetailsBox>
            <div>
              <CardData titleColor={"yellow"}>
                {investmentquotepage?.riderdefinition
                  ? parse(investmentquotepage?.riderdefinition)
                  : parse(
                      "<h1>What is Riders ?</h1><p>Riders Are An Optional Benefits One Can Choose To Extend Their Benefits Over Base Plan At Some Extra Cost.</p>",
                    )}
              </CardData>
              <div
                css={`
                  ${mq.sm} {
                    display: flex;
                    justify-content: start;
                  }
                `}
              >
                <Link
                  css={`
                    text-decoration: none;
                    font-size: 10px;
                    color: #757575;
                    font-weight: 600;
                    display: ${investmentquotepage?.riderdefinition &&
                    investmentquotepage?.riderdefinition !== "NULL"
                      ? "block"
                      : "none"};
                    letter-spacing: 0.6px;
                  `}
                  to={`/rider-information/?enquiryId=${enquiryId}`}
                >
                  Tap to Learn More
                </Link>
              </div>
            </div>
            {!matches && (
              <div
                css={`
                  display: flex;
                  align-items: center;
                  height: 100%;
                `}
              >
                <img
                  src={RiderCard}
                  alt=""
                  className="information-card-image"
                />
              </div>
            )}
          </DetailsBox>
        )}
    </>
  );
}

export const DetailsBox = styled.div`
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(250, 250, 250);
  border: 1px solid var(--border-light);
  &:hover {
    border: 1px solid var(--primary-color);
  }

  .information-card-image {
    height: 140px;
    width: 90px;

    @media (max-width: 1200px) and (min-width: 1050px) {
      height: 95px;
      width: 65px;
    }
    @media (max-width: 1050px) and (min-width: 992px) {
      height: 85px;
      width: 55px;
    }
  }
`;

const CardData = styled.div`
  width: 100%;

  & h1 {
    font-size: 12px;
    width: 100%;
    font-weight: 500;
    border-left: 7px solid ${props => props.titleColor};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 5px;

    ${mq.lg} {
      font-size: 10.7px;
      padding-left: 4px;
    }
  }

  & p {
    margin: 15px auto;
    font-size: 12px !important;
    color: #212529 !important;
    ${mq.lg} {
      font-size: 11px !important;
    }
  }
`;

export default PersonalizedDetailsBox;
