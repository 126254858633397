import React from "react";
import { useSelector } from "react-redux";
import ShareViaForm from "./ShareForm";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../EditDetailsSection/components/TextInput";
import { InputAdornment } from "@material-ui/core";
import { allowOnlyNumbers } from "../../../InputUtils/onInput";
import { useShareQuoteMutation } from "../../../rider/services/rider";
import { selectSelectedQuotesToShare } from "../../Quotes.slice";
import { MdOutlinePhone } from "react-icons/md";
function ShareOnText({ imageUrl }) {
  const [shareQuotes] = useShareQuoteMutation();
  const allProducts = useSelector(selectSelectedQuotesToShare);
  // const quotes = useSelector(selectQuotes);
  // let allQuotes = Object.keys(quotes).map(item => quotes[item]);
  // let productIds = allQuotes.map(item => item && item[0]?.product?.id);
  // let allProductIds = allProducts.map(item => item?.product?.id);
  const { data } = useSelector(state => state.inputForm);
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: { mobile: data.mobile },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = async formData => {
    // const { data } = await patchEnquiries({
    //   input: {
    //     selected_addons: addedRiders,
    //   },
    // });
    shareQuotes({
      purpose: "quote",
      email: "",
      sms: formData.mobile,
      mode: ["SMS"],
      image_to_send: imageUrl,
      via: "sms",
      product_ids: allProducts,
      stage: "QUOTE",
      whatsapp: "",
    }).then(data => {
      if (data.error) {
        toast.error("Sharing Failed!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.success("Message Shared Successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
  };
  const [mobile, setMobile] = React.useState(data.mobile);

  return (
    <ShareViaForm onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        error={errors.mobile && errors.mobile.message}
        {...register("mobile")}
        value={mobile}
        onChange={evt => {
          register("mobile").onChange(evt);
          setMobile(evt.target.value);
          setValue("mobile", evt.target.value);
          trigger("mobile");
        }}
        onInput={allowOnlyNumbers}
        label="Mobile*"
        inputProps={{ minLength: 2, maxLength: 10, pattern: "[0-9]*" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdOutlinePhone className="fs-6" />
            </InputAdornment>
          ),
        }}
      />
    </ShareViaForm>
  );
}

export default ShareOnText;

const schema = yup.object({
  mobile: yup
    .string()
    .matches(/^[6-9]\d{9}$/, "Please enter a valid phone no.")
    .required("Phone is required."),
});
