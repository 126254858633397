import {
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import styled from "styled-components/macro";
import useSetFilter from "../../../../../../../custom-hooks/useSetFilter";
import { useSelector } from "react-redux";
import useGetInWords from "../../../../../../../custom-hooks/useGetInWords";
import { Button } from "react-bootstrap";
function InvestedAmt({ setShow }) {
  const [amount, setAmount] = useState(
    useSelector(state => state.filters.investedAmount) || "100000",
  );
  const [payFrequencyValue, setPayFrequencyValue] = useState(
    useSelector(state => state.filters.payFrequency),
  );
  const [amountFinal, setAmountFinal] = useState(
    useSelector(state => state.filters.investedAmount) || "100000",
  );
  const [freqFinal, setFreqFinal] = useState(
    useSelector(state => state.filters.payFrequency),
  );

  const getAmountWord = useGetInWords(amount);

  const handleAmountChange = val => {
    if (val === "" || val === "0") setAmount("0");
    else if (parseInt(val) === 0) setAmount("0");
    else {
      let str = val.toString();
      if (str[0] === "0") {
        str = str.substring(1);
      }
      setAmount(str);
    }
  };

  function handleSubmit() {
    setAmountFinal(amount);
    setFreqFinal(payFrequencyValue);
    setTimeout(() => {
      setShow(false);
    }, 100);
  }
  useSetFilter({ filter: "investedAmount", value: amountFinal });
  useSetFilter({ filter: "payFrequency", value: freqFinal });

  return (
    <>
      <div
        css={`
          width: 100%;
        `}
      >
        <FormControl variant="standard" css={formCss}>
          <InputLabel>Amount</InputLabel>
          <Input
            type="number"
            variant="standard"
            css={textCss}
            value={amount}
            onChange={e => handleAmountChange(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                {amount && (
                  <SelectText
                    css={`
                      font-size: 14px;
                    `}
                  >
                    {getAmountWord}
                  </SelectText>
                )}
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      {/* <button onClick={() => alert(amount)}>Click me</button> */}
      <SelectWrapper>
        <SelectText
          css={`
            text-align: center;
          `}
        >
          Pay Frequency
        </SelectText>
        <RadioGroup
          aria-labelledby="pay-frequrncy-radio-buttons"
          defaultValue="monthly"
          name="pay-frequrncy-radio-buttons"
          value={payFrequencyValue}
          onChange={e => {
            setPayFrequencyValue(e.target.value);
          }}
        >
          <RadioWrapper>
            <SelectOptions>
              <FormControlLabel
                value="3"
                control={<Radio />}
                label={<SelectText>Monthly</SelectText>}
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={<SelectText>Half Yearly</SelectText>}
              />
            </SelectOptions>
            <SelectOptions>
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={<SelectText>Quarterly</SelectText>}
              />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label={<SelectText>Yearly</SelectText>}
              />
            </SelectOptions>
          </RadioWrapper>
        </RadioGroup>
      </SelectWrapper>
      <Button css={buttonCss} onClick={handleSubmit}>
        Confirm
      </Button>
    </>
  );
}

const formCss = `
width:95%;
display:flex;
margin:auto;
`;
const textCss = `
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
font-size:14px;
font-family: Inter !important;
font-weight: 600;
`;

const buttonCss = `
width:50%;
margin:auto;
display:flex;
justify-content:center;
margin-top:30px;
text-align:center;
font-size:16px;
font-family : Inter;
`;
const RadioWrapper = styled.div`
  display: flex;
  /* flex-direction: ; */
  justify-content: space-between;
  width: 90%;
  margin: auto;
  margin-top: 15px;
`;

const SelectWrapper = styled.div`
  margin-top: 30px;
`;
const SelectOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const SelectText = styled.p`
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  margin: 0px;
  color: #000;
`;

export default InvestedAmt;
