import React, { useState, useEffect } from "react";
import api from "../../api/api";
import Navbar from "../../components/navbar/Navbar-Component";
import styled from "styled-components";
import "styled-components/macro";
import success from "../../assets/images/check1.png";

import * as mq from "../../styles/mediaQueries";
import {
  LabelText,
  ShowText,
  SuccessImage,
  SumbitButton,
} from "./ThankyouPage.style";
import {
  useGetApplicationNumberQuery,
  useGetCartQuery,
} from "../rider/services/rider";
import { useSelector } from "react-redux";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import { useNavigate } from "react-router";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader/FullScreenLoader";
import cross from "../../assets/images/wrong.png";

function ThankyouPage() {
  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const { data } = goTo();
  }, []);

  // const goTo = async (setLocation, setPaymentStatus) => {
  //   const { data } = await api.get("investment/after-payment");
  //   return data;
  // };
  const goTo = async (setLocation, setPaymentStatus) => {
    const { data } = await api.get("investment/after-payment");
    setLocation(data?.data?.redirectTo);
    setPaymentStatus(data?.data?.payment_status);
    return data;
  };
  const { isLoading, data } = useGetCartQuery();

  const enquiryId = useUrlQuery("enquiryId");
  const [location, setLocation] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const {
    frontendData: { tenant },
  } = useSelector(state => state.frontendBoot);
  const navigate = useNavigate();
  const appData = useGetApplicationNumberQuery();
  const appNo = appData?.data?.data.proposal_reference_id;

  useEffect(() => {
    goTo(setLocation, setPaymentStatus);
  }, [data]);
  if (isLoading) return <FullScreenLoader />;

  return (
    <div>
      {" "}
      <Navbar />
      <div
        className="container"
        css={`
          margin-top: 35px;
        `}
      >
        {paymentStatus === "failed" ? (
          <Section className="col-md-12">
            <div className="container">
              <SuccessImage src={cross} />
              <div className="col-md-12">
                <ShowText failed>Payment Failed!</ShowText>
              </div>
            </div>
            <SumbitButton
              onClick={() => {
                navigate(`/quote?enquiryId=${enquiryId}`);
              }}
            >
              Go Back To Quotes
              {/* &nbsp;
              <span style={{ textTransform: "capitalize" }}>
                {location} page
              </span> */}
            </SumbitButton>
          </Section>
        ) : (
          <Section className="col-md-12">
            <div className="container">
              <SuccessImage src={success} />
              <div className="col-md-12">
                <ShowText>Thank you for choosing {tenant?.name}</ShowText>
              </div>
              <div className="col-md-12">
                <LabelText>
                  Your proposal has been submitted successfully and application
                  no. is {appNo}. <br />
                  {data?.product?.company?.name} will review your proposal and
                  we will get back to you shortly
                </LabelText>
              </div>
            </div>
            <SumbitButton
              onClick={() => {
                window.open(location, "_self");
              }}
            >
              Proceed To&nbsp;
              <span style={{ textTransform: "capitalize" }}>Proposal Page</span>
            </SumbitButton>
          </Section>
        )}
      </div>
    </div>
  );
}

export default ThankyouPage;

const Section = styled.div`
  box-shadow: 0px -3px 20px rgb(134 156 213 / 25%);
  padding: 12px 60px;
  width: 57%;
  margin: 0 auto;
  margin-bottom: 24px;
  ${mq.sm} {
    width: 100%;
    padding: 6px 10px;
  }
`;
