import React from "react";
import companyLogo from "../../../assets/images/logo/sib-logo.png";
import { useSelector } from "react-redux";
function FullScreenLoader() {
  const {
    frontendData: { investmentSettings },
  } = useSelector(state => state.frontendBoot);
  const brokerLogo = investmentSettings?.logo || companyLogo;
  return (
    <div>
      <div
        css={`
          height: 100%;
          width: 100vw;
          display: flex;
          align-items: center;
          justify-content: center;
          position: fixed;
          top: 0;
          left: 0;
        `}
        className="loader"
      >
        <img src={brokerLogo} alt="loader" width="200" />
      </div>
    </div>
  );
}

export default FullScreenLoader;
