import React, { useState } from "react";
import useGetFilterOptions from "../../../../../../../custom-hooks/useGetFilterOptions";
import { useSelector } from "react-redux";
import useSetFilter from "../../../../../../../custom-hooks/useSetFilter";
import { CustomButton, CustomGroup } from "./customButton.styled";

function InvestedFor({ setShow }) {
  const [investForValue, setInvestForValue] = useState(
    useSelector(state => state.filters.investFor),
  );
  const investForOptions = useGetFilterOptions({ filter: "pay_term" });

  useSetFilter({ filter: "investFor", value: investForValue });
  // eslint-disable-next-line no-unused-vars
  const [investForCustomOption, setInvestForCustomOption] = useState([
    { code: "1", display_name: "Pay Once" },
    ...investForOptions,
  ]);
  return (
    <>
      <CustomGroup
        color="primary"
        value={+investForValue}
        exclusive
        onChange={e => {
          setInvestForValue(e.target.value);
          setShow(false);
        }}
      >
        {investForCustomOption.length > 0 &&
          investForCustomOption.map((item, index) => (
            <CustomButton key={index} value={item.code}>
              {item.display_name}
            </CustomButton>
          ))}

        {/* {investForOptions.length > 0 &&
          investForOptions.map((item, index) => (
            <CustomButton key={index} value={item.code}>
              {item.display_name}
            </CustomButton>
          ))} */}
      </CustomGroup>
    </>
  );
}

export default InvestedFor;
