import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { BsBookmark } from "react-icons/bs";
import { BsFillBookmarkFill } from "react-icons/bs";
import * as mq from "../../../styles/mediaQueries";
import "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import {
  setShortListedPlan,
  removeShortListedPlan,
  removeShortListedPlansData,
  setShortListedPlansData,
  removeShortListedPlanTemp,
  setShortListedPlanTemp,
} from "../Quotes.slice";
import { useDidMountEffect } from "../../../custom-hooks/useDidMountEffect";
import { updateEnquiries } from "../../Landing/input.slice";

export default function BookmarkQuote({ investmentId, plans }) {
  const [change, setChange] = React.useState(false);
  const dispatch = useDispatch();
  const { ShortListedPlan, ShortListedPlansData } = useSelector(
    state => state.quotes,
  );

  const onSwitch = () => {
    setChange(!change);
    if (ShortListedPlan?.includes(investmentId)) {
      dispatch(removeShortListedPlan(investmentId));
      dispatch(removeShortListedPlansData(investmentId));
      dispatch(removeShortListedPlanTemp(investmentId));
    } else {
      dispatch(setShortListedPlan(investmentId));
      dispatch(setShortListedPlansData(plans));
      dispatch(setShortListedPlanTemp(plans));
    }
  };

  React.useEffect(() => {
    if (ShortListedPlan?.includes(investmentId)) {
      const [p] = ShortListedPlansData.filter(
        item => item?.product.id === investmentId,
      );
      if (p !== plans) {
        dispatch(removeShortListedPlansData(investmentId));
        dispatch(removeShortListedPlansData(investmentId));
        dispatch(setShortListedPlansData(plans));
        dispatch(setShortListedPlanTemp(plans));
      }
    }
  }, []);

  React.useEffect(() => {
    if (ShortListedPlan?.includes(investmentId)) {
      setChange(true);
    }
  }, [ShortListedPlan]);

  useDidMountEffect(() => {
    dispatch(
      updateEnquiries({
        input: {
          shortlistedIds: ShortListedPlan,
        },
      }),
    );
  }, [ShortListedPlan]);

  return (
    <Checkbox
      css={`
        &.MuiCheckbox-root {
          padding: 0px !important;
          &:hover {
            background: #ffff;
          }
        }
      `}
      size="large"
      icon={
        <BsBookmark
          className="bookmark-icon"
          css={`
            color: black;
            height: 18px;
            width: 18px;
            ${mq.lg} {
              height: 18px;
              width: 18px;
            }
            ${mq.xs} {
              height: 18px;
              width: 18px;
            }
          `}
        />
      }
      onClick={onSwitch}
      checkedIcon={
        <BsFillBookmarkFill
          className="bookmark-icon"
          css={`
            height: 18px;
            width: 18px;
            ${mq.lg} {
              height: 18px;
              width: 18px;
            }
            ${mq.xs} {
              height: 18px;
              width: 18px;
            }
          `}
        />
      }
      checked={ShortListedPlan?.includes(investmentId) ? true : false}
    />
  );
}
