import { styled } from "@material-ui/core";
import { TextField } from "@mui/material";
import { memo, useEffect } from "react";
import { AgeWrapper } from "../ProposalPage.style";

// const useStyle = makeStyles({
//   root: {
//     backgroundColor: " rgb(255, 255, 255)",
//     border: "1px solid rgb(207, 207, 207)",
//   },
// });

const TextInput = ({
  label,
  value,
  error,
  capitalize,
  age,
  extraMsg,
  ...props
}) => {
  //   const classes = useStyle();
  const errorElem = document.querySelector(".Mui-error");
  useEffect(() => {
    if (errorElem) {
      errorElem.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [errorElem]);
  return (
    <>
      <CustomField
        label={label}
        variant={"outlined"}
        value={value || ""}
        fullWidth
        autoComplete="off"
        capitalize={capitalize}
        error={Boolean(error)}
        helperText={error}
        {...props}
      />
      {extraMsg && !error && <span>{extraMsg}</span>}
      {age > 0 && !error ? <AgeWrapper>{age + " years"}</AgeWrapper> : <></>}
    </>
  );
};

export default memo(TextInput);
const CustomField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "14px 14px",
    backgroundColor: "rgb(255, 255, 255)",
    textTransform: prop => prop.capitalize && "capitalize",
    "@media (max-width: 576px)": {
      height: "1em !important",
    },
  },

  "& label.Mui-focused, & label.MuiFormLabel-filled, & label.Mui-focused.Mui-error":
    {
      color: "var(--primary-color)",
    },
  "& label.Mui-error": {
    color: "rgba(0, 0, 0, 0.6)",
  },

  "& .MuiOutlinedInput-root": {
    borderRadius: "0",
    "@media (max-width: 576px)": {
      fontSize: "14px !important",
    },

    "&.Mui-focused fieldset": {
      borderColor: "var(--primary-color)",
    },
    "&.Mui-focused.Mui-error fieldset": {
      borderColor: "#d32f2f",
    },
  },
});
