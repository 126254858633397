import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@material-ui/core";
import * as mq from "../../../../../../../styles/mediaQueries";

export const CustomGroup = styled(ToggleButtonGroup)({
  "&.MuiToggleButtonGroup-root": {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "5px",
  },
  [mq.md]: {
    height: "223px",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  [mq.sm]: {
    height: "181px",
    overflowY: "scroll",
    overflowX: "hidden",
  },
});

export const CustomButton = styled(ToggleButton)({
  width: "48%",
  padding: "5px",
  border: "none",
  outline: "none",
  borderRadius: "5px !important",
  fontSize: "12px",
  fontFamily: "Inter",
  fontWeight: "600",
  backgroundColor: "rgba(79, 92, 111, 0.1)",
  textTransform: "capitalize",
  // "&:active": {
  //      backgroundColor: "var(--primary-color)"
  // },
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "var(--primary-color)",
  },
  [mq.md]: {
    fontSize: "18px",
    padding: "10px",
  },
  [mq.sm]: {
    fontSize: "12px",
  },
});