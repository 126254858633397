export const educationalQualificationOptions = [
  {
    code: "1",
    display_name: "Below 10th",
  },
  {
    code: "2",
    display_name: "10th Pass",
  },
  {
    code: "3",
    display_name: "12th Pass",
  },
  {
    code: "4",
    display_name: "Graduate",
  },
  {
    code: "5",
    display_name: "Post Graduate",
  },
  {
    code: "7",
    display_name: "Illiterate",
  },
  {
    code: "14",
    display_name: "Diploma",
  },
  {
    code: "15",
    display_name: "Vocational / ITI",
  },
];

export const EduToCode = {
  post_graduate: "5",
  graduate: "4",
  diploma: "14",
  vocational_iti: "15",
  "12th_pass": "3",
  "10th_pass": "2",
  below_10th: "1",
};

export const OccToCode = {
  salaried: "salaried",
  self_employed: "self_employed",
  business_owner: "business_owner",
  agriculturist: "agriculturist",
  housewife: "housewife",
  retired: "retired",
  others: "others",
};

export const incomeProofOptions = [
  {
    code: "1016010048",
    display_name: "Salary certificate of Current financial year",
  },
  {
    code: "1016010049",
    display_name: "Form 16 A Current or last Assessment year",
  },
  {
    code: "1016010050",
    display_name: "Form 16 Current or last Assessment year",
  },
  {
    code: "1016010051",
    display_name: "Income Tax Return of Current,last Assessment year",
  },
  {
    code: "1016010052",
    display_name: "Salary Slip of Last three months",
  },
  {
    code: "1016010056",
    display_name: "Latest Appointment, Contract Letter(Reputed Co.)",
  },
  {
    code: "1016010432",
    display_name: "Latest Employment Contract",
  },
  {
    code: "1016010433",
    display_name: "Form 26 AS",
  },
  {
    code: "1016010434",
    display_name: "Audited Balance Sheet and Profit and Loss  Account",
  },
  {
    code: "1016010435",
    display_name: "Government Contract Letter / Government Work Order",
  },
  {
    code: "1016010436",
    display_name: "Pension Order",
  },
  {
    code: "1016010441",
    display_name: "Bank Statements with Salary Credits",
  },
  {
    code: "1016010471",
    display_name: "Latest salary revision letter",
  },
  {
    code: "1016010472",
    display_name: "Income Tax Return with COI",
  },
  {
    code: "1016010473",
    display_name: "Income Tax Return with Business Accounts",
  },
  {
    code: "1016010315",
    display_name: "Others",
  },
];

export const companyNameOptions = [
  {
    code: "4102",
    display_name: "APT ONLINE",
  },
  {
    code: "4103",
    display_name: "MAHA ONLINE",
  },
  {
    code: "4122",
    display_name: "MP ONLINE",
  },
  {
    code: "WD08",
    display_name: "Bajaj Allianz General Insurance Company Ltd.",
  },
  {
    code: "WD09",
    display_name: "Bajaj Allianz Life Insurance Co. Ltd.",
  },
  {
    code: "WD10",
    display_name: "BAJAJ AUTO",
  },
  {
    code: "WD11",
    display_name: "Bajaj Auto Finance Ltd.",
  },
  {
    code: "WD12",
    display_name: "Bajaj Auto Holdings Ltd.",
  },
  {
    code: "WD13",
    display_name: "Bajaj Auto Ltd.",
  },
  {
    code: "WD14",
    display_name: "BAJAJ CORP",
  },
  {
    code: "WD15",
    display_name: "BAJAJ ELECT.",
  },
  {
    code: "WD16",
    display_name: "Bajaj Electricals Ltd.",
  },
  {
    code: "WD17",
    display_name: "BAJAJ FINSE",
  },
  {
    code: "WD18",
    display_name: "Bajaj Finserv Ltd.",
  },
  {
    code: "WD19",
    display_name: "Bajaj Holdings and Investment Ltd.",
  },
  {
    code: "WD20",
    display_name: "Bajaj International Pvt. Ltd.",
  },
  {
    code: "WD21",
    display_name: "Bajaj Sevashram Pvt. Ltd.",
  },
  {
    code: "WD22",
    display_name: "Bajaj Ventures Ltd.",
  },
  {
    code: "WD23",
    display_name: "BAJAJHINDLTD",
  },
  {
    code: "WD24",
    display_name: "BAJFINANCE",
  },
  {
    code: "WD25",
    display_name: "BALKRISH IND",
  },
  {
    code: "WD26",
    display_name: "BALLARPUR IN",
  },
  {
    code: "WD27",
    display_name: "BALMER LAWRI",
  },
  {
    code: "WD28",
    display_name: "BALRAMPUR C",
  },
  {
    code: "WD29",
    display_name: "Bangalore Electricity Supply Co",
  },
  {
    code: "WD30",
    display_name: "BANK MAHA",
  },
  {
    code: "WD31",
    display_name: "BANK OF BARODA",
  },
  {
    code: "WD32",
    display_name: "BANK OF INDI",
  },
  {
    code: "WD33",
    display_name: "Bank of Maharashtra",
  },
  {
    code: "WD34",
    display_name: "Baroda Industries Pvt. Ltd.",
  },
  {
    code: "WD35",
    display_name: "BARTRONICSIN",
  },
  {
    code: "WD36",
    display_name: "BASF INDIA",
  },
  {
    code: "WD37",
    display_name: "BATA INDI LT",
  },
  {
    code: "WD38",
    display_name: "BAYER CROP",
  },
  {
    code: "WD39",
    display_name: "Beetel Teletech",
  },
  {
    code: "WD40",
    display_name: "BEML LTD",
  },
  {
    code: "WD41",
    display_name: "BERGE PAINT",
  },
  {
    code: "WD42",
    display_name: "Bernhard Schulte Ship Mgment (India)",
  },
  {
    code: "WD43",
    display_name: "BF UTILITIES",
  },
  {
    code: "WD44",
    display_name: "BGR ENERGY",
  },
  {
    code: "WD45",
    display_name: "BHARAT BIJLI",
  },
  {
    code: "WD46",
    display_name: "Bharat Dynamics",
  },
  {
    code: "WD47",
    display_name: "BHARAT ELECT",
  },
  {
    code: "WD48",
    display_name: "BHARAT FORGE",
  },
  {
    code: "WD49",
    display_name: "Bharat Heavy Electricals Ltd",
  },
  {
    code: "WD50",
    display_name: "BHARAT PETRO",
  },
  {
    code: "WD51",
    display_name: "Bharat Sanchar Nigam",
  },
  {
    code: "WD52",
    display_name: "BHARATI SHIP",
  },
  {
    code: "WD53",
    display_name: "Bharti Airtel",
  },
  {
    code: "WD54",
    display_name: "BHARTI ARTL",
  },
  {
    code: "WD55",
    display_name: "Bharti AXA General Insurance",
  },
  {
    code: "WD56",
    display_name: "Bharti AXA Life Insurance",
  },
  {
    code: "WD57",
    display_name: "Bharti Infratel",
  },
  {
    code: "WD58",
    display_name: "Bharti Realty",
  },
  {
    code: "WD59",
    display_name: "Bharti Retail",
  },
  {
    code: "WD60",
    display_name: "BHEL",
  },
  {
    code: "WD61",
    display_name: "BHUSH STEEL",
  },
  {
    code: "WD62",
    display_name: "Bhushan Power and Steel",
  },
  {
    code: "WD63",
    display_name: "BILCARE LT",
  },
  {
    code: "WD64",
    display_name: "BINANI CEM",
  },
  {
    code: "WD65",
    display_name: "Binani Cement",
  },
  {
    code: "WD66",
    display_name: "Binani Industries Ltd",
  },
  {
    code: "WD67",
    display_name: "BIOCON LTD",
  },
  {
    code: "WD68",
    display_name: "BIRLA CORPOR",
  },
  {
    code: "WD69",
    display_name: "Birla Jingwei Fibres Company Limited",
  },
  {
    code: "WD70",
    display_name: "Birla Laos Pulp and Plantations Company Limited",
  },
  {
    code: "WD71",
    display_name: "Birla Sun Life Asset Management Company Ltd",
  },
  {
    code: "WD72",
    display_name: "Birla Sunlife life insurance company",
  },
  {
    code: "WD73",
    display_name: "BL KASHYAP",
  },
  {
    code: "WD74",
    display_name: "Blue Dart Express Ltd",
  },
  {
    code: "WD75",
    display_name: "BLUE STAR L",
  },
  {
    code: "WD76",
    display_name: "BMW India",
  },
  {
    code: "WD77",
    display_name: "BNP Paribas",
  },
  {
    code: "WD78",
    display_name: "BOC INDIA LT",
  },
  {
    code: "WD79",
    display_name: "Bombay Burmah",
  },
  {
    code: "WD80",
    display_name: "BOMBAY DYEI",
  },
  {
    code: "WD81",
    display_name: "Bombay Dyeing",
  },
  {
    code: "WD82",
    display_name: "BOMBAY RAYON",
  },
  {
    code: "WD83",
    display_name: "Bombay Realty",
  },
  {
    code: "WD84",
    display_name: "BOSCH LTD",
  },
  {
    code: "WD85",
    display_name: "Boston Consultancy Group",
  },
  {
    code: "WD86",
    display_name: "Brakes India",
  },
  {
    code: "WD87",
    display_name: "Bridgestone India",
  },
  {
    code: "WD88",
    display_name: "BRIGADE",
  },
  {
    code: "WD89",
    display_name: "BRITANIA IN",
  },
  {
    code: "WD90",
    display_name: "Brittania",
  },
  {
    code: "WD91",
    display_name: "BS Ltd",
  },
  {
    code: "WD92",
    display_name: "Bunge India Limited",
  },
  {
    code: "WD93",
    display_name: "C Mahendra Exports Ltd",
  },
  {
    code: "WD94",
    display_name: "CADILA HEALT",
  },
  {
    code: "WD95",
    display_name: "CAIRN IND",
  },
  {
    code: "WD96",
    display_name: "CANARA BANK",
  },
  {
    code: "WD98",
    display_name: "Capgemini India",
  },
  {
    code: "WD99",
    display_name: "Capri Global Capital Ltd",
  },
  {
    code: "WE01",
    display_name: "2020 Imaging India Limited",
  },
  {
    code: "WE02",
    display_name: "3M India Limited",
  },
  {
    code: "WE03",
    display_name: "9X Media Private Limited",
  },
  {
    code: "WE04",
    display_name: "A T Kearney Limited",
  },
  {
    code: "WE05",
    display_name: "ABB Limited",
  },
  {
    code: "WE06",
    display_name: "Abbott Private Limited",
  },
  {
    code: "WE07",
    display_name: "Abhyudaya Bank",
  },
  {
    code: "WE08",
    display_name: "Accenture",
  },
  {
    code: "WE09",
    display_name: "Advance Fluorine Technologies Private Limited",
  },
  {
    code: "WE10",
    display_name: "Agro Tech Foods Limited",
  },
  {
    code: "WE11",
    display_name: "Allegis Services India Pvt Ltd",
  },
  {
    code: "WE12",
    display_name: "Almondz Global Securities Ltd",
  },
  {
    code: "WE13",
    display_name: "Amalgamated Plantations Pvt Ltd",
  },
  {
    code: "WE14",
    display_name: "Ambica Steel Ltd",
  },
  {
    code: "WE15",
    display_name: "Anchor Electricals Pvt Ltd",
  },
  {
    code: "WE16",
    display_name: "Andrew Yule and Co. Ltd.-Head Office",
  },
  {
    code: "WE17",
    display_name: "App Dynamics Pvt Ltd",
  },
  {
    code: "WE18",
    display_name: "Arjohuntleigh Healthcare India Private Limited",
  },
  {
    code: "WE19",
    display_name: "Arvind Limited",
  },
  {
    code: "WE20",
    display_name: "Arviva Industries(I)Ltd",
  },
  {
    code: "WE21",
    display_name: "Asap Y Sourcing Solutions Private Limited",
  },
  {
    code: "WE22",
    display_name: "Asian Paints Ltd",
  },
  {
    code: "WE23",
    display_name: "Auchan International",
  },
  {
    code: "WE24",
    display_name: "Benett Coleman andCo Ltd",
  },
  {
    code: "WE25",
    display_name: "Berkadia Services India Private Limited",
  },
  {
    code: "WE26",
    display_name: "Bharat Forge Limited",
  },
  {
    code: "WE27",
    display_name: "Biostadt India Limited",
  },
  {
    code: "WE28",
    display_name: "Bisleri International Pvt Ltd.",
  },
  {
    code: "WE29",
    display_name: "Bombay Dyeing and Manufacturing Co Ltd",
  },
  {
    code: "WE30",
    display_name: "Bright Horizons Child Care Services Pvt Ltd",
  },
  {
    code: "WE31",
    display_name: "Britacel Silicones Ltd",
  },
  {
    code: "WE32",
    display_name: "C. Dinesh and Co Pvt Ltd",
  },
  {
    code: "WE33",
    display_name: "Cassiopae Solutions Pvt Ltd",
  },
  {
    code: "WE34",
    display_name: "Ceat Tyres Ltd",
  },
  {
    code: "WE35",
    display_name: "Chanel India Pvt Ltd",
  },
  {
    code: "WE36",
    display_name: "Cito Infotech Pvt Ltd",
  },
  {
    code: "WE37",
    display_name: "Colgate Palmolive India Ltd",
  },
  {
    code: "WE38",
    display_name: "Conde Nast",
  },
  {
    code: "WE39",
    display_name: "Core Integra Consulting Services Pvt Ltd",
  },
  {
    code: "WE40",
    display_name: "Credit Suisse Securities(India)Pvt Ltd",
  },
  {
    code: "WE41",
    display_name: "Crescent Scientific Pvt Ltd",
  },
  {
    code: "WE42",
    display_name: "Crimsonlogic India Pvt Ltd",
  },
  {
    code: "WE43",
    display_name: "Datamatics Technologies Pvt Ltd",
  },
  {
    code: "WE44",
    display_name: "Deloitte Consulting India Pvt Ltd",
  },
  {
    code: "WE45",
    display_name: "Dhl Express(India)Pvt Ltd",
  },
  {
    code: "WE46",
    display_name: "Dhruva Advisors Llp",
  },
  {
    code: "WE47",
    display_name: "Diageo India Pvt Ltd",
  },
  {
    code: "WE48",
    display_name: "D-Link India Ltd",
  },
  {
    code: "WE49",
    display_name: "Dmg India Pvt Ltd",
  },
  {
    code: "WE50",
    display_name: "Dsp Merrill Lynch",
  },
  {
    code: "WE51",
    display_name: "Dun And Bradstreet",
  },
  {
    code: "WE52",
    display_name: "Edelman India Pvt Ltd",
  },
  {
    code: "WE53",
    display_name: "Elcosoft Pvt Ltd",
  },
  {
    code: "WE54",
    display_name: "Elder Health Care Ltd",
  },
  {
    code: "WE55",
    display_name: "Electrosteel Casting Limited",
  },
  {
    code: "WE56",
    display_name: "Electrosteel Steels Ltd",
  },
  {
    code: "WE57",
    display_name: "Element14 India Private Limited",
  },
  {
    code: "WE58",
    display_name: "Embassy Of Switzerland",
  },
  {
    code: "WE59",
    display_name: "Embedded Computing and Power India Pvt Ltd",
  },
  {
    code: "WE60",
    display_name: "Empire Technical Services Pvt. Ltd.",
  },
  {
    code: "WE61",
    display_name: "Eppendorf India Ltd",
  },
  {
    code: "WE62",
    display_name: "Eureka Forbes Limited",
  },
  {
    code: "WE63",
    display_name: "Exa Thermometrics India Private Limited",
  },
  {
    code: "WE64",
    display_name: "Ez Xbrl Solutions Pvt Ltd",
  },
  {
    code: "WE65",
    display_name: "Fabindia Pvt Ltd",
  },
  {
    code: "WE66",
    display_name: "Fcb Uika Advertising Pvt Ltd",
  },
  {
    code: "WE67",
    display_name: "FCI Aravali Gypsum and Minerals India Limited",
  },
  {
    code: "WE68",
    display_name: "Federal Express Corporation",
  },
  {
    code: "WE69",
    display_name: "Fedex Express Tscs India Pvt Ltd",
  },
  {
    code: "WE70",
    display_name: "Financial Planning Standards Board Of India",
  },
  {
    code: "WE71",
    display_name: "First Rand Bank Ltd",
  },
  {
    code: "WE72",
    display_name: "Forbes Gokak Ltd",
  },
  {
    code: "WE73",
    display_name: "Fortis Healthcare Ltd",
  },
  {
    code: "WE74",
    display_name: "Franklin Templeton Asset Management (India)",
  },
  {
    code: "WE75",
    display_name: "Franklin Templeton International Services (India)",
  },
  {
    code: "WE76",
    display_name: "Freight Connection India Pvt Ltd",
  },
  {
    code: "WE77",
    display_name: "Fullerton India Credit Co . Ltd.",
  },
  {
    code: "WE78",
    display_name: "Gammon India Limited",
  },
  {
    code: "WE79",
    display_name: "Gannon Dunkerley and Co. Ltd",
  },
  {
    code: "WE80",
    display_name: "Gateway Distriparks Ltd",
  },
  {
    code: "WE81",
    display_name: "Ge Capital India and Sbi Cards",
  },
  {
    code: "WE82",
    display_name: "Gebbs Healthcare Solutions Pvt Ltd",
  },
  {
    code: "WE83",
    display_name: "General Mills India Pvt Ltd",
  },
  {
    code: "WE84",
    display_name: "Geodis Overseas Pvt. Ltd.",
  },
  {
    code: "WE85",
    display_name: "Gigamon Solutions India Private Limited",
  },
  {
    code: "WE86",
    display_name: "Glaxo Smithkline Pharmaceuticals Ltd",
  },
  {
    code: "WE87",
    display_name: "Glenmark Pharmaceuticals Ltd",
  },
  {
    code: "WE88",
    display_name: "Godrej Industries Ltd",
  },
  {
    code: "WE89",
    display_name: "Gxs India Technology Centre Pvt Ltd",
  },
  {
    code: "WE90",
    display_name: "H and S Software Development and Knowledge Mgmt",
  },
  {
    code: "WE91",
    display_name: "Hero Moto Corp Ltd",
  },
  {
    code: "WE92",
    display_name: "Hexaware Technologies Ltd",
  },
  {
    code: "WE93",
    display_name: "Hindustan Coca Cola Beverages Pvt Ltd",
  },
  {
    code: "WE94",
    display_name: "Hindustan Construction Company Ltd",
  },
  {
    code: "WE95",
    display_name: "Hindustan Unilever Ltd",
  },
  {
    code: "WE96",
    display_name: "Hiranandani Constructions Pvt Ltd",
  },
  {
    code: "WE97",
    display_name: "Hsbc Bank",
  },
  {
    code: "WE98",
    display_name: "HT Media Limited",
  },
  {
    code: "WE99",
    display_name: "Huber and Suhner",
  },
  {
    code: "WF01",
    display_name: "Iconex Steel Pvt Ltd",
  },
  {
    code: "WF02",
    display_name: "IMS Health Information And Consulting Services",
  },
  {
    code: "WF03",
    display_name: "India Ratings and Research Pvt Ltd.",
  },
  {
    code: "WF04",
    display_name: "Indiabulls Housing Finance Ltd",
  },
  {
    code: "WF05",
    display_name: "Indiabulls Real Estate Pvt Ltd",
  },
  {
    code: "WF06",
    display_name: "Indiabulls Securities Ltd",
  },
  {
    code: "WF07",
    display_name: "Indiabulls Technology Solutions Limited",
  },
  {
    code: "WF08",
    display_name: "Indian Oylmpic Association",
  },
  {
    code: "WF09",
    display_name: "Indoco Remedies Ltd",
  },
  {
    code: "WF10",
    display_name: "Infiniti Retail Limited",
  },
  {
    code: "WF11",
    display_name: "Infor Global Solutions (India) Private Limited",
  },
  {
    code: "WF12",
    display_name: "Innova Telecom Pvt Ltd.",
  },
  {
    code: "WF13",
    display_name: "International Asset Reconstruction Company Pvt Ltd",
  },
  {
    code: "WF14",
    display_name: "International Federation Of Red Cross Red Cresce",
  },
  {
    code: "WF15",
    display_name: "Intralox (India) Private Limited",
  },
  {
    code: "WF16",
    display_name: "Ipsos Research Pvt Ltd",
  },
  {
    code: "WF17",
    display_name: "IRM Insurance Brokers Private Limited",
  },
  {
    code: "WF18",
    display_name: "Jasubhai Media Pvt Ltd",
  },
  {
    code: "WF19",
    display_name: "Jingles India Pvt Ltd",
  },
  {
    code: "WF20",
    display_name: "John Deere India Pvt Ltd",
  },
  {
    code: "WF21",
    display_name: "Johnson and Johnson India Pvt Ltd",
  },
  {
    code: "WF22",
    display_name: "Jubilant Group Of Companies",
  },
  {
    code: "WF23",
    display_name: "Jyothy Labaratories Ltd",
  },
  {
    code: "WF24",
    display_name: "Kalpataru Constructions",
  },
  {
    code: "WF25",
    display_name: "KelloggS India Pvt Ltd",
  },
  {
    code: "WF26",
    display_name: "Kirloskar Brothers Ltd",
  },
  {
    code: "WF27",
    display_name: "KNS Architects Private Limited",
  },
  {
    code: "WF28",
    display_name: "Kodak India Private Limited",
  },
  {
    code: "WF29",
    display_name: "Kores India Limited",
  },
  {
    code: "WF30",
    display_name: "KPMG",
  },
  {
    code: "WF31",
    display_name: "Ksb Pumps Ltd",
  },
  {
    code: "WF32",
    display_name: "Kuehne + Nagel Private Limited",
  },
  {
    code: "WF33",
    display_name: "Leo Burnett Pvt Ltd",
  },
  {
    code: "WF34",
    display_name: "Li and Fung India Pvt Ltd",
  },
  {
    code: "WF35",
    display_name: "Maersk Line India Pvt Ltd",
  },
  {
    code: "WF36",
    display_name: "Mafatlal Industries Ltd",
  },
  {
    code: "WF37",
    display_name: "Mahindra And Mahindra Ltd",
  },
  {
    code: "WF38",
    display_name: "Mahindra Finance Limited",
  },
  {
    code: "WF39",
    display_name: "Mahindra Solar One Pvt Ltd",
  },
  {
    code: "WF40",
    display_name: "Merit Software Services Pvt Ltd.",
  },
  {
    code: "WF41",
    display_name: "Mistral Solutions Private Limited",
  },
  {
    code: "WF42",
    display_name: "Momentive Performance Materials (I) Pvt Ltd",
  },
  {
    code: "WF43",
    display_name: "Mondelez India Foods Pvt Ltd",
  },
  {
    code: "WF44",
    display_name: "Munich Re India Services Pvt Ltd",
  },
  {
    code: "WF45",
    display_name: "Nagravision India Pvt Ltd",
  },
  {
    code: "WF46",
    display_name: "National Securities Depository Ltd",
  },
  {
    code: "WF47",
    display_name: "NC College Of Engineerings",
  },
  {
    code: "WF48",
    display_name: "Neelkamal Ltd",
  },
  {
    code: "WF49",
    display_name: "Nestle India Limited",
  },
  {
    code: "WF50",
    display_name: "New India Co-Operative Bank",
  },
  {
    code: "WF51",
    display_name: "NGA HR India Pvt Ltd",
  },
  {
    code: "WF52",
    display_name: "Nihilent Technologies",
  },
  {
    code: "WF53",
    display_name: "NRB Bearings Ltd",
  },
  {
    code: "WF54",
    display_name: "NYK Shipmanagement (I) Pvt Ltd",
  },
  {
    code: "WF55",
    display_name: "Ogilvy and Mather Ltd",
  },
  {
    code: "WF56",
    display_name: "Oriflame India Pvt. Ltd.",
  },
  {
    code: "WF57",
    display_name: "Perfetti Van Melle India Pvt Ltd",
  },
  {
    code: "WF58",
    display_name: "Pinebridge India Private Limited",
  },
  {
    code: "WF59",
    display_name: "Pramati Technologies Private Limited",
  },
  {
    code: "WF60",
    display_name: "Praxis Interactive Services Private Limited",
  },
  {
    code: "WF61",
    display_name: "Priyadarshini Forwarders Pvt Ltd",
  },
  {
    code: "WF62",
    display_name: "Progility Technologies Pvt. Ltd",
  },
  {
    code: "WF63",
    display_name: "Pudumjee Hygiene Products Ltd",
  },
  {
    code: "WF64",
    display_name: "Quantum Design India Pvt Ltd",
  },
  {
    code: "WF65",
    display_name: "Quixey India Private Limited",
  },
  {
    code: "WF66",
    display_name: "Radiant Hitech Engineering Pvt Ltd",
  },
  {
    code: "WF67",
    display_name: "Raymond Limited",
  },
  {
    code: "WF68",
    display_name: "Reckitt Benckiser India",
  },
  {
    code: "WF69",
    display_name: "Rediff.Com India Ltd",
  },
  {
    code: "WF70",
    display_name: "Rimini Street India Operations Pvt Ltd",
  },
  {
    code: "WF71",
    display_name: "Rolta India Ltd",
  },
  {
    code: "WF72",
    display_name: "Roots Corporation Limited",
  },
  {
    code: "WF73",
    display_name: "S.N.P Shipping Services Private Limited",
  },
  {
    code: "WF74",
    display_name: "SainsburyS Asia Ltd.",
  },
  {
    code: "WF75",
    display_name: "Sakal Group",
  },
  {
    code: "WF76",
    display_name: "Sandoz India",
  },
  {
    code: "WF77",
    display_name: "SAP India Pvt Ltd",
  },
  {
    code: "WF78",
    display_name: "Shivani Locks Pvt. Ltd",
  },
  {
    code: "WF79",
    display_name: "Shop Cj Network Private Limited",
  },
  {
    code: "WF80",
    display_name: "ShopperS Stop Limited",
  },
  {
    code: "WF81",
    display_name: "Sitel India Pvt Ltd",
  },
  {
    code: "WF82",
    display_name: "Soliare Direct Energy India Pvt Ltd",
  },
  {
    code: "WF83",
    display_name: "Srishti School Of Art Design And Technology",
  },
  {
    code: "WF84",
    display_name: "Standard Chartered Bank",
  },
  {
    code: "WF85",
    display_name: "Star India Pvt Ltd",
  },
  {
    code: "WF86",
    display_name: "Sukruti Credit And Risk Managers",
  },
  {
    code: "WF87",
    display_name: "Supreme Infrastructure India Ltd",
  },
  {
    code: "WF88",
    display_name: "Suzlon Energy Ltd",
  },
  {
    code: "WF89",
    display_name: "Swiss Reinsurance Company Ltd",
  },
  {
    code: "WF90",
    display_name: "Syntel Limited",
  },
  {
    code: "WF91",
    display_name: "Taj Air Limited",
  },
  {
    code: "WF92",
    display_name: "Taj Madras Flight Kitchen",
  },
  {
    code: "WF93",
    display_name: "Taj Trade And Transport Company Limited",
  },
  {
    code: "WF94",
    display_name: "Tata AIG General Insurance Co. Ltd.",
  },
  {
    code: "WF95",
    display_name: "Tata Asset Management Company Limited",
  },
  {
    code: "WF96",
    display_name: "Tata Auto Components Limited",
  },
  {
    code: "WF97",
    display_name: "Tata BlueScope Steel Limited",
  },
  {
    code: "WF98",
    display_name: "Tata Business Support Services Limited",
  },
  {
    code: "WF99",
    display_name: "Tata Capital Limited",
  },
  {
    code: "WG00",
    display_name: "IndusInd Bank Limited",
  },
  {
    code: "WG01",
    display_name: "Tata Communications Limited",
  },
  {
    code: "WG02",
    display_name: "Tata Consultancy Services Limited",
  },
  {
    code: "WG03",
    display_name: "Tata Global Beverages Ltd",
  },
  {
    code: "WG04",
    display_name: "Tata Hitachi Construction Machinery Pvt Ltd",
  },
  {
    code: "WG05",
    display_name: "Tata Interactive Systems",
  },
  {
    code: "WG06",
    display_name: "Tata Metaliks Di Pipes Limited",
  },
  {
    code: "WG07",
    display_name: "Tata Metaliks Limited",
  },
  {
    code: "WG08",
    display_name: "Tata Power Sed",
  },
  {
    code: "WG09",
    display_name: "Tata Realty and Infrastructure Ltd.",
  },
  {
    code: "WG10",
    display_name: "Tata Sky Limited",
  },
  {
    code: "WG11",
    display_name: "Tata Steel Processing And Distribution Limited",
  },
  {
    code: "WG12",
    display_name: "Tata Strategic Management Group",
  },
  {
    code: "WG13",
    display_name: "Tata Teleservices Limited",
  },
  {
    code: "WG14",
    display_name: "Tata Unistore Limited",
  },
  {
    code: "WG15",
    display_name: "Tayo Rolls Limited",
  },
  {
    code: "WG16",
    display_name: "TCE Consulting Engineers Limited",
  },
  {
    code: "WG17",
    display_name: "Techbooks Pvt Ltd",
  },
  {
    code: "WG18",
    display_name: "Technimont Icb Limited",
  },
  {
    code: "WG19",
    display_name: "Tesco Sourcing India Private Limited",
  },
  {
    code: "WG20",
    display_name: "THDC India Ltd",
  },
  {
    code: "WG21",
    display_name: "The Associated Building Co Ltd",
  },
  {
    code: "WG22",
    display_name: "The Cosmos Cooperative Bank Ltd",
  },
  {
    code: "WG23",
    display_name: "Tieto Technologies India Pvt Ltd",
  },
  {
    code: "WG24",
    display_name: "Tmeic Industrial Systems India Private Limited",
  },
  {
    code: "WG25",
    display_name: "TNT India Pvt Ltd",
  },
  {
    code: "WG26",
    display_name: "Torrent Pharmaceuticals Ltd.",
  },
  {
    code: "WG27",
    display_name: "Torrent Power Ltd.",
  },
  {
    code: "WG28",
    display_name: "Trading Technologies India Private Limited",
  },
  {
    code: "WG29",
    display_name: "Transafe Services Limited",
  },
  {
    code: "WG30",
    display_name: "Trent Limited",
  },
  {
    code: "WG31",
    display_name: "United Phosphorus Limited",
  },
  {
    code: "WG32",
    display_name: "Vadilal Industries Limited",
  },
  {
    code: "WG33",
    display_name: "Venture Lighting India Ltd",
  },
  {
    code: "WG34",
    display_name: "VIP Industries Ltd",
  },
  {
    code: "WG35",
    display_name: "Vistaprint",
  },
  {
    code: "WG36",
    display_name: "Western Union Company",
  },
  {
    code: "WG37",
    display_name: "WNS Global Services Pvt Ltd",
  },
  {
    code: "WG38",
    display_name: "Wockhardt Limited",
  },
  {
    code: "WG39",
    display_name: "World Wide Fund For Nature / India",
  },
  {
    code: "WG40",
    display_name: "Xorrt India Pvt Ltd",
  },
  {
    code: "WG41",
    display_name: "Yes Bank Ltd",
  },
  {
    code: "WG42",
    display_name: "Advinus Therapeutics Private Limited",
  },
  {
    code: "WG43",
    display_name: "AIG Consumer Finance Group",
  },
  {
    code: "WG44",
    display_name: "Alliance Coffee Limited",
  },
  {
    code: "WG45",
    display_name: "Automotive Composite Systems (Intl) Limited",
  },
  {
    code: "WG46",
    display_name: "Automotive Stampings and Assemblies Limited",
  },
  {
    code: "WG48",
    display_name: "Concorde Motors (India) Limited",
  },
  {
    code: "WG49",
    display_name: "Drive India Enterprise Solutions Limited",
  },
  {
    code: "WG50",
    display_name: "e-Nxt Financials Limited",
  },
  {
    code: "WG51",
    display_name: "GISL",
  },
  {
    code: "WG52",
    display_name: "Hooghly Met Coke and Power Company Limited",
  },
  {
    code: "WG53",
    display_name: "HV Axles Limited",
  },
  {
    code: "WG54",
    display_name: "HV Transmissions Limited",
  },
  {
    code: "WG55",
    display_name: "INCAT Systems Incorporated",
  },
  {
    code: "WG56",
    display_name: "Indian Steel and Wire Products Limited",
  },
  {
    code: "WG57",
    display_name: "JAMIPOL Limited",
  },
  {
    code: "WG58",
    display_name: "Jamshedpur Utilities & Services Company Limited",
  },
  {
    code: "WG59",
    display_name:
      "Knorr-Bremse Systems for Commercial Vehicles India Private L",
  },
  {
    code: "WG60",
    display_name: "LANDMARK",
  },
  {
    code: "WG61",
    display_name: "Mjunction Services Limited",
  },
  {
    code: "WG62",
    display_name: "Mount Everest Mineral Water Limited",
  },
  {
    code: "WG63",
    display_name: "Nelco Limited",
  },
  {
    code: "WG64",
    display_name: "Nelito Systems Ltd.",
  },
  {
    code: "WG65",
    display_name: "TACO Engineering",
  },
  {
    code: "WG66",
    display_name: "TACO Faurecia Design Centre Private Limited",
  },
  {
    code: "WG67",
    display_name: "TACO Hendrickson Suspensions Private Limited",
  },
  {
    code: "WG68",
    display_name: "TACO Interiors and Plastics Division",
  },
  {
    code: "WG69",
    display_name: "TACO MobiApps Telematics Private Limited",
  },
  {
    code: "WG70",
    display_name: "TACO Visteon Engineering Private Limited",
  },
  {
    code: "WG71",
    display_name: "TAL Manufacturing Solutions Limited",
  },
  {
    code: "WG72",
    display_name: "Tata Advanced Systems Limited",
  },
  {
    code: "WG73",
    display_name: "Tata AIA Life Insurance Co. Ltd.",
  },
  {
    code: "WG74",
    display_name: "Tata Autocomp GY Batteries Private Limited",
  },
  {
    code: "WG75",
    display_name: "Tata AutoComp Systems Limited",
  },
  {
    code: "WG76",
    display_name: "Tata BP Solar India Limited",
  },
  {
    code: "WG77",
    display_name: "Tata Capital Markets Limited",
  },
  {
    code: "WG78",
    display_name: "Tata Ceramics Limited",
  },
  {
    code: "WG79",
    display_name: "Tata Elxsi Limited",
  },
  {
    code: "WG80",
    display_name: "Tata Ficosa Automotive Systems Limited",
  },
  {
    code: "WG81",
    display_name: "Tata Financial Services",
  },
  {
    code: "WG82",
    display_name: "Tata Housing Development Company Limited",
  },
  {
    code: "WG83",
    display_name: "Tata Industrial Services Limited",
  },
  {
    code: "WG84",
    display_name: "Tata Marcopolo Motors",
  },
  {
    code: "WG85",
    display_name: "Tata Petrodyne Limited",
  },
  {
    code: "WG86",
    display_name: "Tata Power Trading Company Limited",
  },
  {
    code: "WG87",
    display_name: "Tata Quality Management Services",
  },
  {
    code: "WG88",
    display_name: "Tata Ryerson Limited",
  },
  {
    code: "WG89",
    display_name: "Tata Securities Limited",
  },
  {
    code: "WG90",
    display_name: "Tata Tea Inc.",
  },
  {
    code: "WG91",
    display_name: "Tata Teleservices (Maharashtra) Limited",
  },
  {
    code: "WG92",
    display_name: "Tata Visteon Automotive Private Limited",
  },
  {
    code: "WG93",
    display_name: "Tata Yazaki Autocomp Limited",
  },
  {
    code: "WG94",
    display_name: "TC Springs Limited",
  },
  {
    code: "WG95",
    display_name: "Telco Construction Equipment Co. Ltd.",
  },
  {
    code: "WG96",
    display_name: "The Indian Hotels Co. Ltd.",
  },
  {
    code: "WG97",
    display_name: "The Tinplate Company of India Limited",
  },
  {
    code: "WG98",
    display_name: "TM International Logistics",
  },
  {
    code: "WG99",
    display_name: "Trent Hypermarket Pvt Ltd",
  },
  {
    code: "WH01",
    display_name: "CARBO UNIVER",
  },
  {
    code: "WH02",
    display_name: "Carborundum Universal Ltd",
  },
  {
    code: "WH03",
    display_name: "Cargill India Private",
  },
  {
    code: "WH04",
    display_name: "Casa Decor",
  },
  {
    code: "WH05",
    display_name: "CASTROL INDI",
  },
  {
    code: "WH06",
    display_name: "Caterpillar India",
  },
  {
    code: "WH07",
    display_name: "Ceat Ltd",
  },
  {
    code: "WH08",
    display_name: "Celebrations Apparel Ltd.",
  },
  {
    code: "WH09",
    display_name: "CENTRAL BK",
  },
  {
    code: "WH10",
    display_name: "Centrum Capital Ltd",
  },
  {
    code: "WH11",
    display_name: "Centum Learning",
  },
  {
    code: "WH12",
    display_name: "Century Enka Ltd",
  },
  {
    code: "WH13",
    display_name: "CENTURY TEXT",
  },
  {
    code: "WH14",
    display_name: "CESC LTD",
  },
  {
    code: "WH15",
    display_name: "CHAMBAL FERT",
  },
  {
    code: "WH16",
    display_name: "CHENNAI PETR",
  },
  {
    code: "WH17",
    display_name: "Cholamandalam Investment and Finance Co",
  },
  {
    code: "WH18",
    display_name: "Cigna TTk",
  },
  {
    code: "WH19",
    display_name: "CIPLA LTD.",
  },
  {
    code: "WH20",
    display_name: "Citibank NA",
  },
  {
    code: "WH21",
    display_name: "CITY UNION B",
  },
  {
    code: "WH22",
    display_name: "Cleartrip",
  },
  {
    code: "WH23",
    display_name: "CMC LTD",
  },
  {
    code: "WH24",
    display_name: "COAL INDIA",
  },
  {
    code: "WH25",
    display_name: "Coca-Cola India",
  },
  {
    code: "WH26",
    display_name: "Cognizant Technology Solutions India",
  },
  {
    code: "WH27",
    display_name: "COLGATE PALM",
  },
  {
    code: "WH28",
    display_name: "ColorPlus Fashions Ltd.",
  },
  {
    code: "WH29",
    display_name: "CONSOL CONST",
  },
  {
    code: "WH30",
    display_name: "CONTAIN CORP",
  },
  {
    code: "WH31",
    display_name: "CORE Education and Technologies Ltd",
  },
  {
    code: "WH32",
    display_name: "CORE PROJECT",
  },
  {
    code: "WH33",
    display_name: "COROM INTER",
  },
  {
    code: "WH34",
    display_name: "CORPORATION",
  },
  {
    code: "WH35",
    display_name: "COX KINGS",
  },
  {
    code: "WH36",
    display_name: "Credit Analysis And Research Limited",
  },
  {
    code: "WH37",
    display_name: "CRISIL LTD",
  },
  {
    code: "WH38",
    display_name: "CROMPTON GRE",
  },
  {
    code: "WH39",
    display_name: "CUMMINS INDI",
  },
  {
    code: "WH40",
    display_name: "Cyient Ltd",
  },
  {
    code: "WH41",
    display_name: "D B CORP",
  },
  {
    code: "WH42",
    display_name: "D B REALTY",
  },
  {
    code: "WH43",
    display_name: "DABUR INDIA",
  },
  {
    code: "WH44",
    display_name: "Dahej Harbour and Infrastructure Limited",
  },
  {
    code: "WH45",
    display_name: "Dakshin Gujarat Vij Co",
  },
  {
    code: "WH46",
    display_name: "Dalmia Bharat Ltd",
  },
  {
    code: "WH47",
    display_name: "Damodar Valley Corporation",
  },
  {
    code: "WH48",
    display_name: "DB Corp Ltd",
  },
  {
    code: "WH49",
    display_name: "Dbcorp Limited",
  },
  {
    code: "WH50",
    display_name: "DBS",
  },
  {
    code: "WH51",
    display_name: "Dcb Bank Limited",
  },
  {
    code: "WH52",
    display_name: "DCM Shriram Ltd",
  },
  {
    code: "WH53",
    display_name: "DECCAN CHR",
  },
  {
    code: "WH54",
    display_name: "DEEPAK FERT",
  },
  {
    code: "WH55",
    display_name: "Defence Land Systems",
  },
  {
    code: "WH56",
    display_name: "Delhi Metro Rail Corporation",
  },
  {
    code: "WH57",
    display_name: "Deloitte",
  },
  {
    code: "WH58",
    display_name: "DELTA CORP",
  },
  {
    code: "WH59",
    display_name: "DEN NETWORK",
  },
  {
    code: "WH60",
    display_name: "DENA BANK",
  },
  {
    code: "WH61",
    display_name: "DEV CRBANK",
  },
  {
    code: "WH62",
    display_name: "DEWAN HOU FI",
  },
  {
    code: "WH63",
    display_name: "Dhampur Sugar Mills Ltd",
  },
  {
    code: "WH64",
    display_name: "DHANLAK BANK",
  },
  {
    code: "WH65",
    display_name: "Dharampal Satyapal",
  },
  {
    code: "WH66",
    display_name: "DHFL Pramerica Life Insurance Co. Ltd.",
  },
  {
    code: "WH67",
    display_name: "Dhunseri Petrochem Ltd",
  },
  {
    code: "WH68",
    display_name: "Diamond Power Infrastructure Ltd",
  },
  {
    code: "WH69",
    display_name: "Dilip Buildcon",
  },
  {
    code: "WH70",
    display_name: "DISH TV",
  },
  {
    code: "WH71",
    display_name: "Disha Financial Counselling",
  },
  {
    code: "WH72",
    display_name: "DISHMAN PHAR",
  },
  {
    code: "WH73",
    display_name: "Divis Lab",
  },
  {
    code: "WH74",
    display_name: "DLF LIMITED",
  },
  {
    code: "WH75",
    display_name: "Doosan Power Systems India",
  },
  {
    code: "WH76",
    display_name: "Dow Chemical International",
  },
  {
    code: "WH77",
    display_name: "DR.REDDYS L",
  },
  {
    code: "WH78",
    display_name: "DREDG CORP I",
  },
  {
    code: "WH79",
    display_name: "DrreddyS Laboratories Limited",
  },
  {
    code: "WH80",
    display_name: "Dunlop Auto Tyres Pvt. Ltd., India",
  },
  {
    code: "WH81",
    display_name: "Dunlop India Ltd., India",
  },
  {
    code: "WH82",
    display_name: "Dunlop Polymers Pvt. Ltd., India",
  },
  {
    code: "WH83",
    display_name: "E I Dupont India",
  },
  {
    code: "WH84",
    display_name: "Eastern Power Distribution Co",
  },
  {
    code: "WH85",
    display_name: "ECGC",
  },
  {
    code: "WH86",
    display_name: "Eclerx Services Limited",
  },
  {
    code: "WH87",
    display_name: "EDELWEISS CA",
  },
  {
    code: "WH88",
    display_name: "Edelweiss Financial Services Ltd",
  },
  {
    code: "WH89",
    display_name: "Edelweiss Tokio Life Insurance Co. Ltd.",
  },
  {
    code: "WH90",
    display_name: "EDUCOMP SOLN",
  },
  {
    code: "WH91",
    display_name: "EICHER MOTOR",
  },
  {
    code: "WH92",
    display_name: "EID PARRY",
  },
  {
    code: "WH93",
    display_name: "EIH LIMITED",
  },
  {
    code: "WH94",
    display_name: "ELECON ENG C",
  },
  {
    code: "WH95",
    display_name: "ELECTROSTEL",
  },
  {
    code: "WH96",
    display_name: "Emami Biotech",
  },
  {
    code: "WH97",
    display_name: "EMAMI LIMITE",
  },
  {
    code: "WH98",
    display_name: "EMC",
  },
  {
    code: "WH99",
    display_name: "Emmsons International Ltd",
  },
  {
    code: "WI01",
    display_name: "Emtel",
  },
  {
    code: "WI02",
    display_name: "ENGINER IN",
  },
  {
    code: "WI03",
    display_name: "ENT NETWORK",
  },
  {
    code: "WI04",
    display_name: "EPC Mahindra",
  },
  {
    code: "WI05",
    display_name: "ERA INFRA",
  },
  {
    code: "WI06",
    display_name: "Ericson India Pvt Ltd",
  },
  {
    code: "WI07",
    display_name: "Ericsson India",
  },
  {
    code: "WI08",
    display_name: "Ernst and Young",
  },
  {
    code: "WI09",
    display_name: "ESCORTS LTD.",
  },
  {
    code: "W003",
    display_name: "Titan Industries Limited",
  },
  {
    code: "W007",
    display_name: "TATA STEEL LTD. WIRE DIVISION",
  },
  {
    code: "W009",
    display_name: "WELCOME GRP CHOLA SHERATON",
  },
  {
    code: "W035",
    display_name: "THE SHAMRAO VITHAL CO-OP BANK",
  },
  {
    code: "W037",
    display_name: "ITC HOTELS PARK SHERATON",
  },
  {
    code: "W039",
    display_name: "TATA IRON AND STEEL CO. LTD-Tarapore",
  },
  {
    code: "W052",
    display_name: "RALLIS INDIA LTD ( CHANDIGARH)",
  },
  {
    code: "W054",
    display_name: "NOVAR INDIA LIMITED",
  },
  {
    code: "W056",
    display_name: "Tata Steel",
  },
  {
    code: "W057",
    display_name: "Tata Tea Limited",
  },
  {
    code: "W062",
    display_name: "TELCO CONST.EQUIPMENT CO.LTD.",
  },
  {
    code: "W064",
    display_name: "FISHERMANS COVE",
  },
  {
    code: "W066",
    display_name: "TAJ HARI MAHAL JODHPUR",
  },
  {
    code: "W069",
    display_name: "CMC LIMITED",
  },
  {
    code: "W074",
    display_name: "THE RAMBAGH PALACE HOTEL PVT. LTD.",
  },
  {
    code: "W075",
    display_name: "TAJ KRISHNA",
  },
  {
    code: "W076",
    display_name: "TATA MOTORS LTD (THANE)",
  },
  {
    code: "W077",
    display_name: "CROWNE PLAZA SURYA",
  },
  {
    code: "W079",
    display_name: "TAJ BANJARA",
  },
  {
    code: "W080",
    display_name: "RALLIS INDIA LTD ( NAVI MUMBAI)",
  },
  {
    code: "W081",
    display_name: "TATA IRON AND STEEL CO LTD",
  },
  {
    code: "W082",
    display_name: "UNIQ DETECTIVE and SECURITY SERVICES LTD",
  },
  {
    code: "W084",
    display_name: "TAJ RESIDENCY",
  },
  {
    code: "W087",
    display_name: "MILLIPORE INDIA (P) LTD",
  },
  {
    code: "W090",
    display_name: "INDIAN HOTELS",
  },
  {
    code: "W092",
    display_name: "THE TAJ MAHAL HOTEL",
  },
  {
    code: "W093",
    display_name: "TAJ VIEW HOTEL, AGRA",
  },
  {
    code: "W095",
    display_name: "TAJ PALACE HOTEL",
  },
  {
    code: "W098",
    display_name: "TAJ GATEWAY",
  },
  {
    code: "W099",
    display_name: "TAJ WEST END",
  },
  {
    code: "W100",
    display_name: "PIEM HOTELS ( TAJ RESIDENCY)",
  },
  {
    code: "W102",
    display_name: "TAJ MALABAR, COCHIN",
  },
  {
    code: "W104",
    display_name: "TAJ COROMANDEL",
  },
  {
    code: "W105",
    display_name: "TAJ CONNEMARA",
  },
  {
    code: "W106",
    display_name: "TAJ PRESIDENT",
  },
  {
    code: "W107",
    display_name: "ACCORD SOFTWARE and SYSTEMS PVT.LTD.",
  },
  {
    code: "W109",
    display_name: "THEIS PRECISION STEEL INDIA PRIVATE LIMITED",
  },
  {
    code: "W111",
    display_name: "M/s. HINDUSTAN PETROL SERVICE, RAJKOT",
  },
  {
    code: "W120",
    display_name: "Tata Motors Limited",
  },
  {
    code: "W121",
    display_name: "UNITED HOTELS LTD (THE AMBASSADOR)",
  },
  {
    code: "W122",
    display_name: "TATA AUTOMOTIVE LTD - TAL",
  },
  {
    code: "W123",
    display_name: "TATA STEEL NOAMUNDI",
  },
  {
    code: "W124",
    display_name: "TATA STEEL BEARINGS DIVISION KOLKATA",
  },
  {
    code: "W125",
    display_name: "TATA STEEL WEST BOKARO KOLKATA",
  },
  {
    code: "W126",
    display_name: "TATA STEEL TUBES DIVISION",
  },
  {
    code: "W127",
    display_name: "THE SARASWAT CO-OP BANK LTD",
  },
  {
    code: "W131",
    display_name: "WIPRO LIMITED",
  },
  {
    code: "W132",
    display_name: "TATA TECHNOLOGIES LTD",
  },
  {
    code: "W133",
    display_name: "TATA CHEMICALS LIMITED (FERTILISER DIVISION)",
  },
  {
    code: "W136",
    display_name: "SUPRAJIT ENGG LTD",
  },
  {
    code: "W137",
    display_name: "Tata Sponge Iron Limited",
  },
  {
    code: "W140",
    display_name: "TATA MOTORS LTD LUCKNOW",
  },
  {
    code: "W141",
    display_name: "TAJ RESIDENCY LUCKNOW",
  },
  {
    code: "W146",
    display_name: "TELCO ( THANE)",
  },
  {
    code: "W147",
    display_name: "TATA RYERSON",
  },
  {
    code: "W148",
    display_name: "TATA YODOGAWA LTD",
  },
  {
    code: "W149",
    display_name: "RYATHARA SEVA SAHAKARA BANK LTD",
  },
  {
    code: "W151",
    display_name: "NANDINI MILK PRODUCTS (UNIT OF KMF)",
  },
  {
    code: "W152",
    display_name: "RALCHEM LTD",
  },
  {
    code: "W153",
    display_name: "TATA STEEL - FERRO ALLOYS PLANT",
  },
  {
    code: "W154",
    display_name: "CUMMINS DIESEL SALES and SERVICE INDIA LTD",
  },
  {
    code: "W157",
    display_name: "TINPLATE LTD.",
  },
  {
    code: "W158",
    display_name: "BIOCON INDIA GROUP",
  },
  {
    code: "W159",
    display_name: "TATA HONEYWELL LTD",
  },
  {
    code: "W160",
    display_name: "YOUNG WOMEN CHRISTAN ASSOCIATION",
  },
  {
    code: "W161",
    display_name: "TAJ LAKE PALACE HOTEL, UDAIPUR",
  },
  {
    code: "W163",
    display_name: "TELCO FOUNDRY - OPERATORS",
  },
  {
    code: "W165",
    display_name: "TAJ BLUE DIAMOND",
  },
  {
    code: "W167",
    display_name: "WIPRO INFOTECH",
  },
  {
    code: "W169",
    display_name: "TELCO - HVAL",
  },
  {
    code: "W170",
    display_name: "TELCO JAMSHEDPUR",
  },
  {
    code: "W172",
    display_name: "TELCO FOUNDRY - STAFF",
  },
  {
    code: "W173",
    display_name: "TATA CHEMICALS - MITHAPUR Officers",
  },
  {
    code: "W174",
    display_name: "TATA CHEMICALS - MITHAPUR Workers",
  },
  {
    code: "W176",
    display_name: "TATA INTERNATIONAL",
  },
  {
    code: "W178",
    display_name: "Tata Toyo Radiator Limited",
  },
  {
    code: "W180",
    display_name: "JSW Steel Ltd",
  },
  {
    code: "W184",
    display_name: "Tata Johnson Controls Automotive Limited",
  },
  {
    code: "W186",
    display_name: "TATA TECHNOLOGIES LTD -- JHAMSHEDPUR",
  },
  {
    code: "W187",
    display_name: "TELCON -- JHAMSHEDPUR",
  },
  {
    code: "W188",
    display_name: "TATA CHEMICALS - MITHAPUR SCHOOL",
  },
  {
    code: "W191",
    display_name: "KANSAI NEROLAC PAINTS LTD",
  },
  {
    code: "W192",
    display_name: "RALLIS INDIA LIMITED ( RATNAGIRI)",
  },
  {
    code: "W194",
    display_name: "EXCEL INDUSTRIES LIMITED",
  },
  {
    code: "W195",
    display_name: "CHIPLUN HOTELS PVT LTD",
  },
  {
    code: "W196",
    display_name: "MANIPAL HEALTH ENTERPRISES PVT LTD",
  },
  {
    code: "W198",
    display_name: "WIPRO INFRASTRUCTURE ENGINEERING LTD",
  },
  {
    code: "W199",
    display_name: "TATA INTERNATIONAL LIMITED ( MUMBAI )",
  },
  {
    code: "W200",
    display_name: "N.R CONTAINERS",
  },
  {
    code: "W206",
    display_name: "SRF LIMITED",
  },
  {
    code: "W207",
    display_name: "TAJ RESIDENCY, NASIK",
  },
  {
    code: "W219",
    display_name: "NELCO LIMITED",
  },
  {
    code: "W220",
    display_name: "SAI MIRRA INNOPHARM PVT LTD",
  },
  {
    code: "W222",
    display_name: "AUTOLINE STAMPINGS PVT LTD",
  },
  {
    code: "W223",
    display_name: "USHA FIRE SAFETY EQUIPMENTS PVT LTD",
  },
  {
    code: "W225",
    display_name: "PRINCIPAL D.B.J COLLEGE",
  },
  {
    code: "W226",
    display_name: "TRF Limited",
  },
  {
    code: "W227",
    display_name: "SPASTIC SOCIETY OF TAMILNADU",
  },
  {
    code: "W228",
    display_name: "TATA CUMMINS LIMITED",
  },
  {
    code: "W231",
    display_name: "MADRAS CEMENTS LIMITED",
  },
  {
    code: "W236",
    display_name: "CHIPLUN URBAN CO-OP BANK LTD (CHIPLUN)",
  },
  {
    code: "W237",
    display_name: "Tata Technologies Limited",
  },
  {
    code: "W239",
    display_name: "TAJ SATS AIR CATERING LTD",
  },
  {
    code: "W241",
    display_name: "S S D OIL MILLS CO LTD",
  },
  {
    code: "W247",
    display_name: "TISCO ( DHANBAD )",
  },
  {
    code: "W251",
    display_name: "J K FILES and TOOLS",
  },
  {
    code: "W253",
    display_name: "JOHNSON LIFTS (P) LTD",
  },
  {
    code: "W254",
    display_name: "TATA CHEMICALS (NOIDA)",
  },
  {
    code: "W261",
    display_name: "DINAKARAN",
  },
  {
    code: "W263",
    display_name: "POWAI ENGLISH HIGH SCHOOL AND JR COLLEGE",
  },
  {
    code: "W267",
    display_name: "THE ASSOCIATED BUILDING CO LTD",
  },
  {
    code: "W268",
    display_name: "NATIONAL CENTRE FOR THE PERFORMING ARTS",
  },
  {
    code: "W274",
    display_name: "METROPOLITAN TRANSPORT CORPORATION",
  },
  {
    code: "W276",
    display_name: "THE NATIONAL SPORTS CLUB OF INDIA",
  },
  {
    code: "W280",
    display_name: "MADRAS CEMENTS LTD (RAJAPALAYAM)",
  },
  {
    code: "W284",
    display_name: "VIDESH SANCHAR NIGAM LTD (HQ)",
  },
  {
    code: "W285",
    display_name: "JOHNSON LIFTS (P) LTD (POONAMALLE)",
  },
  {
    code: "W286",
    display_name: "DALI and SAMIR ENGG P LTD",
  },
  {
    code: "W287",
    display_name: "THE TATA POWER COMPANY LTD",
  },
  {
    code: "W294",
    display_name: "SHEMAROO VIDEO PVT LTD",
  },
  {
    code: "W296",
    display_name: "TAJ LANDSEND LTD",
  },
  {
    code: "W301",
    display_name: "Tata Services Limited",
  },
  {
    code: "W306",
    display_name: "DINAKARAN - (MADURAI)",
  },
  {
    code: "W307",
    display_name: "DINAKARAN - (TIRUNELVELI)",
  },
  {
    code: "W310",
    display_name: "Tata Sons Limited",
  },
  {
    code: "W313",
    display_name: "FRITO-LAY INDIA",
  },
  {
    code: "W316",
    display_name: "TATA TEA LTD (JUNIOR MANAGEMENT)",
  },
  {
    code: "W322",
    display_name: "The Tata Pigments Limited",
  },
  {
    code: "W326",
    display_name: "Tata Investment Corporation Limited",
  },
  {
    code: "W330",
    display_name: "SISCO LATEX PVT LTD",
  },
  {
    code: "W331",
    display_name: "TATA MEMORIAL HOSPITAL - LABOUR STAFF",
  },
  {
    code: "W332",
    display_name: "TATA MEMORIAL HOSPITAL - MANAGEMENT STAFF",
  },
  {
    code: "W335",
    display_name: "SALEM CORPORATION",
  },
  {
    code: "W338",
    display_name: "Voltas Limited",
  },
  {
    code: "W340",
    display_name: "Tata Industries Limited",
  },
  {
    code: "W342",
    display_name: "VSNL (WESTERN REGION)",
  },
  {
    code: "W343",
    display_name: "KONE ELEVATOR INDIA (P) LTD",
  },
  {
    code: "W346",
    display_name: "Tata Coffee Limited",
  },
  {
    code: "W350",
    display_name: "THANJAVUR MUNICIPALITY",
  },
  {
    code: "W359",
    display_name: "PRECISION PLASTICS PACKAGING",
  },
  {
    code: "W361",
    display_name: "SANJAY AUTOMOBILE ENGS",
  },
  {
    code: "W367",
    display_name: "TATA FINANCE LTD",
  },
  {
    code: "W373",
    display_name: "EMDET ENGINEERS PVT LTD",
  },
  {
    code: "W376",
    display_name: "TATA ELXSI LIMITED",
  },
  {
    code: "W379",
    display_name: "T C E CONSULTING ENGINEERS LTD",
  },
  {
    code: "W382",
    display_name: "SURFA COAT BANGALORE LTD.",
  },
  {
    code: "W389",
    display_name: "ABB LTD - BALLARD ESTATE",
  },
  {
    code: "W402",
    display_name: "KAM HIGH SCHOOL - KOVILPATTU",
  },
  {
    code: "W403",
    display_name: "North Delhi Power Limited",
  },
  {
    code: "W406",
    display_name: "ASCENDUS TECHNOLOGY",
  },
  {
    code: "W407",
    display_name: "Tata Refractories Limited",
  },
  {
    code: "W425",
    display_name: "RALLIS INDIA - ANKLESHWAR",
  },
  {
    code: "W430",
    display_name: "INSTITUTE OF ENGG and EMERGING TECHNOLOGIES",
  },
  {
    code: "W434",
    display_name: "WEATHER",
  },
  {
    code: "W436",
    display_name: "SUDARSHAN CHEMICALS INDUSTRIES LTD",
  },
  {
    code: "W439",
    display_name: "RALLIS INDIA LTD (AKOLA)",
  },
  {
    code: "W446",
    display_name: "TATA TETLEY LTD",
  },
  {
    code: "W460",
    display_name: "DCM TEXTILES",
  },
  {
    code: "W492",
    display_name: "M/S PUNJAB CHEMICALS AND PHARMA LTD.",
  },
  {
    code: "W504",
    display_name: "GENLITE ENGINEERING (CHENNAI)",
  },
  {
    code: "W508",
    display_name: "Tata Projects Limited",
  },
  {
    code: "W510",
    display_name: "V J EQUIPMENT LTD.",
  },
  {
    code: "W516",
    display_name: "LE MERIDIEN (BANGALORE)",
  },
  {
    code: "W526",
    display_name: "SPEL SEMICONDUCTOR LTD",
  },
  {
    code: "W543",
    display_name: "TATA CHEMICALS LIMITED",
  },
  {
    code: "W545",
    display_name: "Tata International Limited",
  },
  {
    code: "W584",
    display_name: "ORISSA POWER GENERATION CORPORATION LTD. (ITPS)",
  },
  {
    code: "W597",
    display_name: "THE WATERBASE LTD",
  },
  {
    code: "W609",
    display_name: "TAJ RESIDENCY AURANGABAD",
  },
  {
    code: "W619",
    display_name: "ACER MOTORS",
  },
  {
    code: "W667",
    display_name: "NONOI TEA ESTATE, TATA TEA LIMITED",
  },
  {
    code: "W698",
    display_name: "VKC CREDIT AND FOREX SERVICES P LTD",
  },
  {
    code: "W707",
    display_name: "SM COKES LTD",
  },
  {
    code: "W708",
    display_name: "TM INTERNATIONAL LOGISTICS LIMITED",
  },
  {
    code: "W714",
    display_name: "ACHABAM T.E",
  },
  {
    code: "W715",
    display_name: "NAHORKUTIA TEA ESTATE (TATA TEA LIMITED)",
  },
  {
    code: "W716",
    display_name: "TATA TEA LIMITED - BORHAT TEA ESTATE",
  },
  {
    code: "W718",
    display_name: "CHUBWA TEA ESTATE (TATA TEA LIMITED)",
  },
  {
    code: "W721",
    display_name: "THOMSON PRESS INDIA LTD.",
  },
  {
    code: "W722",
    display_name: "MARUTHI AUTO WORKS",
  },
  {
    code: "W747",
    display_name: "PRISMS INDIA (P) LTD",
  },
  {
    code: "W751",
    display_name: "TEOK TEA ESTATE, TATA TEA LIMITED",
  },
  {
    code: "W753",
    display_name: "WEST MAN INTERNATIONAL DENTAL LAB (P) LTD.",
  },
  {
    code: "W754",
    display_name: "LATTAKOOJAN T.E",
  },
  {
    code: "W760",
    display_name: "SMH HIGHER SECONDARY SCHOOL",
  },
  {
    code: "W767",
    display_name: "COUNCIL OF BAPTIST CHURCHES IN NORTHEAST INDIA",
  },
  {
    code: "W771",
    display_name: "EXPRESS DELIVERIES",
  },
  {
    code: "W774",
    display_name: "TAJ BENGAL",
  },
  {
    code: "W791",
    display_name: "TATA TEA LTD (VOCATIONAL TRAINING CENTRE)",
  },
  {
    code: "W792",
    display_name: "LAMABARI T.E. TATA TEA LTD",
  },
  {
    code: "W793",
    display_name: "MAJULI T.E. TATA TEA LTD",
  },
  {
    code: "W794",
    display_name: "NAHORANI T.E. TATA TEA LTD",
  },
  {
    code: "W795",
    display_name: "HATTIGOR T.E. TATA TEA LTD",
  },
  {
    code: "W836",
    display_name: "CHURCH SCHOOL",
  },
  {
    code: "W875",
    display_name: "MS.PIONEER ENGINEERING.INDUSTRIES",
  },
  {
    code: "W882",
    display_name: "INTERNATIONAL AUTO LTD. PLANT-II",
  },
  {
    code: "W883",
    display_name: "INTERNATIONAL AUTO LTD. PLANT-I",
  },
  {
    code: "W884",
    display_name: "RSB TRANSMISSIONS (I) LTD",
  },
  {
    code: "W888",
    display_name: "KEMTECH SOLUTION PVT LTD",
  },
  {
    code: "W890",
    display_name: "Tata Advanced Materials Limited",
  },
  {
    code: "W904",
    display_name: "TATA TEA (SENIOR MANAGEMENT)",
  },
  {
    code: "W920",
    display_name: "INDIAN INSTITUTE OF ENTREPRENURSHIP",
  },
  {
    code: "W930",
    display_name: "McLEOD RUSSEL INDIA LIMITED",
  },
  {
    code: "W937",
    display_name: "STYX INFOSOFT PVT. LTD",
  },
  {
    code: "W953",
    display_name: "WILLIAMSON TEA ASSAM LIMITED-HARCHURAH TEA ESTATE",
  },
  {
    code: "W956",
    display_name: "MITHILA CARGO MOVERS",
  },
  {
    code: "W962",
    display_name: "ASCON MEDICAL INSTRUMENTS PVT LTD",
  },
  {
    code: "W963",
    display_name: "AWTAR ROADLINES",
  },
  {
    code: "W966",
    display_name: "ARUNACHAL PRADESH RURAL BANK",
  },
  {
    code: "W979",
    display_name: "AKSHAYA FORD",
  },
  {
    code: "W990",
    display_name: "ENVIRO CARE INDIA P LTD",
  },
  {
    code: "W993",
    display_name: "SIVARAM CAR CARE",
  },
  {
    code: "WA01",
    display_name: "3I INFOTECH",
  },
  {
    code: "WA02",
    display_name: "A2ZMES",
  },
  {
    code: "WA03",
    display_name: "Aarti Industries Ltd",
  },
  {
    code: "WA04",
    display_name: "ABAN OFFSHO",
  },
  {
    code: "WA05",
    display_name: "ABBOTT INDIA",
  },
  {
    code: "WA06",
    display_name: "ABG SHIPYARD",
  },
  {
    code: "WA07",
    display_name: "ACC LTD",
  },
  {
    code: "WA08",
    display_name: "Acer India",
  },
  {
    code: "WA09",
    display_name: "ACKRUTI",
  },
  {
    code: "WA10",
    display_name: "Adani Agrifresh",
  },
  {
    code: "WA11",
    display_name: "Adani Agro Logistics",
  },
  {
    code: "WA12",
    display_name: "Adani Enterprises",
  },
  {
    code: "WA13",
    display_name: "Adani Gas",
  },
  {
    code: "WA14",
    display_name: "Adani Global",
  },
  {
    code: "WA15",
    display_name: "Adani Logistics",
  },
  {
    code: "WA16",
    display_name: "Adani Mining",
  },
  {
    code: "WA17",
    display_name: "Adani Ports",
  },
  {
    code: "WA18",
    display_name: "ADANI POWER",
  },
  {
    code: "WA19",
    display_name: "Adani realty",
  },
  {
    code: "WA20",
    display_name: "Adani Solar",
  },
  {
    code: "WA21",
    display_name: "Adani Transmission",
  },
  {
    code: "WA22",
    display_name: "Adani Wilmar",
  },
  {
    code: "WA23",
    display_name: "ADHUNIK MET",
  },
  {
    code: "WA24",
    display_name: "ADI BIR NUVO",
  },
  {
    code: "WA25",
    display_name: "Aditya Birla Capital Advisors Private Limited",
  },
  {
    code: "WA26",
    display_name: "Aditya Birla Chemicals (India) Limited",
  },
  {
    code: "WA27",
    display_name: "Aditya Birla Chemicals (Thailand) Limited",
  },
  {
    code: "WA28",
    display_name: "Aditya Birla Fashion and Retail Limited",
  },
  {
    code: "WA29",
    display_name: "Aditya Birla Finance Limited",
  },
  {
    code: "WA30",
    display_name: "Aditya Birla Financial Services Group (ABFSG)",
  },
  {
    code: "WA31",
    display_name: "Aditya Birla Health",
  },
  {
    code: "WA32",
    display_name: "Aditya Birla Insurance Brokers",
  },
  {
    code: "WA33",
    display_name: "Aditya Birla Minerals",
  },
  {
    code: "WA34",
    display_name: "Aditya Birla Money Limited",
  },
  {
    code: "WA35",
    display_name: "Aditya Birla Nuvo Limited",
  },
  {
    code: "WA36",
    display_name: "Aditya Birla Online Fashion Private Limited",
  },
  {
    code: "WA37",
    display_name: "Aditya Birla Retail",
  },
  {
    code: "WA38",
    display_name: "Aditya Birla Science and Technology Company Ltd",
  },
  {
    code: "WA39",
    display_name: "Aditya Medisales",
  },
  {
    code: "WA40",
    display_name: "Adobe India",
  },
  {
    code: "WA41",
    display_name: "Advinus Therapeutics",
  },
  {
    code: "WA42",
    display_name: "Aegis Logistics Ltd",
  },
  {
    code: "WA43",
    display_name: "AEGON  Life Insurance Co. Ltd. ",
  },
  {
    code: "WA44",
    display_name: "Afcons Infrastructure",
  },
  {
    code: "WA45",
    display_name: "Agriculture Insurance Corporation",
  },
  {
    code: "WA46",
    display_name: "AHLUWALIA CO",
  },
  {
    code: "WA47",
    display_name: "AIAENGINEER",
  },
  {
    code: "WA48",
    display_name: "Aircel Group",
  },
  {
    code: "WA49",
    display_name: "Airtel Africa and South Asia",
  },
  {
    code: "WA50",
    display_name: "Airtel Jersey And Guernsey",
  },
  {
    code: "WA51",
    display_name: "Ajanta Pharma Limited",
  },
  {
    code: "WA52",
    display_name: "AKZOINDIA",
  },
  {
    code: "WA53",
    display_name: "Albonair GmbH, Germany",
  },
  {
    code: "WA54",
    display_name: "Alembic Pharmaceuticals Ltd",
  },
  {
    code: "WA55",
    display_name: "Alexandria Carbon Black Company SAE",
  },
  {
    code: "WA56",
    display_name: "Alexandria Fibre Company SAE",
  },
  {
    code: "WA57",
    display_name: "ALFA LAVAL",
  },
  {
    code: "WA58",
    display_name: "ALLAHABAD BK",
  },
  {
    code: "WA59",
    display_name: "Allanasons",
  },
  {
    code: "WA60",
    display_name: "ALLCARGO GLO",
  },
  {
    code: "WA61",
    display_name: "ALLIED DIGIT",
  },
  {
    code: "WA62",
    display_name: "ALOK INDUSTR",
  },
  {
    code: "WA63",
    display_name: "Alstom India Ltd",
  },
  {
    code: "WA64",
    display_name: "ALSTOM PROJE",
  },
  {
    code: "WA65",
    display_name: "Alstom TandD India Ltd",
  },
  {
    code: "WA66",
    display_name: "AMARA RAJA",
  },
  {
    code: "WA67",
    display_name: "Amazon",
  },
  {
    code: "WA68",
    display_name: "AMBUJA CEME",
  },
  {
    code: "WA69",
    display_name: "Amira Pure Foods",
  },
  {
    code: "WA70",
    display_name: "AMTEK AUTO L",
  },
  {
    code: "WA71",
    display_name: "AMTEK INDI L",
  },
  {
    code: "WA72",
    display_name: "ANANT RAJ IN",
  },
  {
    code: "WA73",
    display_name: "ANDHRA BANK",
  },
  {
    code: "WA74",
    display_name: "ANSAL INFRAS",
  },
  {
    code: "WA75",
    display_name: "Apar Industries Ltd",
  },
  {
    code: "WA76",
    display_name: "APL Apollo Tubes Ltd",
  },
  {
    code: "WA77",
    display_name: "APOLLO HOS E",
  },
  {
    code: "WA78",
    display_name: "Apollo Munich",
  },
  {
    code: "WA79",
    display_name: "APOLLO TYRE.",
  },
  {
    code: "WA80",
    display_name: "APTECH LTD",
  },
  {
    code: "WA81",
    display_name: "AQUA LOGIST",
  },
  {
    code: "WA82",
    display_name: "AREVA",
  },
  {
    code: "WA83",
    display_name: "ARSHYIA INTL",
  },
  {
    code: "WA84",
    display_name: "ARSS INFRA",
  },
  {
    code: "WA85",
    display_name: "ARVIND Ltd",
  },
  {
    code: "WA86",
    display_name: "ASAHI INDIA",
  },
  {
    code: "WA87",
    display_name: "Ashok Leyland",
  },
  {
    code: "WA88",
    display_name: "Ashok Leyland Wind Energy Ltd",
  },
  {
    code: "WA89",
    display_name: "ASHOK LEYLND",
  },
  {
    code: "WA90",
    display_name: "Ashoka Buildcon Ltd",
  },
  {
    code: "WA91",
    display_name: "Asian Colour Coated Ispat",
  },
  {
    code: "WA92",
    display_name: "ASIAN PAINTS",
  },
  {
    code: "WA93",
    display_name: "Asian Star Company Ltd",
  },
  {
    code: "WA94",
    display_name: "ASSAMCO",
  },
  {
    code: "WA95",
    display_name: "Associated Building Company",
  },
  {
    code: "WA96",
    display_name: "Atlas Copco (India)",
  },
  {
    code: "WA97",
    display_name: "ATLAS COPCOL",
  },
  {
    code: "WA98",
    display_name: "Atul Ltd",
  },
  {
    code: "WA99",
    display_name: "AUROBINDO PH",
  },
  {
    code: "WB17",
    display_name: "JINDAL IRON and STEEL CO.LTD.",
  },
  {
    code: "WB24",
    display_name: "TATA CHEMICALS LIMITED-KOLKATTA",
  },
  {
    code: "WB28",
    display_name: "KANCHEEPURAM MUNCIPALITY",
  },
  {
    code: "WB60",
    display_name: "BEEKEYEN FREIGHT SERVICES (P) LTD",
  },
  {
    code: "WB68",
    display_name: "JINDAL VIJAYNAGAR STEEL LTD",
  },
  {
    code: "WB74",
    display_name: "HOSUR MUNICIPALITY",
  },
  {
    code: "WC21",
    display_name: "INDIAN STEEL and WIRE PRODUCTS LTD",
  },
  {
    code: "WC56",
    display_name: "Tata Motors Finance Limited",
  },
  {
    code: "WC71",
    display_name: "RALLIS INDIA LTD (BANGALORE)",
  },
  {
    code: "WD01",
    display_name: "AVENTIS PHAR",
  },
  {
    code: "WD02",
    display_name: "Aviva Life Insurance Company India Limited",
  },
  {
    code: "WD03",
    display_name: "AXIS BANK",
  },
  {
    code: "W499",
    display_name: "Rallis India Limited",
  },
  {
    code: "WD04",
    display_name: "B L Kashyap and Sons Ltd",
  },
  {
    code: "WD05",
    display_name: "Bachhraj and Company Pvt. Ltd.",
  },
  {
    code: "WD06",
    display_name: "Bachhraj Factories Pvt. Ltd.",
  },
  {
    code: "WD07",
    display_name: "BAJ HOLD INV",
  },
  {
    code: "WP49",
    display_name: "SEL Manufacturing Company Ltd",
  },
  {
    code: "WP50",
    display_name: "Serum Institute of India",
  },
  {
    code: "WP51",
    display_name: "SESA GOA LTD",
  },
  {
    code: "WP52",
    display_name: "Sesa Sterlite Ltd",
  },
  {
    code: "WP53",
    display_name: "Shakti Bhog Foods",
  },
  {
    code: "WP54",
    display_name: "Shapoorji Pallonji and Co",
  },
  {
    code: "WP55",
    display_name: "SHIPPING COR",
  },
  {
    code: "WP56",
    display_name: "SHIVVANI OIL",
  },
  {
    code: "WP57",
    display_name: "SHOPPER STOP",
  },
  {
    code: "WP58",
    display_name: "SHREE ASHTA",
  },
  {
    code: "WP59",
    display_name: "Shree Bankey Behari Exports",
  },
  {
    code: "WP60",
    display_name: "SHREE CEMENT",
  },
  {
    code: "WP61",
    display_name: "Shree Ganesh Jewellery House (I) Ltd",
  },
  {
    code: "WP62",
    display_name: "SHREE GLOBAL",
  },
  {
    code: "WP63",
    display_name: "Shrenuj and Company Ltd",
  },
  {
    code: "WP64",
    display_name: "Shri Lakshmi Cotsyn Ltd",
  },
  {
    code: "WP65",
    display_name: "Shri Lal Mahal",
  },
  {
    code: "WP66",
    display_name: "Shriram City Union Finance Ltd",
  },
  {
    code: "WP67",
    display_name: "Shriram EPC Ltd",
  },
  {
    code: "WP68",
    display_name: "Shriram Life Insurance Co. Ltd.",
  },
  {
    code: "WP69",
    display_name: "SHRIRAM TRAN",
  },
  {
    code: "WP70",
    display_name: "Shubhalakshmi Polyesters",
  },
  {
    code: "WP71",
    display_name: "Siddhi Vinayak Logistic",
  },
  {
    code: "WP72",
    display_name: "SIEMENS LTD",
  },
  {
    code: "WP73",
    display_name: "Silver Spark Apparel Ltd.",
  },
  {
    code: "WP74",
    display_name: "SIMPLX INFRA",
  },
  {
    code: "WP75",
    display_name: "SINTEX INDUS",
  },
  {
    code: "WP76",
    display_name: "SJVN",
  },
  {
    code: "WP77",
    display_name: "SKF INDIA",
  },
  {
    code: "WP78",
    display_name: "SKS MICRO",
  },
  {
    code: "WP79",
    display_name: "Snapdeal",
  },
  {
    code: "WP80",
    display_name: "SOBHA DEV",
  },
  {
    code: "WP81",
    display_name: "Sobha Ltd",
  },
  {
    code: "WP82",
    display_name: "Sony India",
  },
  {
    code: "WP83",
    display_name: "SOUTH INDIA",
  },
  {
    code: "WP84",
    display_name: "Southern Petrochemicals Industries Corp",
  },
  {
    code: "WP85",
    display_name: "SPICEJET LTD",
  },
  {
    code: "WP86",
    display_name: "SREI INFRA",
  },
  {
    code: "WP87",
    display_name: "SRS Ltd",
  },
  {
    code: "WP88",
    display_name: "ST BK TRAVAN",
  },
  {
    code: "WP89",
    display_name: "ST TRAD CORP",
  },
  {
    code: "WP90",
    display_name: "STAN CHART",
  },
  {
    code: "WP91",
    display_name: "Star Health and Allied",
  },
  {
    code: "WP92",
    display_name: "Star India",
  },
  {
    code: "WP93",
    display_name: "Star Union Dai-ichi Life Insurance Co. Ltd.",
  },
  {
    code: "WP94",
    display_name: "STATE BANK O",
  },
  {
    code: "WP95",
    display_name: "STATE BK MYS",
  },
  {
    code: "WP96",
    display_name: "STEEL AUTHOR",
  },
  {
    code: "WP97",
    display_name: "STER TECH",
  },
  {
    code: "WP98",
    display_name: "STERL BIOTEC",
  },
  {
    code: "WP99",
    display_name: "STERL INTER",
  },
  {
    code: "WQ01",
    display_name: "Sterling Agro Industries",
  },
  {
    code: "WQ02",
    display_name: "STERLITE IN",
  },
  {
    code: "WQ03",
    display_name: "STRIDES ARCO",
  },
  {
    code: "WQ04",
    display_name: "SUBEX LTX",
  },
  {
    code: "WQ05",
    display_name: "Sujana Metal Products Ltd",
  },
  {
    code: "WQ06",
    display_name: "Sujana Towers Ltd",
  },
  {
    code: "WQ07",
    display_name: "Sujana Universal Industries Ltd",
  },
  {
    code: "WQ08",
    display_name: "SUN PHARMACE",
  },
  {
    code: "WQ09",
    display_name: "SUN TVNET",
  },
  {
    code: "WQ10",
    display_name: "Sundaram Clayton Ltd",
  },
  {
    code: "WQ11",
    display_name: "Sundaram Finance Ltd",
  },
  {
    code: "WQ12",
    display_name: "SUNDRAM FAST",
  },
  {
    code: "WQ13",
    display_name: "Sunflag Iron and Steel Company Ltd",
  },
  {
    code: "WQ14",
    display_name: "SUNPHA ADV",
  },
  {
    code: "WQ15",
    display_name: "Sunrise Asian Limited",
  },
  {
    code: "WQ16",
    display_name: "SUNTECK REAL",
  },
  {
    code: "WQ17",
    display_name: "Supertech",
  },
  {
    code: "WQ18",
    display_name: "SUPREM IND",
  },
  {
    code: "WQ19",
    display_name: "Supreme Petrochem Ltd",
  },
  {
    code: "WQ20",
    display_name: "Surana Industries Ltd",
  },
  {
    code: "WQ21",
    display_name: "Surya Roshni Ltd",
  },
  {
    code: "WQ22",
    display_name: "Sutlej Textiles and Industries Ltd",
  },
  {
    code: "WQ23",
    display_name: "SUZLONENERGY",
  },
  {
    code: "WQ24",
    display_name: "Swiss Re",
  },
  {
    code: "WQ25",
    display_name: "SYNDICATE",
  },
  {
    code: "WQ26",
    display_name: "Syngenta India",
  },
  {
    code: "WQ27",
    display_name: "Syntel",
  },
  {
    code: "WQ28",
    display_name: "TAFE Limited",
  },
  {
    code: "WQ29",
    display_name: "TAFE Motors and Tractors",
  },
  {
    code: "WQ30",
    display_name: "Taj Air",
  },
  {
    code: "WQ31",
    display_name: "TAL Manufacturing Solutions",
  },
  {
    code: "WQ32",
    display_name: "TAMILNADU NE",
  },
  {
    code: "WQ33",
    display_name: "Tara Jewels Ltd",
  },
  {
    code: "WQ34",
    display_name: "TAS-AGT Systems",
  },
  {
    code: "WQ35",
    display_name: "TASEC",
  },
  {
    code: "WQ36",
    display_name: "Tata Advanced Materials",
  },
  {
    code: "WQ37",
    display_name: "Tata Advanced Systems",
  },
  {
    code: "WQ38",
    display_name: "Tata Africa Holdings",
  },
  {
    code: "WQ39",
    display_name: "Tata AG",
  },
  {
    code: "WQ40",
    display_name: "Tata AIA Life Insurance",
  },
  {
    code: "WQ41",
    display_name: "Tata AIG General Insurance",
  },
  {
    code: "WQ42",
    display_name: "Tata Asset Management",
  },
  {
    code: "WQ43",
    display_name: "Tata Autocomp Systems",
  },
  {
    code: "WQ44",
    display_name: "Tata Blue Scope Steel",
  },
  {
    code: "WQ45",
    display_name: "Tata Business Excellence Group",
  },
  {
    code: "WQ46",
    display_name: "TATA CHEMICA",
  },
  {
    code: "WQ47",
    display_name: "Tata Chemicals",
  },
  {
    code: "WQ48",
    display_name: "Tata Chemicals Europe",
  },
  {
    code: "WQ49",
    display_name: "Tata Chemicals Magadi",
  },
  {
    code: "WQ50",
    display_name: "Tata Chemicals North America",
  },
  {
    code: "WQ51",
    display_name: "Tata ClassEdge",
  },
  {
    code: "WQ52",
    display_name: "Tata Daewoo Commercial Vehicle Company",
  },
  {
    code: "WQ53",
    display_name: "Tata Pigments",
  },
  {
    code: "WQ54",
    display_name: "Tata SIA Airlines(Vistara)",
  },
  {
    code: "WQ55",
    display_name: "TATAGLOBAL",
  },
  {
    code: "WQ56",
    display_name: "Tech Mahindra",
  },
  {
    code: "WQ57",
    display_name: "Tecpro Systems Ltd",
  },
  {
    code: "WQ58",
    display_name: "TEL EIGHTEEN",
  },
  {
    code: "WQ59",
    display_name: "Telecommunications Consultants India",
  },
  {
    code: "WQ60",
    display_name: "THDC India",
  },
  {
    code: "WQ61",
    display_name: "The Ramco Cements Ltd",
  },
  {
    code: "WQ62",
    display_name: "The Tinplate Company of India",
  },
  {
    code: "WQ63",
    display_name: "THERMAX LMTD",
  },
  {
    code: "WQ64",
    display_name: "THOMAS CK IN",
  },
  {
    code: "WQ65",
    display_name: "TIME TECHNO",
  },
  {
    code: "WQ66",
    display_name: "TINPLATE COM",
  },
  {
    code: "WQ67",
    display_name: "TITAGARH WAG",
  },
  {
    code: "WQ68",
    display_name: "Titan Company",
  },
  {
    code: "WQ69",
    display_name: "TITAN IND.",
  },
  {
    code: "WQ70",
    display_name: "TKM Global Logistics",
  },
  {
    code: "WQ71",
    display_name: "TORNT POWER",
  },
  {
    code: "WQ72",
    display_name: "TORRENT PHAR",
  },
  {
    code: "WQ73",
    display_name: "Toyota Kirloskar Motor",
  },
  {
    code: "WQ74",
    display_name: "Transport Corporation of India Ltd",
  },
  {
    code: "WQ75",
    display_name: "Transstroy India",
  },
  {
    code: "WQ76",
    display_name: "TRENT",
  },
  {
    code: "WQ77",
    display_name: "Trident Ltd",
  },
  {
    code: "WQ78",
    display_name: "Trinity India Ltd.",
  },
  {
    code: "WQ79",
    display_name: "TRIVENI ENGI",
  },
  {
    code: "WQ80",
    display_name: "TRL Krosaki Refractories",
  },
  {
    code: "WQ81",
    display_name: "Ttk Prestige Limited",
  },
  {
    code: "WQ82",
    display_name: "TUBE INVEST",
  },
  {
    code: "WQ83",
    display_name: "TULIP TELE",
  },
  {
    code: "WQ84",
    display_name: "TVS MOTOR L",
  },
  {
    code: "WQ85",
    display_name: "TVS Srichakra Ltd",
  },
  {
    code: "WQ86",
    display_name: "Twitter",
  },
  {
    code: "WQ87",
    display_name: "Uber India",
  },
  {
    code: "WQ88",
    display_name: "UCO BANK",
  },
  {
    code: "WQ89",
    display_name: "UFLEX LTD",
  },
  {
    code: "WQ90",
    display_name: "Ultratech Cement Ltd",
  },
  {
    code: "WQ91",
    display_name: "ULTRATECH CM",
  },
  {
    code: "WQ92",
    display_name: "UNICHEM LAB",
  },
  {
    code: "WQ93",
    display_name: "UNION BANK",
  },
  {
    code: "WQ94",
    display_name: "UNITD SPR",
  },
  {
    code: "WQ95",
    display_name: "UNITECH LTD",
  },
  {
    code: "WQ96",
    display_name: "UNITED BANK",
  },
  {
    code: "WQ97",
    display_name: "UNITED BREW",
  },
  {
    code: "WQ98",
    display_name: "UNITED BREWR",
  },
  {
    code: "WQ99",
    display_name: "United India Insurance",
  },
  {
    code: "WR01",
    display_name: "UNITED PHOSP",
  },
  {
    code: "WR02",
    display_name: "United Spirits Ltd",
  },
  {
    code: "WR03",
    display_name: "Unity Infraprojects Ltd",
  },
  {
    code: "WR04",
    display_name: "UP Rajya Vidyut Utpadan Nigam",
  },
  {
    code: "WR05",
    display_name: "UPL Ltd",
  },
  {
    code: "WR06",
    display_name: "USHA MARTIN",
  },
  {
    code: "WR07",
    display_name: "USV",
  },
  {
    code: "WR08",
    display_name: "Utkal Alumina International Limited",
  },
  {
    code: "WR09",
    display_name: "UTTAM GALVA",
  },
  {
    code: "WR10",
    display_name: "Uttam Value Steels Ltd",
  },
  {
    code: "WR11",
    display_name: "Uttar Gujarat Vij Co",
  },
  {
    code: "WR12",
    display_name: "UTV SOFTWARE",
  },
  {
    code: "WR13",
    display_name: "Va Tech Wabag Ltd",
  },
  {
    code: "WR14",
    display_name: "Vakrangee Ltd",
  },
  {
    code: "WR15",
    display_name: "Vardhman Textiles Ltd",
  },
  {
    code: "WR16",
    display_name: "Varun Beverages",
  },
  {
    code: "WR17",
    display_name: "VARUN SHIPNG",
  },
  {
    code: "WR18",
    display_name: "VASCON ENG",
  },
  {
    code: "WR19",
    display_name: "Venkateshwara Hatcheries",
  },
  {
    code: "WR20",
    display_name: "VIDEOCON IND",
  },
  {
    code: "WR21",
    display_name: "VIJAYA BANK",
  },
  {
    code: "WR22",
    display_name: "Vikas WSP Ltd",
  },
  {
    code: "WR23",
    display_name: "Vimal Oil and Foods Ltd",
  },
  {
    code: "WR24",
    display_name: "Viom Networks",
  },
  {
    code: "WR25",
    display_name: "VIP INDUSTRI",
  },
  {
    code: "WR26",
    display_name: "Viraj Profiles ltd",
  },
  {
    code: "WR27",
    display_name: "Vodafone India",
  },
  {
    code: "WR28",
    display_name: "Volkswagen Group Sales India",
  },
  {
    code: "WR29",
    display_name: "VOLTAMP TRAN",
  },
  {
    code: "WR30",
    display_name: "VOLTAS LTD",
  },
  {
    code: "WR31",
    display_name: "Volvo India",
  },
  {
    code: "WR32",
    display_name: "VVF India",
  },
  {
    code: "WR33",
    display_name: "Wadia Techno-Engineering services",
  },
  {
    code: "WR34",
    display_name: "WALCHAND IN",
  },
  {
    code: "WR35",
    display_name: "WELCORP",
  },
  {
    code: "WR36",
    display_name: "Wellknown Polyesters",
  },
  {
    code: "WR37",
    display_name: "Welspun Corp Ltd",
  },
  {
    code: "WR38",
    display_name: "Welspun India Ltd",
  },
  {
    code: "WR39",
    display_name: "Western Electricity Supply of Orissa",
  },
  {
    code: "WR40",
    display_name: "Westland",
  },
  {
    code: "WR41",
    display_name: "Wheels India Ltd",
  },
  {
    code: "WR42",
    display_name: "WHIRLPOOL IN",
  },
  {
    code: "WR43",
    display_name: "Wipro Enterprises",
  },
  {
    code: "WR44",
    display_name: "Wipro GE Healthcare",
  },
  {
    code: "WR45",
    display_name: "WIPRO LTD.",
  },
  {
    code: "WR46",
    display_name: "WNS",
  },
  {
    code: "WR47",
    display_name: "WOCKHARDT LT",
  },
  {
    code: "WR48",
    display_name: "Worlds Window Impex India",
  },
  {
    code: "WR49",
    display_name: "WS Retail Services",
  },
  {
    code: "WR50",
    display_name: "WYETH LTD",
  },
  {
    code: "WR51",
    display_name: "Yatra",
  },
  {
    code: "WR52",
    display_name: "Ybrant Digital Ltd",
  },
  {
    code: "WR53",
    display_name: "YES BANK",
  },
  {
    code: "WR54",
    display_name: "YouTube",
  },
  {
    code: "WR55",
    display_name: "Zaveri and Co",
  },
  {
    code: "WR56",
    display_name: "ZEE ENTER",
  },
  {
    code: "WR57",
    display_name: "Zensar Technologies Ltd",
  },
  {
    code: "WR58",
    display_name: "ZUARI INDUST",
  },
  {
    code: "WR59",
    display_name: "ZYDUS WELL",
  },
  {
    code: "WR60",
    display_name: "Zylog Systems Ltd",
  },
  {
    code: "WZ99",
    display_name: "Others",
  },
  {
    code: "WD97",
    display_name: "Canara HSBC Oriental Bank of Commerce Life Ins",
  },
  {
    code: "WJ66",
    display_name: "Hinduja Tech Limited",
  },
  {
    code: "WI46",
    display_name: "Future Capital Holdings",
  },
  {
    code: "WN58",
    display_name: "P D Hinduja National Hospital",
  },
  {
    code: "WK74",
    display_name: "Jamshedpur Continuous Annealingand Co",
  },
  {
    code: "WN59",
    display_name: "P.D. Ruia Kayakalp Naturopathy and Health Resort",
  },
  {
    code: "WR61",
    display_name: "Vistara-TATA SIA Airlines Ltd.",
  },
  {
    code: "WR62",
    display_name: "CRPF",
  },
  {
    code: "WR63",
    display_name: "Tata Community Initiatives Trust",
  },
  {
    code: "WR64",
    display_name: "TRL KROSAKI",
  },
  {
    code: "WI10",
    display_name: "ESS DEE ALUM",
  },
  {
    code: "WI11",
    display_name: "ESSAR OIL LT",
  },
  {
    code: "WI12",
    display_name: "Essar Projects India",
  },
  {
    code: "WI13",
    display_name: "ESSAR SHIP",
  },
  {
    code: "WI14",
    display_name: "Essar Steel",
  },
  {
    code: "WI15",
    display_name: "Essel Mining and Industries Limited",
  },
  {
    code: "WI16",
    display_name: "Essel Propack Ltd",
  },
  {
    code: "WI17",
    display_name: "Everblue Apparel Ltd.",
  },
  {
    code: "WI18",
    display_name: "EVEREST KANT",
  },
  {
    code: "WI19",
    display_name: "EVERONN",
  },
  {
    code: "WI20",
    display_name: "EXIDE INDUS",
  },
  {
    code: "WI21",
    display_name: "Exide Life Insurance Co. Ltd.",
  },
  {
    code: "WI22",
    display_name: "Facebook",
  },
  {
    code: "WI23",
    display_name: "Fag Bearings India Ltd",
  },
  {
    code: "WI24",
    display_name: "Falcon Tyres Ltd., India",
  },
  {
    code: "WI25",
    display_name: "FDC LIMITED",
  },
  {
    code: "WI26",
    display_name: "FEDERAL BANK",
  },
  {
    code: "WI27",
    display_name: "Fertilisers and Chemicals Travancore",
  },
  {
    code: "WI28",
    display_name: "Fiat India Automobiles",
  },
  {
    code: "WI29",
    display_name: "Fibre Foam (Bombay) Pvt. Ltd.",
  },
  {
    code: "WI30",
    display_name: "FieldFresh Foods",
  },
  {
    code: "WI31",
    display_name: "FINANC TECHN",
  },
  {
    code: "WI32",
    display_name: "FINOLEX CABL",
  },
  {
    code: "WI33",
    display_name: "FINOLEX IND",
  },
  {
    code: "WI34",
    display_name: "Firstsource Solutions Ltd",
  },
  {
    code: "WI35",
    display_name: "FKONCO",
  },
  {
    code: "WI36",
    display_name: "Flipkart India",
  },
  {
    code: "WI37",
    display_name: "Forbes and Company Ltd",
  },
  {
    code: "WI38",
    display_name: "Force Motors Ltd",
  },
  {
    code: "WI39",
    display_name: "Ford India",
  },
  {
    code: "WI40",
    display_name: "FORTIS",
  },
  {
    code: "WI41",
    display_name: "Frigerio Conserva Allana",
  },
  {
    code: "WI42",
    display_name: "Frigorifco Allana",
  },
  {
    code: "WI43",
    display_name: "FSL",
  },
  {
    code: "WI44",
    display_name: "Future Brands",
  },
  {
    code: "WI45",
    display_name: "FUTURE CAP",
  },
  {
    code: "WI47",
    display_name: "Future Consumer Limited",
  },
  {
    code: "WI48",
    display_name: "Future Enterprises Limited",
  },
  {
    code: "WI49",
    display_name: "Future Generali General Insurance",
  },
  {
    code: "WI50",
    display_name: "Future Generali Life Insurance",
  },
  {
    code: "WI51",
    display_name: "Future Innoversity",
  },
  {
    code: "WI52",
    display_name: "Future Learning",
  },
  {
    code: "WI53",
    display_name: "Future Lifestyle Fashion Ltd",
  },
  {
    code: "WI54",
    display_name: "Future Retail Ltd[12]",
  },
  {
    code: "WI55",
    display_name: "Future Supply Chains",
  },
  {
    code: "WI56",
    display_name: "Future Ventures",
  },
  {
    code: "WI57",
    display_name: "GAIL INDIA",
  },
  {
    code: "WI58",
    display_name: "GAMMON INDI",
  },
  {
    code: "WI59",
    display_name: "GAMMON INFRA",
  },
  {
    code: "WI60",
    display_name: "Garden Silk Mills Ltd",
  },
  {
    code: "WI61",
    display_name: "GATEWAY DIST",
  },
  {
    code: "WI62",
    display_name: "Gayatri Projects Ltd",
  },
  {
    code: "WI63",
    display_name: "Genpact India",
  },
  {
    code: "WI64",
    display_name: "GEOD LTD",
  },
  {
    code: "WI65",
    display_name: "GHCL Ltd",
  },
  {
    code: "WI66",
    display_name: "GIC of India",
  },
  {
    code: "WI67",
    display_name: "GILLETTE IND",
  },
  {
    code: "WI68",
    display_name: "GITANJALI GE",
  },
  {
    code: "WI69",
    display_name: "GLAXOSMI CON",
  },
  {
    code: "WI70",
    display_name: "GLAXOSMITH",
  },
  {
    code: "WI71",
    display_name: "GLENMARK PHA",
  },
  {
    code: "WI72",
    display_name: "GLODYNE TECH",
  },
  {
    code: "WI73",
    display_name: "GMR INFRASTR",
  },
  {
    code: "WI74",
    display_name: "Go AirLines",
  },
  {
    code: "WI75",
    display_name: "Go Airlines India",
  },
  {
    code: "WI76",
    display_name: "Godfrey Philips India Ltd",
  },
  {
    code: "WI77",
    display_name: "Godrej and Boyce Manufacturing Co",
  },
  {
    code: "WI78",
    display_name: "Godrej Agrovet",
  },
  {
    code: "WI79",
    display_name: "GODREJ CONS",
  },
  {
    code: "WI80",
    display_name: "GODREJ INDUS",
  },
  {
    code: "WI81",
    display_name: "GODREJ PROP",
  },
  {
    code: "WI82",
    display_name: "Goibibo",
  },
  {
    code: "WI83",
    display_name: "Gokul Refoils and Solvent Ltd",
  },
  {
    code: "WI84",
    display_name: "Goodyear India Ltd",
  },
  {
    code: "WI85",
    display_name: "Google",
  },
  {
    code: "WI86",
    display_name: "Google India",
  },
  {
    code: "WI87",
    display_name: "Gourmet Investments",
  },
  {
    code: "WI88",
    display_name: "GPPL",
  },
  {
    code: "WI89",
    display_name: "GRAPHIT INDI",
  },
  {
    code: "WI90",
    display_name: "GRASIM INDUS",
  },
  {
    code: "WI91",
    display_name: "Grasim Industries Limited",
  },
  {
    code: "WI92",
    display_name: "GREAT EASTE",
  },
  {
    code: "WI93",
    display_name: "GREAT OFFSH",
  },
  {
    code: "WI94",
    display_name: "GREAVES COTT",
  },
  {
    code: "WI95",
    display_name: "Greenply Industries Ltd",
  },
  {
    code: "WI96",
    display_name: "Gruh Finance Limited",
  },
  {
    code: "WI97",
    display_name: "GTL INFRAST",
  },
  {
    code: "WI98",
    display_name: "GTL LIMITED",
  },
  {
    code: "WI99",
    display_name: "GUJ ALKALI",
  },
  {
    code: "WJ01",
    display_name: "GUJ MIN DEVL",
  },
  {
    code: "WJ02",
    display_name: "GUJ NARMADA",
  },
  {
    code: "WJ03",
    display_name: "GUJ PETRONET",
  },
  {
    code: "WJ04",
    display_name: "GUJAR IN PWR",
  },
  {
    code: "WJ05",
    display_name: "GUJARA NRE C",
  },
  {
    code: "WJ06",
    display_name: "Gujarat Ambuja Exports Ltd",
  },
  {
    code: "WJ07",
    display_name: "Gujarat Energy Transmission Corp",
  },
  {
    code: "WJ08",
    display_name: "GUJARAT FLUO",
  },
  {
    code: "WJ09",
    display_name: "GUJARAT GAS",
  },
  {
    code: "WJ10",
    display_name: "Gujarat Gas Co",
  },
  {
    code: "WJ11",
    display_name: "Gujarat Industries Power Company Ltd",
  },
  {
    code: "WJ12",
    display_name: "Gujarat Mineral Development Corporation",
  },
  {
    code: "WJ13",
    display_name: "Gujarat Narmada Valley Fertilizrs and Chem",
  },
  {
    code: "WJ14",
    display_name: "Gujarat NRE Coke Ltd",
  },
  {
    code: "WJ15",
    display_name: "GUJARAT STAT",
  },
  {
    code: "WJ16",
    display_name: "Gujarat State Electricity Corporation",
  },
  {
    code: "WJ17",
    display_name: "Gujarat State Petroleum Corporation",
  },
  {
    code: "WJ18",
    display_name: "Gujarat Urja Vikas Nigam",
  },
  {
    code: "WJ19",
    display_name: "GULF OIL COR",
  },
  {
    code: "WJ20",
    display_name: "Gulf Oil Corporation Ltd",
  },
  {
    code: "WJ21",
    display_name: "Gulf Oil Lubricants India Limited",
  },
  {
    code: "WJ22",
    display_name: "Gulf Oil Middle East Ltd",
  },
  {
    code: "WJ23",
    display_name: "GVK POWERINF",
  },
  {
    code: "WJ24",
    display_name: "Haldia Petrochemicals",
  },
  {
    code: "WJ25",
    display_name: "Hannover Re",
  },
  {
    code: "WJ26",
    display_name: "Hanung Toys and Textiles Ltd",
  },
  {
    code: "WJ27",
    display_name: "Hari Krishna Exports",
  },
  {
    code: "WJ28",
    display_name: "HATHWAY CAB",
  },
  {
    code: "WJ29",
    display_name: "Hatsun Agro Products Ltd",
  },
  {
    code: "WJ30",
    display_name: "HAVELLSINDIA",
  },
  {
    code: "WJ31",
    display_name: "HBL Power Systems Ltd",
  },
  {
    code: "WJ32",
    display_name: "HCL INFOSYS",
  },
  {
    code: "WJ33",
    display_name: "HCL TECHNO",
  },
  {
    code: "WJ34",
    display_name: "HDFC BANK LT",
  },
  {
    code: "WJ35",
    display_name: "HDFC LTD",
  },
  {
    code: "WJ36",
    display_name: "HDFC Standard Life Insurance Co. Ltd ",
  },
  {
    code: "WJ37",
    display_name: "HEG LIMITED",
  },
  {
    code: "WJ38",
    display_name: "HEIDEL CEM",
  },
  {
    code: "WJ39",
    display_name: "Hercules Hoists Ltd.",
  },
  {
    code: "WJ40",
    display_name: "Heritage Foods Ltd",
  },
  {
    code: "WJ41",
    display_name: "HEROHONDA M",
  },
  {
    code: "WJ42",
    display_name: "Hetero Labs",
  },
  {
    code: "WJ43",
    display_name: "Hewlett-Packard Globalsoft",
  },
  {
    code: "WJ44",
    display_name: "Hewlett-Packard India Sales",
  },
  {
    code: "WJ45",
    display_name: "HEXAWARE LTD",
  },
  {
    code: "WJ46",
    display_name: "Hike Global Limited",
  },
  {
    code: "WJ47",
    display_name: "HIM FUTR COM",
  },
  {
    code: "WJ48",
    display_name: "HIMAD CHEM I",
  },
  {
    code: "WJ49",
    display_name: "Himatsingka Seide Ltd",
  },
  {
    code: "WJ50",
    display_name: "Hind Lamps Ltd.",
  },
  {
    code: "WJ51",
    display_name: "Hind Musafir Agency Ltd.",
  },
  {
    code: "WJ52",
    display_name: "HIND UNI LT",
  },
  {
    code: "WJ53",
    display_name: "HIND.COPPER",
  },
  {
    code: "WJ54",
    display_name: "HIND.OIL EXP",
  },
  {
    code: "WJ55",
    display_name: "HIND.ZINC",
  },
  {
    code: "WJ56",
    display_name: "HINDALCO IN",
  },
  {
    code: "WJ57",
    display_name: "Hindalco Industries Ltd",
  },
  {
    code: "WJ58",
    display_name: "Hinduja Bank (Switzerland) Ltd",
  },
  {
    code: "WJ59",
    display_name: "Hinduja Foundries Ltd",
  },
  {
    code: "WJ60",
    display_name: "Hinduja Global Solutions Ltd",
  },
  {
    code: "WJ61",
    display_name: "Hinduja Group India Limited",
  },
  {
    code: "WJ62",
    display_name: "Hinduja Healthcare Ltd",
  },
  {
    code: "WJ63",
    display_name: "Hinduja Leyland Finance Ltd",
  },
  {
    code: "WJ64",
    display_name: "Hinduja National Power Corporation Ltd",
  },
  {
    code: "WJ65",
    display_name: "Hinduja Realty Ventures Ltd",
  },
  {
    code: "WJ67",
    display_name: "Hinduja Ventures Ltd",
  },
  {
    code: "WJ68",
    display_name: "HINDUS CONST",
  },
  {
    code: "WJ69",
    display_name: "Hindustan Aeronautics",
  },
  {
    code: "WJ70",
    display_name: "Hindustan Coca-Cola Beverages",
  },
  {
    code: "WJ71",
    display_name: "Hindustan Copper Ltd",
  },
  {
    code: "WJ72",
    display_name: "HINDUSTAN PE",
  },
  {
    code: "WJ73",
    display_name: "Hindustan Zinc Ltd",
  },
  {
    code: "WJ74",
    display_name: "Hindusthan National Glass and Industries",
  },
  {
    code: "WJ75",
    display_name: "HMT LTD",
  },
  {
    code: "WJ76",
    display_name: "Honda Cars India",
  },
  {
    code: "WJ77",
    display_name: "Honda Motorcycle and Scooter India",
  },
  {
    code: "WJ78",
    display_name: "Honeywell Automation India Ltd",
  },
  {
    code: "WJ79",
    display_name: "HOTEL LEELAV",
  },
  {
    code: "WJ80",
    display_name: "Houghton International",
  },
  {
    code: "WJ81",
    display_name: "HOUSING DEV",
  },
  {
    code: "WJ82",
    display_name: "HOUSING DEVE",
  },
  {
    code: "WJ83",
    display_name: "HSBC Electronic Data Processing India",
  },
  {
    code: "WJ84",
    display_name: "HSBC Software Development India",
  },
  {
    code: "WJ85",
    display_name: "HSIL Ltd",
  },
  {
    code: "WJ86",
    display_name: "HT MEDIA",
  },
  {
    code: "WJ87",
    display_name: "Hubli Electricity Supply Co",
  },
  {
    code: "WJ88",
    display_name: "Huntsman International India",
  },
  {
    code: "WJ89",
    display_name: "Hyundai Motor India",
  },
  {
    code: "WJ90",
    display_name: "I T C LTD",
  },
  {
    code: "WJ91",
    display_name: "I.C.S.A. IND",
  },
  {
    code: "WJ92",
    display_name: "IB SECURITIE",
  },
  {
    code: "WJ93",
    display_name: "IBM Daksh Business Process Services",
  },
  {
    code: "WJ94",
    display_name: "IBM India",
  },
  {
    code: "WJ95",
    display_name: "IBN18",
  },
  {
    code: "WJ96",
    display_name: "ICICI BANK L",
  },
  {
    code: "WJ97",
    display_name: "ICICI Direct",
  },
  {
    code: "WJ98",
    display_name: "ICICI Foundation",
  },
  {
    code: "WJ99",
    display_name: "ICICI Home Finance Company",
  },
  {
    code: "WK01",
    display_name: "ICICI Lombard General Insurance Company",
  },
  {
    code: "WK02",
    display_name: "ICICI Prudential Asset Management Co",
  },
  {
    code: "WK03",
    display_name: "ICICI Prudential Life Insurance Company",
  },
  {
    code: "WK04",
    display_name: "ICICI Securities",
  },
  {
    code: "WK05",
    display_name: "ICICI Securities Primary Dealership",
  },
  {
    code: "WK06",
    display_name: "ICICI Venture",
  },
  {
    code: "WK07",
    display_name: "ICRA",
  },
  {
    code: "WK08",
    display_name: "IDBI BANK L",
  },
  {
    code: "WK09",
    display_name: "IDBI Federal Life Insurance Co. Ltd.",
  },
  {
    code: "WK10",
    display_name: "IDEA CELL",
  },
  {
    code: "WK11",
    display_name: "Idea Cellular Limited",
  },
  {
    code: "WK12",
    display_name: "IDFC Bank",
  },
  {
    code: "WK13",
    display_name: "IDFC Ltd",
  },
  {
    code: "WK14",
    display_name: "IDL Specialty Chemicals Ltd",
  },
  {
    code: "WK15",
    display_name: "IFCI LTD",
  },
  {
    code: "WK16",
    display_name: "iGate Global Solutions",
  },
  {
    code: "WK17",
    display_name: "IIFL Holdings Ltd",
  },
  {
    code: "WK18",
    display_name: "ILandFS Engineering and Construction Company",
  },
  {
    code: "WK19",
    display_name: "ILandFS TRANS",
  },
  {
    code: "WK20",
    display_name: "IND INFOLINE",
  },
  {
    code: "WK21",
    display_name: "INDIA CEMENT",
  },
  {
    code: "WK22",
    display_name: "India Glycols Ltd",
  },
  {
    code: "WK23",
    display_name: "India Tyre and Rubber Company (India) Ltd, India",
  },
  {
    code: "WK24",
    display_name: "India Yamaha Motor",
  },
  {
    code: "WK25",
    display_name: "INDIAB POWER",
  },
  {
    code: "WK26",
    display_name: "INDIABULLS",
  },
  {
    code: "WK27",
    display_name: "IndiaFirst Life Insurance Co. Ltd.",
  },
  {
    code: "WK28",
    display_name: "INDIAN BANK",
  },
  {
    code: "WK29",
    display_name: "Indian Farmers Fertilizer Cooperative (IFFCo)",
  },
  {
    code: "WK30",
    display_name: "INDIAN MET",
  },
  {
    code: "WK31",
    display_name: "INDIAN OIL C",
  },
  {
    code: "WK32",
    display_name: "INDIAN OVERS",
  },
  {
    code: "WK33",
    display_name: "Indian Potash",
  },
  {
    code: "WK34",
    display_name: "Indo Rama Synthetics (India) Ltd",
  },
  {
    code: "WK35",
    display_name: "INDOSOLAR",
  },
  {
    code: "WK36",
    display_name: "INDRA GAS",
  },
  {
    code: "WK37",
    display_name: "INDUS IND BK",
  },
  {
    code: "WK38",
    display_name: "Indus Towers",
  },
  {
    code: "WK39",
    display_name: "IndusInd Bank",
  },
  {
    code: "WK40",
    display_name: "IndusInd Media and Communications Ltd",
  },
  {
    code: "WK41",
    display_name: "Infiniti Retail",
  },
  {
    code: "WK42",
    display_name: "INFO EDGE",
  },
  {
    code: "WK43",
    display_name: "INFOSYS TECH",
  },
  {
    code: "WK44",
    display_name: "INFOTECH ENR",
  },
  {
    code: "WK45",
    display_name: "INFRA DEVFIN",
  },
  {
    code: "WK46",
    display_name: "ING VYSYA BK",
  },
  {
    code: "WK47",
    display_name: "INGERSOL RND",
  },
  {
    code: "WK48",
    display_name: "Intas Pharmaceuticals",
  },
  {
    code: "WK49",
    display_name: "Intel Technology India",
  },
  {
    code: "WK50",
    display_name: "International Tractors",
  },
  {
    code: "WK51",
    display_name: "Intex Technologies (India)",
  },
  {
    code: "WK52",
    display_name: "Inzpera Health sciences",
  },
  {
    code: "WK53",
    display_name: "IOT Infrastructure and Energy Services",
  },
  {
    code: "WK54",
    display_name: "IPCA LAB LTD",
  },
  {
    code: "WK55",
    display_name: "IRB INFRA",
  },
  {
    code: "WK56",
    display_name: "ISMT Ltd",
  },
  {
    code: "WK57",
    display_name: "ISPAT INDUST",
  },
  {
    code: "WK58",
    display_name: "ITD Cementation India Ltd",
  },
  {
    code: "WK59",
    display_name: "IVRCL LTD",
  },
  {
    code: "WK60",
    display_name: "IVRCLAH",
  },
  {
    code: "WK61",
    display_name: "JandK BANK",
  },
  {
    code: "WK62",
    display_name: "J.K. Helene Curtis Ltd.",
  },
  {
    code: "WK63",
    display_name: "J.K. Investo Trade (India) Ltd.",
  },
  {
    code: "WK64",
    display_name: "JAGRAN PRAK",
  },
  {
    code: "WK65",
    display_name: "Jaguar LandRover",
  },
  {
    code: "WK66",
    display_name: "JAI CORP LIM",
  },
  {
    code: "WK67",
    display_name: "JAIBALA IND",
  },
  {
    code: "WK68",
    display_name: "JAIN IRRI SY",
  },
  {
    code: "WK69",
    display_name: "JAIPRAK ASSO",
  },
  {
    code: "WK70",
    display_name: "Jaiprakash Power Ventures Ltd",
  },
  {
    code: "WK71",
    display_name: "JAMIPOL",
  },
  {
    code: "WK72",
    display_name: "Jammu and Kashmir Bank Ltd",
  },
  {
    code: "WK73",
    display_name: "Jamnalal Sons Pvt. Ltd.",
  },
  {
    code: "WK75",
    display_name: "Jamshedpur Utilities and Servics Co",
  },
  {
    code: "WK76",
    display_name: "Jayant Agro Organics Ltd",
  },
  {
    code: "WK77",
    display_name: "Jayaswal Neco Industries Ltd",
  },
  {
    code: "WK78",
    display_name: "Jaypee Infratech Ltd",
  },
  {
    code: "WK79",
    display_name: "JBF Industries Ltd",
  },
  {
    code: "WK80",
    display_name: "JCB India",
  },
  {
    code: "WK81",
    display_name: "Jeevan Ltd.",
  },
  {
    code: "WK82",
    display_name: "Jessop and Company Ltd.,India",
  },
  {
    code: "WK83",
    display_name: "JET AIRWAYS",
  },
  {
    code: "WK84",
    display_name: "Jhajjar Power",
  },
  {
    code: "WK85",
    display_name: "Jindal Films",
  },
  {
    code: "WK86",
    display_name: "Jindal India",
  },
  {
    code: "WK87",
    display_name: "Jindal India Thermal Power",
  },
  {
    code: "WK88",
    display_name: "JINDAL POLFM",
  },
  {
    code: "WK89",
    display_name: "Jindal Polyfilms",
  },
  {
    code: "WK90",
    display_name: "Jindal Power",
  },
  {
    code: "WK91",
    display_name: "JINDAL SAW",
  },
  {
    code: "WK92",
    display_name: "JINDAL SOUTH",
  },
  {
    code: "WK93",
    display_name: "JINDAL STEEL",
  },
  {
    code: "WK94",
    display_name: "JK CEMENT",
  },
  {
    code: "WK95",
    display_name: "JK Files (India) Ltd.",
  },
  {
    code: "WK96",
    display_name: "JK LAKSHMI",
  },
  {
    code: "WK97",
    display_name: "JK Paper Ltd",
  },
  {
    code: "WK98",
    display_name: "JK TYRE IND",
  },
  {
    code: "WK99",
    display_name: "JM FINANCIAL",
  },
  {
    code: "WL01",
    display_name: "JMC Projects (India) Ltd",
  },
  {
    code: "WL02",
    display_name: "JMD Oils",
  },
  {
    code: "WL03",
    display_name: "JMD TELEFILM",
  },
  {
    code: "WL04",
    display_name: "Johnson and Johnson",
  },
  {
    code: "WL05",
    display_name: "Joyalukkas India",
  },
  {
    code: "WL06",
    display_name: "JP Morgan",
  },
  {
    code: "WL07",
    display_name: "JPINFRATEC",
  },
  {
    code: "WL08",
    display_name: "JPPOWER",
  },
  {
    code: "WL09",
    display_name: "JSL",
  },
  {
    code: "WL10",
    display_name: "JSW ENERGY",
  },
  {
    code: "WL11",
    display_name: "JSW SL",
  },
  {
    code: "WL12",
    display_name: "JUBILANT",
  },
  {
    code: "WL13",
    display_name: "JUBL FOOD",
  },
  {
    code: "WL14",
    display_name: "JUSCO",
  },
  {
    code: "WL15",
    display_name: "Just Dial Limited",
  },
  {
    code: "WL16",
    display_name: "JVL Agro Industries Ltd",
  },
  {
    code: "WL17",
    display_name: "JYOTHY LAB",
  },
  {
    code: "WL18",
    display_name: "JYOTI STRUCT",
  },
  {
    code: "WL19",
    display_name: "K S OILS LTD",
  },
  {
    code: "WL20",
    display_name: "KAIL",
  },
  {
    code: "WL21",
    display_name: "Kailash Auto Finance Limited",
  },
  {
    code: "WL22",
    display_name: "Kajaria Ceramics Ltd",
  },
  {
    code: "WL23",
    display_name: "Kaleesuwari Refinery",
  },
  {
    code: "WL24",
    display_name: "KALPAT POW T",
  },
  {
    code: "WL25",
    display_name: "Kama Holdings Ltd",
  },
  {
    code: "WL26",
    display_name: "KANSAI NERO",
  },
  {
    code: "WL27",
    display_name: "KARNATAKA BK",
  },
  {
    code: "WL28",
    display_name: "Karnataka Power Corporation",
  },
  {
    code: "WL29",
    display_name: "Karnataka Power Transmission Corp",
  },
  {
    code: "WL30",
    display_name: "Karp Impex (TRF",
  },
  {
    code: "WL31",
    display_name: "Karur Vysya Bank Ltd",
  },
  {
    code: "WL32",
    display_name: "KARUTURI GLO",
  },
  {
    code: "WL33",
    display_name: "Kaveri Seed Company Limited",
  },
  {
    code: "WL34",
    display_name: "KEC INTERN",
  },
  {
    code: "WL35",
    display_name: "KEI Industries Ltd",
  },
  {
    code: "WL36",
    display_name: "KESORAM IND",
  },
  {
    code: "WL37",
    display_name: "KFA",
  },
  {
    code: "WL38",
    display_name: "KGN IND LTD",
  },
  {
    code: "WL39",
    display_name: "KIRI DYES",
  },
  {
    code: "WL40",
    display_name: "KIRLOSKAR BR",
  },
  {
    code: "WL41",
    display_name: "Kirloskar Oil Engines Ltd",
  },
  {
    code: "WL42",
    display_name: "KOTAK BANK",
  },
  {
    code: "WL43",
    display_name: "Kotak Mahindra Asset Management Company Limited",
  },
  {
    code: "WL44",
    display_name: "Kotak Mahindra Bank Ltd",
  },
  {
    code: "WL45",
    display_name: "Kotak Mahindra Capital Company (KMCC)",
  },
  {
    code: "WL46",
    display_name: "Kotak Mahindra General Insurance Company Ltd.",
  },
  {
    code: "WL47",
    display_name: "Kotak Mahindra Life Insurance Ltd",
  },
  {
    code: "WL48",
    display_name: "Kotak Mahindra Prime Limited",
  },
  {
    code: "WL49",
    display_name: "Kotak Private Equity Group (KPEG)",
  },
  {
    code: "WL50",
    display_name: "Kotak Realty Fund",
  },
  {
    code: "WL51",
    display_name: "Kotak Securities Limited - Institutional Equities",
  },
  {
    code: "WL52",
    display_name: "Kotak Securities Ltd",
  },
  {
    code: "WL53",
    display_name: "KPB Hinduja College of Commerce",
  },
  {
    code: "WL54",
    display_name: "KPIT CUMMIN",
  },
  {
    code: "WL55",
    display_name: "KPR Mill Ltd",
  },
  {
    code: "WL56",
    display_name: "KRBL Ltd",
  },
  {
    code: "WL57",
    display_name: "Krishak Bharati Co-operative",
  },
  {
    code: "WL58",
    display_name: "KSK ENERGY",
  },
  {
    code: "WL59",
    display_name: "KWA DIAR (I)",
  },
  {
    code: "WL60",
    display_name: "LandT Finance Holdings Ltd",
  },
  {
    code: "WL61",
    display_name: "Lafarge India",
  },
  {
    code: "WL62",
    display_name: "LAKSHMI MA W",
  },
  {
    code: "WL63",
    display_name: "LAKSHMI OVER",
  },
  {
    code: "WL64",
    display_name: "Lakshmi Vilas Bank Ltd",
  },
  {
    code: "WL65",
    display_name: "Lalithaa Jewellery Mart",
  },
  {
    code: "WL66",
    display_name: "LANCO INFRA",
  },
  {
    code: "WL67",
    display_name: "LARSEN and TOU",
  },
  {
    code: "WL68",
    display_name: "Laxmi Diamond",
  },
  {
    code: "WL69",
    display_name: "Leighton India Contractors",
  },
  {
    code: "WL70",
    display_name: "LG Electronics India",
  },
  {
    code: "WL71",
    display_name: "LIC H. FINAN",
  },
  {
    code: "WL72",
    display_name: "LIC of India",
  },
  {
    code: "WL73",
    display_name: "Lloyd Electric and Engineering Ltd",
  },
  {
    code: "WL74",
    display_name: "LOreal India",
  },
  {
    code: "WL75",
    display_name: "Louis Dreyfus Commodities India",
  },
  {
    code: "WL76",
    display_name: "LT Foods Ltd",
  },
  {
    code: "WL77",
    display_name: "Luminious Power Technologies",
  },
  {
    code: "WL78",
    display_name: "LUPIN LTD",
  },
  {
    code: "WL79",
    display_name: "M M T C LTD.",
  },
  {
    code: "WL80",
    display_name: "MandM FINANSER",
  },
  {
    code: "WL81",
    display_name: "M.R.F LTD",
  },
  {
    code: "WL82",
    display_name: "Macleods Pharmaceuticals",
  },
  {
    code: "WL83",
    display_name: "MADHUCON PRO",
  },
  {
    code: "WL84",
    display_name: "Madhya Gujarat Vij Co",
  },
  {
    code: "WL85",
    display_name: "MADRAS CEMNT",
  },
  {
    code: "WL86",
    display_name: "Madras Fertilizers Ltd",
  },
  {
    code: "WL87",
    display_name: "Magma Fincorp Ltd",
  },
  {
    code: "WL88",
    display_name: "MAH HOLIDAY",
  },
  {
    code: "WL89",
    display_name: "MAHANAG TELE",
  },
  {
    code: "WL90",
    display_name: "Mahanagar Gas",
  },
  {
    code: "WL91",
    display_name: "MAHARASH SEA",
  },
  {
    code: "WL92",
    display_name: "Maharashtra Scooters Ltd.",
  },
  {
    code: "WL93",
    display_name: "Maharashtra State Power Generation Co",
  },
  {
    code: "WL94",
    display_name: "MAHINDRA and M",
  },
  {
    code: "WL95",
    display_name: "Mahindra and Mahindra",
  },
  {
    code: "WL96",
    display_name: "Mahindra and Mahindra - Energy",
  },
  {
    code: "WL97",
    display_name: "Mahindra and Mahindra - Farm Equipment",
  },
  {
    code: "WL98",
    display_name: "Mahindra and Mahindra - Military Defence",
  },
  {
    code: "WL99",
    display_name: "Mahindra and Mahindra Financial Services Limited",
  },
  {
    code: "WM01",
    display_name: "Mahindra Aerospace",
  },
  {
    code: "WM02",
    display_name: "Mahindra Agribusiness",
  },
  {
    code: "WM03",
    display_name: "Mahindra Castings",
  },
  {
    code: "WM04",
    display_name: "Mahindra CIE Automotive Ltd",
  },
  {
    code: "WM05",
    display_name: "Mahindra Composites",
  },
  {
    code: "WM06",
    display_name: "Mahindra Comviva",
  },
  {
    code: "WM07",
    display_name: "Mahindra Consulting Engineers",
  },
  {
    code: "WM08",
    display_name: "Mahindra Conveyor Systems",
  },
  {
    code: "WM09",
    display_name: "Mahindra Engineering",
  },
  {
    code: "WM10",
    display_name: "Mahindra First Choice Services",
  },
  {
    code: "WM11",
    display_name: "Mahindra Forgings",
  },
  {
    code: "WM12",
    display_name: "Mahindra Gears and Transmissions",
  },
  {
    code: "WM13",
    display_name: "Mahindra Hinoday Ltd",
  },
  {
    code: "WM14",
    display_name: "Mahindra Holidays and Resorts",
  },
  {
    code: "WM15",
    display_name: "Mahindra Insurance Brokers",
  },
  {
    code: "WM16",
    display_name: "Mahindra Intertrade",
  },
  {
    code: "WM17",
    display_name: "Mahindra Lifespaces",
  },
  {
    code: "WM18",
    display_name: "Mahindra Logisoft",
  },
  {
    code: "WM19",
    display_name: "Mahindra Logistics",
  },
  {
    code: "WM20",
    display_name: "Mahindra Marine Private Limited",
  },
  {
    code: "WM21",
    display_name: "Mahindra Mutual Fund",
  },
  {
    code: "WM22",
    display_name: "Mahindra Racing",
  },
  {
    code: "WM23",
    display_name: "Mahindra Retai",
  },
  {
    code: "WM24",
    display_name: "Mahindra Reva",
  },
  {
    code: "WM25",
    display_name: "Mahindra Rural Housing Finance",
  },
  {
    code: "WM26",
    display_name: "Mahindra Solar One",
  },
  {
    code: "WM27",
    display_name: "Mahindra Sona Ltd.",
  },
  {
    code: "WM28",
    display_name: "Mahindra Special Services Group",
  },
  {
    code: "WM29",
    display_name: "Mahindra Steel Service Centre",
  },
  {
    code: "WM30",
    display_name: "Mahindra Swaraj",
  },
  {
    code: "WM31",
    display_name: "Mahindra Systech",
  },
  {
    code: "WM32",
    display_name: "Mahindra Tractors",
  },
  {
    code: "WM33",
    display_name: "Mahindra Truck and Bus",
  },
  {
    code: "WM34",
    display_name: "Mahindra Two Wheelers",
  },
  {
    code: "WM35",
    display_name: "Mahindra Ugine Steel Company Ltd",
  },
  {
    code: "WM36",
    display_name: "Mahindra USA Inc",
  },
  {
    code: "WM37",
    display_name: "Mahindra World City",
  },
  {
    code: "WM38",
    display_name: "Mahindra Yueda (Yancheng) Tractor Co",
  },
  {
    code: "WM39",
    display_name: "MAHINDRALIFE",
  },
  {
    code: "WM40",
    display_name: "Maithon Power",
  },
  {
    code: "WM41",
    display_name: "Makemytrip",
  },
  {
    code: "WM42",
    display_name: "Man Industries (India) Ltd",
  },
  {
    code: "WM43",
    display_name: "Manaksia Ltd",
  },
  {
    code: "WM44",
    display_name: "MANAP GEN FI",
  },
  {
    code: "WM45",
    display_name: "Manappuram Finance Ltd",
  },
  {
    code: "WM46",
    display_name: "Mangalore Chemicals and Fertilizers Ltd",
  },
  {
    code: "WM47",
    display_name: "MANGALORE RE",
  },
  {
    code: "WM48",
    display_name: "Mankind Pharma",
  },
  {
    code: "WM49",
    display_name: "MARG LTD",
  },
  {
    code: "WM50",
    display_name: "MARICO LTD",
  },
  {
    code: "WM51",
    display_name: "MARUTISUZUK",
  },
  {
    code: "WM52",
    display_name: "MASTEK",
  },
  {
    code: "WM53",
    display_name: "Max Bupa Health",
  },
  {
    code: "WM54",
    display_name: "MAX INDIA L.",
  },
  {
    code: "WM55",
    display_name: "Max Life Insurance Co. Ltd ",
  },
  {
    code: "WM56",
    display_name: "Mazagon Dock",
  },
  {
    code: "WM57",
    display_name: "MCC PTA India Corporation",
  },
  {
    code: "WM58",
    display_name: "Mckensy",
  },
  {
    code: "WM59",
    display_name: "MCLEOD RUSSE",
  },
  {
    code: "WM60",
    display_name: "MCNALLY BHA",
  },
  {
    code: "WM61",
    display_name: "MERCATOR LIN",
  },
  {
    code: "WM62",
    display_name: "Micro Inks",
  },
  {
    code: "WM63",
    display_name: "Micro Labs",
  },
  {
    code: "WM64",
    display_name: "Microsoft Corporation India",
  },
  {
    code: "WM65",
    display_name: "MINDTREE LTD",
  },
  {
    code: "WM66",
    display_name: "Mitsubishi Corporation India",
  },
  {
    code: "WM67",
    display_name: "mjunction services",
  },
  {
    code: "WM68",
    display_name: "MMTC Ltd",
  },
  {
    code: "WM69",
    display_name: "Model Economic Township Limited",
  },
  {
    code: "WM70",
    display_name: "Modern Road Makers",
  },
  {
    code: "WM71",
    display_name: "MOIL LTD",
  },
  {
    code: "WM72",
    display_name: "Mondelez India Foods",
  },
  {
    code: "WM73",
    display_name: "MONNE ISPAT*",
  },
  {
    code: "WM74",
    display_name: "Monotona Tyres Ltd., India",
  },
  {
    code: "WM75",
    display_name: "MONSANTO IND",
  },
  {
    code: "WM76",
    display_name: "MOSER BAER",
  },
  {
    code: "WM77",
    display_name: "MOTH SUMI SY",
  },
  {
    code: "WM78",
    display_name: "Mother Dairy Fruit and Vegetables",
  },
  {
    code: "WM79",
    display_name: "MOTILALOFS",
  },
  {
    code: "WM80",
    display_name: "MP Paschim Kshetra Vidyut Vitaran Co",
  },
  {
    code: "WM81",
    display_name: "MPHASIS LTD",
  },
  {
    code: "WM82",
    display_name: "MRF Ltd",
  },
  {
    code: "WM83",
    display_name: "Mukand Engineers Ltd.",
  },
  {
    code: "WM84",
    display_name: "Mukand Global Finance Ltd.",
  },
  {
    code: "WM85",
    display_name: "Mukand International Ltd.",
  },
  {
    code: "WM86",
    display_name: "Mukand Ltd",
  },
  {
    code: "WM87",
    display_name: "Mumbai International Airport",
  },
  {
    code: "WM88",
    display_name: "MUNDRA PORT",
  },
  {
    code: "WM89",
    display_name: "Munich Re",
  },
  {
    code: "WM90",
    display_name: "Munjal Showa Ltd",
  },
  {
    code: "WM91",
    display_name: "Muthoot Finance Ltd",
  },
  {
    code: "WM92",
    display_name: "MVL LIMITED",
  },
  {
    code: "WM93",
    display_name: "Mylan Laboratories",
  },
  {
    code: "WM94",
    display_name: "Nabha Power",
  },
  {
    code: "WM95",
    display_name: "NAGA FERT CH",
  },
  {
    code: "WM96",
    display_name: "Nagarjuna Fertilizers and Chemicals",
  },
  {
    code: "WM97",
    display_name: "Nahar Industrial Enterprises Ltd",
  },
  {
    code: "WM98",
    display_name: "Nahar Spinning Mills Ltd",
  },
  {
    code: "WM99",
    display_name: "Nakoda Ltd",
  },
  {
    code: "WN01",
    display_name: "NAT ALUM CO",
  },
  {
    code: "WN02",
    display_name: "Nat Steel Holdings",
  },
  {
    code: "WN03",
    display_name: "Natco Pharma Limited",
  },
  {
    code: "WN04",
    display_name: "National Buildings Construction Corp",
  },
  {
    code: "WN05",
    display_name: "NATIONAL FER",
  },
  {
    code: "WN06",
    display_name: "National Handloom Development Corp",
  },
  {
    code: "WN07",
    display_name: "National Insurance Co.",
  },
  {
    code: "WN08",
    display_name: "National Peroxide",
  },
  {
    code: "WN09",
    display_name: "National Small Industries Corporation",
  },
  {
    code: "WN10",
    display_name: "National Steel and Agro Industries Ltd",
  },
  {
    code: "WN11",
    display_name: "NAV BHAR VEN",
  },
  {
    code: "WN12",
    display_name: "NAVNEET PUBL",
  },
  {
    code: "WN13",
    display_name: "NCC",
  },
  {
    code: "WN14",
    display_name: "NDTV LTD.",
  },
  {
    code: "WN15",
    display_name: "Nectar Lifesciences Ltd",
  },
  {
    code: "WN16",
    display_name: "Nelco",
  },
  {
    code: "WN17",
    display_name: "NESCO LIMITD",
  },
  {
    code: "WN18",
    display_name: "NESTLE LTD",
  },
  {
    code: "WN19",
    display_name: "Network 18 Media and Investments Ltd",
  },
  {
    code: "WN20",
    display_name: "New Holland Fiat India",
  },
  {
    code: "WN21",
    display_name: "New India Assurance",
  },
  {
    code: "WN22",
    display_name: "NEYVELI LIG.",
  },
  {
    code: "WN23",
    display_name: "NHPC",
  },
  {
    code: "WN24",
    display_name: "NIIT LTD",
  },
  {
    code: "WN25",
    display_name: "NIIT TECHNO",
  },
  {
    code: "WN26",
    display_name: "Nilkamal Ltd",
  },
  {
    code: "WN27",
    display_name: "Nirma",
  },
  {
    code: "WN28",
    display_name: "NMDC LTD",
  },
  {
    code: "WN29",
    display_name: "NOIDA TOL BR",
  },
  {
    code: "WN30",
    display_name: "Nokia Solutions and Network India",
  },
  {
    code: "WN31",
    display_name: "Norlake Hospitality",
  },
  {
    code: "WN32",
    display_name: "NOVARTIS IND",
  },
  {
    code: "WN33",
    display_name: "Novelis INC",
  },
  {
    code: "WN34",
    display_name: "NTPC LTD",
  },
  {
    code: "WN35",
    display_name: "NTWK MED INV",
  },
  {
    code: "WN36",
    display_name: "Nuclear Power Corporation",
  },
  {
    code: "WN37",
    display_name: "Numaligarh Refinery",
  },
  {
    code: "WN38",
    display_name: "Nxt Digital HITS",
  },
  {
    code: "WN39",
    display_name: "OBEROI REAL",
  },
  {
    code: "WN40",
    display_name: "OCL India Ltd",
  },
  {
    code: "WN41",
    display_name: "Oil And Natural Gas Corporation Ltd",
  },
  {
    code: "WN42",
    display_name: "OIL INDIA",
  },
  {
    code: "WN43",
    display_name: "OLA India",
  },
  {
    code: "WN44",
    display_name: "Olam Agro India",
  },
  {
    code: "WN45",
    display_name: "OMAXE LTD",
  },
  {
    code: "WN46",
    display_name: "OneWeb",
  },
  {
    code: "WN47",
    display_name: "ONG CORP LTD",
  },
  {
    code: "WN48",
    display_name: "ONMOBILE",
  },
  {
    code: "WN49",
    display_name: "OPTO CIRCUIT",
  },
  {
    code: "WN50",
    display_name: "ORACLE FIN",
  },
  {
    code: "WN51",
    display_name: "Oracle India",
  },
  {
    code: "WN52",
    display_name: "ORBITCO",
  },
  {
    code: "WN53",
    display_name: "ORCHID CHEM",
  },
  {
    code: "WN54",
    display_name: "Orient Cement Ltd",
  },
  {
    code: "WN55",
    display_name: "ORIENT PAPandI",
  },
  {
    code: "WN56",
    display_name: "ORIENTAL BK",
  },
  {
    code: "WN57",
    display_name: "Oriental Insurance",
  },
  {
    code: "WN60",
    display_name: "Page Industries Limited",
  },
  {
    code: "WN61",
    display_name: "PANACEA BIOT",
  },
  {
    code: "WN62",
    display_name: "PANTAL RETAI",
  },
  {
    code: "WN63",
    display_name: "Paradeep Phosphates",
  },
  {
    code: "WN64",
    display_name: "Parle Biscuits",
  },
  {
    code: "WN65",
    display_name: "Parle Products",
  },
  {
    code: "WN66",
    display_name: "PARSVNATH",
  },
  {
    code: "WN67",
    display_name: "Paschim Gujarat Vij Co",
  },
  {
    code: "WN68",
    display_name: "PATEL ENGINR",
  },
  {
    code: "WN69",
    display_name: "PATNI COMPUT",
  },
  {
    code: "WN70",
    display_name: "PC Jeweller Ltd",
  },
  {
    code: "WN71",
    display_name: "Pearl Global Industries Ltd",
  },
  {
    code: "WN72",
    display_name: "PEC",
  },
  {
    code: "WN73",
    display_name: "PENLAND LTD",
  },
  {
    code: "WN74",
    display_name: "Pepsico India Holdings",
  },
  {
    code: "WN75",
    display_name: "PERSISTENT",
  },
  {
    code: "WN76",
    display_name: "PETRONET LNG",
  },
  {
    code: "WN77",
    display_name: "PFIZER LTD.",
  },
  {
    code: "WN78",
    display_name: "Pheroze Framroze and Co",
  },
  {
    code: "WN79",
    display_name: "Philips Electronics India",
  },
  {
    code: "WN80",
    display_name: "Phillips Carbon Black Ltd",
  },
  {
    code: "WN81",
    display_name: "PHOENIX MILL",
  },
  {
    code: "WN82",
    display_name: "Piaggio Vehicles",
  },
  {
    code: "WN83",
    display_name: "PIDILITE IND",
  },
  {
    code: "WN84",
    display_name: "Piindustries Limited",
  },
  {
    code: "WN85",
    display_name: "PIPAVAVSHIP",
  },
  {
    code: "WN86",
    display_name: "PIRAMA HEALT",
  },
  {
    code: "WN87",
    display_name: "Piramal Enterprises Ltd",
  },
  {
    code: "WN88",
    display_name: "Piramal Glass Ltd",
  },
  {
    code: "WN89",
    display_name: "Plethico Pharmaceuticals Ltd",
  },
  {
    code: "WN90",
    display_name: "Pmc Fincorp Limited",
  },
  {
    code: "WN91",
    display_name: "PNB Metlife India Insurance Co. Ltd.",
  },
  {
    code: "WN92",
    display_name: "Polaris",
  },
  {
    code: "WN93",
    display_name: "POLARIS LAB",
  },
  {
    code: "WN94",
    display_name: "Polyplex Corporation Ltd",
  },
  {
    code: "WN95",
    display_name: "POWER FINAN",
  },
  {
    code: "WN96",
    display_name: "POWER GRID",
  },
  {
    code: "WN97",
    display_name: "Powerlinks Transmission",
  },
  {
    code: "WN98",
    display_name: "PRAJ INDUSTR",
  },
  {
    code: "WN99",
    display_name: "PRAKASH IND.",
  },
  {
    code: "WO01",
    display_name: "Pratibha Industries Ltd",
  },
  {
    code: "WO02",
    display_name: "PRESTIGE",
  },
  {
    code: "WO03",
    display_name: "Pricewaterhouse Coopers",
  },
  {
    code: "WO04",
    display_name: "PRISM CEMENT",
  },
  {
    code: "WO05",
    display_name: "Prithvi Information Solutions Ltd",
  },
  {
    code: "WO06",
    display_name: "PROCTOR GAMB",
  },
  {
    code: "WO07",
    display_name: "PROVOGUE IND",
  },
  {
    code: "WO08",
    display_name: "PSL LIMITED",
  },
  {
    code: "WO09",
    display_name: "PTC INDIA",
  },
  {
    code: "WO10",
    display_name: "PUNJ LLOYD",
  },
  {
    code: "WO11",
    display_name: "Punjab and Sind Bank",
  },
  {
    code: "WO12",
    display_name: "PUNJAB NATBK",
  },
  {
    code: "WO13",
    display_name: "PUNJABNSIND",
  },
  {
    code: "WO14",
    display_name: "PURAVANKARA",
  },
  {
    code: "WO15",
    display_name: "Pvr Limited",
  },
  {
    code: "WO16",
    display_name: "RADICO KHAIT",
  },
  {
    code: "WO17",
    display_name: "Rail Vikas Nigam",
  },
  {
    code: "WO18",
    display_name: "RAIN COMMODI",
  },
  {
    code: "WO19",
    display_name: "Raj West Power",
  },
  {
    code: "WO20",
    display_name: "Rajasthan Rajya Vidyut Prasaran Nigam",
  },
  {
    code: "WO21",
    display_name: "RAJESH EXPOT",
  },
  {
    code: "WO22",
    display_name: "RALLI INDIA",
  },
  {
    code: "WO23",
    display_name: "Rallis India",
  },
  {
    code: "WO24",
    display_name: "Ramky Infrastructure Ltd",
  },
  {
    code: "WO25",
    display_name: "RANBAXY LABO",
  },
  {
    code: "WO26",
    display_name: "Rane Holdings Ltd",
  },
  {
    code: "WO27",
    display_name: "RASHTRIYA CH",
  },
  {
    code: "WO28",
    display_name: "Rashtriya Ispat Nigam",
  },
  {
    code: "WO29",
    display_name: "Rattanindia Power Limited",
  },
  {
    code: "WO30",
    display_name: "Raymond Apparel Ltd.",
  },
  {
    code: "WO31",
    display_name: "RAYMOND LTD",
  },
  {
    code: "WO32",
    display_name: "Raymond Ltd.",
  },
  {
    code: "WO33",
    display_name: "Raymond Luxury Cottons Ltd.",
  },
  {
    code: "WO34",
    display_name: "Raymond UCO Denim Pvt. Ltd.",
  },
  {
    code: "WO35",
    display_name: "RBL Bank",
  },
  {
    code: "WO36",
    display_name: "Reckitt Benckiser (I)",
  },
  {
    code: "WO37",
    display_name: "RECLTD",
  },
  {
    code: "WO38",
    display_name: "REDINGTON",
  },
  {
    code: "WO39",
    display_name: "REI AGRO LIM",
  },
  {
    code: "WO40",
    display_name: "REISIX TENR",
  },
  {
    code: "WO41",
    display_name: "REL COM LTD",
  },
  {
    code: "WO42",
    display_name: "REL INDL INF",
  },
  {
    code: "WO43",
    display_name: "REL INFRA",
  },
  {
    code: "WO44",
    display_name: "Relax Pharmaceuticals",
  },
  {
    code: "WO45",
    display_name: "RELIANCE",
  },
  {
    code: "WO46",
    display_name: "Reliance Aerospace Technologies Limited",
  },
  {
    code: "WO47",
    display_name: "Reliance Ambit Trade Private Limited",
  },
  {
    code: "WO48",
    display_name: "Reliance Aromatics and Petrochemicals Limited",
  },
  {
    code: "WO49",
    display_name: "Reliance Big Entertainment",
  },
  {
    code: "WO50",
    display_name: "Reliance Brands Limited",
  },
  {
    code: "WO51",
    display_name: "RELIANCE CAP",
  },
  {
    code: "WO52",
    display_name: "Reliance Capital",
  },
  {
    code: "WO53",
    display_name: "Reliance Chemicals Limited",
  },
  {
    code: "WO54",
    display_name: "Reliance Clothing India Private Limited",
  },
  {
    code: "WO55",
    display_name: "Reliance Commercial finance",
  },
  {
    code: "WO56",
    display_name: "Reliance Commercial Land and Infrastructure Ltd",
  },
  {
    code: "WO57",
    display_name: "Reliance Communications",
  },
  {
    code: "WO58",
    display_name: "Reliance Comtrade Private Limited",
  },
  {
    code: "WO59",
    display_name: "Reliance Corporate IT Park Limited",
  },
  {
    code: "WO60",
    display_name: "Reliance Defence",
  },
  {
    code: "WO61",
    display_name: "Reliance Eminent Trading and Commercial Pvt Ltd",
  },
  {
    code: "WO62",
    display_name: "Reliance Energy and Project Development Limited",
  },
  {
    code: "WO63",
    display_name: "Reliance Energy Generation and Distribution Ltd",
  },
  {
    code: "WO64",
    display_name: "Reliance Ethane Holding Pte Ltd.",
  },
  {
    code: "WO65",
    display_name: "Reliance Exploration and Production DMCC",
  },
  {
    code: "WO66",
    display_name: "Reliance Gas Pipelines Limited",
  },
  {
    code: "WO67",
    display_name: "Reliance General Insurance",
  },
  {
    code: "WO68",
    display_name: "Reliance Global Business B.V.",
  },
  {
    code: "WO69",
    display_name: "Reliance Global Commercial Limited",
  },
  {
    code: "WO70",
    display_name: "Reliance Global Energy Services Limited",
  },
  {
    code: "WO71",
    display_name: "Reliance Holdings USA, Inc.",
  },
  {
    code: "WO72",
    display_name: "Reliance Industrial Infrastructure Limited",
  },
  {
    code: "WO73",
    display_name: "Reliance Industrial Investments and Holdings Ltd",
  },
  {
    code: "WO74",
    display_name: "Reliance Industries (Middle East) DMCC",
  },
  {
    code: "WO75",
    display_name: "Reliance Infrastructure",
  },
  {
    code: "WO76",
    display_name: "Reliance Innovative Building Solutions Pvt Ltd",
  },
  {
    code: "WO77",
    display_name: "Reliance Jio Infocomm Limited",
  },
  {
    code: "WO78",
    display_name: "Reliance Jio Media Private Limited",
  },
  {
    code: "WO79",
    display_name: "Reliance Life Insurance",
  },
  {
    code: "WO80",
    display_name: "Reliance Lifestyle Holdings Limited",
  },
  {
    code: "WO81",
    display_name: "Reliance Marcellus Holding, LLC",
  },
  {
    code: "WO82",
    display_name: "Reliance Money",
  },
  {
    code: "WO83",
    display_name: "Reliance Mumbai Metro",
  },
  {
    code: "WO84",
    display_name: "Reliance Mutual Fund",
  },
  {
    code: "WO85",
    display_name: "Reliance Payment Solutions Limited",
  },
  {
    code: "WO86",
    display_name: "Reliance Petro Marketing Limited",
  },
  {
    code: "WO87",
    display_name: "Reliance Petroinvestments Limited",
  },
  {
    code: "WO88",
    display_name: "Reliance Polyolefins Limited",
  },
  {
    code: "WO89",
    display_name: "Reliance Power",
  },
  {
    code: "WO90",
    display_name: "Reliance Progressive Traders Private Limited",
  },
  {
    code: "WO91",
    display_name: "Reliance Prolific Commercial Private Limited",
  },
  {
    code: "WO92",
    display_name: "Reliance Prolific Traders Private Limited",
  },
  {
    code: "WO93",
    display_name: "Reliance Retail Finance Limited",
  },
  {
    code: "WO94",
    display_name: "Reliance Retail Insurance Broking Limited",
  },
  {
    code: "WO95",
    display_name: "Reliance Retail Limited",
  },
  {
    code: "WO96",
    display_name: "Reliance Retail Ventures Limited",
  },
  {
    code: "WO97",
    display_name: "Reliance Roads",
  },
  {
    code: "WO98",
    display_name: "Reliance Securities",
  },
  {
    code: "WO99",
    display_name: "Reliance Sibur Elastomers Private Limited",
  },
  {
    code: "WP01",
    display_name: "Reliance Strategic Investments Limited",
  },
  {
    code: "WP02",
    display_name: "Reliance Supply Solutions Private Limited",
  },
  {
    code: "WP03",
    display_name: "Reliance Textiles Limited",
  },
  {
    code: "WP04",
    display_name: "Reliance Trading Limited",
  },
  {
    code: "WP05",
    display_name: "Reliance Universal Commercial Limited",
  },
  {
    code: "WP06",
    display_name: "Reliance Universal Enterprises Limited",
  },
  {
    code: "WP07",
    display_name: "Reliance Universal Traders Private Limited",
  },
  {
    code: "WP08",
    display_name: "Reliance USA Gas Marketing LLC",
  },
  {
    code: "WP09",
    display_name: "Reliance Vantage Retail Limited",
  },
  {
    code: "WP10",
    display_name: "Reliance Ventures Limited",
  },
  {
    code: "WP11",
    display_name: "Reliance World Trade Private Limited",
  },
  {
    code: "WP12",
    display_name: "Reliance-GrandOptical Private Limited",
  },
  {
    code: "WP13",
    display_name: "Religare",
  },
  {
    code: "WP14",
    display_name: "RELIGARE ENT",
  },
  {
    code: "WP15",
    display_name: "RELMEDIA",
  },
  {
    code: "WP16",
    display_name: "Renault Nissan Automotive India",
  },
  {
    code: "WP17",
    display_name: "RENUKA SUGAR",
  },
  {
    code: "WP18",
    display_name: "Responsive Industries Ltd",
  },
  {
    code: "WP19",
    display_name: "Rico Auto Industries Ltd",
  },
  {
    code: "WP20",
    display_name: "Ring Plus Aqua Ltd.",
  },
  {
    code: "WP21",
    display_name: "Robert Bosch Eng and Business Solutions",
  },
  {
    code: "WP22",
    display_name: "Rohit Ferro-Tech Ltd",
  },
  {
    code: "WP23",
    display_name: "ROLTA IND",
  },
  {
    code: "WP24",
    display_name: "Roots Corporation",
  },
  {
    code: "WP25",
    display_name: "Rosy Blue India",
  },
  {
    code: "WP26",
    display_name: "RPOWER",
  },
  {
    code: "WP27",
    display_name: "RSWM Ltd",
  },
  {
    code: "WP28",
    display_name: "Ruchi Infrastructure Ltd",
  },
  {
    code: "WP29",
    display_name: "RUCHI SOYA.",
  },
  {
    code: "WP30",
    display_name: "Rungta Mines",
  },
  {
    code: "WP31",
    display_name: "Rural Electrification Corporation Ltd",
  },
  {
    code: "WP32",
    display_name: "S BK BIKANER",
  },
  {
    code: "WP33",
    display_name: "S. KUMAR NAT",
  },
  {
    code: "WP34",
    display_name: "SABMiller India",
  },
  {
    code: "WP35",
    display_name: "SADBHAV ENGG",
  },
  {
    code: "WP36",
    display_name: "Sahara India Life Insurance Co. Ltd.",
  },
  {
    code: "WP37",
    display_name: "Sakthi Sugars Ltd",
  },
  {
    code: "WP38",
    display_name: "Samsung India Electronics",
  },
  {
    code: "WP39",
    display_name: "Sandvik Asia",
  },
  {
    code: "WP40",
    display_name: "Sangam (India) Ltd",
  },
  {
    code: "WP41",
    display_name: "Sanofi India Ltd",
  },
  {
    code: "WP42",
    display_name: "Sanwaria Agro Oils Ltd",
  },
  {
    code: "WP43",
    display_name: "SASKEN COMM",
  },
  {
    code: "WP44",
    display_name: "Savita Oil Technologies Ltd",
  },
  {
    code: "WP45",
    display_name: "SB Energy",
  },
  {
    code: "WP46",
    display_name: "SBI Life Insurance Co. Ltd ",
  },
  {
    code: "WP47",
    display_name: "Schneider Electric India Private",
  },
  {
    code: "WP48",
    display_name: "Security Printing and Minting Corp",
  },
];

export const organizationTypeOptions = [
  {
    code: "Government Establishment",
    display_name: "Government Establishment",
  },
  {
    code: "Public Limited",
    display_name: "Public Limited",
  },
  {
    code: "Private Limited",
    display_name: "Private Limited",
  },
  {
    code: "Partnership Firm",
    display_name: "Partnership Firm",
  },
  {
    code: "Proprietorship Firm",
    display_name: "Proprietorship Firm",
  },
  {
    code: "Professional Practice",
    display_name: "Professional Practice",
  },
  {
    code: "Others",
    display_name: "Others",
  },
];
export const industryOptions = [
  {
    code: "Construction",
    display_name: "Construction",
  },
  {
    code: "Mining / Oil And Natural Gas",
    display_name: "Mining / Oil And Natural Gas",
  },
  {
    code: "Transport/Automobile",
    display_name: "Transport/Automobile",
  },
  {
    code: "Healthcare/Pharmaceutical",
    display_name: "Healthcare/Pharmaceutical",
  },
  {
    code: "Aviation / Transport",
    display_name: "Aviation / Transport",
  },
  {
    code: "Fisheries/Agriculture/Dairy/Poultry",
    display_name: "Fisheries/Agriculture/Dairy/Poultry",
  },
  {
    code: "Information Technology",
    display_name: "Information Technology",
  },
  {
    code: "Manufacturing",
    display_name: "Manufacturing",
  },
  {
    code: "Retail",
    display_name: "Retail",
  },
  {
    code: "Textile",
    display_name: "Textile",
  },
  {
    code: "Banking / Financial / Service / Insurance",
    display_name: "Banking / Financial / Service / Insurance",
  },
  {
    code: "Education",
    display_name: "Education",
  },
  {
    code: "Armed & Allied Forces",
    display_name: "Armed & Allied Forces",
  },
  {
    code: "Marine/Cargo",
    display_name: "Marine/Cargo",
  },
  {
    code: "Food and Beverages",
    display_name: "Food and Beverages",
  },
  {
    code: "Entertainent/ Media",
    display_name: "Entertainent/ Media",
  },
  {
    code: "Religion / Social Service",
    display_name: "Religion / Social Service",
  },
  {
    code: "Hospitality",
    display_name: "Hospitality",
  },
  {
    code: "Others",
    display_name: "Others",
  },
  {
    code: "Professional Sport",
    display_name: "Professional Sport",
  },
  {
    code: "Forestry",
    display_name: "Forestry",
  },
];

export const natureOfWorkAviationOptions = [
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "A01",
  },
  {
    display_name: "Air Traffic Controllers",
    code: "A04",
  },
  {
    display_name: "Aircraft  Assemblers",
    code: "A10",
  },
  {
    display_name:
      "Cleaning staff / Worker- ground level / works at height less than 49 Feet or 15 Meter",
    code: "A19",
  },
  {
    display_name:
      "Cleaning staff / Worker -  works at heights above 49 Feet or 15 Meter",
    code: "A20",
  },
  {
    display_name: "Airport ground staff",
    code: "A34",
  },
  {
    display_name: "Aircraft Cargo Supervisors",
    code: "A54",
  },
  {
    display_name: "Air Hostess/Steward - Ground Staff",
    code: "A61",
  },
  {
    display_name: "Inspectors - ground staff, assembly, maintenance",
    code: "A62",
  },
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "A66",
  },
  {
    display_name: "Air rescue service - Pilot / Crew",
    code: "A71",
  },
  {
    display_name:
      "Major airline - Pilot / Air Cabin Crew / Air Hostees / Flight Attendant",
    code: "A74",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "A02",
  },
  {
    display_name: "Sales & Marketing - Outdoor",
    code: "C91",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "C20",
  },
  {
    display_name: "Crane Slinger / Operator",
    code: "A25",
  },
  {
    display_name: "Cargo van / Truck / Transport / Crane driver",
    code: "A24",
  },
  {
    display_name: "Pilot - Testing prototypes or repaired/modified aircraft",
    code: "A93",
  },
  {
    display_name: "Pilot - Instructor / Trainer",
    code: "C48",
  },
  {
    display_name: "Trainer - Service / Technical - Ground Staff",
    code: "C19",
  },
  {
    display_name:
      "Non commercial / Private / Helicopter / Company aircraft / Aerial photography / Surveys  Pilot",
    code: "A27",
  },
  {
    display_name: "Air Transportation Workers",
    code: "A32",
  },
  {
    display_name: "Aerospace Engineers",
    code: "C31",
  },
  {
    display_name: "Electronics Engineers",
    code: "C32",
  },
  {
    display_name: "Electronics Technicians",
    code: "C33",
  },
  {
    display_name: "Electrical Engineers",
    code: "C34",
  },
  {
    display_name: "Electrical Technicians",
    code: "C35",
  },
  {
    display_name: "Compliance Officers",
    code: "C37",
  },
  {
    display_name: "Computer Network Architects / Specialists",
    code: "C38",
  },
  {
    display_name: "Distribution Managers",
    code: "C40",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "C41",
  },
  {
    display_name: "Network Systems Administrator",
    code: "C42",
  },
  {
    display_name: "Quality inspection",
    code: "C43",
  },
  {
    display_name: "Administrative Service Manager",
    code: "C44",
  },
  {
    display_name: "Transportation Ticket Agents",
    code: "D79",
  },
  {
    display_name: "Security Guard - Armed",
    code: "E97",
  },
  {
    display_name: "Security Guard - unarmed",
    code: "E98",
  },
  {
    display_name: "Private Car Driver",
    code: "F75",
  },
  {
    display_name:
      "Minor Airline - Pilot / Air Cabin Crew / Air Hostees / Flight Attendant",
    code: "A75",
  },
  {
    display_name: "Administrative - Senior Management",
    code: "A67",
  },
];

export const natureOfWorkBankingOptions = [
  {
    display_name: "Quality inspection",
    code: "W04",
  },
  {
    display_name: "Survey Researchers",
    code: "H56",
  },
  {
    display_name: "Surveyors",
    code: "H57",
  },
  {
    display_name: "Distribution Managers",
    code: "W06",
  },
  {
    display_name: "Financial Managers",
    code: "W29",
  },
  {
    display_name: "Fundraisers",
    code: "H73",
  },
  {
    display_name: "Administrative - Senior Management",
    code: "H74",
  },
  {
    display_name: "Compliance Officers",
    code: "H75",
  },
  {
    display_name: "Insurance Claims Clerks",
    code: "H76",
  },
  {
    display_name: "Lawyer / Advocate / Judge",
    code: "Q05",
  },
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "W33",
  },
  {
    display_name:
      "Financial Clerks / Debt Collector / Accountant - Office Based",
    code: "W01",
  },
  {
    display_name: "Actuary",
    code: "W02",
  },
  {
    display_name: "Adjudicators",
    code: "W03",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "O67",
  },
  {
    display_name: "Agents / Brokers / Consultant - Purchase / Sales",
    code: "W07",
  },
  {
    display_name: "Revenue Agents",
    code: "W13",
  },
  {
    display_name: "Housekeeping / Support Staff / Cleaner",
    code: "W16",
  },
  {
    display_name:
      "Owner - Money Changers / Forex Changers / Money Lender / Share & Stock Dealer",
    code: "W19",
  },
  {
    display_name: "Financial Analysts / Economist",
    code: "W24",
  },
  {
    display_name: "Administrative Service Manager",
    code: "C15",
  },
  {
    display_name: "Deliverymen",
    code: "W30",
  },
  {
    display_name: "Couriers",
    code: "W31",
  },
  {
    display_name: "Small business owner",
    code: "W32",
  },
  {
    display_name: "Database Administrators",
    code: "W34",
  },
  {
    display_name: "Large Shop owner",
    code: "W37",
  },
  {
    display_name: "Public relation Manager",
    code: "W38",
  },
  {
    display_name: "Customer Service Representative",
    code: "W44",
  },
  {
    display_name: "Advertising Manager",
    code: "W41",
  },
  {
    display_name: "Computer Network Architects",
    code: "W42",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "W45",
  },
  {
    display_name: "Sales & Marketing - Outdoor",
    code: "W46",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "W21",
  },
  {
    display_name: "Security Guard - Armed",
    code: "W48",
  },
  {
    display_name: "Security Guard - unarmed",
    code: "W49",
  },
  {
    display_name: "Tax - Preparers / Consultant",
    code: "W52",
  },
  {
    display_name: "Teller - Bank",
    code: "W56",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "W05",
  },
  {
    display_name:
      "Underwriters / Assesssors - Insurance / Loan / Shares / Stocks",
    code: "W58",
  },
  {
    display_name: "Debt Collector - Non Office Based",
    code: "W17",
  },
  {
    display_name: "Fire Service Department - Fireman / Firefighter",
    code: "Q70",
  },
  {
    display_name: "Fire Service Department - Superintendent / Station Manager",
    code: "Q68",
  },
  {
    display_name: "Others",
    code: "O74",
  },
];

export const natureOfWorkConstructionOptions = [
  {
    display_name: "Machine Operator",
    code: "C26",
  },
  {
    display_name: "Driver - LMV",
    code: "D58",
  },
  {
    display_name: "Agents / Brokers / Consultant - Purchase / Sales",
    code: "D84",
  },
  {
    display_name: "Fire Inspectors",
    code: "D85",
  },
  {
    display_name: "Fabricators",
    code: "D86",
  },
  {
    display_name: "Advertising Manager",
    code: "D87",
  },
  {
    display_name: "Financial Managers",
    code: "D88",
  },
  {
    display_name: "Administrative - Senior Management",
    code: "D89",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "D90",
  },
  {
    display_name: "Shop assistant / Shop staff",
    code: "D91",
  },
  {
    display_name: "Large Shop owner",
    code: "D92",
  },
  {
    display_name: "Small business owner",
    code: "D93",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "I01",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "I02",
  },
  {
    display_name:
      "Cleaning staff / Worker -  works at heights above 49 Feet or 15 Meter",
    code: "I39",
  },
  {
    display_name:
      "Cleaning staff / Worker- ground level / works at height less than 49 Feet or 15 Meter",
    code: "I40",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "I42",
  },
  {
    display_name: "Contractor - commercial office premises, not on site",
    code: "I47",
  },
  {
    display_name: "Crane Operator / forklifts / Bulldozers",
    code: "I53",
  },
  {
    display_name: "Graphic Designers / Architect",
    code: "I65",
  },
  {
    display_name: "Cargo van/ Construction driver",
    code: "I78",
  },
  {
    display_name:
      "Electricians - ground level / works at height less than 49 Feet or 15 Meter",
    code: "I83",
  },
  {
    display_name: "Engine/Machine Assemblers",
    code: "I88",
  },
  {
    display_name: "Environmental Engineers",
    code: "I90",
  },
  {
    display_name:
      "Fitter -  ground level / works at height less than 49 Feet or 15 Meter",
    code: "IA6",
  },
  {
    display_name:
      "Foreman - ground level / works at height less than 49 Feet or 15 Meter",
    code: "IA8",
  },
  {
    display_name:
      "Construction Inspectors - works at heights above 49 Feet or 15 Meter",
    code: "IC1",
  },
  {
    display_name:
      "Heavy / Manual Labour - ground level / works at height less than 49 Feet or 15 Meter",
    code: "ID3",
  },
  {
    display_name: "Administrative Service Manager",
    code: "IE0",
  },
  {
    display_name: "Construction Managers",
    code: "IE1",
  },
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "IE2",
  },
  {
    display_name: "Service Technicians",
    code: "IE7",
  },
  {
    display_name: "Quality inspection",
    code: "IL0",
  },
  {
    display_name: "Driver - Truck / Lorry / Goods Vehicles / Dumper",
    code: "IM0",
  },
  {
    display_name:
      "Construction Workers - Odd Job / Demolition worker with Explosive",
    code: "H81",
  },
  {
    display_name:
      "Construction Workers - Odd Job / Demolition worker without Explosive",
    code: "IN2",
  },
  {
    display_name:
      "Heavy / Manual Labour - works at heights above 49 Feet or 15 Meter",
    code: "O41",
  },
  {
    display_name:
      "Technician and Workers - works at heights above 49 Feet or 15 Meter",
    code: "O60",
  },
  {
    display_name: "Sales & Marketing - Outdoor",
    code: "IG5",
  },
  {
    display_name:
      "Installer / Erector - Lift / Escalator / Aerial - works at heights above 49 Feet or 15 Meter",
    code: "IA4",
  },
  {
    display_name:
      "Installer / Erector - Lift / Escalator / Aerial - ground level / works at height less than 49 Feet or 15 Meter",
    code: "IA5",
  },
  {
    display_name: "Underground Worker",
    code: "IM2",
  },
];

export const natureOfWorkEducationOptions = [
  {
    display_name: "Financial Managers",
    code: "E88",
  },
  {
    display_name: "Sales & Marketing - Outdoor",
    code: "D76",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "E89",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "O01",
  },
  {
    display_name: "Peon/Office boy/Clerk",
    code: "O70",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "O02",
  },
  {
    display_name: "Librarian",
    code: "O14",
  },
  {
    display_name: "Principal / Lecturer / Professor",
    code: "O17",
  },
  {
    display_name: "School /College - Governor/Chairman/Trustee/Board Member",
    code: "O19",
  },
  {
    display_name: "Student",
    code: "O21",
  },
  {
    display_name: "Teachers and Instructors",
    code: "O23",
  },
  {
    display_name: "Physical education Teachers",
    code: "O25",
  },
  {
    display_name: "Private Tutor",
    code: "O27",
  },
  {
    display_name: "Tutor- school, college, university or Coaching",
    code: "O28",
  },
  {
    display_name: "Governess/Aaya/Support Staff",
    code: "O03",
  },
  {
    display_name: "Driver/Helper - School Bus/Van",
    code: "O07",
  },
  {
    display_name: "Gardener",
    code: "O09",
  },
  {
    display_name: "Laboratory - Analyst/Assitant/Technician",
    code: "O10",
  },
  {
    display_name: "Others",
    code: "O79",
  },
];

export const natureOfWorkEntertainmentOptions = [
  {
    display_name: "Financial Managers",
    code: "E86",
  },
  {
    display_name: "Web Developers",
    code: "E87",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "L02",
  },
  {
    display_name: "Advertising Manager",
    code: "L05",
  },
  {
    display_name: "Graphic Designers",
    code: "L06",
  },
  {
    display_name: "Broadcast News Analysts",
    code: "L07",
  },
  {
    display_name: "News Analysts, Reporters",
    code: "L14",
  },
  {
    display_name: "Authors",
    code: "L16",
  },
  {
    display_name: "Art Director",
    code: "L27",
  },
  {
    display_name: "Event Planners",
    code: "L32",
  },
  {
    display_name: "News and Street Vendors",
    code: "L38",
  },
  {
    display_name: "Film and Video Editors",
    code: "L46",
  },
  {
    display_name: "Models",
    code: "L53",
  },
  {
    display_name: "Administrative Service Manager",
    code: "L63",
  },
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "L64",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "L65",
  },
  {
    display_name: "Photographers",
    code: "L76",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "L85",
  },
  {
    display_name: "Operations Research Analysts",
    code: "L88",
  },
  {
    display_name: "Journalist / Reporter",
    code: "L95",
  },
  {
    display_name: "Advertising Sales Agents",
    code: "L99",
  },
  {
    display_name: "Sales & Marketing - Outdoor",
    code: "LA0",
  },
  {
    display_name: "Broadcast Technicians",
    code: "LA6",
  },
  {
    display_name: "Quality inspection",
    code: "LB4",
  },
  {
    display_name: "Actor / Actress",
    code: "N02",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "N06",
  },
  {
    display_name: "Makeup Artists",
    code: "N09",
  },
  {
    display_name: "Camera man",
    code: "N25",
  },
  {
    display_name: "Art and Design Workers",
    code: "N41",
  },
  {
    display_name: "Fitness instructor",
    code: "N58",
  },
  {
    display_name: "Massager / Barber / Beautician / Hairdresser",
    code: "N64",
  },
  {
    display_name: "Fashion Designers",
    code: "N73",
  },
  {
    display_name: "Distribution Managers",
    code: "N78",
  },
  {
    display_name: "Operations Managers",
    code: "N82",
  },
  {
    display_name: "Musicians and Singers",
    code: "N91",
  },
  {
    display_name: "Large Shop owner",
    code: "N96",
  },
  {
    display_name: "Small business owner",
    code: "NA1",
  },
  {
    display_name: "Film Producers",
    code: "NA7",
  },
  {
    display_name: "Public relation Manager",
    code: "NB8",
  },
  {
    display_name: "Shop assistant / Shop staff",
    code: "ND2",
  },
  {
    display_name: "Service Technicians",
    code: "NE0",
  },
  {
    display_name: "Interpreters and Translators",
    code: "QN0",
  },
  {
    display_name: "Stuntman",
    code: "ND7",
  },
];

export const natureOfWorkFisheriesOptions = [
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "B85",
  },
  {
    display_name: "Milk seller",
    code: "B88",
  },
  {
    display_name:
      "Owner - Agri Farm/Fisheries/Dairy/Poultry - <15% non-admin duties",
    code: "BA9",
  },
  {
    display_name: "Fishing Vessel/Boat- Captain/Other Crew Members",
    code: "C57",
  },
  {
    display_name: "Fishing Vessel/Boat- Trawlerman",
    code: "C58",
  },
  {
    display_name: "Fisherman",
    code: "C04",
  },
  {
    display_name: "Administrative Service Manager",
    code: "C46",
  },
  {
    display_name: "Agricultural Engineers",
    code: "C47",
  },
  {
    display_name: "Agricultural Scientist",
    code: "C49",
  },
  {
    display_name: "Agricultural Technicians",
    code: "C50",
  },
  {
    display_name: "Operations Research Analysts",
    code: "C51",
  },
  {
    display_name: "Quality inspection",
    code: "C52",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "C53",
  },
  {
    display_name: "Service Technicians",
    code: "C54",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "B02",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "B01",
  },
  {
    display_name: "Animal Breeders",
    code: "C60",
  },
  {
    display_name: "Animal Care and Service Worker",
    code: "C61",
  },
  {
    display_name: "Animal Trainers",
    code: "C62",
  },
  {
    display_name: "Farmer / Harvester/ Labourer",
    code: "B21",
  },
  {
    display_name: "Heavy / Manual Labour",
    code: "C64",
  },
  {
    display_name: "Housekeeping / Cleaning staff",
    code: "C65",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "C66",
  },
  {
    display_name: "Farm Management Advisors",
    code: "C67",
  },
  {
    display_name: "Financial Managers",
    code: "C68",
  },
  {
    display_name: "Food Scientists",
    code: "C69",
  },
  {
    display_name: "Forest and Conservation Worker",
    code: "C70",
  },
  {
    display_name: "Public relation Manager",
    code: "C72",
  },
  {
    display_name: "Domestic Helpers",
    code: "D71",
  },
  {
    display_name: "Butchers / Slaughterers and Meat Packers",
    code: "R13",
  },
  {
    display_name: "Food/Drink Stall Owners/Assistants",
    code: "R40",
  },
  {
    display_name: "Sales & Marketing - Outdoor",
    code: "R41",
  },
  {
    display_name: "Agricultural Inspectors",
    code: "R46",
  },
  {
    display_name: "Shop assistant / Shop staff",
    code: "S53",
  },
  {
    display_name: "Operations Managers",
    code: "C88",
  },
  {
    display_name: "Administrative - Senior Management",
    code: "B86",
  },
  {
    display_name: "Zoologists/Wildlife Biologists",
    code: "Z26",
  },
  {
    display_name: "Pest Controller",
    code: "BB9",
  },
];

export const natureOfWorkOthersOptions = [
  {
    code: "Z64",
    display_name: "Housewife",
  },
  {
    code: "Z69",
    display_name: "Juvenile",
  },
  {
    code: "Z87",
    display_name: "Retired",
  },
  {
    code: "ZA0",
    display_name: "Unemployed",
  },
];

export const natureOfWorkForestryOptions = [
  {
    code: "D21",
    display_name: "Forest Worker",
  },
  {
    code: "D18",
    display_name:
      "Forester / Ranger - Other than missions in the Tropical Forests",
  },
  {
    code: "D26",
    display_name: "Forestry Officer",
  },
  {
    code: "D25",
    display_name: "Lumberjack",
  },
  {
    code: "D16",
    display_name: "Tree Feller",
  },
  {
    code: "D32",
    display_name: "Wood Cutter",
  },
  {
    code: "D68",
    display_name: "Forester / Ranger - Missions in the Tropical Forests",
  },
];

export const natureOfWorkArmedOptions = [
  {
    display_name: "Machine Operator",
    code: "C22",
  },
  {
    display_name: "Aerospace Engineers",
    code: "C30",
  },
  {
    display_name: "Space Scientists",
    code: "E99",
  },
  {
    display_name: "Criminal Investigators",
    code: "H54",
  },
  {
    display_name: "Paramilitary Forces-Ranks Commandant/SP & Above",
    code: "H91",
  },
  {
    display_name: "All Ranks of RPF, CIVIL Defence, Indian Home Guard",
    code: "H92",
  },
  {
    display_name:
      "Non Combat-All Branches of Armed, Paramilitary, Navy and Police Force",
    code: "H93",
  },
  {
    display_name: "Combat-Army-Rank Colonel and above",
    code: "H94",
  },
  {
    display_name: "Combat-Navy-Captain and above",
    code: "H95",
  },
  {
    display_name: "Combat-Air Force-Group Captain and above",
    code: "H96",
  },
  {
    display_name: "Combat-Police Force-DSP/ACP and above",
    code: "H97",
  },
  {
    display_name: "Combat-Police Force- Rank below DSP/ACP",
    code: "H98",
  },
  {
    display_name: "Combat- NDRF, Relief Operations, Anti Piracy",
    code: "H99",
  },
  {
    display_name: "Prison Warder",
    code: "QH6",
  },
  {
    display_name: "Combat-Army-Rank below Colonel",
    code: "O30",
  },
  {
    display_name: "Combat-Navy-Below Captain",
    code: "O31",
  },
  {
    display_name: "Paramilitary Forces-Ranks below Commandant/SP",
    code: "O32",
  },
  {
    display_name: "Combat-Navy-Pilots, Divers, Sub Mariners Crew",
    code: "O34",
  },
  {
    display_name: "Combat-UN Peace Keeping mission",
    code: "O35",
  },
  {
    display_name: "Combat-Navy- Helicopter Units",
    code: "O36",
  },
  {
    display_name: "Bomb Disposal Unit-Any Force",
    code: "O37",
  },
  {
    display_name: "Non Combat-Air Force-Air transportation only",
    code: "O29",
  },
  {
    display_name: "Non Combat-Air Force-Instructors - air transportation only",
    code: "O65",
  },
  {
    display_name: "Combat-Air Force-Instructors - General duties flying",
    code: "O64",
  },
  {
    display_name:
      "Combat-Air Force-Flying / below flying officer - Testing prototypes or repaired/modified aircraft",
    code: "O66",
  },
  {
    display_name: "Combat-Air Force-Flying officer- Wing Commander",
    code: "O38",
  },
  {
    display_name: "Combat-Air Force- Below Flying officer",
    code: "O39",
  },
  {
    display_name:
      "Non Combat - Transport Driver - All branches of Armed & Allied Forces",
    code: "Q47",
  },
  {
    display_name:
      "Admin duties only - Peon/Office boy/Clerk/Support Staff - All branches of Armed & Allied Forces",
    code: "V20",
  },
  {
    display_name: "Computer Network Specialists",
    code: "C14",
  },
  {
    display_name: "Airfield Operations Specialist",
    code: "V23",
  },
  {
    display_name: "Nuclear Power Reactor Operator",
    code: "V18",
  },
  {
    display_name: "Airforce - General Duties - Ground Staff",
    code: "V22",
  },
  {
    display_name: "Paramedics",
    code: "V24",
  },
  {
    display_name: "Surgeons/Doctor",
    code: "V25",
  },
  {
    display_name: "Nuclear Technicians",
    code: "V31",
  },
  {
    display_name: "Nuclear Engineers",
    code: "V32",
  },
  {
    display_name: "Special Forces",
    code: "V74",
  },
  {
    display_name: "Special Forces Officers",
    code: "V75",
  },
];

export const natureOfWorkMiningOptions = [
  {
    display_name: "Machine Operator",
    code: "C25",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "C73",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "C78",
  },
  {
    display_name: "Operations Managers",
    code: "C80",
  },
  {
    display_name: "Public relation Manager",
    code: "C81",
  },
  {
    display_name: "Quality inspection",
    code: "C82",
  },
  {
    display_name: "Fire Inspectors",
    code: "C83",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "E02",
  },
  {
    display_name: "Crane Operator / forklifts / Bulldozers",
    code: "E08",
  },
  {
    display_name: "handling hazardous chemical -open mine & quarry",
    code: "O62",
  },
  {
    display_name: "Driver - LMV",
    code: "E20",
  },
  {
    display_name: "Service Technicians- Not Underground",
    code: "E23",
  },
  {
    display_name: "Explosive Handler- open mine & quarry",
    code: "E25",
  },
  {
    display_name: "Foreman-underground",
    code: "E31",
  },
  {
    display_name: "Geologist, Geophysicist, Mining Enginners- open/Underground",
    code: "E33",
  },
  {
    display_name: "Administrative Service Manager",
    code: "E40",
  },
  {
    display_name: "Mechanic / Repaireman-open mine & quarry",
    code: "E44",
  },
  {
    display_name: "Small business owner",
    code: "E52",
  },
  {
    display_name: "Underground- Supervisor",
    code: "E64",
  },
  {
    display_name: "Underground- Electrician, Welder, Carpenter",
    code: "O44",
  },
  {
    display_name:
      "open mine / surface mining- Electrician, Welder, Carpenter, Supervisor",
    code: "O45",
  },
  {
    display_name: "Underground- Labourer - Not using Explosive",
    code: "E39",
  },
  {
    display_name: "Underground- Explosive Handler / Blaster / Shotfirers",
    code: "E26",
  },
  {
    display_name: "Underground-Fireman",
    code: "O48",
  },
  {
    display_name: "oil rig platform- Flying Personnel",
    code: "O40",
  },
  {
    display_name: "oil rig platform- Electrician / Mechanic / Rigger",
    code: "O59",
  },
  {
    display_name:
      "oil rig platform- Manual Worker / Roughneck / Roustabout / Derrickman",
    code: "O49",
  },
  {
    display_name: "Underground-Fitter",
    code: "O58",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "E03",
  },
  {
    display_name: "Sales & Marketing - Outdoor",
    code: "C79",
  },
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "E41",
  },
  {
    display_name: "Administrative - Senior Management",
    code: "E42",
  },
];

export const natureOfWorkReligionOptions = [
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "P01",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "P02",
  },
  {
    display_name: "Missionary / Monk/ Nun/ Pastor /Priest / clergyman",
    code: "P06",
  },
  {
    display_name: "Social Worker - Freelancer / ASHA & other Govt Employees",
    code: "QK2",
  },
  {
    display_name: "Others",
    code: "O81",
  },
];
export const natureOfWorkRetailOptions = [
  {
    display_name: "Deliverymen",
    code: "C94",
  },
  {
    display_name: "Advertising Manager",
    code: "E94",
  },
  {
    display_name: "Business Managers",
    code: "E95",
  },
  {
    display_name: "Distribution Managers",
    code: "E96",
  },
  {
    display_name: "Goldsmith / Jeweller",
    code: "JB2",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "S01",
  },
  {
    display_name: "Agents / Brokers / Consultant - Purchase / Sales",
    code: "S03",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "S09",
  },
  {
    display_name: "Florists",
    code: "S20",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "S25",
  },
  {
    display_name: "Administrative Service Manager",
    code: "S31",
  },
  {
    display_name: "Sales & Marketing - Outdoor",
    code: "S34",
  },
  {
    display_name: "Large Shop owner",
    code: "S35",
  },
  {
    display_name: "Small business owner",
    code: "S36",
  },
  {
    display_name: "Service Technicians",
    code: "S40",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "S47",
  },
  {
    display_name: "Shop assistant / Shop staff",
    code: "S48",
  },
  {
    display_name: "Administrative - Senior Management",
    code: "S10",
  },
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "S32",
  },
  {
    display_name: "Financial Managers",
    code: "C17",
  },
  {
    display_name: "Operations Managers",
    code: "S56",
  },
  {
    display_name: "Public relation Manager",
    code: "C18",
  },
  {
    display_name: "Others",
    code: "O77",
  },
];

export const natureOfWorkTextileOptions = [
  {
    display_name: "Agents / Brokers / Consultant - Purchase / Sales",
    code: "D94",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "D96",
  },
  {
    display_name: "Sales & Marketing - Outdoor",
    code: "D51",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "D98",
  },
  {
    display_name: "Administrative Service Manager",
    code: "D99",
  },
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "E69",
  },
  {
    display_name: "Financial Managers",
    code: "E70",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "E72",
  },
  {
    display_name: "Operations Managers",
    code: "E74",
  },
  {
    display_name: "Public relation Manager",
    code: "E76",
  },
  {
    display_name: "Large Shop owner",
    code: "E77",
  },
  {
    display_name: "Fashion Designers",
    code: "J48",
  },
  {
    display_name: "Quality inspection",
    code: "JC3",
  },
  {
    display_name: "Service Technicians",
    code: "JH8",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "JR0",
  },
  {
    display_name: "Small business owner",
    code: "H88",
  },
  {
    display_name: "Factory Workers - Skilled",
    code: "JV8",
  },
  {
    display_name: "Factory Workers - Unskilled / Heavy / Manual Labour",
    code: "J45",
  },
];

export const natureOfWorkTransAutoOptions = [
  {
    display_name: "Operations Research Analysts",
    code: "C84",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "F01",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "FB4",
  },
  {
    display_name: "Industrial Workers",
    code: "F64",
  },
  {
    display_name: "Advertising Manager",
    code: "C97",
  },
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "C98",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "C99",
  },
  {
    display_name: "Financial Managers",
    code: "D49",
  },
  {
    display_name: "Administrative - Senior Management",
    code: "D50",
  },
  {
    display_name: "Operations Managers",
    code: "D52",
  },
  {
    display_name: "Public relation Manager",
    code: "D53",
  },
  {
    display_name: "Service Technicians",
    code: "D54",
  },
  {
    display_name: "Inspectors",
    code: "D56",
  },
  {
    display_name: "Quality inspection",
    code: "D57",
  },
  {
    display_name: "Agents / Brokers / Consultant - Purchase / Sales",
    code: "D80",
  },
  {
    display_name: "Car Washers / Attendents",
    code: "F12",
  },
  {
    display_name: "Crane Operator / Forklifts / Bulldozers",
    code: "F18",
  },
  {
    display_name: "Deliverymen",
    code: "F21",
  },
  {
    display_name: "Sales & Marketing - Outdoor",
    code: "FA3",
  },
  {
    display_name: "Industrial Designers",
    code: "F23",
  },
  {
    display_name: "Guard - Locomotive",
    code: "F66",
  },
  {
    display_name: "Driver - Locomotive",
    code: "F96",
  },
  {
    display_name: "Cargo van/ Transport driver",
    code: "F47",
  },
  {
    display_name: "Driving Instructors",
    code: "F48",
  },
  {
    display_name: "Industrial Engineers",
    code: "F50",
  },
  {
    display_name: "Administrative Service Manager",
    code: "F69",
  },
  {
    display_name: "Mechanic / Repaireman",
    code: "F71",
  },
  {
    display_name: "Large Shop owner",
    code: "F81",
  },
  {
    display_name: "Small business owner",
    code: "F82",
  },
  {
    display_name: "Shop assistant / Shop staff",
    code: "FA4",
  },
  {
    display_name: "Driver - LMV / Auto Rickshaw / Tuk Tuk",
    code: "FC3",
  },
  {
    display_name: "Driver - Truck / Lorry / Goods Vehicles / Tractor / HMV",
    code: "FD3",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "D83",
  },
  {
    display_name: "Petrol Station Attendant",
    code: "QE6",
  },
  {
    display_name: "Rickshaw Man",
    code: "FA2",
  },
];

export const natureOfWorkFoodOptions = [
  {
    display_name: "Deliverymen",
    code: "R28",
  },
  {
    display_name: "Dietitians and Nutritionists",
    code: "E90",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "E91",
  },
  {
    display_name: "Farm Management Advisors",
    code: "E92",
  },
  {
    display_name: "Public relation Manager",
    code: "E93",
  },
  {
    display_name: "Housekeeping / Support Staff",
    code: "R20",
  },
  {
    display_name: "Factory Workers - Skilled",
    code: "C16",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "R03",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "R02",
  },
  {
    display_name: "Bakers (factories & shops)",
    code: "R04",
  },
  {
    display_name: "Butchers",
    code: "R12",
  },
  {
    display_name: "Cleaners / Sweeper",
    code: "R19",
  },
  {
    display_name: "Market Merchants(poultry/seafood/fruit/vegetable)",
    code: "R38",
  },
  {
    display_name: "Sales & Marketing - Outdoor",
    code: "R42",
  },
  {
    display_name: "Domestic Helpers",
    code: "R48",
  },
  {
    display_name: "Factory Workers - Unskilled / Heavy / Manual Labour",
    code: "R49",
  },
  {
    display_name: "Machine Operator",
    code: "R50",
  },
  {
    display_name: "Administrative Service Manager",
    code: "R51",
  },
  {
    display_name: "Distribution Managers",
    code: "R52",
  },
  {
    display_name: "Large Shop owner",
    code: "R54",
  },
  {
    display_name: "Small business owner",
    code: "R56",
  },
  {
    display_name: "Quality inspection",
    code: "R64",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "R70",
  },
  {
    display_name: "Shop assistant / Shop staff",
    code: "R72",
  },
  {
    display_name: "Service Technicians",
    code: "R75",
  },
  {
    display_name: "Slaughterers and Meat Packers",
    code: "R76",
  },
  {
    display_name: "Owners - Food / Drink Stall / Hawker / Street Food Shop",
    code: "R78",
  },
  {
    display_name: "Waiters / Waitresses / Bar Staff",
    code: "R84",
  },
  {
    display_name: "Advertising Manager",
    code: "C23",
  },
  {
    display_name: "Food Scientists",
    code: "C59",
  },
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "R79",
  },
  {
    display_name: "Administrative - Senior Management",
    code: "R80",
  },
  {
    display_name: "Others",
    code: "O75",
  },
];

export const natureOfWorkHealthOptions = [
  {
    display_name: "Domestic Helpers",
    code: "D72",
  },
  {
    display_name: "Quality inspection",
    code: "E78",
  },
  {
    display_name: "Pediatricians",
    code: "E79",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "E80",
  },
  {
    display_name: "Financial Managers",
    code: "E81",
  },
  {
    display_name: "Surgical Technologists",
    code: "E82",
  },
  {
    display_name: "Yoga instructor",
    code: "E83",
  },
  {
    display_name: "Skincare Specialists",
    code: "E84",
  },
  {
    display_name: "Community Health Workers",
    code: "M03",
  },
  {
    display_name: "Anesthesiologists",
    code: "M07",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "M02",
  },
  {
    display_name: "Dietitians and Nutritionists",
    code: "M09",
  },
  {
    display_name: "Microbiologists",
    code: "M11",
  },
  {
    display_name: "Sales & Marketing - Outdoor",
    code: "C90",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "M14",
  },
  {
    display_name: "Counselors",
    code: "M17",
  },
  {
    display_name: "Dental - Hygienists / Assistants",
    code: "M19",
  },
  {
    display_name: "Doctor / Dentist",
    code: "M22",
  },
  {
    display_name: "Owner - Medicine Shop",
    code: "M23",
  },
  {
    display_name: "Public relation Manager",
    code: "M29",
  },
  {
    display_name: "Compliance Officers",
    code: "M35",
  },
  {
    display_name: "Medical Attendants",
    code: "M37",
  },
  {
    display_name: "Medical Assistants",
    code: "M38",
  },
  {
    display_name: "Housekeeping / Support Staff",
    code: "M39",
  },
  {
    display_name: "Cleaning staff / Sweeper",
    code: "M45",
  },
  {
    display_name: "Technicians",
    code: "M46",
  },
  {
    display_name: "Administrative Service Manager",
    code: "M49",
  },
  {
    display_name: "Childcare Workers",
    code: "M51",
  },
  {
    display_name: "Emergency Medical Technician",
    code: "M61",
  },
  {
    display_name: "Paramedics",
    code: "M62",
  },
  {
    display_name: "PhysioTherapist / Occupation Therapist",
    code: "M64",
  },
  {
    display_name: "Psychiatrists",
    code: "M65",
  },
  {
    display_name: "Engine/Machine Assemblers",
    code: "M68",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "M70",
  },
  {
    display_name: "Medical Equipment Repairers",
    code: "M72",
  },
  {
    display_name: "Physicians and Surgeons",
    code: "M78",
  },
  {
    display_name: "Medical Equipment Preparers",
    code: "M80",
  },
  {
    display_name: "Diagnostic Technicians",
    code: "M81",
  },
  {
    display_name: "Medical Scientists",
    code: "M83",
  },
  {
    display_name: "Veternary Doctor",
    code: "M84",
  },
  {
    display_name: "Ward boy",
    code: "M87",
  },
  {
    display_name: "Ward Boys / Attendents - rest or old people's hom",
    code: "M88",
  },
  {
    display_name: "Research / Lab work",
    code: "Q19",
  },
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "C87",
  },
  {
    display_name: "Administrative - Senior Management",
    code: "C24",
  },
  {
    display_name: "Driver - Ambulance",
    code: "M04",
  },
  {
    display_name: "Others",
    code: "O78",
  },
];

export const natureOfWorkHospitalityOptions = [
  {
    display_name: "Car Washers / Attendents",
    code: "C92",
  },
  {
    display_name: "Event Planners",
    code: "D62",
  },
  {
    display_name: "Parking Lot Attendants",
    code: "D64",
  },
  {
    display_name: "Quality inspection",
    code: "D65",
  },
  {
    display_name: "Sales & Marketing - Outdoor",
    code: "D66",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "D67",
  },
  {
    display_name:
      "Service Technicians / Electrician -  ground level / Less than 49 Feet or 15 Meter",
    code: "D69",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "D70",
  },
  {
    display_name: "Advertising Manager",
    code: "D73",
  },
  {
    display_name: "Financial Managers",
    code: "D74",
  },
  {
    display_name: "Administrative - Senior Management",
    code: "D75",
  },
  {
    display_name: "Operations Managers",
    code: "D77",
  },
  {
    display_name: "Training/Development Managers",
    code: "D78",
  },
  {
    display_name: "Transportation Travel Clerks",
    code: "D82",
  },
  {
    display_name: "Yoga instructor",
    code: "E85",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "H01",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "H02",
  },
  {
    display_name: "Bar Staff",
    code: "H04",
  },
  {
    display_name: "Catering Staff",
    code: "H15",
  },
  {
    display_name: "Housekeeping / Support Staff / Laundry Worker",
    code: "H31",
  },
  {
    display_name: "Janitors / Caretakers",
    code: "H34",
  },
  {
    display_name: "Cleaning staff / Kitchen Helper",
    code: "H35",
  },
  {
    display_name: "Domestic Helpers",
    code: "H37",
  },
  {
    display_name: "Administrative Service Manager",
    code: "H39",
  },
  {
    display_name: "Public relation Manager",
    code: "H43",
  },
  {
    display_name: "Room Attendant",
    code: "H45",
  },
  {
    display_name: "Transportation Ticket Agents",
    code: "H48",
  },
  {
    display_name: "Tour Guides and Escorts",
    code: "H49",
  },
  {
    display_name: "Under water Diving (Deep Sea and Others)",
    code: "H77",
  },
  {
    display_name: "Gym instructor",
    code: "H79",
  },
  {
    display_name: "Therapists",
    code: "M82",
  },
  {
    display_name: "Aerobics Instructors",
    code: "N07",
  },
  {
    display_name: "Massager / Barber / Beautician / Hairdresser",
    code: "N23",
  },
  {
    display_name: "Deliverymen",
    code: "N51",
  },
  {
    display_name: "Lifeguards",
    code: "N70",
  },
  {
    display_name: "Photographers",
    code: "NA4",
  },
  {
    display_name: "Cook / Chef",
    code: "R24",
  },
  {
    display_name: "Shop owner",
    code: "R60",
  },
  {
    display_name: "Small business owner",
    code: "R62",
  },
  {
    display_name: "Shop assistant / Shop staff",
    code: "R73",
  },
  {
    display_name: "Waiters/Waitresses",
    code: "H52",
  },
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "H85",
  },
  {
    display_name: "Bouncer / Doorman - night club, bar or lounge",
    code: "H06",
  },
  {
    display_name:
      "Doorman - international hotel or other high class establishment",
    code: "H22",
  },
  {
    display_name: "Driver - sightseeing bus / coach",
    code: "H24",
  },
  {
    display_name:
      "Service Technicians / Electrician - works at heights above 49 Feet or 15 Meter",
    code: "H84",
  },
  {
    display_name: "Others",
    code: "O76",
  },
];

export const natureOfWorkITOptions = [
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "H60",
  },
  {
    display_name: "Administrative Service Manager",
    code: "H61",
  },
  {
    display_name: "Business Managers",
    code: "H62",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "H63",
  },
  {
    display_name: "Public relation Manager",
    code: "H65",
  },
  {
    display_name: "Quality inspection",
    code: "H66",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "H67",
  },
  {
    display_name: "Service Technicians / Hardware Professional",
    code: "W25",
  },
  {
    display_name: "Large Shop owner",
    code: "H69",
  },
  {
    display_name: "Software Professional",
    code: "W51",
  },
  {
    display_name: "Web Developers",
    code: "H71",
  },
  {
    display_name: "Financial Managers",
    code: "H90",
  },
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "H86",
  },
  {
    display_name: "Computer Network Specialists",
    code: "H64",
  },
  {
    display_name: "Operations Research Analysts",
    code: "H87",
  },
  {
    display_name: "Sales & Marketing - Outdoor",
    code: "H59",
  },
  {
    display_name: "Administrative - Senior Management",
    code: "H55",
  },
  {
    display_name: "Small business owner",
    code: "H89",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "H83",
  },
  {
    display_name: "Information Analyst",
    code: "W10",
  },
  {
    display_name: "Computer Network Architect",
    code: "W28",
  },
  {
    display_name: "Others",
    code: "O80",
  },
];

export const natureOfWorkManufacturingOptions = [
  {
    display_name: "Machine Operator / Boiler Operator",
    code: "C27",
  },
  {
    display_name: "handling hazardous chemical or explosive materials",
    code: "C74",
  },
  {
    display_name: "Nuclear Engineers",
    code: "C75",
  },
  {
    display_name: "Nuclear Power Reactor Operator",
    code: "C76",
  },
  {
    display_name: "Nuclear Technicians",
    code: "C77",
  },
  {
    display_name: "Rail Transportation Workers",
    code: "D55",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "J02",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "D97",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "E71",
  },
  {
    display_name: "Operations Managers",
    code: "E73",
  },
  {
    display_name: "Public relation Manager",
    code: "E75",
  },
  {
    display_name: "Railroad Signal Operators",
    code: "F95",
  },
  {
    display_name: "Rail Car Repairers",
    code: "FA1",
  },
  {
    display_name: "Railroad Conductor/Yardmaster",
    code: "FB9",
  },
  {
    display_name: "Operations Research Analysts",
    code: "J01",
  },
  {
    display_name:
      "Electricians - ground level / works at height less than 49 Feet or 15 Meter",
    code: "J68",
  },
  {
    display_name: "Industrial Engineers",
    code: "J72",
  },
  {
    display_name: "Service Technicians",
    code: "J73",
  },
  {
    display_name: "Locomotive Engineers",
    code: "J74",
  },
  {
    display_name:
      "Fitter -  ground level / works at height less than 49 Feet or 15 Meter",
    code: "J87",
  },
  {
    display_name: "Foreman",
    code: "J91",
  },
  {
    display_name: "Cooling Equipment Operators",
    code: "JC0",
  },
  {
    display_name: "Chemical Plant Operators",
    code: "JC1",
  },
  {
    display_name: "Goldsmith / Jeweller",
    code: "JC7",
  },
  {
    display_name: "Heavy / Manual Labour",
    code: "JF0",
  },
  {
    display_name: "Locksmiths and Safe Repairers",
    code: "JG1",
  },
  {
    display_name: "Rail Yard Engineers",
    code: "JH0",
  },
  {
    display_name: "Administrative Service Manager",
    code: "JH4",
  },
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "JH5",
  },
  {
    display_name: "Materials Scientists",
    code: "JI0",
  },
  {
    display_name: "Large Shop owner",
    code: "JJ4",
  },
  {
    display_name: "Small business owner",
    code: "JJ5",
  },
  {
    display_name: "Quality inspection",
    code: "JM3",
  },
  {
    display_name:
      "Mechanic / Repaireman - ground level / works at height less than 49 Feet or 15 Meter",
    code: "JM6",
  },
  {
    display_name: "Instrument/Equipment Repairer",
    code: "JM8",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "JQ9",
  },
  {
    display_name: "Industrial Technician",
    code: "JR3",
  },
  {
    display_name: "welder",
    code: "JT6",
  },
  {
    display_name: "Asbestos Worker",
    code: "JT9",
  },
  {
    display_name: "Industrial Workers - odd job",
    code: "JU1",
  },
  {
    display_name: "Factory Workers",
    code: "JV6",
  },
  {
    display_name:
      "Technician and Workers - works at heights above 49 Feet or 15 Meter",
    code: "O61",
  },
  {
    display_name: "Wind Turbine Technicians",
    code: "QN4",
  },
  {
    display_name: "Security Guard - Armed",
    code: "S51",
  },
  {
    display_name: "Security Guard - unarmed",
    code: "S52",
  },
  {
    display_name: "Sales & Marketing - Indoor",
    code: "H80",
  },
  {
    display_name: "Administrative - Senior Management",
    code: "J92",
  },
  {
    display_name: "Driver - Truck / Lorry / HMV",
    code: "JS5",
  },
];

export const natureOfWorkMarineOptions = [
  {
    display_name: "Custom & Excise Officer",
    code: "D59",
  },
  {
    display_name: "Marine Engineers",
    code: "D60",
  },
  {
    display_name: "Fire Inspectors",
    code: "D61",
  },
  {
    display_name: "Shipping/ Receiving Clerks",
    code: "G02",
  },
  {
    display_name: "Inspectors / Quality inspection",
    code: "GC7",
  },
  {
    display_name: "STEVEDOVE ( Loading / Unloading of Goods on dock)",
    code: "GD3",
  },
  {
    display_name: "On Shore  Administrative Office Job",
    code: "GE0",
  },
  {
    display_name:
      "welder- ship & boat building yard, finished hull, hull construction",
    code: "GE9",
  },
  {
    display_name: "Under water Diving (Deep Sea and Others)",
    code: "H78",
  },
  {
    display_name: "offshore work-Seaman, Quartermaster, Steerer",
    code: "O42",
  },
  {
    display_name: "Offshore- Captain, officer, Master, Mate, Skipper",
    code: "O50",
  },
  {
    display_name: "Offshore- Ship Engineers",
    code: "O51",
  },
  {
    display_name: "Offshore- Baggage Master",
    code: "O52",
  },
  {
    display_name: "Offshore-Bargeman,  Dredgermaster, Dredgerman",
    code: "O53",
  },
  {
    display_name: "Offshore-Purser, Lighterman, Steward, Catering Staff",
    code: "O54",
  },
  {
    display_name: "Offshore-Leadsman,Navigator, Pilot",
    code: "O55",
  },
  {
    display_name: "Offshore-Survey Sounder, Tugboatman",
    code: "O56",
  },
  {
    display_name: "Offshore-Boatswain, Engine room worker",
    code: "O57",
  },
  {
    display_name: "Administrative/Front Office - Assistant/Executive/Officer",
    code: "G01",
  },
  {
    display_name: "Peon/Office boy/Clerk/Support Staff",
    code: "D63",
  },
  {
    display_name: "Administrative - Junior/Middle Management",
    code: "C45",
  },
  {
    display_name: "Chief Executive/Senior Leadership Role",
    code: "C36",
  },
  {
    display_name: "Administrative - Senior Management",
    code: "C21",
  },
];
export const natureOfWorkProfessionalOptions = [
  {
    display_name: "ARCHERY - coach",
    code: "Y01",
  },
  {
    display_name: "ARCHERY - professional player",
    code: "Y02",
  },
  {
    display_name: "ATHLETE - track & field, coach",
    code: "Y03",
  },
  {
    display_name: "ATHLETE - track & field, professional player",
    code: "Y04",
  },
  {
    display_name: "BADMINTON - coach",
    code: "Y05",
  },
  {
    display_name: "BADMINTON - professional player",
    code: "Y06",
  },
  {
    display_name: "BASEBALL - coach",
    code: "Y07",
  },
  {
    display_name: "BASEBALL - professional player",
    code: "Y08",
  },
  {
    display_name: "BASKETRBALL - coach",
    code: "Y09",
  },
  {
    display_name: "BASKETRBALL - professional player",
    code: "Y10",
  },
  {
    display_name: "BICYCLE - coach",
    code: "Y11",
  },
  {
    display_name: "BICYCLE RACING - road & track, professional player",
    code: "Y12",
  },
  {
    display_name: "BICYCLE SCRAMBLER - professional player",
    code: "Y13",
  },
  {
    display_name: "BOBSLEIGH - coach",
    code: "Y14",
  },
  {
    display_name: "BOBSLEIGH - professional player",
    code: "Y15",
  },
  {
    display_name: "BOWLING - coach",
    code: "Y16",
  },
  {
    display_name: "BOWLING - professional player",
    code: "Y17",
  },
  {
    display_name: "BOXER - coach",
    code: "Y18",
  },
  {
    display_name: "BOXER - professional player",
    code: "Y19",
  },
  {
    display_name: "CANOEIST - coach",
    code: "Y20",
  },
  {
    display_name: "CANOEIST - slalom & sprint events, professional player",
    code: "Y21",
  },
  {
    display_name: "CANOEIST - wild water events, professional player",
    code: "Y22",
  },
  {
    display_name: "CAR RACING - coach or professional driver",
    code: "Y23",
  },
  {
    display_name: "CONOEING - coach",
    code: "Y24",
  },
  {
    display_name: "CONOEING - professional player",
    code: "Y25",
  },
  {
    display_name: "CRICKET - coach",
    code: "Y26",
  },
  {
    display_name: "CRICKET - professional player",
    code: "Y27",
  },
  {
    display_name: "DARTS - coach",
    code: "Y28",
  },
  {
    display_name: "DARTS - professional player",
    code: "Y29",
  },
  {
    display_name: "DIVING - highboard & springboard, coach",
    code: "Y30",
  },
  {
    display_name: "DIVING - highboard & springboard, professional player",
    code: "Y31",
  },
  {
    display_name: "DIVING - sub-aqua, coach or professional player",
    code: "Y32",
  },
  {
    display_name: "DOG RACING - dog handler or trainer",
    code: "Y33",
  },
  {
    display_name: "FENCER - coach",
    code: "Y34",
  },
  {
    display_name: "FENCER - professional player",
    code: "Y35",
  },
  {
    display_name: "FOLK-LIKE  SPORT ACTIVITIES - coach",
    code: "Y36",
  },
  {
    display_name: "FOLK-LIKE  SPORT ACTIVITIES - professional player",
    code: "Y37",
  },
  {
    display_name: "FOOTBALL - coach",
    code: "Y38",
  },
  {
    display_name: "FOOTBALL - professional player",
    code: "Y39",
  },
  {
    display_name: "GO-KART & MICRO-MIDGET - coach or professional player",
    code: "Y40",
  },
  {
    display_name: "GOLF - caddie",
    code: "Y41",
  },
  {
    display_name: "GOLF - coach",
    code: "Y42",
  },
  {
    display_name: "GOLF - guide",
    code: "Y43",
  },
  {
    display_name: "GOLF - professional player",
    code: "Y44",
  },
  {
    display_name: "GYMNASTICS - coach",
    code: "Y45",
  },
  {
    display_name: "GYMNASTICS - professional player",
    code: "Y46",
  },
  {
    display_name: "HANDBALL - coach",
    code: "Y47",
  },
  {
    display_name: "HANDBALL - professional player",
    code: "Y48",
  },
  {
    display_name: "HOCKEY - excluding ice hockey, coach",
    code: "Y49",
  },
  {
    display_name: "HOCKEY - excluding ice hockey, professional player",
    code: "Y50",
  },
  {
    display_name: "HOCKEY - ice, coach",
    code: "Y51",
  },
  {
    display_name: "HOCKEY - ice, professional player",
    code: "Y52",
  },
  {
    display_name: "HORSE - exercise boy",
    code: "Y53",
  },
  {
    display_name: "Professional Spor - HORSE BREEDER",
    code: "Y54",
  },
  {
    display_name: "Professional Spor - HORSE GROOM",
    code: "Y55",
  },
  {
    display_name: "HORSE RACING - coach",
    code: "Y56",
  },
  {
    display_name: "HORSE RACING - professional player",
    code: "Y57",
  },
  {
    display_name: "HORSE RIDING - coach",
    code: "Y58",
  },
  {
    display_name: "HORSE RIDING - professional player",
    code: "Y59",
  },
  {
    display_name: "Professional Spor - HORSE TRAINER",
    code: "Y60",
  },
  {
    display_name: "JET SKI - coach",
    code: "Y61",
  },
  {
    display_name: "JET SKI - professional player",
    code: "Y62",
  },
  {
    display_name: "JOCKEY - horse racing, flat or steeple chase",
    code: "Y63",
  },
  {
    display_name: "JUDO - coach",
    code: "Y64",
  },
  {
    display_name: "JUDO - professional fighter",
    code: "Y65",
  },
  {
    display_name: "KARATE - coach",
    code: "Y66",
  },
  {
    display_name: "KARATE - professional fighter",
    code: "Y67",
  },
  {
    display_name: "KUNG FU - instructor",
    code: "Y68",
  },
  {
    display_name: "KUNG FU - professional fighter",
    code: "Y69",
  },
  {
    display_name: "MA FOO - horse",
    code: "Y70",
  },
  {
    display_name: "MANAGER - sports team",
    code: "Y71",
  },
  {
    display_name: "MARTIAL ARTS - instructor",
    code: "Y72",
  },
  {
    display_name: "MARTIAL ARTS - professional fighter",
    code: "Y73",
  },
  {
    display_name: "MOTOR CYCLE RACING - coach or professional player",
    code: "Y74",
  },
  {
    display_name: "MOTOR RACING - coach or professional player",
    code: "Y75",
  },
  {
    display_name: "MOTOR RACING - ground staff",
    code: "Y76",
  },
  {
    display_name: "MOTOR SCOOTER RACING - coach or professional player",
    code: "Y77",
  },
  {
    display_name: "MOUNTAINEER - coach or professional player",
    code: "Y78",
  },
  {
    display_name: "POLO PLAYER - on horse",
    code: "Y79",
  },
  {
    display_name: "POWER BOAT RACING - coach or professional player",
    code: "Y80",
  },
  {
    display_name: "RUGBY - coach",
    code: "Y81",
  },
  {
    display_name: "RUGBY - professional player",
    code: "Y82",
  },
  {
    display_name: "SAILING - pilot",
    code: "Y83",
  },
  {
    display_name: "SHOOTING - coach",
    code: "Y84",
  },
  {
    display_name: "SHOOTING - professional player",
    code: "Y85",
  },
  {
    display_name: "SKATING - coach",
    code: "Y86",
  },
  {
    display_name: "SKATING - professional player",
    code: "Y87",
  },
  {
    display_name: "SNOOKER - coach",
    code: "Y88",
  },
  {
    display_name: "SNOOKER - professional player",
    code: "Y89",
  },
  {
    display_name: "SOCCER - coach",
    code: "Y90",
  },
  {
    display_name: "SOCCER - professional player",
    code: "Y91",
  },
  {
    display_name: "SOFTBALL - coach",
    code: "Y92",
  },
  {
    display_name: "SOFTBALL - professional player",
    code: "Y93",
  },
  {
    display_name: "SQUASH - coach",
    code: "Y94",
  },
  {
    display_name: "SQUASH - professional player",
    code: "Y95",
  },
  {
    display_name: "STABLE HAND - horse",
    code: "Y96",
  },
  {
    display_name: "SWIMMING - coach",
    code: "Y97",
  },
  {
    display_name: "SWIMMING - professional player",
    code: "Y98",
  },
  {
    display_name: "TABLE TENNIS - coach",
    code: "Y99",
  },
  {
    display_name: "TABLE TENNIS - professional player",
    code: "YA0",
  },
  {
    display_name: "TENNIS - coach",
    code: "YA1",
  },
  {
    display_name: "TENNIS - professional player",
    code: "YA2",
  },
  {
    display_name: "VOLLEYBALL - coach",
    code: "YA3",
  },
  {
    display_name: "VOLLEYBALL - professional player",
    code: "YA4",
  },
  {
    display_name: "WATER SKI - coach",
    code: "YA5",
  },
  {
    display_name: "WATER SKI - professional player",
    code: "YA6",
  },
  {
    display_name: "WATERBALL - coach",
    code: "YA7",
  },
  {
    display_name: "WATERBALL - professional player",
    code: "YA8",
  },
  {
    display_name: "WEIGHT LIFTER - coach",
    code: "YA9",
  },
  {
    display_name: "WEIGHT LIFTER - professional player",
    code: "YB0",
  },
  {
    display_name: "WINDSURFING - coach",
    code: "YB1",
  },
  {
    display_name: "WINDSURFING - professional player",
    code: "YB2",
  },
  {
    display_name: "WRESTLER - coach",
    code: "YB3",
  },
  {
    display_name: "WRESTLER - professional player",
    code: "YB4",
  },
  {
    display_name: "YACHTING - coach",
    code: "YB5",
  },
  {
    display_name: "YACHTING - professional player",
    code: "YB6",
  },
];
