import { createSlice } from "@reduxjs/toolkit";

const frontendBoot = createSlice({
  name: "frontendBoot",
  initialState: {
    frontendData: {},
    isLoading: true,
  },
  reducers: {
    setFrontendData: (state, action) => {
      state.frontendData = action.payload.data
        ? { ...state.frontendData, ...action.payload.data }
        : {};
      state.isLoading = action.payload.isLoading;
    },
    setDefaultFilters: (state, action) => {
      state.frontendData.investmentdefaultfilters = action.payload;
    },
  },
});

export const { setFrontendData, setDefaultFilters } = frontendBoot.actions;

export default frontendBoot.reducer;

export const selectFrontendData = state => state.frontendBoot.frontendData;

export const selectICLogo = alias => state => {
  return state?.frontendBoot?.frontendData?.investmentcompanies?.[alias]?.logo;
};

export const selectCompanies = state =>
  state.frontendBoot.frontendData.investmentcompanies;
