import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setGetMoneyAs,
  setIncomeFor,
  setInvestedAmount,
  setInvestFor,
  setPayFrequency,
  setReturnsAfter,
} from "../pages/Filters/filterSlice";
import { useGetEnquiriesQuery } from "../pages/rider/services/rider";

function useUpdateFilterWithEnquiry() {
  const dispatch = useDispatch();
  const { data, isLoading } = useGetEnquiriesQuery();
  const filter = useSelector(state => state.filters);

  useEffect(() => {
    if (!isLoading && data) {
      if (data.data && data.data.input) {
        const investedAmount = data.data.input?.investment_amount;
        const investFor = data.data.input?.pay_term;
        const incomeFor = data.data.input?.income_for;
        const returnsAfter = data.data.input?.policy_term;
        const payFrequency = data.data.input?.pay_frequency;
        const getMoneyAs = data.data.input?.get_money_as;
        if (filter?.investedAmount !== investedAmount)
          dispatch(setInvestedAmount(investedAmount));
        if (filter?.investFor?.toString() !== investFor?.toString())
          dispatch(setInvestFor(investFor?.toString()));
        if (filter?.incomeFor?.toString() !== incomeFor?.toString())
          dispatch(setIncomeFor(incomeFor?.toString()));
        if (filter?.returnsAfter?.toString() !== returnsAfter?.toString())
          dispatch(setReturnsAfter(returnsAfter?.toString()));
        if (filter?.payFrequency !== payFrequency)
          dispatch(setPayFrequency(payFrequency));
        if (filter?.getMoneyAs !== getMoneyAs)
          dispatch(setGetMoneyAs(getMoneyAs));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);
}

export default useUpdateFilterWithEnquiry;
