import { yupResolver } from "@hookform/resolvers/yup";
import { InputAdornment } from "@mui/material";
import { useForm } from "react-hook-form";
import { MdMailOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import TextInput from "../../QuotesPage/components/EditDetailsSection/components/TextInput";
import ShareViaForm from "../../QuotesPage/components/Share/ShareForm";
import { useShareQuoteMutation } from "../../rider/services/rider";
import * as yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import { selectCompareQuotes } from "../ComparePlanPopupSlice";

const schema = yup.object({
  email: yup.string().email().required("Please enter email"),
});

function ShareMailCompare() {
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  const { data } = useSelector(state => state.inputForm);
  const { showDifference, selectedFeatures } = useSelector(
    state => state.compare,
  );
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { email: data.email || "" },
    resolver: yupResolver(schema),
  });

  const onSubmit = async formData => {
    let selected = [];
    const key = Object.keys(selectedFeatures);
    key.map(item => {
      selected.push(selectedFeatures[item]);
    });
    selected = selected.flat();
    shareQuotes({
      purpose: "compare",
      email: formData.email,
      via: "email",
      selected_riders: {},
      mode: ["EMAIL"],
      stage: "COMPARE",
      image_to_send: "",
      whatsapp: "",
      sms: "",
      show_difference: showDifference,
      selected_features: selected,
    }).then(data => {
      if (data.error) {
        toast.error("Sharing Failed!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.success("Compare Shared Successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
  };
  const [email, setEmail] = useState(data.email);

  return (
    <ShareViaForm onSubmit={handleSubmit(onSubmit)} isLoading={isLoading}>
      <TextInput
        error={errors.email && errors.email.message}
        {...register("email")}
        value={email}
        onChange={evt => {
          register("email").onChange(evt);
          setEmail(evt.target.value);
          setValue("email", evt.target.value);
        }}
        label="Email*"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdMailOutline className="fs-6" />
            </InputAdornment>
          ),
        }}
      />
    </ShareViaForm>
  );
}

export default ShareMailCompare;
