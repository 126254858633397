import React, { useState, useEffect } from "react";
import "./QuotesPage.scss";
// import Quotes from "./components/QuotesPage";
import { ColWrapper, QuotesPageWrapper } from "./QuotesPage.style";
import Filters from "../Filters/Filters";
import styled from "styled-components/macro";
import ShortListQuotes from "./components/ShortListQuotes/ShortListQuotes";

import Navbar from "../../components/navbar/Navbar-Component";
import QuotesMobile from "./components/Mobile/QuotesMobile";
import * as mq from "../../styles/mediaQueries";
import NavbarMobile from "./components/Mobile/components/NavbarMobile";
import Quotes from "./components/Quotes";
import TraceId from "./components/TraceId";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import mainImage from "../../assets/images/main-background.png";
import PersonalizedDetailsBox from "./components/PersonalizedDetailsBox";
import ComparePlanPopup from "../ComparePage/ComparePlanPopup-Component";
import { clearQuotes } from "../ComparePage/ComparePlanPopupSlice";
import { useDispatch, useSelector } from "react-redux";
import DifferentQuotes from "./components/DifferentQuotes";
import { selectDifferentQuotes } from "./Quotes.slice";
import CreateCustomCompare from "../NewCompare/components/CreateCustomCompare";
import CreateCustomCompareMobile from "../NewCompare/components/CreateCustomCompareMobile";

const QuotesPage = () => {
  const [showAddOn, setShowAddOn] = useState(false);
  // const { data } = useGetQuoteQuery({ company_alias: "icici_pru" });
  const dispatch = useDispatch();
  const { compareQuotes } = useSelector(state => state.compareQuotes);
  const { showDifferentQuotes } = useSelector(state => state.quotes);
  const diffQuotes = useSelector(selectDifferentQuotes);
  const matches = useMediaQuery(`${mq.mdForHook}`);

  useEffect(() => {
    dispatch(clearQuotes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {matches ? <NavbarMobile /> : <Navbar />}
      <Main
        css={`
          background: url(${mainImage});
          background-repeat: no-repeat;
          background-size: auto;
          background-attachment: inherit;
        `}
      >
        <div
          css={`
            width: 96rem;
          `}
        >
          <QuotesPageContainer
            css={`
              padding-bottom: ${compareQuotes.length ? "12em" : "2em"};
            `}
          >
            {matches && <CreateCustomCompareMobile />}

            <QuotesPageWrapper>
              <ColWrapper
                css={`
                  width: 72%;
                `}
              >
                {/* filters component to be here and add margin bottom to it*/}
                <Filters />
                <Quotes showAddOn={showAddOn} setShowAddOn={setShowAddOn} />

                {showDifferentQuotes && diffQuotes && (
                  <div className="different-quotes__heading">
                    Insurer's offering Product - Plans with slightly different
                    criteria are shown below
                  </div>
                )}
                <DifferentQuotes
                  showAddOn={showAddOn}
                  setShowAddOn={setShowAddOn}
                />
              </ColWrapper>

              <ColWrapper
                css={`
                  width: 27%;
                  display: flex;
                  flex-direction: column;
                `}
              >
                <div
                  css={`
                    height: 50px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: center;
                    margin-top: 3px;
                    margin-bottom: 12px;
                    border: solid 1px var(--border-light);
                    background: #fff;
                    border-radius: 5px;
                    ${mq.lg} {
                      height: 45px;
                      margin-top: 6px;
                      margin-bottom: 14px;
                    }
                  `}
                >
                  <TraceId label="CRN/Trace Id : " />{" "}
                </div>
                <CreateCustomCompare />
                <ShortListQuotes />
                <PersonalizedDetailsBox />
              </ColWrapper>
            </QuotesPageWrapper>
            {/*quotes page mobile component starts from here*/}
            <QuotesMobile showAddOn={showAddOn} setShowAddOn={setShowAddOn} />
          </QuotesPageContainer>
        </div>
      </Main>

      <ComparePlanPopup />
    </>
  );
};

export default QuotesPage;

export const QuotesPageContainer = styled.div`
  ${mq.md} {
    flex-direction: column;
    margin: 0px;
    padding: 0px;
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
