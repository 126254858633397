import styled from "styled-components";
import * as mq from "../../styles/mediaQueries";

export const QuotesPageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 5% 20px 5%;
  flex-wrap: wrap;
  ${mq.lg} {
    padding: 20px 3.5% 20px 3.5%;
  }
  ${mq.md} {
    display: none;
  }
`;
export const ColWrapper = styled.div`
  padding: 0px;
  position: relative;
`;

export const LogoWrapper = styled.div``;
