import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function useGetAmount() {
  let investedAmount = useSelector(state => state.filters.investedAmount);
  let payFrequency = useSelector(state => state.filters.payFrequency);
  let investForValue = useSelector(state => state.filters.investFor);
  function editAmount(val) {
    let temp = parseInt(val);
    if (payFrequency === "0") {
      return val;
    } else if (investForValue === "1") {
      return val;
    } else if (payFrequency === "1") {
      temp = temp * 2;
    } else if (payFrequency === "2") {
      temp = temp * 4;
    } else if (payFrequency === "3") {
      temp = temp * 12;
    }
    if (temp === null || temp === undefined || temp === 0) {
      return "0";
    }
    return temp.toString();
  }
  //   let amount = editAmount(investedAmount) || "0";
  const [amount, setAmount] = useState(editAmount(investedAmount) || "0");
  useEffect(() => {
    setAmount(editAmount(investedAmount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investedAmount, payFrequency, investForValue]);

  return amount;
}

export default useGetAmount;
