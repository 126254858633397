import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { capitalize } from "../../../utils";
import * as mq from "../../../styles/mediaQueries";
import CancelCircularIcon from "../../../assets/svg/CancelCircleIcon";
import ShareMailCompare from "./ShareMailCompare";
import ShareTextCompare from "./ShareTextCompare";

import WhatsAppShareCompare from "./WhatsappShareCompare";
function ShareCompareQuotes({ showPopup, setShowPopup }) {
  const { data: userData } = useSelector(state => state.inputForm);
  const proposerName = capitalize(userData?.name?.split(" ")[0]);

  return (
    <Modal
      centered
      show={showPopup}
      onHide={() => setShowPopup(false)}
      css={`
        & .modal-dialog {
          max-width: 650px;
          ${mq.md} {
            margin: 0px auto;
          }
          ${mq.sm} {
            max-width: 100%;
            margin: 0px 0.8em;
          }
        }
      `}
    >
      <Modal.Header>
        <Modal.Title
          css={`
            font-size: 1.2rem;

            font-family: Inter;
            font-weight: 500;
            padding-right: 2.67em;
            ${mq.sm} {
              font-size: 0.8rem;
            }

            &::before {
              content: "";
              position: absolute;
              height: 30px;
              width: 10px;
              top: 1;
              left: 0;
              background: #ffcb00;
              border-radius: 0 45px 45px 0;
            }
          `}
        >
          Hi {proposerName}, Please choose the way you wish to share your Life
          Insurance Quote Comparison.
        </Modal.Title>
        <CancelCircularIcon
          width="27"
          onClick={() => setShowPopup(false)}
          css={`
          position: absolute,
            right: -15px,
            top: -15px,
            ${mq.sm} {
              right: 1px;
              top: 2px;
            }
          `}
        />
      </Modal.Header>
      <Modal.Body>
        {
          <div
            css={`
              animation-duration: 0.9s;
              animation-name: fadein;

              @keyframes fadein {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
            `}
          >
            <ShareMailCompare />
            <WhatsAppShareCompare mobile={userData.mobile} />
            <ShareTextCompare />
            {/* <MoreOptionsButton onClick={toggleShow}>
              {showMore ? "Hide" : "View More Options"}
            </MoreOptionsButton> */}
          </div>
        }
        {/* {showMore && (
          <div>
            <QRCodeComp />
            <CopyToClipboardForm />
          </div>
        )} */}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default ShareCompareQuotes;
