import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import styled from "styled-components/macro";
import * as mq from "../../../../../../styles/mediaQueries";

function FilterModal({ show, setShow, head, Body, getMoneyAs, body }) {
  const handleClose = () => setShow(false);
  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement={"bottom"}
      css={`
        border-radius: 10px 10px 0px 0px;
        ${mq.md} {
          /* height: ${getMoneyAs ? "auto" : "467px"}; */
          height: auto;
        }
        /* ${mq.sm} {
          height: ${getMoneyAs ? "auto" : "367px"};
        } */
      `}
    >
      <Offcanvas.Header closeButton css={headerCss}>
        {head}
      </Offcanvas.Header>
      <OffcanvasBodyWrapper>
        {body ? body : <Body setShow={setShow} />}
      </OffcanvasBodyWrapper>
    </Offcanvas>
  );
}

const OffcanvasBodyWrapper = styled.div`
  width: 100%;
  overflow: auto;
  padding: 10px 10px;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;
const headerCss = `
height:55px;
padding:11px 15px;
font-size:16px;
font-family: Inter;
  font-weight: 600;
background-color: var(--primary-color-light);
border-radius:10px 10px 0px 0px;
${mq.md}{
  font-size:20px;
},
${mq.sm}{
  font-size:16px;
}


`;

export default FilterModal;
