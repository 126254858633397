import moment from "moment";

export const getAge = dob => {
  const date = moment(dob, "YYYY-MM-DD");
  const age = moment().diff(date, "year");
  return `${age}`;
};

export const getAgeValue = dob => {
  const date = moment(dob, "YYYY-MM-DD");
  const age = moment().diff(date, "year");
  return age;
};

export const getAge2 = dob => {
  const date = moment(dob, "DD/MM/YYYY");

  const age = moment().diff(date, "year");
  return age;
};
export const tobaccoHabit = tobacco_habit => {
  switch (tobacco_habit) {
    case "S":
      return "Smoker";
    case "NS":
    default:
      return "Non-Smoker";
  }
};
export const getGender = gender => {
  switch (gender) {
    case "F":
      return "Female";
    case "M":
    default:
      return "Male";
  }
};
export const getAnnualIncome = annualIncome => {
  const length = String(annualIncome).length;
  //100000
  if (length >= 8) {
    return `${+(
      Math.round(annualIncome / Math.pow(10, 7) + "e+2") + "e-2"
    )} Cr`;
  }
  if (length >= 6) {
    return `${+(
      Math.round(annualIncome / Math.pow(10, 5) + "e+2") + "e-2"
    )} Lacs`;
  }
  if (length >= 4) {
    return `${+(
      Math.round(annualIncome / Math.pow(10, 3) + "e+2") + "e-2"
    )} Th`;
  }
};
