import styled from "styled-components/macro";
// import { FaPlusCircle, FaCheckCircle } from "react-icons/fa";
import * as colors from "../../../../../styles/colors";
import * as mq from "../../../../../styles/mediaQueries";

import ButtonLoader from "../../../../../components/Loaders/ButtonLoader/ButtonLoader";
import { amount } from "../../../../../utils";
import RiderDescription from "../../../../RiderPageNew/RiderDescription";

function RiderCard({
  rider,
  isAdded,
  isHidden,
  onPremiumClick = () => {},
  children,
  isLoading,
  ...props
}) {
  const { rider_name, net_premium, rider_type } = rider;

  const handlePremiumClick = () => onPremiumClick(rider);

  return (
    <RiderCardWrap rider={rider} active={isAdded} {...props}>
      {isHidden ? <HideRider /> : null}
      <div
        css={`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={`
            font-family: "Inter";
            font-weight: 600;
            font-size: 16px;
            ${mq.sm} {
              font-size: 12px;
              width: 60%;
              font-weight: 700;
            }
          `}
        >
          {rider_name}
        </div>
        {rider_type !== "Free" ? (
          <RiderPremiumButton
            isAdded={isAdded}
            isLoading={isLoading}
            onPremiumClick={handlePremiumClick}
          >
            {amount(net_premium)}
          </RiderPremiumButton>
        ) : (
          <RiderPremiumButton isAdded={true} isLoading={isLoading} free={true}>
            {"Free"}
          </RiderPremiumButton>
        )}
      </div>
      <RiderDescriptionWrap>
        <RiderDescription rider={rider} />
      </RiderDescriptionWrap>
      <div>{children}</div>
    </RiderCardWrap>
  );
}

export default RiderCard;

function RiderPremiumButton({
  children,
  isLoading,
  isAdded,
  free,
  onPremiumClick,
  ...props
}) {
  return (
    <AddButton
      onClick={onPremiumClick}
      active={isAdded}
      disabled={isLoading || free}
      {...props}
      css={`
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        align-items: center;
        ${mq.sm} {
          width: 20%;
        }
        ${mq.xs} {
          width: calc(40% - 0.3em);
          height: auto;
        }
      `}
    >
      {isLoading ? (
        <ButtonLoader colored={!isAdded} />
      ) : (
        <>
          <span
            css={`
              display: flex;
              flex-direction: row;
              font-family: Inter;
              font-family: 600;
              font-size: 14px;
              justify-content: space-around;
              width: 100%;
              margin: auto;
              ${mq.sm} {
                font-size: 12px;
                font-weight: 700;
              }
            `}
          >
            {children}
            {isAdded ? (
              <div
                css={`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 25%;
                  height: 25px;
                  border-radius: 50%;
                  border: 1px solid #e6f3ff;
                  background-color: var(--primary-color);
                  ${mq.sm} {
                    width: 18%;
                    height: 18px;
                  }
                `}
                className="icon-container"
              >
                <i style={{ color: "#e6f3ff" }} className="fas fa-check" />
              </div>
            ) : (
              <div
                css={`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 25%;
                  height: 25px;
                  border-radius: 50%;
                  background-color: #e6f3ff;
                  ${mq.sm} {
                    width: 18%;
                    height: 20px;
                  }
                  ${mq.xs} {
                    width: 18%;
                    height: 18px;
                  }
                `}
                className="icon-container"
              >
                <i
                  style={{ color: "var(--primary-color)" }}
                  className="fas fa-plus"
                />
              </div>
            )}
          </span>
        </>
      )}
    </AddButton>
  );
}

const RiderDescriptionWrap = styled.div`
  color: #666;
  font-size: 14px;
  margin: 1em 0;
`;

const HideRider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff90;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

const RiderCardWrap = styled.div`
  box-shadow: 0 0 12px 0px #00000021;
  margin: 0;
  padding: 15px;
  border-radius: 0.3em;
  border: 1px solid transparent;
  border-color: ${props =>
    props.active ? "var(--primary-color)" : "transparent"};
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  ${mq.sm} {
  }
`;

const AddButton = styled.button`
  background-color: ${props =>
    props.active === true ? colors.primary : "#fff"};
  color: ${props => (props.active === true ? "#fff" : colors.primary)};
  display: flex;
  ${"" /* align-items: center; */}
  ${"" /* justify-content: space-around; */}
  width: 120px;
  height: 50px;
  padding: 10px 5px;
  border-radius: 0.16em;
  border: 1px solid ${colors.primary};

  & i {
    font-size: 11px;
    padding: auto;
    ${mq.sm} {
      font-size: 10px;
    }
  }
  ${mq.sm} {
    width: calc(40% - 0.3em);
    height: auto;
  }

  ${
    "" /* &:hover {
    background-color: #ffffff;
    color: ${colors.primary};
    border-color: ${colors.primary};
  } */
  }
`;
