import React, { useState } from "react";
import { useSelector } from "react-redux";
import CardSkeletonLoader from "../../../components/Loaders/CardSkeletonLoader/CardSkeletonLoader";
import { selectCompanies } from "../../frontendBoot/frontendBoot.slice";
import { useGetDifferentQuoteQuery } from "../../rider/services/rider.js";
import * as mq from "../../../styles/mediaQueries";
import QuoteCard from "../QuoteCard";

import { selectDifferentQuotes } from "../Quotes.slice";
import GetDetails from "./Details/GetDetails";
import { QuoteCardWrapper } from "./Quotes.style";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import QuotesCardMobile from "./Mobile/QuotesCardMobile";
import { useLocation } from "react-router";

const DifferentQuotes = props => {
  let companies = useSelector(selectCompanies);
  companies = Object.keys(companies || {});

  let filteredCompanies = companies;

  let quotes = useSelector(selectDifferentQuotes);

  if (Object.keys(quotes).length > 0) {
    quotes = Object.values(quotes).map(quotes => (quotes ? quotes[0] : null));

    const filteredQuotes = quotes
      .filter(quote => !!quote)
      .map(quote => quote.company_alias)
      .filter(company => filteredCompanies.includes(company));

    const restCompanies = filteredCompanies.filter(
      company => !filteredQuotes.includes(company),
    );

    filteredCompanies = [...filteredQuotes, ...restCompanies];
  }
  return (
    <div {...props}>
      {filteredCompanies.length > 0 &&
        filteredCompanies.map(company => (
          <Quote
            showAddOn={props.showAddOn}
            setShowAddOn={props.setShowAddOn}
            company_alias={company}
            key={company}
          />
        ))}
    </div>
  );
};

export default DifferentQuotes;

function Quote({ company_alias, quoteArr, ...props }) {
  const { ShortListedPlan } = useSelector(state => state?.quotes);
  const location = useLocation();
  const [selectedProductId, setSelectProductId] = useState();
  //   const [policyTermToShow, setPolicyTermToShow] = useState(
  //     parseInt(quoteArr[0]?.policy_term)
  //   );
  const matches = useMediaQuery(`${mq.mdForHook}`);
  const { isFilterUpdating } = useSelector(state => state.filters);

  const { newFilters } = useSelector(state => state.inputForm);
  const { showDetails } = useSelector(state => state?.quotes);
  const { isLoading, isFetching, isUninitialized, isError, data } =
    useGetDifferentQuoteQuery({
      company_alias,
      newFilters,
    });
  if (isError) return null;

  if (isUninitialized || isLoading || isFetching || isFilterUpdating)
    return <CardSkeletonLoader />;

  const { data: quotes } = data;

  if (quotes.length === 0) return null;

  const quote = quotes[0];
  if (
    location.pathname === "/shortlisted-plans" &&
    !ShortListedPlan?.some(el => el === quote?.product?.id)
  ) {
    return <></>;
  }
  if (!quote) return null;
  return (
    <>
      {matches ? (
        <>
          <QuotesCardMobile
            showAddOn={props.showAddOn}
            setShowAddOn={props.setShowAddOn}
            quoteData={{ ...quote, differentQuote: true }}
            onBuyNowClick={() => {
              setSelectProductId(quote?.product?.id);
            }}
            // hasMorePlans={hasMorePlans}
            // setShowMorePlan={setShowMorePlan}
            // showMorePlan={showMorePlan}
            // morePlanCount={keyArr?.length}
            policyTermToShow={quote?.policy_term}
            // setPolicyTermToShow={setPolicyTermToShow}
          />
          <GetDetails
            show={quote?.product?.id === selectedProductId && showDetails}
            company_alias={quote?.company_alias}
            quoteData={quote}
            setShow={() => setSelectProductId(false)}
          />
        </>
      ) : (
        <QuoteCardWrapper {...props}>
          <QuoteCard
            showAddOn={props.showAddOn}
            setShowAddOn={props.setShowAddOn}
            quoteData={{ ...quote, differentQuote: true }}
            onBuyNowClick={() => {
              setSelectProductId(quote?.product?.id);
            }}
            isQuoteLoading={isLoading || isFetching || isFilterUpdating}
            //   hasMorePlans={hasMorePlans}
            //   customColor={customColor}
            //   setShowMorePlan={setShowMorePlan}
            //   showMorePlan={showMorePlan}
            //   morePlanCount={keyArr?.length}
            policyTermToShow={quote?.policy_term}
            // setPolicyTermToShow={setPolicyTermToShow}
          />

          <GetDetails
            show={quote?.product?.id === selectedProductId && showDetails}
            company_alias={quote?.company_alias}
            quoteData={quote}
            setShow={() => setSelectProductId(false)}
          />
        </QuoteCardWrapper>
      )}
    </>
  );
}
