import { Box, Skeleton } from "@mui/material";
import React from "react";
import {
  ProductNameText,
  ShortListedPlanLast,
} from "../../../../pages/QuotesPage/components/ShortListQuotes/ShortListQuotes";
import "styled-components/macro";
const ShortlistCardLoader = () => {
  return (
    <ShortListedPlanLast>
      <div className="splantop-section">
        <div
          css={`
            width: 37%;
          `}
        >
          <Skeleton variant="rectangular" width="100%" height={40} />
        </div>
        <ProductNameText>
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
        </ProductNameText>
      </div>
      <div className="splantop-section">
        <div
          css={`
            width: 48%;
          `}
        >
          <Skeleton variant="text" width="100%" />
        </div>
        <div
          css={`
            width: 48%;
          `}
        >
          <Skeleton variant="text" width="100%" />
        </div>
      </div>
    </ShortListedPlanLast>
  );
};
export default ShortlistCardLoader;
