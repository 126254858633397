import {
  Box,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from "@mui/material";
import premium from "../../../assets/images/premium.png";
import alarm from "../../../assets/images/alarm.png";
import information from "../../../assets/images/information.png";
import investment from "../../../assets/images/investment.png";
import frequencyIcon from "../../../assets/images/frequency.png";
import irr from "../../../assets/images/irr.png";
import incomeBenefits from "../../../assets/images/income-benefits.png";
import wallet from "../../../assets/images/wallet.png";
import { useDispatch, useSelector } from "react-redux";
import CompareTableQuote from "./CompareTableQuote";
import styled from "styled-components";
import GetDetails from "../../QuotesPage/components/Details/GetDetails";
import { useState } from "react";
import { setSelectedQuoteToBuy } from "../slice/newCompare.slice";
import CompareTableMobileQuote from "./CompareTableMobileQuote";
import { md } from "../../../styles/mediaQueries";

const CompareTable = ({ isMobileOrTablet }) => {
  const { quotesList, selectedQuoteToBuy } = useSelector(
    state => state?.newCompare,
  );
  const { showDetails } = useSelector(state => state?.quotes);
  const [showGetDetailsPopup, setShowGetDetailsPopup] = useState(false);
  const dispatch = useDispatch();
  return (
    <div style={{ padding: 0, marginTop: "1.5rem" }}>
      <Box>
        <TableContainerStyled>
          <TableStyled aria-label="simple table">
            {!isMobileOrTablet && (
              <TableHeadStyled>
                <TableCellStyled>
                  <TableCellStackStyled
                    spacing={1}
                    sx={{ paddingRight: "5rem" }}
                  >
                    <img
                      src={information}
                      alt="information"
                      width={20}
                      height={20}
                    />
                    <TypographyStyled
                      variant="body2"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      Product Details
                    </TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
                <TableCellStyled>
                  <TableCellStackStyled spacing={1}>
                    <img
                      src={investment}
                      alt="investment"
                      width={20}
                      height={20}
                    />
                    <TypographyStyled variant="body2">
                      Investment Amount
                    </TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
                <TableCellStyled>
                  <TableCellStackStyled spacing={1}>
                    <img
                      src={frequencyIcon}
                      alt="frequency Icon"
                      width={20}
                      height={20}
                    />
                    <TypographyStyled variant="body2">
                      Frequency
                    </TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
                <TableCellStyled>
                  <TableCellStackStyled spacing={1}>
                    <img src={wallet} alt="wallet" width={20} height={20} />
                    <TypographyStyled
                      variant="body2"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      Invest For
                    </TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
                <TableCellStyled>
                  <TableCellStackStyled spacing={1}>
                    <img src={alarm} alt="alarm" width={20} height={20} />
                    <TypographyStyled
                      variant="body2"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      Returns After
                    </TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
                <TableCellStyled>
                  <TableCellStackStyled spacing={1}>
                    <img
                      src={incomeBenefits}
                      alt="income-benefits"
                      width={20}
                      height={20}
                    />
                    <TypographyStyled variant="body2">
                      Income /<br /> Lumpsum Benefits
                    </TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
                <TableCellStyled>
                  <TableCellStackStyled spacing={1}>
                    <img src={irr} alt="irr" width={20} height={20} />
                    <TypographyStyled variant="body2">
                      IRR (Internal <br />
                      Rate of Returns)
                    </TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
                <TableCellStyled>
                  <TableCellStackStyled spacing={1}>
                    <img src={premium} alt="premium" width={20} height={20} />
                    <TypographyStyled variant="body2">Premium</TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
              </TableHeadStyled>
            )}
            <TableBodyStyled>
              {isMobileOrTablet
                ? quotesList.map((quote, i) => {
                    return (
                      <CompareTableMobileQuote
                        quote={quote}
                        key={i}
                        i={i}
                        setShowGetDetailsPopup={setShowGetDetailsPopup}
                      />
                    );
                  })
                : quotesList.map((quote, i) => {
                    return (
                      <CompareTableQuote
                        quote={quote}
                        key={i}
                        i={i}
                        setShowGetDetailsPopup={setShowGetDetailsPopup}
                      />
                    );
                  })}
            </TableBodyStyled>
          </TableStyled>
        </TableContainerStyled>
        <GetDetails
          show={showGetDetailsPopup && showDetails}
          company_alias={selectedQuoteToBuy?.company_alias}
          quoteData={selectedQuoteToBuy}
          setShow={() => {
            setShowGetDetailsPopup(false);
            dispatch(setSelectedQuoteToBuy({}));
          }}
        />
      </Box>
    </div>
  );
};

export default CompareTable;

const TableContainerStyled = styled(TableContainer)`
  border: none !important;
  ${md} {
    box-shadow: none !important;
  }
  box-shadow: 6px 12px 20px #e7f0f7 !important;
`;
const TableStyled = styled(Table)`
  border: none !important;
`;
const TableHeadStyled = styled(TableHead)`
  background-color: #ebf2f6 !important;
  border-radius: 10px !important;
`;
const TableBodyStyled = styled(TableBody)`
  justify-content: center !important;
`;
const TableCellStyled = styled(TableCell)`
  border: none !important;
  padding: 0.25rem 1rem !important;
  font-size: 0.8rem !important;
`;
const TableCellStackStyled = styled(Stack)`
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
  line-height: 0.5rem !important;
`;
const TypographyStyled = styled(Typography)`
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  color: #333 !important;
  margin-left: 10px !important;
  margin-bottom: 5px !important;
`;
