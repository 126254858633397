import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import { riderApi } from "../pages/rider/services/rider";
import logger from "redux-logger";
import {
  persistStore,
  persistReducer,
  FlUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const middleware = [...getDefaultMiddleware(), riderApi.middleware];

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware,
});

export { store };