import React from "react";
import "styled-components/macro";
import styled from "styled-components";
import { Button } from "../../Landing.style";
import * as mq from "../../../../styles/mediaQueries";
import parse from "html-react-parser";
function Progress({ contentToDisplay, ...props }) {
  return (
    <div
      css={`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.3em;
        margin-top: 12px;
        margin-bottom: 20px;
      `}
      {...props}
    >
      <Dot state={contentToDisplay >= 0 ? "past" : "future"} />
      <Line state={contentToDisplay >= 1 ? "past" : "future"} />
      <Dot state={contentToDisplay >= 1 ? "past" : "future"} />
      <Line state={contentToDisplay >= 2 ? "past" : "future"} />
      <Dot state={contentToDisplay >= 2 ? "past" : "future"} />
      <Line state={contentToDisplay >= 3 ? "past" : "future"} />
      <Dot state={contentToDisplay >= 3 ? "past" : "future"} />
    </div>
  );
}

const PROGRESS_STATE_COLORS = {
  past: "var(--primary-color)",
  present: "white",
  future: "#dedede",
};

const Dot = styled.div`
  height: 0.5em;
  width: 0.5em;
  border-radius: 50%;
  background-color: ${({ state = "present" }) => PROGRESS_STATE_COLORS[state]};
`;

const Line = styled.div`
  height: 0.2em;
  width: 3em;
  border-radius: 1em;
  background-color: ${({ state = "present" }) => PROGRESS_STATE_COLORS[state]};
`;

const FormWithTitle = ({ children, title, onSubmit, current, showButton }) => {
  return (
    <>
      <form
        noValidate
        onSubmit={onSubmit}
        css={`
          width: 100%;
        `}
      >
        <div
          css={`
            padding: 17px;
            width: 100%;
            & h1 {
              font-size: 24px;
              font-weight: 900;
              color: rgb(74, 89, 113);
              ${mq.sm} {
                font-size: 18px;
              }
            }
          `}
          // css={`
          //   padding: 17px;
          //   width: 100%;
          //   ${mq.sm} {
          //     padding: 0px;
          //   }
          // `}
        >
          {parse(title)}

          <Progress contentToDisplay={current} />
          <div>{children}</div>
        </div>
        {showButton && (
          <div
            css={`
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              // margin-bottom: 2em;
              padding: 0px;

              ${mq.sm} {
              }
            `}
          >
            <Button type="submit">
              Get Started
              {/* <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="none"
                  stroke-linecap="square"
                  stroke-miterlimit="10"
                  stroke-width="48"
                  d="M268 112l144 144-144 144m124-144H100"
                ></path>
              </svg> */}
            </Button>
          </div>
        )}
      </form>
    </>
  );
};

export default FormWithTitle;
