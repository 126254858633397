import styled from "styled-components/macro";
import { BsPlusCircle } from "react-icons/bs";
import { AddPlanPopup } from "./AddPlanPopup";
import { amount } from "../../../utils/index";
import * as mq from "../../../styles/mediaQueries";
// import { usePlanDetails } from '../../rider/component/PlanCard/planCard.util';
import { FaTimes } from "react-icons/fa";
import { useState } from "react";
import { images } from "../../../assets/images/logo/logo";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import {
  useGetCompareProductsQuery,
  useGetDifferentQuoteQuery,
  useGetQuoteQuery,
  usePutCompareProductsMutation,
} from "../../rider/services/rider";
import { useEffect } from "react";
import { ComparePageLoader } from "../../../components/Loaders/CardSkeletonLoader/ComparePageLoader/ComparePageLoader";
import { selectICLogo } from "../../frontendBoot/frontendBoot.slice";
import {
  fetchKnowMoreData,
  setSelectedQuote,
} from "../../QuotesPage/Quotes.slice";
import { updateEnquiries } from "../../Landing/input.slice";
import GetDetails from "../../QuotesPage/components/Details/GetDetails";

function ProductCard({ product, ...props }) {
  const matches = useMediaQuery(mq.mdForHook);
  const { product_id, company_alias } = product;
  const { newFilters } = useSelector(state => state.inputForm);
  const isDifferent = product.different_quote === "Y";
  const { data, isLoading, isFetching } = useGetQuoteQuery(
    { company_alias, newFilters },
    {
      skip: isDifferent,
    },
  );
  const { data: different_data, isLoading: isDifferentQuoteLoading } =
    useGetDifferentQuoteQuery(
      { company_alias, newFilters },
      {
        skip: !isDifferent,
      },
    );
  const { data: compareProductsData } = useGetCompareProductsQuery();
  const [updateCompareQuotes] = usePutCompareProductsMutation();

  const quotes = isDifferent
    ? different_data?.data === undefined
      ? []
      : different_data?.data?.filter(item => item?.product.id === product_id)
    : data?.data === undefined
    ? []
    : data?.data?.filter(item => item?.product.id === product_id);
  const quote = quotes[0];

  const { products } = compareProductsData.data;

  useEffect(() => {
    if (!quote && !isLoading && !isDifferentQuoteLoading) {
      const updatedCompareQuotes = products.filter(
        product => product.product_id !== product_id,
      );

      updateCompareQuotes({ products: updatedCompareQuotes });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote]);

  useEffect(() => {
    if (products.length >= 3 && matches) {
      const updatedCompareQuotes = products.filter(
        (product, index) => index < products.length - 1,
      );
      updateCompareQuotes({ products: updatedCompareQuotes });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);
  if (isLoading)
    return (
      <ProductCardContainer>
        <ComparePageLoader />
      </ProductCardContainer>
    );

  if (!quote) return null;

  return (
    <ProductCardContainer {...props}>
      {isDifferent && (
        <div
          css={`
            position: absolute;
            background: #fff;
            border: 2px solid red;
            border-radius: 50%;
            padding: 0px 6px;
            top: 12px;
            left: 20px;
            color: red;
            font-size: 16px;
            z-index: 11;
          `}
          title="Different Quote"
        >
          <i className="fa fa-not-equal"></i>
        </div>
      )}
      <ProductCardDetails
        quote={quote}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </ProductCardContainer>
  );
}

function ProductCardDetails({ quote, ...props }) {
  const {
    company_alias,
    total_premium,
    investment_insurance_id,
    product: { name, id: product_id },
  } = quote;

  const dispatch = useDispatch();
  const logoSrcUrl = useSelector(selectICLogo(quote?.company_alias));
  const logoURL = images[company_alias] || logoSrcUrl;
  const { data: compareProductsData } = useGetCompareProductsQuery();
  const [updateCompareQuotes] = usePutCompareProductsMutation();
  const { products } = compareProductsData.data;

  const removeQuoteFromCompare = () => {
    const updatedCompareQuotes = products.filter(
      product => product.product_id !== product_id,
    );
    updateCompareQuotes({ products: updatedCompareQuotes });
  };

  const [selectedProductId, setSelectProductId] = useState();
  // eslint-disable-next-line no-unused-vars
  const [policyTermToShow, setPolicyTermToShow] = useState(quote?.policy_term);
  // eslint-disable-next-line no-unused-vars
  const [selectedProduct, setSelectedProduct] = useState({
    investment_insurance_id: investment_insurance_id,
    product_id,
  });

  const handleGetDetails = () => {
    dispatch(setSelectedQuote(quote));
    dispatch(
      updateEnquiries({
        input: {
          selected_product: selectedProduct,
          policy_term: policyTermToShow,
        },
      }),
    );
    dispatch(fetchKnowMoreData(product_id));
    setSelectProductId(product_id);
  };

  return (
    <>
      <ProductCardWrap {...props}>
        <CloseButtonComp onClick={removeQuoteFromCompare} />
        <LogoWrap>
          <Logo src={logoURL} alt="insurance-company" />
        </LogoWrap>
        <ProductName>{name}</ProductName>
        <PremiumButton onClick={handleGetDetails}>
          {amount(total_premium)}
          {<Frequency>Yearly </Frequency>}
        </PremiumButton>
      </ProductCardWrap>
      <GetDetails
        show={product_id === selectedProductId}
        company_alias={company_alias}
        quoteData={quote}
        setShow={() => setSelectProductId(false)}
      />
    </>
  );
}

function SelectProductCard({ ...props }) {
  const [addPlanPopup, setAddPlanPopup] = useState(false);

  const showAddPlanPopup = () => setAddPlanPopup(true);
  const hideAddPlanPopup = () => setAddPlanPopup(false);

  return (
    <ProductCardContainer {...props} dashed>
      <button
        css={`
          background: none;
          border: none;
          color: var(--primary-color);
          height: 100%;
          width: 100%;
          display: block;
        `}
        onClick={showAddPlanPopup}
      >
        <ProductCardWrap
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <div
            css={`
              font-size: 3.5rem;
              ${mq.lg} {
                font-size: 3rem;
              }
            `}
          >
            <BsPlusCircle />
          </div>
          <div
            css={`
              font-size: 1rem;
              font-family: Inter;
              font-weight: 500;
              ${mq.lg} {
                font-size: 0.9rem;
              }
            `}
          >
            Add Plan
          </div>
        </ProductCardWrap>
      </button>
      <AddPlanPopup show={addPlanPopup} onClose={hideAddPlanPopup} />
    </ProductCardContainer>
  );
}

function CloseButtonComp({ css = ``, ...props }) {
  return (
    <button
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        position: "absolute",
        border: "none",
        top: "-5px",
        right: "-5px",
        zIndex: 10,
        color: "rgb(80, 80, 80)",
        backgroundColor: "rgb(255, 255, 255)",
      }}
      css={`
        // position: absolute;
        // right: 0px;
        // top: 0;
        // background-color: transparent;
        // height: 1.2em;
        // color: rgb(80, 80, 80);
        // width: 1.2em;
        // font-size: 1.2rem;
        // border-radius: 50%;
        // border: 1px solid rgb(80, 80, 80);
        // display: flex;
        // align-items: center;
        // justify-content: center;

        // ${mq.lg} {
        //   height: 1.1em;
        //   width: 1.1em;
        //   font-size: 1.15rem;
        // }
        // ${mq.md} {
        //   height: 1.2em;
        //   width: 1.2em;
        //   font-size: 1.15rem;
        // }
        ${mq.sm} {
          // height: auto;
          // width: 1em;
          // font-size: 1.1rem;
          // position: absolute !important;
          // right: 0;
          // top: 0;
        }

        ${css};
      `}
      {...props}
    >
      <FaTimes />
    </button>
  );
}

export { ProductCard, SelectProductCard };

export const ProductCardContainer = styled.div`
  border: ${props =>
    props.dashed
      ? "2px dashed rgb(10, 135, 255,0.3);"
      : "1px solid rgb(10, 135, 255,0.3);"};
  border-radius: 10px;
  padding: 1em;
  position: relative;
  ${mq.lg} {
    padding: 0.8em;
  }
  ${mq.md} {
    margin: 15px 5px;
    height: 155px;
    padding: 1em 0.7em;
  }
`;

export const ProductCardWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const LogoWrap = styled.div``;

const Frequency = styled.span`
  font-size: 13px;

  margin-left: 5px;
`;

const Logo = styled.img`
  max-height: 3.3em;
  ${mq.lg} {
    max-height: 3.2em;
  }
  ${mq.md} {
    max-height: 3.2em;
  }
  ${mq.sm} {
    max-height: 2.3em;
  }
`;

const ProductName = styled.p`
  font-size: 12px;
  color: var(--secondary-text);
  text-align: center;
  margin: 0;
  font-family: "Inter";
  font-weight: 600;
  ${mq.lg} {
    font-size: 11.6px;
  }
  ${mq.md} {
    font-size: 12px;
  }
  ${mq.sm} {
    font-size: 11px;
    line-height: 1.2;
  }
`;

export const PremiumButton = styled.button`
  background: none;
  border: none;
  background-color: var(--primary-color);
  color: #fff;
  font-family: "Inter";
  font-weight: 600;
  border-radius: 6px;
  height: 2.3em;

  width: 100%;
  font-size: 1.1rem;
  ${mq.lg} {
    height: 2.2em;
    font-size: 0.87rem;
  }

  ${mq.md} {
    width: 90%;
    border-radius: 3px;
    padding: 6px;
    font-size: 0.8rem;
    max-height: 2.7em;
  }
  ${mq.sm} {
    width: 100%;
    padding: 5px;
    max-height: 2.5em;
  }
`;
