import { styled } from "@material-ui/core";
import { TextField } from "@mui/material";
import React from "react";

// const useStyle = makeStyles({
//   root: {
//     backgroundColor: " rgb(255, 255, 255)",
//     border: "1px solid rgb(207, 207, 207)",
//   },
// });

const TextInput = React.forwardRef(
    ({ label, value, error, capitalize, defaultValue, ...props }, ref) => {
        //   const classes = useStyle();
        return (
            <>
                <CustomField
                    ref={ref}
                    label={label}
                    variant={"outlined"}
                    value={value || ""}
                    fullWidth
                    defaultValue={defaultValue || ""}
                    capitalize={capitalize}
                    error={Boolean(error)}
                    helperText={error}
                    {...props}
                />
            </>
        );
    },
);

export default TextInput;
const CustomField = styled(TextField)({
    minHeight: "65px",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
    },
    "& input[type=number]": {
        MozAppearance: "textfield",
    },

    "& .MuiOutlinedInput-input": {
        padding: "0px 14px",
        backgroundColor: "rgb(255, 255, 255)",
        textTransform: prop => prop.capitalize && "capitalize",
        height: "65px",
    },
    "& label.Mui-focused, & label.MuiFormLabel-filled, & label.Mui-focused.Mui-error":
    {
        transform: "translate(14px, -13px) scale(0.75)",
        color: "var(--primary-color)",
    },
    "& label.Mui-error": {
        color: "rgba(0, 0, 0, 0.6)",
    },
    "& .MuiInputLabel-root": {
        top: "6px",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "4px",
        height: "65px",

        "&.Mui-focused fieldset": {
            borderColor: "var(--primary-color)",
        },
        "&.Mui-focused.Mui-error fieldset": {
            borderColor: "#d32f2f",
        },
    },
});
