import { createSlice } from "@reduxjs/toolkit";

const compareQuotes = createSlice({
  name: "compareQuotes",
  initialState: {
    compareQuotes: [],
    compareData: {},
    downloading: false,
  },

  reducers: {
    addQuote: (state, { payload }) => {
      state.compareQuotes = [...state.compareQuotes, payload];
    },
    removeQuote: (state, { payload }) => {
      state.compareQuotes = state.compareQuotes.filter(
        quote => quote.product.id !== payload.product.id,
      );
    },
    clearQuotes: state => {
      state.compareQuotes = [];
    },
  },
});

export const { addQuote, removeQuote, clearQuotes } = compareQuotes.actions;

export default compareQuotes.reducer;
export const selectCompareQuotes = state => state.compareQuotes.compareQuotes;
