import React, { useState } from "react";
import useGetFilterOptions from "../../../../../../../custom-hooks/useGetFilterOptions";
import { useSelector } from "react-redux";
import useSetFilter from "../../../../../../../custom-hooks/useSetFilter";
import { CustomButton, CustomGroup } from "./customButton.styled";

function IncomeFor({ setShow }) {
  const [incomeForValue, setIncomeForValue] = useState(
    useSelector(state => state.filters.incomeFor),
  );
  const incomeForOptions = useGetFilterOptions({ filter: "income_for" });
  useSetFilter({ filter: "incomeFor", value: incomeForValue });
  return (
    <>
      <CustomGroup 
        color="primary"
        value={incomeForValue}
        exclusive
        onChange={e => {
          setIncomeForValue(e.target.value);
          setShow(false);
        }}
      >
        {incomeForOptions.length > 0 &&
          incomeForOptions.map((item, index) => (
            <CustomButton key={index} value={item.code}>
              {item.display_name}
            </CustomButton>
          ))}
      </CustomGroup>
    </>
  );
}




export default IncomeFor;
