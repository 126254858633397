import { useEffect } from "react";
import { useSelector } from "react-redux";

function useGetPayFreqWord() {
  let payFrequency = useSelector(state => state.filters.payFrequency);
  function update() {
    if (payFrequency === "0") return "yearly";
    if (payFrequency === "1") return "half yearly";
    if (payFrequency === "2") return "quarterly";
    if (payFrequency === "3") return "monthly";
    return "yearly";
  }
  let temp = update();

  useEffect(() => {
    temp = update();
  }, [payFrequency]);

  return temp;
}

export default useGetPayFreqWord;
