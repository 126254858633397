import styled from "styled-components/macro";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import * as mq from "../../styles/mediaQueries";
import Filters from "../Filters/Filters";
import CompareFeaturesSections from "./components/CompareFeaturesSections";
import CompareHeader from "./components/CompareHeader";
import ProductCards from "./components/ProductCards";
import mainImage from "../../assets/images/main-background.png";
import { Main } from "../QuotesPage/QuotesPage";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { clearQuotes } from "./ComparePlanPopupSlice";
import CompareFiltersMobile from "./mobile/CompareFiltersMobile";
function ComparePage() {
  const matches = useMediaQuery(mq.mdForHook);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearQuotes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Main
      css={`
        background: url(${mainImage});
        background-repeat: no-repeat;
      `}
    >
      <StyledContainer>
        <div style={{ marginTop: "1em" }}>
          {matches ? (
            <CompareFiltersMobile />
          ) : (
            <Filters isComparePage={true} />
          )}
        </div>
        {!matches && <CompareHeader />}
        <ComparePageMain />
      </StyledContainer>
    </Main>
  );
}

export default ComparePage;

const StyledContainer = styled.div`
  max-width: 1170px;
  width: 90%;
  margin: auto;
  ${mq.lg} {
    width: 93%;
  }
  ${mq.md} {
    width: 100%;
    margin: 0px 15px;
  }
  ${mq.sm} {
    width: 100%;
    margin: 0px 10px;
  }
`;

function ComparePageMain({ ...props }) {
  return (
    <div
      id="compare-page-main"
      css={`
        margin-bottom: 3em;
        height: auto;
        ${mq.lg} {
          margin-top: 10px;
        }
        ${mq.md} {
          width: 100%;
          margin-top: 15px;
          margin-bottom: 5em;
        }
      `}
      {...props}
    >
      <ProductCards />
      <CompareFeaturesSections />
    </div>
  );
}
