import styled from "styled-components";
import * as mq from "../../styles/mediaQueries";
export const Header = styled.p`
  font-size: 12px;
  line-height: 1.3;
  margin-bottom: 5px;
  color: black;
  padding-left: 10px;
  border-left: 6px solid #ffcb00;
`;

export const Description = styled.p`
  min-width: 290px;
  font-size: 10px;
  line-height: 1.3;
  padding-left: 16px;
  color: gray;
`;

export const SelectLabel = styled.p`
  font-size: 0.76rem;
  color: var(--primary-color);
  margin: 0;
`;

export const AddButton = styled.button`
  text-transform: capitalize;
  line-height: 21px;
  display: flex;
  text-align: left;
  font-size: 100%;
  border: 1px solid var(--primary-color);
  box-shadow: 0px 10px 25px 0px rgb(123 147 171 / 15%);
  border-radius: 3px;
  padding: 0px;
  background: ${props =>
    props.active === false ? "#fff" : "var(--primary-color)"};
  color: ${props => (props.active === false ? "var(--primary-color)" : "#fff")};
  min-width: 135px !important;
  height: 55px;
  padding-left: 0px;
  justify-content: space-evenly;
  align-items: center;
  transform: translateZ(0);
  &:hover {
    ${"" /* color: var(--primary-color) !important; */}
    border: 1px solid var(--primary-color);
    &:before {
      color: var(--primary-color) !important;
    }
  }
  ${mq.sm} {
    min-width: 95px !important;
    height: 40px;
  }
`;

export const Outer = styled.div`
  box-shadow: 0px -3px 20px rgb(134 156 213 / 25%);
  padding: 10px 0px 10px 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border: 1px solid
    ${({ riderAdded }) => (riderAdded ? "var(--primary-color)" : "transparent")};
  &:hover {
    border: 1px solid var(--primary-color);
  }
`;

export const Heading = styled.div`
  width: ${props => (props.header === "Life Stage Benefit" ? "100%" : "300px")};
`;

export const Filter = styled.div`
  flex: 1;
  border: 1px solid var(--tertiary-shade);
  border-radius: 0.3em;
  padding: 0.3em 0.6em;
`;
