import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";
import styled from "styled-components/macro";
import ButtonLoader from "../../../components/Loaders/ButtonLoader/ButtonLoader";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";
import * as mq from "../../../styles/mediaQueries";
import { amount } from "../../../utils";
import { findFrequency, redirectToIc } from "../../../utils/helper";
import { useFrontendData } from "../../frontendBoot/useFrontendData";
import { generateBI } from "../../ProposalPage/serviceApi";
import {
  riderApi,
  useGetCartQuery,
  useGetRedirectionMutation,
} from "../../rider/services/rider";
import { ContinueButton } from "./PlanCard.style";
import { usePlanCard } from "./planCard.util";
import { clearBiLoading, initBiLoading } from "../../cart/cart.slice";
import { setRedirectionTriggered } from "../../ProposalPage/ProposalPage.slice";

function RiderNewPlanCard({ hideBuy, loader, load }) {
  const { selectedProduct, totalPremium, premiumLoading } = usePlanCard();
  let disabledButton = selectedProduct?.term_riders
    ?.map(item => item.premium === "0" || load === true || loader === true)
    .includes(true);
  return (
    <div>
      <PlanCardWrap>
        <Header selectedProduct={selectedProduct} />
        <PlanInfoWrap>
          <PlanDetails selectedProduct={selectedProduct} />
          <PremiumDetails />
          {/* {selectedProduct.product.company && (
            <InbuiltBenefits selectedProduct={selectedProduct} />
          )} */}
          <TotalPremium
            totalPremium={totalPremium}
            premiumLoading={premiumLoading}
          />
          {!hideBuy && <ContinueToBuy btn={disabledButton} />}
        </PlanInfoWrap>
      </PlanCardWrap>
    </div>
  );
}

export default RiderNewPlanCard;
const PlanCardWrap = styled.div`
  box-shadow: 0 0 12px 0px #00000021;
  width: calc(100% - 1em);
  font-size: 0.76rem;
  position: sticky;
  top: 0;

  ${mq.sm} {
    width: 100%;
    box-shadow: none;
    position: unset;
    top: unset;
  }
`;

const PlanInfoWrap = styled.div`
  padding: 15px;
`;

function Header({ selectedProduct }) {
  const { getCompanyLogo } = useFrontendData();
  const product = selectedProduct.product;
  const alias = product?.company?.alias;
  const name = product?.name;
  const logoSrc = getCompanyLogo(alias);
  const [planName, planVariant, planVariant2] = name?.split("-") || [
    "",
    "",
    "",
  ];

  return (
    <HeaderWrap>
      <CompanyLogoWrap>
        {logoSrc ? (
          <CompanyLogo src={logoSrc} alt="m" />
        ) : (
          <Skeleton variant="rect" height="60px" width="8.3rem" />
        )}
      </CompanyLogoWrap>
      <PlanDetailsWrap>
        <div
          css={`
            color: #333;
            margin-bottom: 0.3em;
            font-family: "Inter";
            font-weight: 600;
          `}
        >
          {planName || (
            <Skeleton
              variant="text"
              width="100px"
              sx={{
                mx: "auto",
              }}
            />
          )}
        </div>
        <div
          css={`
            color: #666;
            margin-bottom: 0.3em;
          `}
        >
          {planVariant ? (
            planVariant + " " + (planVariant2 ? planVariant2 : "")
          ) : (
            <Skeleton
              variant="text"
              width="60px"
              sx={{
                mx: "auto",
              }}
            />
          )}
        </div>
        <DownloadBrochure />
      </PlanDetailsWrap>
    </HeaderWrap>
  );
}

const HeaderWrap = styled.div`
  background-color: var(--tertiary-shade);
  padding: 1em;
  display: flex;
  border-bottom: 1px solid #dedeff;
  ${mq.sm} {
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
  }
`;

const CompanyLogoWrap = styled.div`
  max-width: 8.3em;
`;

const CompanyLogo = styled.img`
  width: 100%;
`;

const PlanDetailsWrap = styled.div`
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

function PlanDetails({ props, selectedProduct }) {
  // const { coverAmount, coverUpto, paymentOption, payFrequency, isLoading } =
  //   usePlanDetails();
  const { data: cartData, isLoading } = useGetCartQuery();
  const filterData = useSelector(state => state.filters);

  const { getMoneyAs } = filterData;

  return (
    <DetailsWrap {...props}>
      <DetailTitle>Plan Details</DetailTitle>
      <div>
        <DetailRow
          label="Investment Amount"
          value={cartData?.investment_amount}
          isLoading={isLoading}
        />
        <DetailRow
          label="Invest For "
          year="years"
          value={cartData?.pay_term}
          isLoading={isLoading}
        />
        {getMoneyAs === "L" ? (
          <DetailRow
            label="Guaranteed Returns After"
            year="years"
            value={cartData?.policy_term}
            // value={selectedQuote?.policy_term}
            isLoading={isLoading}
          />
        ) : (
          <DetailRow
            label="Income For"
            year={cartData?.income_for > 100 ? "" : "years"}
            value={
              cartData?.income_for > 100
                ? "Till Death"
                : `${cartData?.income_for}`
            }
            // value={selectedQuote?.income_for}
            isLoading={isLoading}
          />
        )}

        <DetailRow
          label="Pay Frequency"
          value={findFrequency(cartData?.frequency)}
          isLoading={isLoading}
        />
      </div>
    </DetailsWrap>
  );
}

// function InbuiltBenefits({ selectedProduct, ...props }) {
//   const {
//     company: { alias },
//   } = selectedProduct.product;
//   const { newFilters } = useSelector(state => state.inputForm);
//   const { isLoading, data } = useGetQuoteQuery({
//     company_alias: alias,
//     differentQuote: false,
//     newFilters,
//   });

//   if (isLoading) return <p>Checking inbuilt riders...</p>;

//   if (!data.data[0]) return null;

//   const { riders_list } = data.data[0];

//   const inbuiltRiders = riders_list.filter(
//     rider => rider.rider_type === "Free",
//   );

//   if (!inbuiltRiders.length) return null;

//   return (
//     <DetailsWrap {...props}>
//       <DetailTitle>Inbuilt Benefits</DetailTitle>
//       <div>
//         {inbuiltRiders.map(rider => (
//           <DetailRow label={rider.rider_name} />
//         ))}
//       </div>
//     </DetailsWrap>
//   );
// }

function DetailRow({ label, value, isLoading, year, Rs }) {
  return (
    <DetailRowWrap>
      <div
        css={`
          width: 70%;
          color: #666;
        `}
      >
        {label}
      </div>
      {isLoading ? (
        <Skeleton variant="text" width="100px" />
      ) : (
        <div
          css={`
            color: #333;
            text-transform: capitalize;
            ${mq.sm} {
              font-weight: 600;
              font-size: 1em;
            }
          `}
        >
          {Rs} {value} {year}
        </div>
      )}
    </DetailRowWrap>
  );
}

function DownloadBrochure() {
  const { data: cartData, isLoading: isCartLoading } = useGetCartQuery();

  if (isCartLoading) return <p>Loading brochure...</p>;

  const { brochure_url } = cartData || {};

  return (
    <a href={brochure_url} target="_blank" rel="noreferrer">
      <div
        css={`
          color: #333;
          text-decoration: underline;
          cursor: pointer;
        `}
      >
        Download Brochure
      </div>
    </a>
  );
}

function PremiumDetails(props) {
  const { ridersAdded } = usePlanCard();

  const { data: cartData, isLoading: isCartLoading } = useGetCartQuery();

  return (
    <DetailsWrap {...props}>
      <DetailTitle>Premium Details</DetailTitle>
      <div>
        <DetailRow
          label="Investment Premium"
          value={`₹ ${cartData?.investment_amount}`}
          isLoading={isCartLoading}
        />
        {ridersAdded.map(rider => (
          <RiderRow rider={rider} />
        ))}
        <DetailRow
          label={"Goods & Service Tax"}
          value={cartData?.service_tax}
          Rs="₹ "
          isLoading={isCartLoading}
        />
        {/* <PlanTotalPay>
          <div>Total Premium Payable</div>
          <div>₹ {cartData?.total_premium}</div>
        </PlanTotalPay> */}
      </div>
    </DetailsWrap>
  );
}

function RiderRow({ rider }) {
  return <DetailRow label={rider.name} value={amount(rider.premium)} />;
}

const DetailsWrap = styled.div`
  margin-bottom: 1em;
`;

const DetailTitle = styled.h2`
  font-size: 1rem;
  color: var(--primary-color);
  border-bottom: 1px solid #dedeff;
  padding-bottom: 0.79em;
`;

const DetailRowWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.6em;
`;
// const PlanTotalPay = styled.div`
//   background: var(--tertiary-shade);
//   padding: 14px;
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
// `;

function TotalPremium({ totalPremium, premiumLoading }) {
  const { data: cartData } = useGetCartQuery();
  return (
    <TotalPremiumWrap>
      <div
        css={`
          color: #666;
        `}
      >
        Total Premium Payable
      </div>
      {premiumLoading ? (
        <Skeleton variant="text" width="60px" />
      ) : (
        <div>{`${amount(totalPremium)} - ${findFrequency(
          cartData?.frequency,
        )}`}</div>
      )}
    </TotalPremiumWrap>
  );
}

const TotalPremiumWrap = styled.div`
  background-color: var(--tertiary-shade);
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
`;

export const getBi = company_alias => {
  return async (dispatch, getState) => {
    try {
      dispatch(initBiLoading());
      let flscodedetails = getState().proposalForm?.distributor_summary;
      let url = "";

      const {
        data: { data },
      } = await generateBI({ company_alias, flscodedetails });

      url = data[0].bi_url;

      return url;
    } catch (err) {
    } finally {
      dispatch(clearBiLoading());
    }
  };
};

export function ContinueToBuy({ btn }) {
  const navigate = useNavigate();

  const enquiryId = useUrlQuery("enquiryId");

  const [getRedirection, { isLoading, status: redirectionApiStatus }] =
    useGetRedirectionMutation();
  const { biLoading } = useSelector(state => state.cart);

  const { data: cartData, isLoading: isCartLoading } = useGetCartQuery();
  const dispatch = useDispatch();

  const { indiaFirstRiderVaildationError, selectedQuote } = useSelector(
    state => state.quotes,
  );
  const { distributor_summary } = useSelector(state => state.proposalForm);
  const isSell =
    selectedQuote?.journey_name === "S" ||
    selectedQuote?.journey_name === "POS";
  if (isCartLoading || !cartData) return <p>Loading...</p>;
  const {
    investment_insurance_id: investmentInsuranceId,
    product: {
      id: productId,
      company: { alias: company_alias },
    },
  } = cartData;

  function redirection() {
    getRedirection({
      company_alias,
      product_id: productId,
      investment_insurance_id: investmentInsuranceId,
    }).then(res => {
      if (res.data && redirectionApiStatus !== "rejected") {
        const { data } = res.data;
        if (
          data[0] &&
          ["R", "PF"].includes(data[0].journey_type) &&
          data[0].redirection_url
        ) {
          redirectToIc(res.data.data[0]);
          return;
        } else {
          dispatch(riderApi.util.invalidateTags(["Enquiry"]));
          navigate(`/investment/proposal?enquiryId=${enquiryId}`);
        }
      } else {
        toast.error(
          res?.error?.data?.message
            ? res?.error?.data?.message
            : "Something went wrong",
          {
            hideProgressBar: true,
            toastId: "failed1",
            autoClose: 3000,
            position: "bottom-center",
            theme: "colored",
          },
        );
      }
    });
  }

  const handleContinueClick = () => {
    if (
      company_alias === "icici_pru" ||
      company_alias === "pnb_metlife" ||
      company_alias === "tata_aia" ||
      company_alias === "hdfc_life"
    ) {
      if (isSell && company_alias === "hdfc_life") {
        if (!distributor_summary) {
          dispatch(setRedirectionTriggered(true));
          return;
        }
      }
      dispatch(getBi(company_alias)).then(data => {
        if (data) {
          const link = document.createElement("a");
          link.href = data;
          link.download = "BenefitIllustration";
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          redirection();
        } else console.log("BI Generate Error");
      });
    } else {
      redirection();
    }
  };

  return (
    <>
      <ContinueButton
        disabled={btn || indiaFirstRiderVaildationError}
        onClick={handleContinueClick}
      >
        {isLoading || biLoading ? <ButtonLoader /> : "CONTINUE TO BUY"}
      </ContinueButton>
    </>
  );
}
