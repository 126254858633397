import { Button, Stack, TableCell, TableRow } from "@mui/material";
import trash from "../../../assets/images/trash.png";
import styled from "styled-components";
import { memo } from "react";
import { getInWords } from "../../../utils/helper";
import { useDispatch } from "react-redux";
import { removeQuoteInList } from "../slice/newCompare.slice";
import useCompareTableQuote from "../hooks/useCompareTableQuote";
import CustomCompareSkeletonLoader from "../../../components/Loaders/CustomCompareSkeletonLoader/CustomCompareSkeletonLoader";

const CompareTableQuote = ({ quote, i, setShowGetDetailsPopup }) => {
  const {
    insuranceCompany,
    product,
    investmentAmount,
    frequency,
    investFor,
    returnsAfter,
  } = quote || {};

  const {
    isLoading,
    data,
    incomeBenefit,
    maturityBenefit,
    irrValue,
    handleGetDetails,
  } = useCompareTableQuote({
    quote,
    i,
    setShowGetDetailsPopup,
  });
  const dispatch = useDispatch();
  return isLoading && !data ? (
    <CustomCompareSkeletonLoader />
  ) : (
    <TableRowStyled>
      <TableCellICWrapperStyled>
        <div>
          <img src={insuranceCompany?.logo} alt="IC Logo" />
        </div>
        <div>{product?.display_name}</div>
      </TableCellICWrapperStyled>
      <TableCellStyled>{getInWords(investmentAmount)}</TableCellStyled>
      <TableCellStyled>{frequency?.display_name}</TableCellStyled>
      <TableCellStyled>{investFor?.display_name}</TableCellStyled>
      <TableCellStyled>{returnsAfter?.display_name}</TableCellStyled>
      <TableCellStyled>
        {getInWords(incomeBenefit ? incomeBenefit : maturityBenefit)}
      </TableCellStyled>
      <TableCellStyled>{irrValue}%</TableCellStyled>
      <TableCellButtonStyled>
        <Button
          onClick={handleGetDetails}
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: "var(--primary-color)",
          }}
        >
          {`₹ ${Number(data?.data?.[0]?.total_premium).toLocaleString(
            "en-IN",
          )}`}
        </Button>
        <TableCellDeleteButtonStyled
          onClick={() => dispatch(removeQuoteInList(i))}
        >
          <img src={trash} alt="trash" height={18} width={18} />
        </TableCellDeleteButtonStyled>
      </TableCellButtonStyled>
    </TableRowStyled>
  );
};

export default memo(CompareTableQuote);

const TableRowStyled = styled(TableRow)`
  &:last-child td,
  &:last-child th {
    border: none !important;
  }

  border-bottom: none !important;
`;
const TableCellStyled = styled(TableCell)`
  text-align: center !important;
  border: none !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  div {
    font-size: 0.6rem;
    opacity: 0.7;
  }
`;
const TableCellICWrapperStyled = styled(TableCell)`
  border: none !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center;
  img {
    height: 30px;
    width: 70px;
    object-fit: contain;
    margin-right: 5px;
  }
  div {
    margin: 2px;
    a {
      text-decoration: none;
      font-size: 0.7rem;
    }
  }
`;
const TableCellButtonStyled = styled(TableCell)`
  border: none !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
  button {
    background-color: #367cff !important;
    width: 8rem !important;
  }
`;
const TableCellDeleteButtonStyled = styled(Stack)`
  height: 30px;
  width: 30px;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebf2f6;
  margin-left: 10px;
  cursor: pointer !important;
`;
