import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import { Grid, Stack } from "@mui/material";
import CompareFilterDropdown from "./CompareFilterDropdown";
import refresh from "../../../assets/images/refresh.png";
import { useDispatch } from "react-redux";

import {
  setInsuranceCompany,
  setProduct,
  setInvestmentAmount,
  setFrequency,
  setInvestFor,
  setReturnsAfter,
  resetStates,
  setIncomeFor,
} from "../slice/newCompare.slice";
import InvestmentAmountTextInput from "./InvestmentAmountTextInput";
import useNewCompare from "../hooks/useNewCompare";
import ButtonLoader from "../../../components/Loaders/ButtonLoader/ButtonLoader";
import { ButtonStyled } from "./CompareFilters";

const CompareFiltersMobile = ({ isFirst, show, setShow }) => {
  const {
    investmentCompaniesList,
    productsList,
    frequencyList,
    investForList,
    incomeForList,
    returnsAfterList,
    isProductLoading,
    isFilterLoading,
    isCompany,
    isProduct,
    isInvestmentAmount,
    isFrequency,
    isInvestFor,
    isIncomeFor,
    isReturnsAfter,
    insuranceCompany,
    product,
    investmentAmount,
    frequency,
    investFor,
    incomeFor,
    returnsAfter,
    isIncome,
    checkValues,
    handleAddToList,
  } = useNewCompare();

  const closeButtonStyle = {
    fontSize: "12px", // Adjust the font size as needed
    borderBottom: "none",
    paddingBottom: "0",
  };

  const AddToListButtonStyle = {
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    border: "none",
    height: "40px",
    borderRadius: "4px",
    padding: "12px 18px",
    fontSize: ".8rem",
    fontWeight: "bold",
    cursor: "pointer",
    textTransform: "none",
  };

  const resetButtonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    height: "40px",
    textTransform: "none",
    border: "2px solid #dfdfdf",
  };

  const bodyStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
  };

  const footerStyle = {
    borderTop: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const YellowHeaderStyle = {
    content: "",
    position: "absolute",
    width: "4px",
    height: "30px",
    left: "0",
    top: "21px",
    background: "#FFA800",
    borderRadius: "8px 0 0 8px",
    transform: "rotate(180deg)",
  };

  const selectItemsStyle = {
    border: "1px solid #E3E8EC",
    borderRadius: "4px",
    padding: "4px 10px",
    whiteSpace: "nowrap",
  };

  const handleClose = () => {
    setShow(false);
  };

  const dispatch = useDispatch();

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={closeButtonStyle}>
          <Modal.Title>
            <span style={YellowHeaderStyle}></span>
            <span style={{ fontSize: "1rem" }}>
              {isFirst ? "Add New Quote" : "Generate Quote"}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={bodyStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={0} style={selectItemsStyle}>
                <>Insurance Company</>
                <CompareFilterDropdown
                  options={investmentCompaniesList}
                  isReset={isCompany}
                  isFullWidthOnMobile
                  previousSelectedValue={insuranceCompany?.code}
                  onChangeHandler={setInsuranceCompany}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={0} style={selectItemsStyle}>
                <>Product Name</>
                {isProductLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <>
                    <CompareFilterDropdown
                      options={isCompany ? productsList : []}
                      isReset={isProduct}
                      isFullWidthOnMobile
                      onChangeHandler={setProduct}
                      previousSelectedValue={product?.code}
                    />
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={6}>
              <Stack spacing={0} style={selectItemsStyle}>
                <>Investment Amount</>
                {isFilterLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <>
                    <InvestmentAmountTextInput
                      isReset={isInvestmentAmount}
                      isDisable={!isProduct}
                      investmentAmount={investmentAmount}
                      onChangeHandler={setInvestmentAmount}
                    />
                  </>
                )}
              </Stack>
            </Grid>
            <Grid item sm={6} xs={6}>
              <Stack style={selectItemsStyle}>
                <>Frequency </>
                {isFilterLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <>
                    <CompareFilterDropdown
                      options={isProduct ? frequencyList : []}
                      isReset={isFrequency}
                      previousSelectedValue={frequency?.code}
                      onChangeHandler={setFrequency}
                    />
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={6}>
              <Stack spacing={0} style={selectItemsStyle}>
                <>Invest For</>
                {isFilterLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <CompareFilterDropdown
                    options={isProduct ? investForList : []}
                    isReset={isInvestFor}
                    previousSelectedValue={investFor?.code}
                    onChangeHandler={setInvestFor}
                  />
                )}
              </Stack>
            </Grid>
            <Grid item sm={6} xs={6}>
              <Stack spacing={0} style={selectItemsStyle}>
                <>{isIncome ? "Income For" : "Returns After"}</>
                {isFilterLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <>
                    {isIncome ? (
                      <CompareFilterDropdown
                        options={isProduct ? isIncome && incomeForList : []}
                        isReset={isIncomeFor}
                        previousSelectedValue={incomeFor?.code}
                        onChangeHandler={setIncomeFor}
                      />
                    ) : (
                      <CompareFilterDropdown
                        options={isProduct ? returnsAfterList : []}
                        isReset={isReturnsAfter}
                        previousSelectedValue={returnsAfter?.code}
                        onChangeHandler={setReturnsAfter}
                      />
                    )}
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer style={footerStyle}>
          <Button
            variant="secondary"
            onClick={() => dispatch(resetStates())}
            style={resetButtonStyle}
          >
            <img src={refresh} alt="Refresh" />
            Reset
          </Button>
          <ButtonStyled
            onClick={() => {
              handleAddToList();
              handleClose();
            }}
            style={AddToListButtonStyle}
            disabled={checkValues()}
          >
            Add to List
          </ButtonStyled>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CompareFiltersMobile;
