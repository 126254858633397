export const occupationTypes = [
  { code: "self_employed", display_name: "Self Employed" },
  {
    code: "salaried",
    display_name: "Salaried",
  },
];
export const educationTypes = [
  { code: "post_graduate", display_name: "Post Graduate" },
  { code: "graduate", display_name: "Graduate" },
  { code: "12th_pass", display_name: "12th Pass" },
  { code: "10th_pass", display_name: "10th Pass" },
];
