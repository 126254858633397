import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { riderResponseFormat } from "../../../utils/helper";
import { clearFilterUpdating } from "../../Filters/filterSlice";
// import { clearFilterLoading } from "../../product-page/Filters/Filters.slice";

import {
  saveDifferentQuotes,
  saveQuotes,
  setQuotesLoading,
  setShowDifferentQuotes,
  updateRidersCollection,
} from "../../QuotesPage/Quotes.slice";

// import { riderResponseFormat } from "../helpers";
import {
  setDefaultFilters,
  setFrontendData,
} from "../../frontendBoot/frontendBoot.slice";
import { setCompareProducts } from "../../ComparePage/Compare.slice";
import { clearQuotes } from "../../ComparePage/ComparePlanPopupSlice";
import { setData, setFilters } from "../../Landing/input.slice";

const { REACT_APP_API_BASE_URL: baseUrl } = process.env;
const sortQuotesByReturn = quotes => {
  const validQuotes = quotes.filter(item => item !== null);

  validQuotes.sort((quote1, quote2) => {
    // if (!quote1 || !quote2) return -1;
    const incomeForValue = quote1.income_for || "20";
    const investForValue = quote1.pay_term || "10";

    const totalAmountToBeReceived1 =
      quote1?.income_benfit * incomeForValue +
      Number(quote1.maturity_benefit) +
      quote1?.additional_lumpsum +
      quote1?.additional_rop;

    const totalAmountInvested1 = quote1?.premium * investForValue;
    const returns1 =
      Math.round((totalAmountToBeReceived1 / totalAmountInvested1) * 100) / 100;

    const totalAmountToBeReceived2 =
      quote2?.income_benfit * incomeForValue +
      Number(quote2.maturity_benefit) +
      quote2?.additional_lumpsum +
      quote2?.additional_rop;

    const totalAmountInvested2 = quote2?.premium * investForValue;
    const returns2 =
      Math.round((totalAmountToBeReceived2 / totalAmountInvested2) * 100) / 100;
    return returns2 - returns1;
  });
  return validQuotes;
};
function quoteForSort(quote = {}) {
  return {
    investment_insurance_id: quote?.investment_insurance_id,
    premium: quote?.premium,
    company_alias: quote?.company_alias,
    total_premium: quote?.total_premium,
    tax_amount: quote?.tax_amount,

    product: {
      id: quote?.product?.id,
      company: { csr: quote?.product?.company.csr },
    },
    ...quote,
  };
}

export const riderApi = createApi({
  reducerPath: "riderApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      const urlQueryStrings = new URLSearchParams(window.location.search);
      const EnquiryId = urlQueryStrings.get("enquiryId");
      headers.set("Enquiry-Id", EnquiryId);
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  tagTypes: [
    "Cart",
    "Quote",
    "Enquiry",
    "Rider",
    "DifferentQuote",
    "Compare",
    "FrontendBoot",
    "Bi",
    "KnowMore",
  ],
  endpoints: builder => ({
    getQuote: builder.query({
      query: ({ differentQuote = false, company_alias }) => ({
        url: differentQuote
          ? `companies/different/investment-quotes`
          : `companies/investment-quotes`,
        method: "POST",
        body: { company_alias },
      }),
      providesTags: ["Quote"],
      keepUnusedDataFor: 1000000,
      transformResponse: res => {
        return { data: sortQuotesByReturn(res.data) };
      },
      onQueryStarted: async (
        { company_alias },
        { dispatch, queryFulfilled, getState },
      ) => {
        // dispatch(clearShortListedPlansData());
        dispatch(clearQuotes());
        dispatch(clearFilterUpdating());
        dispatch(setQuotesLoading({ [company_alias]: true }));
        const { data } = await queryFulfilled;
        // const { ridersList } = getState().quotes;
        if (data) {
          const { data: quotes } = data;

          dispatch(setQuotesLoading({ [company_alias]: false }));

          if (quotes.length) {
            dispatch(
              saveQuotes({
                [company_alias]: sortQuotesByReturn(quotes).map(quoteForSort),
              }),
            );
            return;
          }
        }

        dispatch(saveQuotes({ [company_alias]: null }));
      },
    }),
    getEnquiries: builder.query({
      query: () => ({ url: `enquiries`, method: "GET" }),
      providesTags: ["Enquiry"],
      invalidatesTags: ["Compare"],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        const {
          gender,
          dob,
          pincode,
          tobacco_habit,
          occupation,
          education,
          annual_income,
          shortlistedIds,
          suitability,
          // eligibility_pincode,
          ...filters
        } = data?.data?.input;
        const newData = {
          gender,
          dob,
          pincode,
          tobacco_habit,
          occupation,
          education,
          // eligibility_pincode,
          annual_income,
          name: data?.data.name,
          email: data?.data.email,
          mobile: data?.data.mobile,
          shortlistedIds: shortlistedIds && [
            ...new Set([...shortlistedIds]?.map(item => item)),
          ],
          suitability,
        };
        dispatch(setData(newData));
        dispatch(setFilters(filters));
        dispatch(setDefaultFilters(data?.investmentdefaultfilters));
        //   dispatch(
        //     updateFilter(
        //       filters,
        //       data?.termdefaultfilters,
        //       data?.nominee_gets_table
        //     )
        //   );
        //   dispatch(setAddedRiders(selected_addons || {}));
      },
    }),
    patchEnquiries: builder.mutation({
      query: body => ({
        url: `enquiries`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Enquiry"],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const updateResult = dispatch(
          riderApi.util.updateQueryData("getEnquiries", undefined, draft => {
            Object.assign(draft, {
              ...args,
              input: {
                ...args.input,
                selected_insurers: args.input.selected_insurers,
              },
            });
          }),
        );
        queryFulfilled.catch(() => updateResult.undo());
      },
    }),
    shareQuote: builder.mutation({
      query: ({ page = "quotes", ...body }) => ({
        url: `life/sib/communications`,
        method: "POST",
        body,
      }),
    }),
    contactDetails: builder.mutation({
      query: ({ page = "quotes", ...body }) => ({
        url: `life/sib/communications`,
        method: "POST",
        body,
      }),
    }),
    getRiderDescription: builder.query({
      query: ({ productId, investment_insurance }) => ({
        url: `products/${productId}/insurance/${investment_insurance}/riders`,
        method: "GET",
      }),
    }),
    getRiders: builder.query({
      query: ({ productId, investment_insurance_id }) => ({
        url: `products/${productId}/insurance/${investment_insurance_id}/investment_riders`,
        method: "GET",
      }),
      transformResponse: res => res.data,
      providesTags: ["Rider"],
      onQueryStarted: async ({ productId }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          updateRidersCollection({
            [productId]: data,
          }),
        );
      },
    }),
    getRiderPremium: builder.mutation({
      query: ({ productId, investmentInsuranceId, ...body }) => {
        return {
          url: `products/${productId}/insurance/${investmentInsuranceId}/investment_riders`,
          method: "POST",
          body,
        };
      },
    }),
    getCart: builder.query({
      query: () => ({
        url: `investment/cart-items`,
        method: "GET",
      }),
      transformResponse: res => ({ ...res, ...res.data }),
      providesTags: ["Cart"],
    }),
    updateCart: builder.mutation({
      query: ({ cartId, ...body }) => ({
        url: cartId
          ? `investment/cart-items/${cartId}`
          : `investment/cart-items`,
        method: cartId ? "PUT" : "POST",
        body,
      }),
      onQueryStarted: async (
        { riders, ...cartData },
        { dispatch, queryFulfilled },
      ) => {
        const updateResult = dispatch(
          riderApi.util.updateQueryData("getCart", undefined, draft => {
            Object.assign(draft, {
              ...cartData,
              investment_riders: riders.map(riderResponseFormat),
            });
          }),
        );
        queryFulfilled.catch(() => updateResult.undo());
      },
      invalidatesTags: ["Cart"],
    }),
    getRedirection: builder.mutation({
      query: body => ({
        url: `investment/redirection`,
        method: "POST",
        body,
      }),
    }),
    getBi: builder.query({
      query: ({ company_alias }) => ({
        url: `investment/generate-bi`,
        method: "POST",
        body: { company_alias },
      }),

      providesTags: ["Bi"],
    }),
    getKnowMoreData: builder.query({
      query: () => ({
        url: `/investment_products/investment_knowmore_features`,
        method: "GET",
      }),
      providesTags: ["KnowMore"],
    }),
    getProposalData: builder.query({
      query: () => ({
        url: "investment/proposals",
        method: "GET",
      }),
    }),
    getFrontendBoot: builder.query({
      query: () => ({
        url: "investment/frontend-boot",
        method: "GET",
      }),
      providesTags: ["FrontendBoot"],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data) {
          const { investmentdefaultfilters, ...newData } = data;
          dispatch(setFrontendData({ data: newData, isLoading: false }));
        }
        return;
      },
    }),
    // Compare Products
    getCompareFeatures: builder.query({
      query: ({ product_id }) => ({
        url: `investment_products/${product_id}/investment_compare_features`,
        method: "GET",
      }),
      providesTags: ["Compare"],
    }),
    putCompareProducts: builder.mutation({
      query: body => ({
        url: `investment_comparisons`,
        body,
        method: "PUT",
      }),
      onQueryStarted: async ({ products }, { dispatch, queryFulfilled }) => {
        const updateCompareQuotes = dispatch(
          riderApi.util.updateQueryData(
            "getCompareProducts",
            undefined,
            draft => {
              Object.assign(draft, { data: { products } });
            },
          ),
        );

        queryFulfilled.catch(() => updateCompareQuotes.undo());
      },
    }),
    getCompareProducts: builder.query({
      query: () => ({
        url: `investment_comparisons`,
        method: "GET",
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        const { products } = data?.data ? data.data : {};
        const temp =
          products?.length > 0 && products?.map(item => item.product_id);

        dispatch(setCompareProducts(temp));
      },
    }),
    getComparePdf: builder.mutation({
      query: body => ({
        url: `/investment/compare/pdf_link`,
        body,
        method: "POST",
      }),
    }),
    getApplicationNumber: builder.query({
      query: () => ({
        url: "investment/get-user-proposal",
        method: "GET",
      }),
    }),
    getGenerateOTP: builder.mutation({
      query: ({ ...body }) => ({
        url: "life/sib/communications",
        method: "POST",
        body,
      }),
      providesTags: ["GenerateOTP"],
    }),
    getDifferentQuote: builder.query({
      query: ({ differentQuote = true, company_alias, product_id }) => ({
        url: `companies/different/investment-quotes`,
        method: "POST",
        body: { company_alias, product_id },
      }),
      keepUnusedDataFor: 1000000,
      providesTags: ["DifferentQuote"],
      onQueryStarted: async (
        { company_alias },
        { dispatch, queryFulfilled },
      ) => {
        const { data } = await queryFulfilled;

        if (data) {
          const { data: quotes } = data;
          if (quotes.length) {
            dispatch(setShowDifferentQuotes(true));
            dispatch(
              saveDifferentQuotes({
                [company_alias]: quotes.map(quote => ({
                  ...quoteForSort(quote),
                  differentQuote: true,
                })),
              }),
            );

            return;
          }
        }

        dispatch(saveDifferentQuotes({ [company_alias]: null }));
      },
    }),
    getSellerInfo: builder.query({
      query: ({ token }) => ({
        url: "dashboard/details/user",
        method: "POST",
        body: {
          token,
        },
      }),
    }),
    getDistributorSummary: builder.query({
      query: () => ({
        url: "investment/hdfc_distributor_summary",
        method: "GET",
      }),
    }),

    // New Compare / Custom Compare
    getProductsName: builder.query({
      query: ({ alias }) => ({
        url: `/investment_products/${alias}/get_products`,
        method: "GET",
      }),
      providesTags: ["NewCompareProducts"],
    }),
    getCompareFilters: builder.query({
      query: ({ id }) => ({
        url: `/investment_products/${id}/get_filters`,
        method: "GET",
      }),
      providesTags: ["NewCompareFilters"],
    }),
    getCompareQuotePremium: builder.query({
      query: ({
        company_alias,
        product_id,
        get_money_as,
        investment_amount,
        policy_term,
        pay_term,
        income_for,
        pay_frequency,
      }) => ({
        url: `companies/compare/investment-quotes`,
        method: "POST",
        body: {
          company_alias,
          product_id,
          get_money_as,
          investment_amount,
          policy_term,
          pay_term,
          income_for,
          pay_frequency,
        },
      }),
      providesTags: ["NewCompareQuotePremium"],
    }),
  }),
});

export const {
  useGetQuoteQuery,
  useGetKnowMoreDataQuery,
  usePatchEnquiriesMutation,
  useGetEnquiriesQuery,
  useGetRiderDescriptionQuery,
  useGetProposalDataQuery,
  useGetRiderPremiumMutation,
  useGetRidersQuery,
  useGetCartQuery,
  useUpdateCartMutation,
  useGetRedirectionMutation,
  useShareQuoteMutation,
  useContactDetailsMutation,
  useGetFrontendBootQuery,
  useGetBiQuery,
  useGetDifferentQuoteQuery,
  useGetCompareFeaturesQuery,
  useGetCompareProductsQuery,
  usePutCompareProductsMutation,
  useGetComparePdfMutation,
  useGetApplicationNumberQuery,
  useGetGenerateOTPMutation,
  useGetSellerInfoQuery,
  useGetDistributorSummaryQuery,
  useGetProductsNameQuery,
  useGetCompareFiltersQuery,
  useGetCompareQuotePremiumQuery,
} = riderApi;
