import React, { useState } from "react";
import styled from "styled-components/macro";
import { FiChevronDown } from "react-icons/fi";
import FilterModal from "./Modal/FilterModal";
import InvestedAmt from "./Modal/ModalBody/InvestedAmt";
import GetMoneyAs from "./Modal/ModalBody/GetMoneyAs";
import InvestedFor from "./Modal/ModalBody/InvestedFor";
import ReturnsAfter from "./Modal/ModalBody/ReturnsAfter";
import IncomeFor from "./Modal/ModalBody/IncomeFor";
import { useSelector } from "react-redux";
import useGetPayFreqWord from "../../../../../custom-hooks/useGetPayFreqWord";
import useGetInWords from "../../../../../custom-hooks/useGetInWords";
import CompareButtonPopupMobile from "../../../../ComparePage/CompareButtonPopupMobile";
import CompareImage from "../../../../../assets/images/compare.svg";
import useGetFilterOptions from "../../../../../custom-hooks/useGetFilterOptions";
import { reduceValueFromOptions } from "../../../../ProposalPage/InputUtils/reduceValueFormOption";

function FiltersMobile() {
  const [showModal, setShowModal] = React.useState(false);
  const handleClose = () => setShowModal(false);

  const getMoneyAs = useSelector(state => state.filters.getMoneyAs);
  const investedAmount = useSelector(state => state.filters.investedAmount);
  const incomeFor = useSelector(state => state.filters.incomeFor);
  const investFor = useSelector(state => state.filters.investFor);
  const returnsAfter = useSelector(state => state.filters.returnsAfter);
  const [investedAmtModal, setInvestedAmtModal] = useState(false);
  const [investedForModal, setInvestedForModal] = useState(false);
  const [incomeForModal, setIncomeForModal] = useState(false);
  const [returnsAfterModal, setReturnsAfterModal] = useState(false);
  const [getMoneyAsModal, setGetMoneyAsModal] = useState(false);
  const payFrequencyWord = useGetPayFreqWord();
  const investedAmountWord = useGetInWords(investedAmount);
  // eslint-disable-next-line no-unused-vars
  const [offset, setOffset] = useState(0);
  function getMoneyWord(x) {
    if (x === "I") return "Income";
    if (x === "L") return "Lumpsum";
    if (x === "B") return "Both";
  }
  const returnsAfterOptions = useGetFilterOptions({ filter: "policy_term" });

  return (
    <>
      <FiltersMobileWrapper>
        <FilterInnerWrap>
          <FiltersMobileMain>
            <FilterMainIndividual>
              <FilterTextMedium>Investment</FilterTextMedium>
              <FilterTextBold
                css={`
                  font-size: 10.3px;
                `}
                onClick={() => setInvestedAmtModal(true)}
              >
                {investedAmountWord} | {payFrequencyWord}
                <i
                  key={"edit"}
                  className="fas fa-edit"
                  style={{
                    color: `${offset > 80 ? "rgb(245,245,245)" : "#000"}`,
                    fontSize: "11px",
                    marginLeft: "4px",
                  }}
                />
              </FilterTextBold>
            </FilterMainIndividual>
            <FilterMainIndividual>
              <FilterTextMedium>Invested For</FilterTextMedium>
              <FilterTextBold onClick={() => setInvestedForModal(true)}>
                {investFor === "1" ? "Single Pay" : `${investFor} Years`}
                <FiChevronDown />
              </FilterTextBold>
            </FilterMainIndividual>
            {getMoneyAs === "I" || getMoneyAs === "B" ? (
              <FilterMainIndividual>
                <FilterTextMedium>Income For</FilterTextMedium>
                <FilterTextBold onClick={() => setIncomeForModal(true)}>
                  {incomeFor > 100 ? "Till Death" : `${incomeFor} Years`}
                  <FiChevronDown />
                </FilterTextBold>
              </FilterMainIndividual>
            ) : (
              <FilterMainIndividual>
                <FilterTextMedium>Returns After</FilterTextMedium>
                <FilterTextBold onClick={() => setReturnsAfterModal(true)}>
                  {reduceValueFromOptions(returnsAfterOptions, returnsAfter)}
                  <FiChevronDown />
                </FilterTextBold>
              </FilterMainIndividual>
            )}
          </FiltersMobileMain>
          <FiltersMobileSub>
            <FilterSubIndividual>
              <FilterTextMedium sub>Get Money As</FilterTextMedium>
              <FilterTextBold onClick={() => setGetMoneyAsModal(true)} sub>
                {getMoneyWord(getMoneyAs)} <FiChevronDown />
              </FilterTextBold>
            </FilterSubIndividual>

            <Compare onClick={() => setShowModal(true)}>
              <span>Compare Plans</span>
              <img src={CompareImage} alt="" />
            </Compare>
          </FiltersMobileSub>
        </FilterInnerWrap>
      </FiltersMobileWrapper>
      <CompareButtonPopupMobile show={showModal} handleClose={handleClose} />
      <FilterModal
        show={investedAmtModal}
        setShow={setInvestedAmtModal}
        head={"Invested Amount"}
        Body={InvestedAmt}
      />
      <FilterModal
        show={investedForModal}
        setShow={setInvestedForModal}
        head={"Invested For"}
        Body={InvestedFor}
      />
      <FilterModal
        show={incomeForModal}
        setShow={setIncomeForModal}
        head={"Income For"}
        Body={IncomeFor}
      />
      <FilterModal
        show={returnsAfterModal}
        setShow={setReturnsAfterModal}
        head={"Returns After"}
        Body={ReturnsAfter}
      />
      <FilterModal
        getMoneyAs
        show={getMoneyAsModal}
        setShow={setGetMoneyAsModal}
        head={"Get Money As"}
        Body={GetMoneyAs}
      />
    </>
  );
}

const FiltersMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 3px 10px 4px 10px;
  margin-top: 15px;
`;

const FilterInnerWrap = styled.div`
  width: 100%;
  box-shadow: rgb(0 0 0 / 15%) 0px 3px 5px 0px;
`;

const FiltersMobileMain = styled.div`
  width: 100%;
  height: 47px;
  display: flex;
  padding: 5px 5px;
  justify-content: space-around;
  background-color: #ffff;
  border-radius: 2px;
`;
const FilterMainIndividual = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3px;
`;
const FilterTextMedium = styled.p`
  font-size: ${props => (props.sub ? "10px" : "11.3px")};
  color: rgb(107, 119, 137);
  margin-bottom: 0px;
  white-space: nowrap;
`;
const FilterTextBold = styled.p`
  font-size: ${props => (props.sub ? "11px" : "12.3px")};
  color: ${props => props.sub && "var(--primary-color)"};
  margin-bottom: 0px;
  cursor: pointer;
  text-transform: capitalize;
`;
const FiltersMobileSub = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  background: rgb(79, 92, 111, 0.1);
  margin-top: 0px;
  padding: 5px 0px;
`;
const FilterSubIndividual = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
  border-right: 1px solid black;
`;

const Compare = styled.div`
  color: var (--primary-color);
  width: 50%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  padding: 0px 5px;
  font-family: Inter;
  font-size: 600;
  cursor: pointer;
  & span {
    color: rgb(107, 119, 137);
    font-size: 11px !important;
  }
`;

export default FiltersMobile;
