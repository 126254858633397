import { useMemo } from "react";
import { useSelector } from "react-redux";

const useGetFilterOptions = ({ filter }) => {
  const { frontendData, isLoading } = useSelector(state => state.frontendBoot);

  function getData() {
    let tempData = frontendData;
    if (isLoading) {
      return [];
    }
    if (tempData) {
      tempData =
        tempData.investmentdefaultfilters !== undefined
          ? tempData.investmentdefaultfilters
          : [];
      if (tempData.length > 0) {
        tempData = tempData.find(e => e.code === filter);
        tempData = tempData?.options || [];
      }
    }
    return tempData;
  }

  const final = useMemo(() => {
    return getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, frontendData]);

  return final;
};

export default useGetFilterOptions;
