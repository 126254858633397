import React from "react";
import "styled-components/macro";
import { DynamicWrap, MessageContainer } from "../../Landing.style";
import * as mq from "../../../../styles/mediaQueries";
import { useState } from "react";
import styled from "styled-components";
import { AiOutlineCheck } from "react-icons/ai";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { BiRightArrow } from "react-icons/bi";
const ListItem = ({ children }) => {
  return (
    <>
      <div
        css={`
          display: flex;
          // justify-content: flex-start;
          align-items: center;
          width: 100%;

          margin-bottom: 12px;
          font-size: 16px;
          & i {
            color: var(--primary-color);
            display: inline;
          }
          & p {
            margin: 0px 8px;
            line-height: 16px;

            ${mq.sm} {
              margin: 0px;
              padding: 0 5px;
            }
          }
          ${mq.sm} {
            font-size: 9px;
            margin-bottom: 0;
          }
        `}
      >
        <i className="fas fa-check-circle"></i>
        <p>{children}</p>
      </div>
    </>
  );
};
const LandingMessage = () => {
  const { frontendData } = useSelector(state => state.frontendBoot);
  const [show, setShow] = useState(false);
  return (
    <>
      <div
        css={`
          width: 50%;
          padding: 0 25px;
          ${mq.sm} {
            width: 100%;
          }
          ${mq.md} {
            width: 100%;
            padding: 0;
          }
        `}
      >
        <MessageContainer>
          <h3>Endowment Plans</h3>
          {frontendData?.investmentinputpage?.investmentinsurancedescription ? (
            <>
              <div
                css={`
                  display: flex;
                  gap: 5px;
                `}
              >
                <BiRightArrow
                  css={`
                    display: none;
                    ${mq.xs} {
                      display: inline;
                      height: 13px;
                      width: 13px;
                      cursor: pointer;
                      color: rgb(50, 50, 50);
                      transition: all 0.2s ease;
                      transform: ${show ? "rotate(90deg)" : "rotate(0deg)"};
                    }
                  `}
                  onClick={e => setShow(!show)}
                />
                <DynamicWrap show={show}>
                  {parse(
                    frontendData?.investmentinputpage
                      ?.investmentinsurancedescription,
                  )}
                </DynamicWrap>
              </div>
            </>
          ) : (
            <>
              <h1>
                Buy Guaranteed Return Plans In Few Simple Steps
                <i
                  className="fas fa-info-circle down ps-1"
                  onClick={e => setShow(!show)}
                />
              </h1>
              <div
                css={`
                  display: auto;
                  ${mq.md} {
                    display: auto;
                    width: 100%;
                  }
                  ${mq.sm} {
                    display: ${show ? "block" : "none"};
                    width: 100%;
                    transition: all 0.2s ease;
                  }
                `}
              >
                <ListItem>Compare Endowment Plans</ListItem>
                <ListItem>Instant Policy Issuance</ListItem>
                <ListItem>Free Claims Assistance</ListItem>
                <ListItem>Get Plan Recommendation in Seconds</ListItem>
              </div>
            </>
          )}
        </MessageContainer>
      </div>
      {/* <LandingTitle>
        <MessageTitle>
          <div>
            Buy Guaranteed{" "}
            <br
              css={`
                ${mq.sm} {
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-starts;
                }
              `}
            />{" "}
            Return Plans In <br />
            <span className="text-primary">Few Simple Steps</span>
          </div>
        </MessageTitle>
        <MessageList>
          <div
            css={`
              ${mq.sm} {
                width: 100%;
              }
            `}
          >
            <h6>
              <checkmark>
                <AiOutlineCheck />
              </checkmark>
              <List> Compare Term Insurance Plans</List>
            </h6>
            <h6>
              {" "}
              <checkmark>
                <AiOutlineCheck />
              </checkmark>
              <List> Instant Policy Issuance</List>
            </h6>
            <h6>
              {" "}
              <checkmark>
                <AiOutlineCheck />
              </checkmark>
              <List> Free Claims Assistance</List>
            </h6>
            <h6>
              {" "}
              <checkmark>
                <AiOutlineCheck />
              </checkmark>
              <List> Get Plan Recommendation in Seconds</List>
            </h6>
          </div>
        </MessageList>
      </LandingTitle> */}
    </>
  );
};

export default LandingMessage;

const MessageTitle = styled.h1`
  font-size: 58px;
  font-weight: 900;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${"" /* padding-right: 3rem; */}
  margin-bottom: 12px;
  ${mq.sm} {
    font-size: 34px;
    margin-bottom: 0;
    line-height: normal;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const MessageList = styled.h1`
  font-size: 44px;
  font-weight: 600;
  padding-top: 12px;
  width: 100%;
  padding-right: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mq.sm} {
    padding-top: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  & h6 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
    display: flex;
    width: 100%;
    ${mq.sm} {
      font-size: 18px;
      margin-bottom: 0;
      width: 160%;
      margin-top: 5px;
    }
  }
  & checkmark {
    display: none;
    ${mq.md} {
      display: flex;
      color: #2cd44a;
      font-size: 22px;
    }
  }
`;
const LandingTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 0 20px;

  ${mq.sm} {
    width: 100%;
  }
`;

const List = styled.div`
  ${mq.sm} {
    padding-left: 4px;
    font-size: 20px;
  }
`;
