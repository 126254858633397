export const reduceValueFromOptions = (options, val) => {
  const option = options.find(item => item.code === val);
  if (option) return option.display_name;
  else return val;
};
export const reduceYN = val => {
  return val === "Y" || val === true ? "Yes" : "No";
};
export const reduceYNN = val => {
  return val === "L" || val === true ? "Life Assured" : "Others";
};
export const reduceYNNN = val => {
  return val === "P" || val === true ? "Proposer" : "Others";
};
