export const riskProfile = [
  {
    code: "Conservative",
    display_name: "Conservative",
  },
  {
    code: "Risk Averse",
    display_name: "Risk Averse",
  },
  {
    code: "Balance",
    display_name: "Balance",
  },
  {
    code: "Growth",
    display_name: "Growth",
  },
  {
    code: "Aggressive",
    display_name: "Aggressive",
  },
];
export const lifeStage = [
  {
    code: "Single",
    display_name: "Single",
  },
  {
    code: "Married",
    display_name: "Married",
  },
  {
    code: "Married with Young Children",
    display_name: "Married with Young Children",
  },
  {
    code: "Married with Older Children",
    display_name: "Married with Older Children",
  },
  {
    code: "Near Retirement",
    display_name: "Near Retirement",
  },
  {
    code: "Retirement",
    display_name: "Retirement",
  },
  {
    code: "Others",
    display_name: "Others",
  },
];
export const financialAndFamilyGoal = [
  {
    code: "Saving and Investments",
    display_name: "Saving and Investments",
  },
  {
    code: "Regular Income",
    display_name: "Regular Income",
  },
  {
    code: "Retirement",
    display_name: "Retirement",
  },
  {
    code: "Child",
    display_name: "Child",
  },
  {
    code: "Protection",
    display_name: "Protection",
  },
];
const i = [
  "2.5",
  "3",
  "3.5",
  "4",
  "4.5",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "78",
  "79",
  "80",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "90",
  "91",
  "92",
  "93",
  "94",
  "95",
  "96",
  "97",
  "98",
  "99",
  "100",
];
export const income = i.map(item => {
  return {
    code: (item * 100000).toString(),
    display_name: item + " Lakhs",
  };
});
