import React from "react";
import Navbar from "../../components/navbar/Navbar-Component";
import styled from "styled-components/macro";
import "styled-components/macro";
import success from "../../assets/images/check1.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  LabelText,
  ShowText,
  SuccessImage,
} from "../ThankYouPage/ThankyouPage.style";
import {
  useGetApplicationNumberQuery,
  useGetCartQuery,
} from "../rider/services/rider";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader/FullScreenLoader";

function PaymentStatusPage() {
  const {
    frontendData: { tenant },
  } = useSelector(state => state.frontendBoot);
  const { isLoading, data } = useGetCartQuery();
  const appData = useGetApplicationNumberQuery();
  const appNo = appData?.data?.data.proposal_reference_id;
  if (isLoading) return <FullScreenLoader />;
  return (
    <div>
      <Navbar />
      <div
        className="container"
        css={`
          margin-top: 35px;
        `}
      >
        <Section className="col-md-12">
          <div className="container">
            <SuccessImage src={success} />
            <div className="col-md-12">
              <ShowText>Thank you for choosing {tenant?.name}</ShowText>
            </div>
            <div className="col-md-12">
              <LabelText>
                {" "}
                Your documents has been submitted successfully and application
                no. is {appNo}. <br />
                {data?.product?.company?.name} will review your documents and we
                will get back to you shortly
              </LabelText>
            </div>
          </div>
          <div
            css={`
              text-align: center;
              margin-top: 20px;
            `}
          >
            <GoBackLink to="/">Back To Quotes</GoBackLink>
          </div>
        </Section>
      </div>
    </div>
  );
}

export default PaymentStatusPage;

const Section = styled.div`
  box-shadow: 0px -3px 20px rgb(134 156 213 / 25%);
  padding: 12px 60px;
  width: 57%;
  margin: 0 auto;
  margin-bottom: 24px;
`;
const GoBackLink = styled(Link)`
  border: 1px solid var(--primary-color);
  color: #fff;
  background-color: var(--primary-color);
  box-shadow: none;
  border-radius: 6px;
  min-width: 145px;
  line-height: 34px;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  padding: 0 6px;
  font-size: 14px;
  display: inline-block;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
`;
