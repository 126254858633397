import React from "react";
import styled from "styled-components";

const PolicyTermHeaderMobile = ({ logo, planName, planType }) => {
  return (
    <FlexWrapper>
      <Img src={logo} alt="error"></Img>
      <div>
        <H1>{planName}</H1>

        <p>{planType.join("-")}</p>
      </div>
    </FlexWrapper>
  );
};

export default PolicyTermHeaderMobile;
const FlexWrapper = styled.div`
  width: 90%;
  /* height: 60px; */
  display: flex;
  align-items: center;
  // justify-content: space-between;

  div {
    margin-left: 10px;

    p {
      font-family: "Inter";
      font-size: 10px;
      margin-bottom: 0px;

      @media (max-width: 768px) {
        font-size: 9.5px;
        font-weight: 400;
      }
    }
  }
  & ~ button {
    width: 0.25em !important;
    height: 0.25em !important;
    padding: 0.25em !important;
    margin-right: 0.15rem !important;
  }
`;

const Img = styled.img`
  height: 30px;
  /* margin-left: 10px; */
`;
const H1 = styled.h1`
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px;
  @media (max-width: 768px) {
    font-size: 12px;
    font-family: Inter;
    font-weight: 600;
  }

  @media (max-width: 576px) {
    font-size: 11px;
  }
`;
