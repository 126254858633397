import React, { useState } from "react";
import { Checkbox } from "@material-ui/core";
import whatsapp from "../../../../assets/images/whatsapp-icon.svg";
import * as mq from "../../../../styles/mediaQueries";
import styled from "styled-components/macro";

const WhatsappUpdate = () => {
  const [checked, setChecked] = useState(true);
  return (
    <div
      css={`
        width: 100%;
      `}
    >
      <div
        css={`
          display: flex;
          justify-content: flex-start;
          width: auto;
          margin-left: -10px;

          // ${mq.sm} {
          //   width: 100%;
          //   margin-left: 11px;
          // }
          // ${mq.md} {
          //   width: 94%;
          //   justify-content: flex-start;
          // }
          // @media screen and (min-width: 820px) and (max-width: 884px) {
          //   width: 92%;
          // }
        `}

        // css={`
        //   display: flex;
        //   justify-content: flex-start;
        //   width: auto;
        //   background-color: red;
        //   margin-left: -10px;
        //   ${mq.sm} {
        //     width: 100%;

        //     // margin-left: 11px;
        //   }
        // `}
      >
        <Checkbox
          size="small"
          style={{
            color: "var(--primary-color)",
          }}
          defaultChecked={checked}
          onChange={() => setChecked(!checked)}
        />

        <h6
          style={{
            marginTop: "11px",
          }}
        >
          Get Updates on{" "}
          <img
            src={whatsapp}
            alt="Whatsapp-icon"
            style={{
              height: "40px",
              width: "20px",
              marginBottom: "2px",
            }}
          />{" "}
          Whatsapp
        </h6>
      </div>
    </div>
  );
};

export default WhatsappUpdate;
