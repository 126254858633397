import React from "react";
import styled from "styled-components";
import * as mq from "../../styles/mediaQueries";

function CancelCircleIcon({ width, style, onClick }) {
  return (
    <CancelCircleWrapper style={style} onClick={onClick} width={width}>
      <i className="fas fa-times"></i>
    </CancelCircleWrapper>
  );
}

export default CancelCircleIcon;

const CancelCircleWrapper = styled.div`
  z-index: 100;
  border-radius: 50%;
  position: absolute;
  width: ${props => `${props.width}px`};
  height: ${props => `${props.width}px`};
  top: -10px;
  right: -10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  & i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  ${mq.md} {
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
  }
`;
