import "./riderskeleton.scss";

const RiderSkeletonLoader = ({ noOfCards = 4 }) =>
  Array(noOfCards)
    .fill("-")
    .map((_, i) => (
      <div id="u_0_v" key={i}>
        <div className="lightui2">
          <div className="lightui2-shimmer">
            <div className="_1iwr" />
            <div className="_1iws" />
            <div className="_1iwt" />
            <div className="_1iwu" />
            <div className="_1iwv" />
            <div className="_1iww" />
            <div className="_1iwx" />
            <div className="_1iwy" />
          </div>
        </div>
      </div>
    ));

export default RiderSkeletonLoader;
