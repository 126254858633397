import React, { useState, useEffect, useCallback } from "react";
import { FilterLabel, FilterSection, FilterWrapper } from "./Filter.style";
import { useRef } from "react";
import { useSelector } from "react-redux";
import SelectComp, { DropDownArrow, TextInput } from "./FilterDropDown";
import "styled-components/macro";
import useGetFilterOptions from "../../custom-hooks/useGetFilterOptions";
import useSetFilter from "../../custom-hooks/useSetFilter";
import { debounce } from "lodash";
import { getInWords } from "../../utils/helper";
import * as mq from "../../styles/mediaQueries";
import { getMoneyAsOptions } from "./Filter.constant";

const Filters = ({ setShowInsurer, isComparePage }) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);
  const investedAmountCss = `
background: transparent;
width: 5rem;
font-size: 13px;
font-family: "Inter";
font-weight: 600;
border: none;
outline: none;
color:${offset > 80 && "white"};

${mq.lg} {
  font-size: 11.5px;
}
`;

  const filterData = useSelector(state => state.filters);

  const {
    getMoneyAs,
    investedAmount,
    investFor,
    returnsAfter,
    incomeFor,
    payFrequency,
  } = filterData;

  const [getMoneyAsValue, setGetMoneyValue] = useState(getMoneyAs);
  const [investedAmountValue, setInvestedAmountValue] =
    useState(investedAmount);
  const [investForValue, setInvestForValue] = useState(investFor);
  const [returnsAfterValue, setReturnsAfterValue] = useState(returnsAfter);
  const [incomeForValue, setIncomeForValue] = useState(incomeFor);
  const [payFrequencyValue, setPayFrequencyValue] = useState(payFrequency);
  const [value, setValue] = useState(investedAmount);

  useEffect(() => {
    setInvestForValue(investFor);
    setIncomeForValue(incomeFor);
    setReturnsAfterValue(returnsAfter);
  }, [investFor, incomeFor, returnsAfter]);

  const investForOptions = useGetFilterOptions({ filter: "pay_term" });
  const payFrequencyOptions = useGetFilterOptions({ filter: "pay_frequency" });
  // const incomeForOptions = useGetFilterOptions({ filter: "income_for" });
  // const returnsAfterOptions = useGetFilterOptions({ filter: "policy_term" });

  const ref = useRef();
  const inputRef = useRef();
  const [edit, setEdit] = useState(false);

  useSetFilter({ filter: "investFor", value: investForValue });
  useSetFilter({ filter: "incomeFor", value: incomeForValue });
  useSetFilter({ filter: "returnsAfter", value: returnsAfterValue });
  useSetFilter({ filter: "payFrequency", value: payFrequencyValue });
  useSetFilter({ filter: "investedAmount", value: investedAmountValue });
  useSetFilter({ filter: "getMoneyAs", value: getMoneyAsValue });

  const extractNumbers = (str = "") => String(str).replace(/[^0-9]/g, "");

  const handleCoverChange = e => {
    setValue(e);
  };

  const inputFocus = () => {
    inputRef.current.focus();
  };

  useEffect(() => {
    if (edit) {
      inputFocus();
    }
  }, [edit]);

  useEffect(() => {
    investFor?.toString() !== investForValue?.toString() &&
      setInvestForValue(investFor);
    investedAmount !== investedAmountValue &&
      setInvestedAmountValue(investedAmount);
    returnsAfter?.toString() !== returnsAfterValue?.toString() &&
      setReturnsAfterValue(returnsAfter);
    incomeFor?.toString() !== incomeForValue?.toString() &&
      setIncomeForValue(incomeFor);
    payFrequency !== payFrequencyValue && setPayFrequencyValue(payFrequency);
    value !== investedAmount && setValue(investedAmount);
    getMoneyAsValue !== getMoneyAs && setGetMoneyValue(getMoneyAs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(data => setInvestedAmountValue(data), 500),
    [],
  );

  const returnsAfterOptions =
    investForOptions?.filter(option => option?.code === +investForValue)?.[0]
      ?.policy_term || [];
  const incomeForOptions =
    investForOptions?.filter(option => option?.code === +investForValue)?.[0]
      ?.income_for || [];

  return (
    <>
      <FilterWrapper ref={ref} isComparePage={isComparePage} offset={offset}>
        <FilterSection>
          <SelectComp
            offset={offset}
            label={"Get Money As"}
            valueStyle={{ fontSize: "11px" }}
            options={
              !Array?.isArray(getMoneyAsOptions)
                ? Object?.values(getMoneyAsOptions)
                : getMoneyAsOptions
            }
            selectedItem={getMoneyAsValue}
            onChange={value => {
              setGetMoneyValue(value);
            }}
            // placeholder={getMoneyAsOptions[getMoneyAsValue]}
          />
        </FilterSection>
        <FilterSection>
          <TextInput
            onClick={() => {
              setEdit(!edit);
            }}
          >
            <div
              css={`
                display: flex;
                flex-direction: column;
                width: 80%;
              `}
            >
              <FilterLabel offset={offset}>Invested Amt</FilterLabel>
              <div
                css={`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <input
                  ref={inputRef}
                  className="select-child"
                  type=""
                  inputMode="numeric"
                  css={investedAmountCss}
                  onBlur={() => {
                    // setEdit(false);
                    debouncedSave(extractNumbers(value));
                  }}
                  readOnly={!edit}
                  value={edit ? extractNumbers(value) : getInWords(value)}
                  maxLength={11}
                  onChange={e => handleCoverChange(e.target.value)}
                />
                <DropDownArrow>
                  {edit ? (
                    <i
                      key={"tick"}
                      style={{
                        color: `${offset > 80 ? "rgb(245,245,245)" : "#000"}`,
                        fontSize: "11px",
                      }}
                      className="fas fa-check-circle"
                      onClick={e => {
                        setInvestedAmountValue(value);
                      }}
                    />
                  ) : (
                    <i
                      key={"edit"}
                      className="fas fa-edit"
                      style={{
                        color: `${offset > 80 ? "rgb(245,245,245)" : "#000"}`,
                        fontSize: "11px",
                      }}
                    />
                  )}
                </DropDownArrow>
              </div>
            </div>
          </TextInput>
        </FilterSection>
        <FilterSection>
          <SelectComp
            offset={offset}
            label={"Invest For"}
            valueStyle={{ fontSize: "11px" }}
            options={investForOptions}
            selectedItem={+investForValue === 1 ? "Single Pay" : +investFor}
            customButtonText="Pay Once"
            customButton={true}
            customOnClick={() => {
              setInvestForValue(1);
            }}
            onChange={value => {
              setInvestForValue(value);
            }}
          />
        </FilterSection>
        {getMoneyAs === "I" || getMoneyAs === "B" ? (
          <FilterSection>
            <SelectComp
              offset={offset}
              label={"Income For"}
              valueStyle={{ fontSize: "11px" }}
              options={incomeForOptions}
              selectedItem={+incomeFor}
              onChange={value => {
                setIncomeForValue(value);
                // const age = getAge(data.dob);
                // const newReturnAfter = Math.abs(value - age);
                // setReturnsAfterValue(newReturnAfter);
              }}
            />
          </FilterSection>
        ) : (
          <FilterSection>
            <SelectComp
              offset={offset}
              label={"Returns After"}
              valueStyle={{ fontSize: "11px" }}
              options={returnsAfterOptions}
              selectedItem={+returnsAfter}
              onChange={value => {
                setReturnsAfterValue(value);
              }}
            />
          </FilterSection>
        )}
        <FilterSection last>
          {+investFor === 1 ? (
            <div className="single-pay">Single Pay</div>
          ) : (
            <SelectComp
              offset={offset}
              label={"Pay"}
              valueStyle={{ fontSize: "11px" }}
              options={payFrequencyOptions}
              selectedItem={payFrequency}
              onChange={value => {
                setPayFrequencyValue(value);
              }}
            />
          )}
        </FilterSection>
      </FilterWrapper>
    </>
  );
};

export default Filters;
