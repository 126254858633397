import React from 'react';
import dollor from '../../../../assets/images/dollar.svg';
import dollorgroup from '../../../../assets/images/Group 234.svg';
import money from '../../../../assets/images/money.svg';
import moneybag from '../../../../assets/images/MoneyBag.svg';
// import arrow from "../../../../assets/images/Arrow.svg";
import add from '../../../../assets/images/add.png';
import styled from 'styled-components/macro';
import * as mq from '../../../../styles/mediaQueries';

import { Accordion } from '@material-ui/core';
import { FaChevronDown } from 'react-icons/fa';
import { AccordionSummary, AccordionDetails } from '@material-ui/core';

function PlanDetailsMobile({ quoteData }) {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Main>
      <div
        css={`
          width: 90%;
          margin: 10px auto 5px auto;
          padding-top: 10px;
        `}
      >
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          style={{
            boxShadow: '0px 5px 10px 3px rgba(0,0,0,0,0.15)',
            marginBottom: '10px',
            border: 'none',
            borderRadius: '5px',
          }}
        >
          <AccordionSummary
            style={{ height: '8em' }}
            expandIcon={<FaChevronDown />}
          >
            <Title>
              <span
                css={`
                  color: var(--primary-color);
                  font-weight: bold;
                `}
              >
                Basic Criteria
                {/* {item.feature_key} */}
              </span>{' '}
              <span
                css={`
                  font-weight: 600;
                  font-size: 11px;
                `}
              >
                Boundary Conditions for which the Life Assured is Eligible is
                for this Product
                {/* {item.feature_description} */}
              </span>
            </Title>
          </AccordionSummary>
          <Info>
            <AccordionDetails
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span
                css={`
                  color: var(--secondary-text);
                  font-weight: 600;
                `}
              >
                Entry Age : 18 to 60 Years
                {/* {i.feature_key_title}
                {" : "}
                
                {i.feature_key_value} */}
              </span>

              <span
                css={`
                  padding-bottom: 10px;
                  border-bottom: 1px dashed black;
                `}
              >
                {' '}
                The Minimum And Maximum Entry Age is 18 years and 60 Years
                respectively. For Pay till 60, The Minimum Entry Age is 50
                Years.
                {/* {i.feature_key_description} */}
              </span>
            </AccordionDetails>
            <AccordionDetails
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span
                css={`
                  color: var(--secondary-text);
                  font-weight: 600;
                `}
              >
                Entry Age : 18 to 60 Years
                {/* {i.feature_key_title}
                {" : "}
                
                {i.feature_key_value} */}
              </span>

              <span
                css={`
                  padding-bottom: 10px;
                  border-bottom: 1px dashed black;
                `}
              >
                {' '}
                The Minimum And Maximum Entry Age is 18 years and 60 Years
                respectively. For Pay till 60, The Minimum Entry Age is 50
                Years.
                {/* {i.feature_key_description} */}
              </span>
            </AccordionDetails>
          </Info>
        </Accordion>
      </div>
    </Main>
  );
}

export default PlanDetailsMobile;
const Main = styled.div`
  display: none;
  ${mq.md} {
    display: block;
  }
`;

const Info = styled.div`
  background: var(--tertiary-shade);
  text-align: left;
  border-radius: 10px;
  margin-top: 15px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
`;

const Head = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const Title1 = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #000;
`;
const Text = styled.div`
  font-size: 8px;
  font-weight: 500;
`;

const InvestSection = styled.div`
  border: 1px solid #e4e4e4;
  border-radius: 0.7em;
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: 1em;
`;
const InvestSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0.5em;
`;
const Box1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  border: 1px solid var(--primary-color);
  padding: 0.5em;
  border-radius: 0.7em;
  height: 9rem;
`;
const Part = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Image = styled.div``;
const Text2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BoxContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const BoxButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 0.5em;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex: 1;
  padding: 0.5em;
  height: 1.5rem;
  font-size: 7px;
  border-radius: 0.4em;
  background-color: var(--primary-color);
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0.8;
`;
const Text3 = styled.div`
  font-size: 0.7em;
  font-weight: 500;
  text-align: center;
  line-height: 0.9em;
`;

<InvestSection>
  <InvestSectionContainer>
    <Head>You Invest</Head>
    <Box1
      css={`
        background-color: #f0f7ff;
      `}
    >
      <BoxContent>
        <Part>
          <Image>
            <img src={dollor} alt="dollor" height={30} />
          </Image>
          <Text className="mt-2">You Invest</Text>
          <Text2>
            <Title1>10k/</Title1>
            <Text>Month</Text>
          </Text2>
        </Part>
        <Part>
          <Image>
            <img src={dollorgroup} alt="dollorgroup" height={30} />
          </Image>
          <Text className="mt-2">Total Investment</Text>
          <Text2>
            <Title1>12 Lakh</Title1>
          </Text2>
        </Part>
      </BoxContent>
      <BoxButton>
        <Buttons>2023</Buttons>
        <Content>
          <Text3>For 10 years </Text3>
          {/* <img src={arrow} alt="arrow" width={35} /> */}
        </Content>
        <Buttons>2032</Buttons>
      </BoxButton>
    </Box1>
  </InvestSectionContainer>
  <InvestSectionContainer>
    <Head>You Receive</Head>
    <Box1
      css={`
        border: 1px solid #2cd44a;
        background-color: #dcffea;
      `}
    >
      <BoxContent>
        <Part>
          <Image>
            <img src={money} alt="money" height={25} />
          </Image>
          <Text className="mt-2">Monthly Income</Text>
          <Text2>
            <Title1>9.07k/</Title1>
            <Text>Month</Text>
          </Text2>
        </Part>
        <Part>
          <Image>
            <img src={moneybag} alt="moneybag" height={25} />
          </Image>
          <Text className="mt-2">Lumpsum Amount</Text>
          <Text2>
            <Title1>12 Lakh</Title1>
          </Text2>
        </Part>
      </BoxContent>
      <BoxButton>
        <Buttons
          css={`
            background-color: #2cd44a;
          `}
        >
          2051 to 2057
        </Buttons>
        <Content>
          <img src={add} alt="arrow" width={20} />
        </Content>
        <Buttons
          css={`
            background-color: #2cd44a;
          `}
        >
          2023
        </Buttons>
      </BoxButton>
    </Box1>
  </InvestSectionContainer>
</InvestSection>;
