import CompareFilterDropdown from "./CompareFilterDropdown";
import { Box, Button, Grid } from "@mui/material";
import refresh from "../../../assets/images/refresh.png";
import { useDispatch } from "react-redux";
import {
  setInsuranceCompany,
  setProduct,
  setInvestmentAmount,
  setFrequency,
  setInvestFor,
  setReturnsAfter,
  resetStates,
  setIncomeFor,
} from "../slice/newCompare.slice";
import styled from "styled-components";
import ButtonLoader from "../../../components/Loaders/ButtonLoader/ButtonLoader";
import InvestmentAmountTextInput from "./InvestmentAmountTextInput";
import useNewCompare from "../hooks/useNewCompare";

const CompareFilters = () => {
  const {
    investmentCompaniesList,
    productsList,
    frequencyList,
    investForList,
    incomeForList,
    returnsAfterList,
    isPremiumLoading,
    isProductLoading,
    isFilterLoading,
    isCompany,
    isProduct,
    isInvestmentAmount,
    isFrequency,
    isInvestFor,
    isIncomeFor,
    isReturnsAfter,
    insuranceCompany,
    product,
    investmentAmount,
    frequency,
    investFor,
    incomeFor,
    returnsAfter,
    isIncome,
    checkValues,
    handleAddToList,
  } = useNewCompare();
  const dispatch = useDispatch();
  return (
    <FilterBoxStyled>
      <div className="row">
        <div className="col-md-12 col-lg-12 ">
          <StyledGrid container>
            {/* Insurance Company */}
            <GridChild item xs={2}>
              <p>Insurance Company</p>
              <CompareFilterDropdown
                options={investmentCompaniesList}
                isReset={isCompany}
                previousSelectedValue={insuranceCompany?.code}
                onChangeHandler={setInsuranceCompany}
              />
            </GridChild>
            {/* Product Name */}
            <GridChild item xs={2}>
              <p>Product Name</p>
              {isProductLoading ? (
                <ButtonLoader colored />
              ) : (
                <CompareFilterDropdown
                  options={isCompany ? productsList : []}
                  isReset={isProduct}
                  onChangeHandler={setProduct}
                  previousSelectedValue={product?.code}
                />
              )}
            </GridChild>
            {/* Investment Amount */}
            <GridChild item xs={1.6}>
              <p>Investment Amount</p>
              {isFilterLoading ? (
                <ButtonLoader colored />
              ) : (
                <InvestmentAmountTextInput
                  isReset={isInvestmentAmount}
                  isDisable={!isProduct}
                  investmentAmount={investmentAmount}
                  onChangeHandler={setInvestmentAmount}
                />
              )}
            </GridChild>
            {/* Frequency */}
            <GridChild item xs={1.6}>
              <p>Frequency</p>
              {isFilterLoading ? (
                <ButtonLoader colored />
              ) : (
                <CompareFilterDropdown
                  options={isProduct ? frequencyList : []}
                  isReset={isFrequency}
                  previousSelectedValue={frequency?.code}
                  onChangeHandler={setFrequency}
                />
              )}
            </GridChild>
            {/* Invest For */}
            <GridChild item xs={1.6}>
              <p>Invest For</p>{" "}
              {isFilterLoading ? (
                <ButtonLoader colored />
              ) : (
                <CompareFilterDropdown
                  options={isProduct ? investForList : []}
                  isReset={isInvestFor}
                  previousSelectedValue={investFor?.code}
                  onChangeHandler={setInvestFor}
                />
              )}
            </GridChild>
            {/* Returns After */}
            <GridChild item xs={1.6}>
              <p>{isIncome ? "Income For" : "Returns After"}</p>
              {isFilterLoading ? (
                <ButtonLoader colored />
              ) : (
                <>
                  {isIncome ? (
                    <CompareFilterDropdown
                      options={isProduct ? incomeForList : []}
                      isReset={isIncomeFor}
                      previousSelectedValue={incomeFor?.code}
                      onChangeHandler={setIncomeFor}
                    />
                  ) : (
                    <CompareFilterDropdown
                      options={isProduct ? returnsAfterList : []}
                      isReset={isReturnsAfter}
                      previousSelectedValue={returnsAfter?.code}
                      onChangeHandler={setReturnsAfter}
                    />
                  )}
                </>
              )}
            </GridChild>
            {/* Add to list Button */}
            <GridChildButtonWrap item xs={1.6}>
              <ButtonStyled
                disabled={checkValues()}
                onClick={handleAddToList}
                variant="contained"
                fullWidth
              >
                {isPremiumLoading ? <ButtonLoader /> : "Add to list"}
              </ButtonStyled>
              <img
                src={refresh}
                alt="Refresh"
                onClick={() => dispatch(resetStates())}
              />
            </GridChildButtonWrap>
          </StyledGrid>
        </div>
      </div>
    </FilterBoxStyled>
  );
};

export default CompareFilters;

const FilterBoxStyled = styled(Box)`
  margin-top: 20px;
  box-shadow: 6px 12px 20px #e7f0f7;
  padding: 10px 0;
  border-radius: 4px;
`;

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
`;

const GridChild = styled(Grid)`
  border-right: 2px solid #e8ebee;
  padding: 0 15px 0 15px;
  border: ${props => props.border};

  p {
    margin: 0px;
    font-weight: 600;
    color: #333;
    white-space: nowrap;
  }
`;

const GridChildButtonWrap = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: 10px;
    height: 15px;
    width: 15px;
    padding: 2px;
    cursor: pointer;
  }
`;

export const ButtonStyled = styled(Button)`
  text-transform: none !important;
  width: 90px !important;
  font-size: 0.9rem !important;
  white-space: nowrap;
  background-color: var(--primary-color) !important;
  color: #fff !important;
`;
