import { createSlice } from '@reduxjs/toolkit';

const compareSlice = createSlice({
  name: 'compare',
  initialState: {
    showSelected: false,
    showDifference: false,
    isDownloading: false,
    compareProducts: [],
    allFeaturesData: {
      planType: {},
      paymentOptions: {},
      planBenefits: {},
      insurerDetails: {},
    },
    differentFeatures: {
      planType: [],
      paymentOptions: [],
      planBenefits: [],
      insurerDetails: [],
    },
    selectedFeatures: {
      selectedDetails: [],
      planType: [],
      paymentOptions: [],
      planBenefits: [],
      insurerDetails: [],
    },
  },
  reducers: {
    setCompareProducts: (state, action) => {
      const all = action.payload;
      state.compareProducts = action.payload;

      let k1 = Object.keys(state.allFeaturesData.planType);
      k1.length > 0 &&
        k1.map((item) => {
          if (state.allFeaturesData.planType[item].length > 0) {
            state.allFeaturesData.planType[item] =
              state.allFeaturesData.planType[item].filter((el) => {
                return all.includes(el.product_id);
              });

            let arr = state.allFeaturesData.planType[item];
            if (arr.length === 2) {
              if (arr[0].compare_value === arr[1].compare_value) {
                state.differentFeatures.planType =
                  state.differentFeatures.planType.filter((x) => x != item);
              } else if (
                arr[0].compare_value !== arr[1].compare_value &&
                !state.differentFeatures.planType.includes(item)
              ) {
                state.differentFeatures.planType.push(item);
              }
            }
            if (arr.length === 3) {
              if (
                arr[0].compare_value === arr[1].compare_value &&
                arr[1].compare_value === arr[2].compare_value
              ) {
                state.differentFeatures.planType =
                  state.differentFeatures.planType.filter((x) => x != item);
              } else if (
                !(
                  arr[0].compare_value === arr[1].compare_value &&
                  arr[1].compare_value === arr[2].compare_value
                ) &&
                !state.differentFeatures.planType.includes(item)
              ) {
                state.differentFeatures.planType.push(item);
              }
            }
          }
        });

      let k2 = Object.keys(state.allFeaturesData.paymentOptions);
      k2.length > 0 &&
        k2.map((item) => {
          if (state.allFeaturesData.paymentOptions[item].length > 0) {
            state.allFeaturesData.paymentOptions[item] =
              state.allFeaturesData.paymentOptions[item].filter((el) => {
                return all.includes(el.product_id);
              });
          }
          let arr = state.allFeaturesData.paymentOptions[item];
          if (arr.length === 2) {
            if (arr[0].compare_value === arr[1].compare_value) {
              state.differentFeatures.paymentOptions =
                state.differentFeatures.paymentOptions.filter((x) => x != item);
            } else if (
              arr[0].compare_value !== arr[1].compare_value &&
              !state.differentFeatures.paymentOptions.includes(item)
            ) {
              state.differentFeatures.paymentOptions.push(item);
            }
          }
          if (arr.length === 3) {
            if (
              arr[0].compare_value === arr[1].compare_value &&
              arr[1].compare_value === arr[2].compare_value
            ) {
              state.differentFeatures.paymentOptions =
                state.differentFeatures.paymentOptions.filter((x) => x != item);
            } else if (
              !(
                arr[0].compare_value === arr[1].compare_value &&
                arr[1].compare_value === arr[2].compare_value
              ) &&
              !state.differentFeatures.paymentOptions.includes(item)
            ) {
              state.differentFeatures.paymentOptions.push(item);
            }
          }
        });

      let k3 = Object.keys(state.allFeaturesData.planBenefits);
      k3.length > 0 &&
        k3.map((item) => {
          if (state.allFeaturesData.planBenefits[item].length > 0) {
            state.allFeaturesData.planBenefits[item] =
              state.allFeaturesData.planBenefits[item].filter((el) => {
                return all.includes(el.product_id);
              });
          }

          let arr = state.allFeaturesData.planBenefits[item];
          if (arr.length === 2) {
            if (arr[0].compare_value === arr[1].compare_value) {
              state.differentFeatures.planBenefits =
                state.differentFeatures.planBenefits.filter((x) => x != item);
            } else if (
              arr[0].compare_value !== arr[1].compare_value &&
              !state.differentFeatures.planBenefits.includes(item)
            ) {
              state.differentFeatures.planBenefits.push(item);
            }
          }
          if (arr.length === 3) {
            if (
              arr[0].compare_value === arr[1].compare_value &&
              arr[1].compare_value === arr[2].compare_value
            ) {
              state.differentFeatures.planBenefits =
                state.differentFeatures.planBenefits.filter((x) => x != item);
            } else if (
              !(
                arr[0].compare_value === arr[1].compare_value &&
                arr[1].compare_value === arr[2].compare_value
              ) &&
              !state.differentFeatures.planBenefits.includes(item)
            ) {
              state.differentFeatures.planBenefits.push(item);
            }
          }
        });

      let k4 = Object.keys(state.allFeaturesData.insurerDetails);
      k4.length > 0 &&
        k4.map((item) => {
          if (state.allFeaturesData.insurerDetails[item].length > 0) {
            state.allFeaturesData.insurerDetails[item] =
              state.allFeaturesData.insurerDetails[item].filter((el) => {
                return all.includes(el.product_id);
              });
          }

          let arr = state.allFeaturesData.insurerDetails[item];
          if (arr.length === 2) {
            if (arr[0].compare_value === arr[1].compare_value) {
              state.differentFeatures.insurerDetails =
                state.differentFeatures.insurerDetails.filter((x) => x != item);
            } else if (
              arr[0].compare_value !== arr[1].compare_value &&
              !state.differentFeatures.insurerDetails.includes(item)
            ) {
              state.differentFeatures.insurerDetails.push(item);
            }
          }
          if (arr.length === 3) {
            if (
              arr[0].compare_value === arr[1].compare_value &&
              arr[1].compare_value === arr[2].compare_value
            ) {
              state.differentFeatures.insurerDetails =
                state.differentFeatures.insurerDetails.filter((x) => x != item);
            } else if (
              !(
                arr[0].compare_value === arr[1].compare_value &&
                arr[1].compare_value === arr[2].compare_value
              ) &&
              !state.differentFeatures.insurerDetails.includes(item)
            ) {
              state.differentFeatures.insurerDetails.push(item);
            }
          }
        });

      return state;
    },
    //****************************************************/
    addCompareFeatures: (state, action) => {
      state.compareFeatures.push(action.payload);
      return state;
    },

    // set all features and check if they are different
    setAllFeatures: (state, action) => {
      const { featureName, item, product_id } = action.payload;
      const idObj = { product_id, ...item };

      if (featureName.includes('plan_type')) {
        if (!state.allFeaturesData.planType[featureName]) {
          state.allFeaturesData.planType[featureName] = [idObj];
        } else {
          if (
            !state.allFeaturesData.planType[featureName].some(
              (v) => v.product_id === product_id
            )
          ) {
            state.allFeaturesData.planType[featureName].push(idObj);
          }
          let temp = state.allFeaturesData.planType[featureName];

          if (
            temp.length === 2 &&
            !state.differentFeatures.planType.includes(featureName)
          ) {
            if (temp[0].compare_value !== temp[1].compare_value) {
              state.differentFeatures.planType.push(featureName);
            }
          }
          if (
            temp.length === 3 &&
            !state.differentFeatures.planType.includes(featureName)
          ) {
            if (
              !(
                temp[0].compare_value === temp[1].compare_value &&
                temp[1].compare_value === temp[2].compare_value
              )
            ) {
              state.differentFeatures.planType.push(featureName);
            }
          }
        }
      }
      //************************************************/
      else if (featureName.includes('payment_options')) {
        if (!state.allFeaturesData.paymentOptions[featureName]) {
          state.allFeaturesData.paymentOptions[featureName] = [idObj];
        } else {
          if (
            !state.allFeaturesData.paymentOptions[featureName].some(
              (v) => v.product_id === product_id
            )
          ) {
            state.allFeaturesData.paymentOptions[featureName].push(idObj);
          }
          let temp = state.allFeaturesData.paymentOptions[featureName];
          if (
            temp.length === 2 &&
            !state.differentFeatures.paymentOptions.includes(featureName)
          ) {
            if (temp[0].compare_value != temp[1].compare_value) {
              state.differentFeatures.paymentOptions.push(featureName);
            }
          }
          if (
            temp.length === 3 &&
            !state.differentFeatures.paymentOptions.includes(featureName)
          ) {
            if (
              !(
                temp[0].compare_value === temp[1].compare_value &&
                temp[1].compare_value === temp[2].compare_value
              )
            ) {
              state.differentFeatures.paymentOptions.push(featureName);
            }
          }
        }
      }
      //************************************************/
      else if (featureName.includes('plan_benefits')) {
        if (!state.allFeaturesData.planBenefits[featureName]) {
          state.allFeaturesData.planBenefits[featureName] = [idObj];
        } else {
          if (
            !state.allFeaturesData.planBenefits[featureName].some(
              (v) => v.product_id === product_id
            )
          ) {
            state.allFeaturesData.planBenefits[featureName].push(idObj);
          }
          let temp = state.allFeaturesData.planBenefits[featureName];
          if (
            temp.length === 2 &&
            !state.differentFeatures.planBenefits.includes(featureName)
          ) {
            if (temp[0].compare_value != temp[1].compare_value) {
              state.differentFeatures.planBenefits.push(featureName);
            }
          }
          if (
            temp.length === 3 &&
            !state.differentFeatures.planBenefits.includes(featureName)
          ) {
            if (
              !(
                temp[0].compare_value === temp[1].compare_value &&
                temp[1].compare_value === temp[2].compare_value
              )
            ) {
              state.differentFeatures.planBenefits.push(featureName);
            }
          }
        }
      }
      //************************************************/
      else if (featureName.includes('insurer_details')) {
        if (!state.allFeaturesData.insurerDetails[featureName]) {
          state.allFeaturesData.insurerDetails[featureName] = [idObj];
        } else {
          if (
            !state.allFeaturesData.insurerDetails[featureName].some(
              (v) => v.product_id === product_id
            )
          ) {
            state.allFeaturesData.insurerDetails[featureName].push(idObj);
          }
          let temp = state.allFeaturesData.insurerDetails[featureName];
          if (
            temp.length === 2 &&
            !state.differentFeatures.insurerDetails.includes(featureName)
          ) {
            if (temp[0].compare_value != temp[1].compare_value) {
              state.differentFeatures.insurerDetails.push(featureName);
            }
          }
          if (
            temp.length === 3 &&
            !state.differentFeatures.insurerDetails.includes(featureName)
          ) {
            if (
              !(
                temp[0].compare_value === temp[1].compare_value &&
                temp[1].compare_value === temp[2].compare_value
              )
            ) {
              state.differentFeatures.insurerDetails.push(featureName);
            }
          }
        }
      }

      return state;
    },

    addSelectedFeature: (state, action) => {
      let featureName = action.payload;
      if (
        featureName.includes('plan_type') &&
        !state.selectedFeatures.planType.includes(featureName)
      ) {
        state.selectedFeatures.planType.push(action.payload);
      } else if (
        featureName.includes('payment_options') &&
        !state.selectedFeatures.paymentOptions.includes(featureName)
      ) {
        state.selectedFeatures.paymentOptions.push(action.payload);
      } else if (
        featureName.includes('plan_benefits') &&
        !state.selectedFeatures.planBenefits.includes(featureName)
      ) {
        state.selectedFeatures.planBenefits.push(action.payload);
      } else if (
        featureName.includes('insurer_details') &&
        !state.selectedFeatures.insurerDetails.includes(featureName)
      ) {
        state.selectedFeatures.insurerDetails.push(action.payload);
      } else {
        if (!state.selectedFeatures.selectedDetails.includes(featureName)) {
          state.selectedFeatures.selectedDetails.push(action.payload);
        }
      }
      return state;
    },
    removeSelectedFeature: (state, action) => {
      let featureName = action.payload;
      if (featureName.includes('plan_type')) {
        state.selectedFeatures.planType =
          state.selectedFeatures.planType.filter(
            (item) => item != action.payload
          );
      } else if (featureName.includes('payment_options')) {
        state.selectedFeatures.paymentOptions =
          state.selectedFeatures.paymentOptions.filter(
            (item) => item != action.payload
          );
      } else if (featureName.includes('plan_benefits')) {
        state.selectedFeatures.planBenefits =
          state.selectedFeatures.planBenefits.filter(
            (item) => item != action.payload
          );
      } else if (featureName.includes('insurer_details')) {
        state.selectedFeatures.insurerDetails =
          state.selectedFeatures.insurerDetails.filter(
            (item) => item != action.payload
          );
      } else {
        state.selectedFeatures.selectedDetails =
          state.selectedFeatures.selectedDetails.filter(
            (item) => item != action.payload
          );
      }

      if (
        state.selectedFeatures.planType.length === 0 &&
        state.selectedFeatures.paymentOptions.length === 0 &&
        state.selectedFeatures.planBenefits.length === 0 &&
        state.selectedFeatures.selectedDetails.length === 0 &&
        state.selectedFeatures.insurerDetails.length === 0 &&
        state.showDifference === false
      ) {
        state.showSelected = false;
      }

      return state;
    },
    setShowDifference: (state, action) => {
      state.showDifference = action.payload;
      if (
        state.selectedFeatures.planType.length === 0 &&
        state.selectedFeatures.paymentOptions.length === 0 &&
        state.selectedFeatures.planBenefits.length === 0 &&
        state.selectedFeatures.selectedDetails.length === 0 &&
        state.selectedFeatures.insurerDetails.length === 0 &&
        action.payload === false
      ) {
        state.showSelected = false;
      }
    },

    setShowSelected: (state, { payload }) => {
      state.showSelected = payload;
    },
    initDownloading: (state) => {
      state.isDownloading = true;
    },
    clearDownloading: (state) => {
      state.isDownloading = false;
    },
  },
});

export const {
  setCompareProducts,
  addCompareFeatures,
  removeSelectedFeature,
  addSelectedFeature,
  setShowDifference,
  setShowSelected,
  initDownloading,
  clearDownloading,
  setAllFeatures,
} = compareSlice.actions;

export default compareSlice.reducer;

export const selectShowDifference = (state) => state.compare.showDifference;
