import React from "react";
import styled from "styled-components/macro";

import { ModalBodyInner } from "./GetDetails";
import * as mq from "../../../../styles/mediaQueries";
import { useSelector } from "react-redux";

const findAboutCompany = (aboutCompany, key) => {
  return Object.values(aboutCompany).find(
    (item) => item.feature_key_title === key
  );
};
function AboutComany() {
  const {
    frontendData: { investmentcompanies },
  } = useSelector((state) => state.frontendBoot);
  const { selectedQuote } = useSelector((state) => state.quotes);
  const { aboutCompany } = useSelector((state) => state.quotes.knowMoreData);
  return (
    <ModalBodyInner>
      <About
        css={`
          ${mq.md} {
            width: 100%;
          }
        `}
      >
        <AboutTitle>
          About{" "}
          <span>
            {investmentcompanies[selectedQuote?.company_alias]?.short_name}
          </span>
        </AboutTitle>
        <Text>
          {
            findAboutCompany(aboutCompany, "Company Info")
              ?.feature_key_description
          }
        </Text>
        <AboutBottom>
          {Object.values(aboutCompany).map((item, index) => {
            if (index)
              return (
                <>
                  <AboutInd
                    notFirst={index !== 1}
                    mobileRight={index % 2 === 0}
                    mobileTop={index < 3}
                    key={item.feature_key_title}
                  >
                    <div className="about-head">{item?.feature_key_title}</div>
                    <div className="about-val"> {item?.feature_key_value}</div>
                    <div className="about-text">
                      {item?.feature_key_description}
                    </div>
                  </AboutInd>
                </>
              );
            else return <></>;
          })}
        </AboutBottom>
      </About>
    </ModalBodyInner>
  );
}

export default AboutComany;

const About = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inter";
`;

const AboutTitle = styled.div`
  width: 100%;
  font-family: "Inter";
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
  text-align: center;
  span {
    color: var(--primary-color);
  }
  ${mq.lg} {
    font-size: 18px;
  }
  ${mq.md} {
    font-size: 15px;
  }
  ${mq.xs} {
    font-size: 13px;
  }
`;

const Text = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
  text-align: justify;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-light);
  ${mq.lg} {
    font-size: 12px;
    font-weight: 400;
  }
  ${mq.md} {
    font-size: 12px;
    text-align: center;
  }
  ${mq.xs} {
    font-size: 11px;
    text-align: center;
  }
`;

const AboutBottom = styled.div`
  margin-top: 20px;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  ${mq.md} {
    flex-wrap: wrap;
  }
`;
const AboutInd = styled.div`
  width: 24.8%;
  gap: 20px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  border-left: ${(props) => props.notFirst && "1px solid var(--border-light)"};
  ${mq.lg} {
    gap: 15px;
    padding: 15px 10px;
  }
  ${mq.md} {
    width: 50%;
    gap: 10px;
    border-left: 0px;
    border-left: ${(props) =>
      props.mobileRight && "1px solid var(--border-light)"};
    border-bottom: ${(props) =>
      props.mobileTop && "1px solid var(--border-light)"};
  }

  .about-head {
    font-family: "Inter";
    font-weight: 500;
    font-size: 18px;
    transition: all 0.3s ease;
    ${mq.lg} {
      font-size: 16px;
    }
    ${mq.md} {
      font-size: 12px;
      color: #000;
    }
    ${mq.xs} {
      font-size: 11px;
    }
  }
  &:hover {
    box-shadow: rgb(0, 0, 0, 0.16) 0px 3px 15px 0px;
    .about-head {
      color: var(--primary-color) !important;
    }
  }

  .about-val {
    font-family: "Inter";
    font-weight: 500;
    font-size: 20px;
    font-weight: 600;
    ${mq.lg} {
      font-size: 18px;
    }
    ${mq.md} {
      font-size: 16px;
    }
    ${mq.xs} {
      font-size: 14px;
    }
  }
  .about-text {
    font-family: "Inter";
    font-size: 14px;
    ${mq.lg} {
      font-size: 13px;
    }
    ${mq.md} {
      font-size: 12px;
    }
    ${mq.xs} {
      font-size: 11px;
    }
  }
`;
