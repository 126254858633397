import React from "react";
import {
  createStyles,
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import * as mq from "../../../../../../../styles/mediaQueries";
import { useState } from "react";
import styled from "styled-components/macro";
import useSetFilter from "../../../../../../../custom-hooks/useSetFilter";
import { useSelector } from "react-redux";

function GetMoneyAs({ setShow }) {
  const val = useSelector(state => state.filters.getMoneyAs);
  const [getMoneyAsValue, setGetMoneyValue] = useState(val);
  useSetFilter({ filter: "getMoneyAs", value: getMoneyAsValue });

  return (
    <Wrapper>
      <RadioGroup
        aria-labelledby="pay-frequrncy-radio-buttons"
        name="pay-frequrncy-radio-buttons"
        value={val}
        onChange={e => {
          setGetMoneyValue(e.target.value);
          setShow(false);
        }}
      >
        <RadioIndv>
          <FormControlLabel
            value="L"
            control={<Radio size="small" />}
            label={
              <span
                css={`
                  ${mq.md} {
                    font-size: 18px;
                  }
                  ${mq.sm} {
                    font-size: 13px;
                  }
                `}
              >
                Lumpsum
              </span>
            }
          />
        </RadioIndv>
        <RadioIndv>
          <FormControlLabel
            value="I"
            control={<Radio size="small" />}
            label={
              <span
                css={`
                  ${mq.md} {
                    font-size: 18px;
                  }
                  ${mq.sm} {
                    font-size: 13px;
                  }
                `}
              >
                Income
              </span>
            }
          />
        </RadioIndv>
        <RadioIndv>
          <FormControlLabel
            value="B"
            control={<Radio size={mq.sm ? "small" : "medium"} />}
            label={
              <span
                css={`
                  ${mq.md} {
                    font-size: 18px;
                  }
                  ${mq.sm} {
                    font-size: 13px;
                  }
                `}
              >
                Lumpsum + Income
              </span>
            }
          />
        </RadioIndv>
      </RadioGroup>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const RadioIndv = styled.div`
  border-radius: 5px;
  margin-top: 10px;
  width: 99%;
  border: 1px solid rgb(210, 210, 210);
  margin: 3px;
  ${mq.md} {
    padding: 5px 10px;
  }
  ${mq.sm} {
    padding: 0px 10px;
  } ;
`;
export default GetMoneyAs;
