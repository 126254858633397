//  import { debounce } from "@material-ui/core";
// import { useCallback, useEffect } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectInvestmentFilters } from "../../Filters/filterSlice";
import { setSelectedQuote } from "../../QuotesPage/Quotes.slice";
import { useGetCartQuery, useGetQuoteQuery } from "../../rider/services/rider";

function usePlanCard() {
  const { data: cartData } = useGetCartQuery();
  const { isFilterUpdating, ...filters } = useSelector(state => state.filters);

  const { newFilters } = useSelector(state => state.inputForm);
  const dispatch = useDispatch();
  const {
    data: quoteData,
    isLoading,
    isFetching,
  } = useGetQuoteQuery(
    {
      company_alias: cartData?.data?.product?.company.alias,
      newFilters,
      differentQuote: false,
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (!isLoading && !isFetching && quoteData?.data[0]) {
      dispatch(setSelectedQuote(quoteData?.data[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching]);

  // const quote = quoteData?.data?.find(
  //   item => item.product.id === cartData?.data?.product?.id,
  // );

  const selectedProduct = cartData;
  const premiumLoading = isLoading || isFetching;
  const ridersAdded = cartData?.investment_riders;

  // const debouncedUpdate = useCallback(
  //   debounce(data => updateCart(data), 500),
  //   [],
  // );
  // useDidMountEffect(() => {
  //   updateCart({
  //     cartId: cartData.id,
  //     ...sendProductData,
  //   });
  // }, [quote?.total_premium]);

  if (!cartData || !quoteData)
    return {
      totalGst: 0,
      totalPremium: 0,
      basePremium: 0,
      ridersAdded: [],
      quote: {},
      premiumLoading,
      selectedProduct: { product: {} },
      filters,
    };

  // const { total_premium: basePremium, tax_amount: basePremiumTax } = quote;

  const totalRidersPremium = ridersAdded.reduce(
    (sum, rider) => parseInt(sum) + parseInt(rider?.total_premium),
    0,
  );

  // const totalRidersTax = ridersAdded.reduce(
  //   (taxSum, rider) => parseInt(taxSum) + parseInt(rider?.tax_amount),
  //   0,
  // );

  // const totalGst = parseInt(cartData?.service_tax) + parseInt(totalRidersTax);

  const totalPremium =
    parseInt(cartData?.total_premium || cartData?.total_premium) +
    parseInt(totalRidersPremium);

  return {
    // totalGst,
    totalPremium,
    premiumLoading,
    basePremium: cartData?.total_premium - cartData?.service_tax,
    ridersAdded,
    selectedProduct,
    filters,
  };
}

const paymentOptions = {
  regularPay: "Regular Pay",
  limitedPay: "Limited Pay",
  singlePay: "Single Pay",
};

function usePlanDetails() {
  const { totalPremium } = usePlanCard();
  const investmentFilters = useSelector(selectInvestmentFilters);

  // const {
  //   // term_sum_assured,
  //   term_coverupto,
  //   term_pay_frequency,
  // } = useSelector(selectFilterOptions);

  // const coverAmountObject = term_sum_assured.find(
  //   sumAssured => sumAssured.code === termFilters.term_sum_assured,
  // );
  const coverAmountObject = () => {
    let numbers = [];
    if (String(investmentFilters?.term_sum_assured).length >= 8) {
      let value = investmentFilters?.term_sum_assured / 10000000;
      numbers.push({
        code: investmentFilters?.term_sum_assured,
        display_name: `${value} Crore`,
      });
    } else if (String(investmentFilters?.term_sum_assured).length >= 6) {
      let value = investmentFilters?.term_sum_assured / 100000;
      numbers.push({
        code: investmentFilters?.term_sum_assured,
        display_name: `${value} Lac`,
      });
    }
    return numbers;
  };

  // const coverUptoObject = term_coverupto?.find(
  //   coverUpto => coverUpto?.code === investmentFilters?.term_coverupto,
  // );

  // const payFrequencyObject = term_pay_frequency?.find(
  //   payFrequency =>
  //     parseInt(payFrequency.code) === investmentFilters.term_pay_frequency,
  // );

  const [{ display_name: coverAmount }] = coverAmountObject();
  // const { code: coverUptoValue, display_name: coverUpto } = coverUptoObject;

  // const { display_name: payFrequency } = payFrequencyObject;

  // const limitedPayText = (term_pay_till, age, paytTill60) => {
  //   if (term_pay_till === 60 && paytTill60 === "Y") {
  //     return "Pay till 60 yrs";
  //   } else return `Limited Pay ${term_pay_till - age} `;
  // };

  // let paymentOption =
  //   term_pay_till === 1
  //     ? "Single Pay"
  //     : term_pay_till < coverUptoValue
  //     ? limitedPayText(term_pay_till, age, payTill60)
  //     : "Regular Pay";

  // if (term_pay_till < termFilters.term_coverupto) {
  //   paymentOption =
  //     paymentOptions.limitedPay + " " + String(term_pay_till - age);
  //   if (term_pay_till === 60) {
  //     paymentOption = "Pay till 60";
  //   }
  // }
  // if (term_pay_till === 1) paymentOption = paymentOptions.singlePay;

  return {
    coverAmount,
    // coverUpto,
    // paymentOption,
    // payFrequency,
    isLoading: !totalPremium,
    // isLoading: false,
  };
}

function redirectToIc(redirectionData) {
  if (redirectionData) {
    const { redirection_url, redirection_method, redirection_params } =
      redirectionData;

    const form = document.createElement("form");

    form.setAttribute("action", redirection_url);
    form.setAttribute("method", redirection_method);
    if (redirection_method === "POST") {
      Object.entries(redirection_params).forEach(([key, value]) => {
        const inputData = document.createElement("input");
        inputData.name = key;
        inputData.value = value;
        form.appendChild(inputData);
      });

      document.body.appendChild(form);
      form.submit();

      document.body.removeChild(form);
    } else if (redirection_method === "GET") {
      window.open(redirection_url, "_self");
    }
  }
}

export { usePlanCard, usePlanDetails, paymentOptions, redirectToIc };
