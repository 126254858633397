import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@material-ui/core";
import { useSelector } from "react-redux";
import useGetFilterOptions from "../../../../../../../custom-hooks/useGetFilterOptions";
import useSetFilter from "../../../../../../../custom-hooks/useSetFilter";
import * as mq from "../../../../../../../styles/mediaQueries";
import { CustomButton, CustomGroup } from "./customButton.styled";

function ReturnsAfter({ setShow }) {
  const [returnsAfterValue, setReturnsAfterValue] = useState(
    useSelector(state => state.filters.returnsAfter),
  );
  const returnsAfterOptions = useGetFilterOptions({ filter: "policy_term" });
  useSetFilter({ filter: "returnsAfter", value: returnsAfterValue });
  return (
    <>
      <CustomGroup
        color="primary"
        value={returnsAfterValue}
        exclusive
        onChange={e => {
          setReturnsAfterValue(e.target.value);
          setShow(false);
        }}
      >
        {returnsAfterOptions.length > 0 &&
          returnsAfterOptions.map((item, index) => (
            <CustomButton key={index} value={item.code}>
              {item.display_name}
            </CustomButton>
          ))}
      </CustomGroup>
    </>
  );
}




export default ReturnsAfter;
