import { InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { SiWhatsapp } from "react-icons/si";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNumberInput } from "../../../utils";
import TextInput from "../../QuotesPage/components/EditDetailsSection/components/TextInput";
import ShareViaForm from "../../QuotesPage/components/Share/ShareForm";
import { useShareQuoteMutation } from "../../rider/services/rider";
import { selectCompareQuotes } from "../ComparePlanPopupSlice";

const WhatsAppShareCompare = ({ mobile, imageUrl }) => {
  const { data } = useSelector(state => state.inputForm);
  const [shareQuotes] = useShareQuoteMutation();
  const [phone, handleChange] = useNumberInput(data.mobile);
  //   const addedRiders = useSelector(selectAddedRiders);
  const { showDifference, selectedFeatures } = useSelector(
    state => state.compare,
  );
  const compareQuotes = useSelector(selectCompareQuotes);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (phone.length === 10) setError(false);
  }, [phone]);
  const handleSubmit = async evt => {
    evt.preventDefault();

    if (phone.length === 10) {
      setError(false);
      shareQuotes({
        purpose: "compare",
        groupCode: "10003511",
        mode: ["WHATSAPP"],
        show_difference: showDifference,
        product_ids: compareQuotes,
        selected_features: selectedFeatures,
        via: "whatsapp",
        stage: "COMPARE",
        whatsapp: phone,
      }).then(data => {
        if (data.error) {
          toast.error("Sharing Failed!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          toast.success(" Shared Successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      });

      // const form = document.createElement("form");
      // form.setAttribute("action", "https://api.whatsapp.com/send");
      // form.setAttribute("target", "_blank");
      // form.setAttribute("method", "GET");

      // const phoneInput = document.createElement("input");
      // phoneInput.setAttribute("value", "91" + phone);
      // phoneInput.setAttribute("name", "phone");

      // const textInput = document.createElement("input");
      // textInput.setAttribute("value", window.location);
      // textInput.setAttribute("name", "text");

      // form.appendChild(phoneInput);
      // form.appendChild(textInput);

      // document.body.appendChild(form);
      // form.submit();
      // document.body.removeChild(form);
    } else setError("Please enter a valid whatsapp no.");
  };

  // const handleSubmit = async evt => {
  //   evt.preventDefault();
  //   // const { data } = await patchEnquiries({
  //   //   input: {
  //   //     selected_addons: addedRiders,
  //   //   },
  //   // });
  //   if (phone?.length === 10) {
  //     setError(false);
  //     const form = document.createElement("form");
  //     form.setAttribute("action", "https://api.whatsapp.com/send");
  //     form.setAttribute("target", "_blank");
  //     form.setAttribute("method", "GET");

  //     const phoneInput = document.createElement("input");
  //     phoneInput.setAttribute("value", "91" + phone);
  //     phoneInput.setAttribute("name", "phone");

  //     const textInput = document.createElement("input");
  //     textInput.setAttribute("value", window.location);
  //     textInput.setAttribute("name", "text");

  //     form.appendChild(phoneInput);
  //     form.appendChild(textInput);

  //     document.body.appendChild(form);
  //     form.submit();
  //     document.body.removeChild(form);
  //   } else setError("Please enter a valid whatsapp no.");
  // };

  return (
    <ShareViaForm
      // icon={<SiWhatsapp />}
      onSubmit={handleSubmit}
      // errorMessage={error}
    >
      <TextInput
        label=" Whatsapp Number"
        variant="outlined"
        error={error}
        value={phone}
        name="phone"
        onChange={handleChange}
        inputProps={{ minLength: 2, maxLength: 10, pattern: "[0-9]*" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SiWhatsapp className="fs-6" />
            </InputAdornment>
          ),
        }}
      />
      {/* <ShareFormInput
          type="tel"
          maxLength={10}
          value={phone}
          onChange={handleChange}
          placeholder="Enter your Whatsapp Number"
        /> */}
    </ShareViaForm>
  );
};
export default WhatsAppShareCompare;
