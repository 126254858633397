import React from "react";
import styled from "styled-components";
import * as mq from "../../../styles/mediaQueries";
import PlanCardPopup from "./PlanCardPopup";

function RiderFooterMobile({
  cartData,
  handleContinueClick,
  isRedirectionLoading,
}) {
  const [showModal, setShowModal] = React.useState(false);
  const handleClose = () => setShowModal(false);
  return (
    <Card>
      <FooterContainer>
        <FooterContent>
          <span>Total Premium Payable</span>
          <span className="fw-bolder text-black fs-6">Rs 40,135/Year</span>
        </FooterContent>
        <FooterButton1>
          <button
            className="btn btn-primary btn-sm  text-primary  bg-white border-primary fw-bold py-2 "
            onClick={() => setShowModal(!showModal)}
          >
            View Details
          </button>

          <PlanCardPopup
            show={showModal}
            handleClose={handleClose}
            cartData={cartData}
            handleContinueClick={handleContinueClick}
            isRedirectionLoading={isRedirectionLoading}
          />
        </FooterButton1>
        <FooterButton2>
          <button onClick={handleContinueClick} className="btn btn-primary btn-sm fw-bold  py-2  ">
            Continue
          </button>
        </FooterButton2>
      </FooterContainer>
    </Card>
  );
}
const Card = styled.div`
  display: none;
  ${mq.sm} {
    display: block;
    width: 100%;
    background-color: #f5f5f5;
    z-index: 1;
    position: fixed;
    left: 0;
    bottom: 0;
  }
`;
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px;
`;
const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;
const FooterButton1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  margin: 10px;
`;
const FooterButton2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
`;

export default RiderFooterMobile;
