import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useDispatch } from "react-redux";
import styled from "styled-components";
// import * as mq from "../../../styles/mediaQueries";

const CompareFilterDropdown = ({
  options = [],
  isReset = false,
  isDisable = false,
  isFullWidthOnMobile = false,
  onChangeHandler = () => {},
  previousSelectedValue = "",
}) => {
  if (!isReset) {
    options = [
      isDisable
        ? { code: "4", display_name: "Single Pay" }
        : { code: "select_option", display_name: "Select Option" },
      ...options,
    ];
  }
  const [selectedOption, setSelectedOption] = useState(
    previousSelectedValue ? previousSelectedValue : options?.[0]?.code,
  );

  const dispatch = useDispatch();

  const handleChange = e => {
    const data = options?.filter(
      option => e.target.value === option?.code,
    )?.[0];
    setSelectedOption(e.target.value);
    dispatch(onChangeHandler(data));
  };

  const menuItemStyles = {
    maxHeight: 180,
    "::-webkit-scrollbar": {
      width: "5px",
    },
    "::-webkit-scrollbar-thumb": {
      display: "block",
      outline: "none",
      backgroundColor: "#dfdfdf",
    },
    "@media (max-width:575px)": {
      width: isFullWidthOnMobile ? "100%" : "normal",
    },
  };

  return (
    <FormControlStyled>
      <SelectStyled
        onChange={handleChange}
        variant="standard"
        disableUnderline
        value={isReset ? selectedOption : options?.[0]?.code}
        IconComponent={FaAngleDown}
        MenuProps={{
          PaperProps: {
            sx: { ...menuItemStyles },
          },
        }}
      >
        {options?.map(option => (
          <MenuItemStyled key={option?.code} value={option?.code}>
            {option?.display_name}
          </MenuItemStyled>
        ))}
      </SelectStyled>
    </FormControlStyled>
  );
};

export default CompareFilterDropdown;

const FormControlStyled = styled(FormControl)`
  width: 100% !important;
  margin: 0 !important;
  padding: 3px 0 !important;
  line-height: 18px !important;
  height: 30px !important;
  border-radius: 4px !important;
`;
const SelectStyled = styled(Select)`
  font-size: 0.9rem !important;
  font-weight: bold !important;
  :focus {
    background-color: none !important;
  }
  text-overflow: ellipsis !important;
`;
const MenuItemStyled = styled(MenuItem)`
  font-size: 0.9rem !important;
  font-weight: bold !important;
  width: 100% !important;
  white-space: pre-wrap !important;
`;
