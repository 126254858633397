export const checkSize = (e, size_limit) => {
  try {
    var size = e.target.files[0].size / 1024 / 1024;
    return size < size_limit;
  } catch (e) {
    return false;
  }
};
export const checkDocType = (e, types) => {
  try {
    var type = e.target.files[0].name.split(".");

    return types.includes(type[type.length - 1]);
  } catch (e) {
    return false;
  }
};

export const convert = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
