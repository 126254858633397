import { useEffect } from "react";
import { addQuoteInList, setGetMoneyAs } from "../slice/newCompare.slice";
import {
  useGetCompareFiltersQuery,
  useGetProductsNameQuery,
} from "../../rider/services/rider";
import { useDispatch, useSelector } from "react-redux";

const useNewCompare = () => {
  const dispatch = useDispatch();
  const {
    frontendData: { investmentcompanies = {} },
  } = useSelector(state => state.frontendBoot);

  const {
    insuranceCompany,
    product,
    investmentAmount,
    frequency,
    investFor,
    returnsAfter,
    incomeFor,
    getMoneyAs,
    isPremiumLoading,
  } = useSelector(state => state?.newCompare);

  // Investment Companies List
  const investmentCompaniesList = Object.values(investmentcompanies).map(e => {
    return {
      code: e.alias,
      display_name: e.short_name,
      id: e.id,
      logo: e.logo,
    };
  });

  const { data: productData = {}, isLoading: isProductLoading } =
    useGetProductsNameQuery({
      alias: insuranceCompany?.code,
    });

  // Product Name List
  const productsList =
    productData?.data?.map(product => {
      const { name, id } = product;
      return {
        code: id,
        display_name: name,
      };
    }) || [];

  const { data: filtersData = {}, isLoading: isFilterLoading } =
    useGetCompareFiltersQuery({
      id: product?.code,
    });
  const isIncome = filtersData?.data?.[0]?.get_money_as === "I";
  // Frequency list
  const frequencyList = filtersData?.data?.[2]?.options || [];
  // Invest For List
  const investForList = filtersData?.data?.[1]?.options || [];
  // Returns After List
  const returnsAfterList = investFor?.policy_term || [];
  // Income For List
  const incomeForList = investFor?.income_for || [];
  // Get Money As
  useEffect(() => {
    dispatch(setGetMoneyAs(filtersData?.data?.[0]?.get_money_as));
  }, [filtersData, dispatch]);

  const isCompany = Object.keys(insuranceCompany)?.length ? true : false;
  const isProduct = Object.keys(product)?.length ? true : false;
  const isInvestmentAmount = investmentAmount?.length ? true : false;
  const isFrequency = Object.keys(frequency)?.length ? true : false;
  const isInvestFor = Object.keys(investFor)?.length ? true : false;
  const isReturnsAfter = Object.keys(returnsAfter)?.length ? true : false;
  const isIncomeFor = Object.keys(incomeFor)?.length ? true : false;

  // Validation
  const checkValues = () => {
    return isCompany &&
      isProduct &&
      isInvestmentAmount &&
      isInvestFor &&
      isReturnsAfter &&
      isFrequency
      ? false
      : true;
  };
  // Add to List Handler
  const handleAddToList = () => {
    dispatch(
      addQuoteInList({
        insuranceCompany,
        product,
        getMoneyAs,
        investmentAmount,
        frequency,
        investFor,
        incomeFor,
        returnsAfter,
      }),
    );
  };
  return {
    investmentCompaniesList,
    productsList,
    frequencyList,
    investForList,
    incomeForList,
    returnsAfterList,
    isPremiumLoading,
    isProductLoading,
    isFilterLoading,
    isCompany,
    isProduct,
    isInvestmentAmount,
    isFrequency,
    isInvestFor,
    isIncomeFor,
    isReturnsAfter,
    insuranceCompany,
    product,
    investmentAmount,
    frequency,
    investFor,
    incomeFor,
    returnsAfter,
    isIncome,
    checkValues,
    handleAddToList,
  };
};

export default useNewCompare;
