import React from "react";
import { Checkbox } from "@mui/material";
import "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import * as mq from "../../../styles/mediaQueries";
import { addQuote, removeQuote } from "../../ComparePage/ComparePlanPopupSlice";

export function CompareCheckbox({ quoteData }) {
  const dispatch = useDispatch();
  const matches = useMediaQuery(`${mq.mdForHook}`);
  const compareQuotes = useSelector(state => state.compareQuotes.compareQuotes);
  const productIdOfCompareQuotes = compareQuotes.map(i => i?.product?.id);
  let isPresent = compareQuotes.some(
    item => item?.product?.id === quoteData?.product?.id,
  );
  const addToCompare = () => {
    if (compareQuotes.length < (matches ? 2 : 3) && !isPresent) {
      dispatch(addQuote(quoteData));
    } else {
      dispatch(removeQuote(quoteData));
    }
  };
  return (
    <div
      onClick={() => addToCompare()}
      css={`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1px;
        ${mq.lg} {
          gap: 0px;
        }
      `}
    >
      <Checkbox
        inputProps={{
          "aria-label": "controlled",
        }}
        sx={{
          "& .MuiSvgIcon-root": {
            fontSize: 16,
          },
        }}
        css={`
          padding: 3px 3px 3px 0px !important;
          ${mq.lg} {
            padding: 3px 2px 3px 0px !important;
          }
        `}
        disabled={
          productIdOfCompareQuotes.length === 1 &&
          !productIdOfCompareQuotes.includes(quoteData?.product?.id)
            ? true
            : false
        }
        checked={
          compareQuotes.filter(
            quote => quote?.product?.id === quoteData?.product?.id,
          ).length
        }
      />
      <div
        css={`
          font-size: 11.5px;
          margin-left: 3px;
          font-weight: 700;
          ${mq.lg} {
            font-size: 11px;
            font-weight: 600;
          }
          ${mq.sm} {
            font-weight: 400;
          }
        `}
      >
        {matches ? "Compare" : "Add to Compare"}
      </div>
    </div>
  );
}
