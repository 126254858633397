import React, { memo, useState } from "react";
import { styled } from "@material-ui/core";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";

import TextInput from "./TextInput";
import { useEffect } from "react";
import moment from "moment";

const DateComponent = ({
  label,
  value,
  error,
  handleChange,
  maxDate,
  minDate,
  readOnly,
  ageWrapper,
  placeholder,
  dateView,
  format,
  isCCR,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [age, setAge] = useState(null);

  useEffect(() => {
    setSelectedDate(moment(value, "DD/MM/YYYY"));
  }, [value]);

  useEffect(() => {
    setAge(moment().diff(selectedDate, "years"));
  }, [selectedDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {isCCR ? (
        <CustomMobileDateField
          readOnly={readOnly}
          allowSameDateSelection
          maxDate={maxDate}
          minDate={minDate || moment("01/01/1900")}
          inputProps={{ placeholder: placeholder ? placeholder : "DD/MM/YYYY" }}
          value={selectedDate}
          views={dateView ? dateView : ["year", "month", "day"]}
          openTo="year"
          onChange={newDate => {
            setSelectedDate(newDate);
            handleChange(newDate?.format("DD/MM/YYYY"));
          }}
          onAccept={newDate => {
            setSelectedDate(newDate);
            handleChange(newDate?.format("DD/MM/YYYY"));
          }}
          inputFormat={format ? format : "DD/MM/YYYY"}
          renderInput={params => (
            <>
              <TextInput
                {...params}
                label={label}
                error={error}
                age={ageWrapper ? age : 0}
              />
            </>
          )}
          {...props}
        />
      ) : (
        <CustomDateField
          readOnly={readOnly}
          allowSameDateSelection
          maxDate={maxDate}
          minDate={minDate || moment("01/01/1900")}
          inputProps={{ placeholder: placeholder ? placeholder : "DD/MM/YYYY" }}
          value={selectedDate}
          views={dateView ? dateView : ["year", "month", "day"]}
          openTo="year"
          onChange={newDate => {
            setSelectedDate(newDate);
            handleChange(newDate?.format("DD/MM/YYYY"));
          }}
          onAccept={newDate => {
            setSelectedDate(newDate);
            handleChange(newDate?.format("DD/MM/YYYY"));
          }}
          inputFormat={format ? format : "DD/MM/YYYY"}
          renderInput={params => (
            <>
              <TextInput
                {...params}
                label={label}
                error={error}
                age={ageWrapper ? age : 0}
              />
            </>
          )}
          {...props}
        />
      )}
    </LocalizationProvider>
  );
};
export default memo(DateComponent);

const CustomDateField = styled(DatePicker)({});
const CustomMobileDateField = styled(MobileDatePicker)({});
