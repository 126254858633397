import { createSlice } from "@reduxjs/toolkit";
import { getKnowMoreDetails } from "../../services/QuotesService";
const quote = createSlice({
  name: "quotes",
  initialState: {
    selectedQuote: {},
    sortBy: "Low To High",
    quotes: {},
    differentQuotes: {},
    quotesLoading: {},
    knowMoreData: {
      additionalBenefits: [],
      aboutCompany: [],
      planDetails: [],
      otherDetails: {},
      documents: {},
      documentData: {},
    },
    showDifferentQuotes: false,
    isLoading: false,
    isDiabetic: false,
    addedRiders: {},
    ridersList: {},
    selectedQuotesToShare: [],
    shareQuotesCanvas: [],
    ShortListedPlan: [],
    ShortListedPlansData: [],
    ShortListedPlanTemp: [],
    addedShortNames: { data: [], lastFetched: {} },
    shortNameToRemove: "",
    showMoreQuotes: [],
    showDetails: false,
  },
  reducers: {
    replaceQuotes: (state, { payload }) => {
      state.showDifferentQuotes = false;
      state.quotes = payload;
      state.differentQuotes = payload;
    },
    clearQuotes: state => {
      state.quotes = [];
      state.differentQuotes = [];
    },
    saveQuotes: (state, { payload }) => {
      state.quotes = { ...state.quotes, ...payload };
    },
    saveDifferentQuotes: (state, { payload }) => {
      state.differentQuotes = { ...state.differentQuotes, ...payload };
    },
    setShowDifferentQuotes: (state, { payload }) => {
      state.showDifferentQuotes = payload;
    },
    updateRidersCollection: (state, { payload }) => {
      state.ridersList = { ...state.ridersList, ...payload };
    },
    setSelectedQuote: (state, { payload }) => {
      state.selectedQuote = payload;
    },
    setSelectedQuotesToShare: (state, { payload }) => {
      state.selectedQuotesToShare = payload;
    },
    setShareQuotesCanvas: (state, { payload }) => {
      state.shareQuotesCanvas = payload;
    },
    setShortListedPlan: (state, { payload }) => {
      state.ShortListedPlan = [...state.ShortListedPlan, payload];
      // if (
      //   state.ShortListedPlansData.length === 0 ||
      //   (state.ShortListedPlan.length > 0 &&
      //     state.ShortListedPlan.some((item) => item === payload) === false)
      // ) {
      //   state.ShortListedPlan.push(payload);
      // }
      // return state;
    },
    setAddShowMoreQuotes: (state, { payload }) => {
      state.showMoreQuotes.push(payload);
      return state;
    },
    setRemoveShowMore: (state, { payload }) => {
      state.showMoreQuotes = state.showMoreQuotes.filter(
        item => item !== payload,
      );
      return state;
    },

    setShortListedPlansData: (state, { payload }) => {
      if (
        state.ShortListedPlansData.length === 0 ||
        (state.ShortListedPlansData.length > 0 &&
          state.ShortListedPlansData.some(
            item => item?.product.id === payload?.product.id,
          ) === false)
      ) {
        state.ShortListedPlansData.push(payload);
      }
      return state;
    },
    removeShortListedPlan: (state, { payload }) => {
      state.ShortListedPlan = state.ShortListedPlan.filter(item => {
        return item !== payload;
      });
    },
    removeShortListedPlansData: (state, { payload }) => {
      state.ShortListedPlansData = state.ShortListedPlansData.filter(item => {
        return item?.product.id !== payload;
      });
    },
    setShortListedPlanTemp: (state, { payload }) => {
      if (
        state.ShortListedPlanTemp.length === 0 ||
        (state.ShortListedPlanTemp.length > 0 &&
          state.ShortListedPlanTemp.some(
            item => item?.product.id === payload?.product.id,
          ) === false)
      ) {
        state.ShortListedPlanTemp.push(payload);
      }
      return state;
    },
    removeShortListedPlanTemp: (state, { payload }) => {
      state.ShortListedPlanTemp = state.ShortListedPlanTemp.filter(item => {
        return item?.product.id !== payload;
      });
    },
    documentDataDetails: (state, { payload }) => {
      state.documentData = payload;
    },
    clearShortListedPlansData: state => {
      state.ShortListedPlansData = [];
      state.ShortListedPlan = [];
      return state;
    },
    setQuotesLoading: (state, { payload }) => {
      state.quotesLoading = { ...state.quotesLoading, ...payload };
    },
    setKnowMoreData: (state, { payload }) => {
      const {
        // lastFetched,
        aboutCompany,
        additionalBenefits,
        // data,
        otherDetails,

        planDetails,
        documents,
      } = payload;
      // state.knowMoreData.lastFetched = lastFetched;
      state.knowMoreData.aboutCompany = aboutCompany;
      state.knowMoreData.additionalBenefits = additionalBenefits;
      // state.knowMoreData.data = data;
      state.knowMoreData.otherDetails = otherDetails;

      state.knowMoreData.planDetails = planDetails;
      state.knowMoreData.documents = documents;
    },
    setShowGetDetails: (state, { payload }) => {
      state.showDetails = payload;
    },
    setDiabeticTrue: state => {
      state.isDiabetic = true;
    },
    setDiabeticFalse: state => {
      state.isDiabetic = false;
    },

    updateRiderShortName: (state, { payload }) => {
      state.addedShortNames.data = payload.data;
      state.addedShortNames.lastFetched = payload.company_alias;
    },
    updateRiderListPremium: (state, { payload }) => {
      state?.ridersList[payload?.productId]?.forEach((item, index) => {
        if (item?.rider_id === payload?.rider_id) {
          state.ridersList[payload?.productId][index] = payload?.data;
        }
      });
      state?.addedRiders[payload?.productId]?.forEach((item, index) => {
        if (item?.rider_id === payload?.rider_id) {
          state.addedRiders[payload?.productId][index].total_premium =
            payload?.data.net_premium;
        }
      });
    },
    insertAddedRiders: (state, { payload }) => {
      state.addedRiders = {
        ...state.addedRiders,
        [payload.productId]: [
          ...(state.addedRiders[payload.productId]
            ? state.addedRiders[payload.productId]
            : []),
          payload.rider,
        ],
      };
    },
    removeShortName: (state, { payload }) => {
      state.shortNameToRemove = payload;
      state.addedShortNames.data = state.addedShortNames.data.filter(
        item => item !== payload,
      );
    },
    removeAddedRiders: (state, { payload }) => {
      state.addedRiders = {
        ...state.addedRiders,
        [payload.productId]: [
          ...(state.addedRiders[payload.productId]
            ? state.addedRiders[payload.productId].filter(
                item => item.id !== payload.id,
              )
            : []),
        ],
      };
    },

    clearKnowMoreData: state => {
      state.knowMoreData.additionalBenefits = [];
      state.knowMoreData.aboutCompany = [];
      state.knowMoreData.planDetails = [];
      state.knowMoreData.otherDetails = {};
      state.knowMoreData.documents = {};
    },
  },
});

export const {
  setAddShowMoreQuotes,
  removeAddedRiders,
  removeShortName,
  setRemoveShowMore,
  setShortListedPlan,
  setSelectedQuotesToShare,
  setShareQuotesCanvas,
  removeShortListedPlan,
  saveQuotes,
  saveDifferentQuotes,
  setKnowMoreData,
  clearKnowMoreData,
  setShowDifferentQuotes,
  updateRidersCollection,
  replaceQuotes,
  clearQuotes,
  updateRiderShortName,
  updateRiderListPremium,
  insertAddedRiders,
  setSelectedQuote,
  setShortListedPlansData,
  removeShortListedPlansData,
  setShortListedPlanTemp,
  removeShortListedPlanTemp,
  clearShortListedPlansData,
  setQuotesLoading,
  setShowGetDetails,
  setDiabeticFalse,
  setDiabeticTrue,
  documentDataDetails,
} = quote.actions;

export const fetchKnowMoreData = productId => {
  return async dispatch => {
    try {
      const { data } = await getKnowMoreDetails(productId);
      if (data?.document) {
        dispatch(documentDataDetails(data?.document));
      }
      dispatch(setShowGetDetails(true));
      const notIncludeInPlanDetails = ["Additional Benefits", "About Company"];
      const additionalBenefits = data?.know_more?.[0]?.find(
        item => item?.feature_key === "Additional Benefits",
      )?.feature_values;

      const aboutCompany = data?.know_more?.[0]?.find(
        item => item?.feature_key === "About Company",
      )?.feature_values;
      const planDetails = data?.know_more?.[0]?.filter(
        item => !notIncludeInPlanDetails.includes(item?.feature_key),
      );
      const otherDetails = data?.know_more?.[1]?.values;
      dispatch(
        setKnowMoreData({
          // lastFetched: productId,
          // data: data.know_more,
          additionalBenefits,
          // claimProcess,
          aboutCompany,
          planDetails,
          otherDetails,
          documents: data?.document,
        }),
      );
    } catch (err) {
      console.error(err);
    }
  };
};

export default quote.reducer;

export const selectQuotes = state => state.quotes.quotes;
export const selectDifferentQuotes = state => state.quotes.differentQuotes;
export const selectSortBy = state => state.quotes.sortBy;
export const selectShortListedPlan = state => state.quotes.ShortListedPlan;
export const selectShortListedPlanData = state =>
  state.quotes.ShortListedPlansData;
export const selectSelectedQuotesToShare = state =>
  state.quotes.selectedQuotesToShare;
export const selectShortListedPlanTemp = state =>
  state.quotes.ShortListedPlanTemp;
