import { useDispatch } from "react-redux";
import { useGetCompareQuotePremiumQuery } from "../../rider/services/rider";
import { useEffect } from "react";
import {
  removeQuoteInList,
  setIsPremiumLoading,
  setSelectedQuoteToBuy,
  updateQuoteInList,
} from "../slice/newCompare.slice";
import { toast } from "react-toastify";
import {
  fetchKnowMoreData,
  setSelectedQuote,
} from "../../QuotesPage/Quotes.slice";
import { updateEnquiries } from "../../Landing/input.slice";

const useCompareTableQuote = ({ quote, i, setShowGetDetailsPopup }) => {
  const dispatch = useDispatch();
  const {
    product,
    insuranceCompany,
    investmentAmount,
    frequency,
    investFor,
    returnsAfter,
    incomeFor,
    getMoneyAs,
  } = quote;

  const { data, isLoading } = useGetCompareQuotePremiumQuery({
    company_alias: insuranceCompany?.code,
    product_id: product?.code,
    get_money_as: getMoneyAs,
    investment_amount: +investmentAmount,
    pay_term: +investFor?.code,
    policy_term: +returnsAfter?.code,
    income_for: +incomeFor?.code,
    pay_frequency: frequency?.code,
  });

  useEffect(() => {
    dispatch(setIsPremiumLoading(isLoading));
  }, [isLoading, dispatch]);

  useEffect(() => {
    data?.data?.[0] &&
      dispatch(
        updateQuoteInList({
          i,
          quote: {
            ...quote,
            totalPremium: data?.data?.[0]?.total_premium,
            termInsuranceId: data?.data?.[0]?.term_insurance_id,
          },
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data, dispatch]);

  if (data?.data?.length === 0) {
    dispatch(removeQuoteInList(i));
    toast.error("This Combination is not available !!", {
      position: "top-right",
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: false,
      className: "toast-message",
    });
  }

  const {
    irr_value: irrValue,
    income_benfit: incomeBenefit,
    maturity_benefit: maturityBenefit,
  } = data?.data?.[0] || {};

  const { investment_insurance_id, pay_term, income_for, policy_term } =
    data?.data?.[0] || {};

  const handleGetDetails = () => {
    dispatch(
      setSelectedQuote({
        ...data?.data?.[0],
        frequency: frequency?.code,
        getMoneyAs,
      }),
    );
    dispatch(
      updateEnquiries({
        input: {
          selected_product: {
            investment_insurance_id,
            product_id: product?.code,
          },
          pay_term,
          policy_term,
          income_for,
        },
      }),
    );
    dispatch(fetchKnowMoreData(product?.code));
    dispatch(setSelectedQuoteToBuy(data?.data?.[0]));
    setShowGetDetailsPopup(true);
  };
  return {
    handleGetDetails,
    irrValue,
    incomeBenefit,
    maturityBenefit,
    isLoading,
    data,
  };
};

export default useCompareTableQuote;
