import React from "react";
import styled from "styled-components/macro";
// import ThreeBlueCircle from "../../../../assets/images/ThreeBlueCircle.svg";
// import CircleOrangeDot from "../../../../assets/images/CircleOrangeDot.svg";

const OverlayCalFirst = () => {
  const OverlayData = [
    {
      parameters: "Structure",
      fixed_deposit: "The Structure Of Fixed Deposits Is Same Across Banks.",
      guranted:
        "The Structure Of The Guaranteed Returns Plans Is Not The Same Across Insurers And Customers Have A Wide Array Of Payout Options To Choose From.",
    },
    {
      parameters: "USP",
      fixed_deposit: "A Fixed Deposit offers guaranteed returns.",
      guranted:
        "Savings-oriented life insurance plans in addition to helping one to save forlong-term goals also provides life cover that is as high as up to 10 times the annual premium paid.",
    },
    {
      parameters: "Tenure",
      fixed_deposit:
        "Fixed deposits are suitable for long-term as well as short pay term investment ranging from 1 year- 5 years.",
      guranted:
        "Guaranteed Savings plan not just guarantee returns for 5 or 10 years but up to a maximum of 40-45 years -rightly catering to the reinvestment risk.",
    },
    {
      parameters: "Invesment",
      fixed_deposit:
        "You Can Start Investing In Fixed Deposits With A Minimum Sum Of Rs.1,000 Whereas, There Is No Limit On The Maximum Investment.",
      guranted:
        "The premium of the policy differs from plans to plans and is determined based on various different factors such as the age of the policyholder, policy value, health condition of the insured, etc.",
    },
    {
      parameters: "Returns",
      fixed_deposit:
        "Fixed Deposit offers fixed return on investment as it is mentioned in the beginning  when you open the fixed deposit accounts.",
      guranted:
        "The Income Plan offers you a guaranteed and regular flow of income so that you can meet your monthly/annual expenses. Also, investment in savings plan such as guaranteed return policy offers assured and higher returns to the investors as compared to FD.",
    },
    {
      parameters: "Withdrawal",
      fixed_deposit:
        "Fixed Deposits offer the option of partial withdrawal. However, breaking FD account before the completion of the maturity period affects the interest rate of investment and results in low ROI.",
      guranted:
        "The Guaranteed Returns Plan offers a withdrawal after the completion of the policy lock-in period of 3 years.",
    },
    {
      parameters: "Payout",
      fixed_deposit:
        "You can take the payout amount as a lump sum at the end of the policy period.",
      guranted:
        "You can take the payout as monthly income or annual income or lump-sum of the entire corpus.",
    },
    {
      parameters: "Taxation",
      fixed_deposit:
        "Fixed Deposits, in most circumstances, do not offer any tax advantages.You can, however, invest for a five-year period in tax-saving fixed deposits and get tax benefits under section 80C.",
      guranted:
        "Most life insurance products follow exempt-exempt-exempt (EEE) taxation. You can obtain a tax benefit on the premiums paid and the maturity benefits.",
    },
  ];

  return (
    <>
      <OvarlayContainerMain>
        <OverlayContainer>
          <OverlayHead>
            <HeadTextParmameters>Parameters</HeadTextParmameters>
            <HeadTextFixed>Fixed Deposits</HeadTextFixed>
            <HeadTextGuaranteed>Guaranteed Returns Plan</HeadTextGuaranteed>
          </OverlayHead>
          {OverlayData.map((item, i) => {
            return (
              <>
                <OverlayBody key={i}>
                  <HeadContent>
                    <HeadText>{item.parameters}</HeadText>
                    {/* <Image1 /> */}
                  </HeadContent>
                  <BodyText1>
                    <Text>{item.fixed_deposit}</Text>
                    {/* <Image2 /> */}
                  </BodyText1>
                  <BodyText>
                    <Text1>{item.guranted}</Text1>
                  </BodyText>
                </OverlayBody>
              </>
            );
          })}
        </OverlayContainer>
      </OvarlayContainerMain>
    </>
  );
};

export default OverlayCalFirst;

const OvarlayContainerMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
`;
const OverlayContainer = styled.div`
  width: 96rem;
  display: flex;
  flex-direction: column;
`;
const OverlayHead = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HeadText = styled.div`
  font-weight: 600;
  font-size: 16px;
  display: flex;
  flex: 1;
`;
const HeadTextParmameters = styled.div`
  font-weight: 600;
  font-size: 18px;
  display: flex;
  flex: 1;
  text-decoration: underline;
`;

const HeadTextGuaranteed = styled.div`
  font-weight: 600;
  font-size: 18px;
  display: flex;
  flex: 1.2;
  text-decoration: underline;
`;

const HeadTextFixed = styled.div`
  display: flex;
  flex: 1.2;
  justify-content: start;
  align-items: center;
  font-weight: 600;
  font-size: 18px;

  text-decoration: underline;
`;
const HeadContent = styled.div`
  display: flex;
  flex: 1;
`;
const Text = styled.div`
  font-size: 15px;
  width: 310px;
`;
const Text1 = styled.div`
  font-size: 15px;
`;

const BodyText1 = styled.div`
  display: flex;
  flex: 1.2;
  justify-content: space-between;
  align-items: start;
`;

const BodyText = styled.div`
  display: flex;
  flex: 1.2;
  justify-content: start;
  align-items: start;
`;

const OverlayBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  margin-top: 12px;
`;

// const Image1 = styled.img`
//   margin-right: 4em;
//   content: url(${ThreeBlueCircle});
// `;
// const Image2 = styled.img`
//   margin-right: 4rem;
//   top: 0px;
//   content: url(${CircleOrangeDot});
// `;
